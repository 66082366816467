export const services = [
  {
    label: 'Counseling on Family Planning',
    id: 'counselling_on_family_planning'
  },
  {
    label: 'Provision of Combined oral contraceptive pills',
    id: 'combined_oral_contraceptive_pills_provision'
  },
  {
    label: 'Provision of Progestin only contraceptive pills',
    id: 'progestin_only_contraceptive_pills_provision'
  },
  {
    label: 'Provision of Progestin only injectable contraceptives',
    id: 'progestin_only_injectable_contraceptives_provision'
  },
  {
    label: 'Provision of Male condom',
    id: 'male_condom_provision'
  },
  {
    label: 'Intrauterine device insertion',
    id: 'intrauterine_device_insertion'
  },
  {
    label: 'Subdermal Implants with anesthesia',
    id: 'subdermal_implants'
  },
  {
    label: 'Provision of Cycle beads for standard days method',
    id: 'cycle_beads_for_standard_days_method_provision'
  }
];

export const guidelines = [
  {
    label: 'FP Clinical Standards Manual (2014 Edition)',
    id: 'fp_clinical_standards_manual_2014_edition'
  },
  {
    label: 'FP Form 1',
    id: 'fp_form1'
  }
];

export const trainings = [
  {
    label: 'Basic Family Planning or Family Planning Competency-Based Training Level 1',
    id: 'family_planning_training'
  },
  {
    label: 'Comprehensive FP Training or FPCBT Level 2',
    id: 'fp_or_fpcbt_level2_training'
  },
  {
    label: 'No-Scalpel Vasectomy',
    id: 'no_scalpel_vasectomy_training'
  },
  {
    label: 'Bilateral Tubal Ligation-Mini-Laparotomy',
    id: 'bilateral_tubal_ligation_mini_laparotomy'
  },
  {
    label: 'Visual Inspection using Acetic Acid (VIA)',
    id: 'via_training'
  }
];

export const observations = [
  {
    label: 'Individual records maintained for planning clients',
    id: 'individual_records_for_family_planning_clients'
  }
];
