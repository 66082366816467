import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { clearErrors } from 'src/actions';

const Alert = (props) => (
  <MuiAlert elevation={6} {...props} />
);

const SnackbarAlert = (props) => {
  const [state, setState] = React.useState({
    open: false,
    vertical: 'bottom',
    horizontal: 'center',
    message: '',
    severity: 'success'
  });

  const {
    vertical,
    horizontal,
    open,
    message,
    severity
  } = state;
  const { errors, success } = props;

  useEffect(() => {
    if (errors) {
      errors.map((error, key) => (
        setState({
          ...state,
          open: true,
          message: errors[key],
          severity: 'error'
        })
      ));
    }
  }, [errors]);

  useEffect(() => {
    if (success) {
      setState({
        ...state,
        open: true,
        message: success,
        severity: 'success'
      });
    }
  }, [success]);

  const handleClose = (event, reason) => {
    props.clearErrors();
    if (reason === 'clickaway') {
      return;
    }
    setState({ ...state, open: false });
  };

  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <span>
          <Alert onClose={handleClose} severity={severity}>
            { message === 'token_not_valid'
              ? 'Your session has expired. Please log in.'
              : message }
          </Alert>
        </span>
      </Snackbar>
    </div>
  );
};

const mapStateToProps = (state) => ({
  errors: Object.values(state.messages.errors),
  success: state.messages.success
});

export default connect(
  mapStateToProps, { clearErrors }
)(SnackbarAlert);
