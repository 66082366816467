import React from 'react';
import { Box, TableContainer } from '@mui/material/';
import {
  procedures,
  guidelines,
  trainings
} from 'src/constants/fields/section4/newbornCare';
import YesNoTable from '../tables/YesNoTable';

const Section404 = (props) => {
  const { provinces, data } = props;

  return (
    <TableContainer>
      <YesNoTable
        ariaLabel="4.4 newborn care procedures"
        provinces={provinces}
        rows={procedures}
        response={data}
        title="Procedures routinely performed by providers of newborn care"
        sectionKey="newborn_care"
      />
      <Box sx={{ height: 40 }} />
      <YesNoTable
        ariaLabel="4.4 newborn care guidelines"
        provinces={provinces}
        rows={guidelines}
        response={data}
        title="National guidelines, job-aids, checklist, charts for newborn care"
        sectionKey="newborn_care"
      />
      <Box sx={{ height: 40 }} />
      <YesNoTable
        ariaLabel="4.5 newborn care trained personnels"
        provinces={provinces}
        rows={trainings}
        response={data}
        title="Procedures routinely performed by providers of newborn care"
        sectionKey="newborn_care"
      />
    </TableContainer>
  );
};

export default Section404;
