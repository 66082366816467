export default {
  adult_weighing_scale_available: null,
  adult_weighing_scale_functioning: null,
  infant_weighing_scale_available: null,
  infant_weighing_scale_functioning: null,
  non_mercury_sphygmomanometer_available: null,
  non_mercury_sphygmomanometer_functioning: null,
  stethoscope_for_adults_available: null,
  stethoscope_for_adults_functioning: null,
  stethoscope_for_pediatric_use_available: null,
  stethoscope_for_pediatric_use_functioning: null,
  delivery_table_with_pail_available: null,
  delivery_table_with_pail_functioning: null,
  newborn_resuscitation_table_available: null,
  newborn_resuscitation_table_functioning: null,
  examination_or_patient_bed_available: null,
  examination_or_patient_bed_functioning: null,
  delivery_pack_available: null,
  delivery_pack_functioning: null,
  bandage_scissors_available: null,
  bandage_scissors_functioning: null,
  thumb_forceps_available: null,
  thumb_forceps_functioning: null,
  umbilical_cord_scissors_available: null,
  umbilical_cord_scissors_functioning: null,
  uterine_sound_available: null,
  uterine_sound_functioning: null,
  disposable_delivery_kit_available: null,
  disposable_delivery_kit_functioning: null,
  instrument_tray_with_cover_available: null,
  instrument_tray_with_cover_functioning: null,
  kidney_basin_available: null,
  kidney_basin_functioning: null,
  instrument_cabinet_available: null,
  instrument_cabinet_functioning: null,
  instrument_table_available: null,
  instrument_table_functioning: null,
  fetal_doppler_available: null,
  fetal_doppler_functioning: null,
  vaginal_speculum_available: null,
  vaginal_speculum_functioning: null,
  sponge_forceps_available: null,
  sponge_forceps_functioning: null,
  suction_apparatus_available: null,
  suction_apparatus_functioning: null,
  suction_catheter_available: null,
  suction_catheter_functioning: null,
  rubber_suction_bulb_available: null,
  rubber_suction_bulb_functioning: null,
  ambu_bag_available: null,
  ambu_bag_functioning: null,
  o2_tank_with_regulator_and_humidifier_available: null,
  o2_tank_with_regulator_and_humidifier_functioning: null,
  cone_mask_available: null,
  cone_mask_functioning: null,
  newborn_size_mask_0_and_1_available: null,
  newborn_size_mask_0_and_1_functioning: null,
  portable_nebulizer_available: null,
  portable_nebulizer_functioning: null,
  rx_box_available: null,
  rx_box_functioning: null,
  examination_light_available: null,
  examination_light_functioning: null,
  romm_thermometer_available: null,
  romm_thermometer_functioning: null,
  iv_stand_available: null,
  iv_stand_functioning: null,
  non_mercury_thermometer_available: null,
  non_mercury_thermometer_functioning: null,
  tape_measure_available: null,
  tape_measure_functioning: null,
  storage_containers_with_cove_for_dry_cotton_balls_available: null,
  storage_containers_with_cove_for_dry_cotton_balls_functioning: null,
  jar_available: null,
  jar_functioning: null,
  wall_clock_with_seconds_hand_available: null,
  wall_clock_with_seconds_hand_functioning: null,
  slippers_for_delivery_area_available: null,
  slippers_for_delivery_area_functioning: null
};
