export const services = [
  {
    label: 'Antenatal physical examination + Leopold maneuver and fundic height measurement',
    id: 'antenatal_physical_examination'
  },
  {
    label: 'Iron and Folic Acid Supplementation',
    id: 'iron_folic_acid_supplementation'
  },
  {
    label: 'Calcium Carbonate',
    id: 'calcium_carbonate'
  },
  {
    label: 'Basic Oral Health Care',
    id: 'basic_oral_health_care'
  },
  {
    label: 'Tetanus Toxoid vaccination / Tetnus-diphtheria (Td) vaccination',
    id: 'tetanus_vaccination'
  },
  {
    label: 'Monitoring for Hypertensive Disorder of Pregnancy',
    id: 'monitoring_for_hypertensive_disorder_of_pregnancy'
  },
  {
    label: 'Health education on the danger signs of pregnancy and importance of 4 prenatal visits',
    id: 'counseling_on_pregnancy_dangers'
  },
  {
    label: 'Health education on having a birth plan and facility-based delivery',
    id: 'counseling_on_having_a_birth_plan_and_facility_based_delivery'
  },
  {
    label: 'Health education regarding Newborn Screening (Blood and Hearing)',
    id: 'health_education_on_newborn_screening'
  },
  {
    label: 'Health education on Family Planning',
    id: 'counseling_on_family_planning'
  },
  {
    label: 'Health education on Breastfeeding',
    id: 'counseling_on_breastfeeding'
  },
  {
    label: 'Health education on sexually transmitted infections from mother to child, including HIV',
    id: 'counseling_on_sti_from_mother_to_child'
  },
  {
    label: 'Mental Health Screening',
    id: 'mental_health_screening'
  }
];

export const schedule = {
  label: 'Schedules for delivery of antenatal care services',
  id: 'antenatal_care_services_schedule'
};

export const guidelines = [
  {
    label: 'QAP Manual / PCNPC Manual',
    id: 'qap_manual_or_pcpnc_manual'
  },
  {
    label: 'Mother-Baby Book',
    id: 'mother_baby_book'
  },
  {
    label: 'Maternal Health Record',
    id: 'maternal_health_record'
  }
];

export const trainings = [
  {
    label: 'QAP Training / PCPNC Training',
    id: 'qap_or_pcpnc_training'
  },
  {
    label: 'Interpersonnal Communication Skills',
    id: 'ics_training'
  },
  {
    label: 'Community Tracking Tool',
    id: 'community_tracking_tool_training'
  },
  {
    label: 'Supportive Suppervision',
    id: 'supportive_supervision_training'
  },
  {
    label: 'Lactation Management Training',
    id: 'lactation_management_training'
  },
  {
    label: 'Diagnosis and Treatment of Malaria in Pregnancy',
    id: 'diagnosis_and_treatment_of_malaria_in_pregnancy_training'
  },
  {
    label: 'Diagnosis and/or Treatment of STI, excluding HIV',
    id: 'diagnosis_treatment_of_sti'
  },
  {
    label: 'HIV Counseling',
    id: 'hiv_counseling_training'
  },
  {
    label: 'Newborn Screening',
    id: 'newborn_screening_training'
  },
  {
    label: 'Hemoglobin Determination using Copper Sulfate',
    id: 'hemoglobin_determination_using_copper_sulfate_training'
  }
];

export const examinations = [
  {
    label: 'Hemoglobin determination',
    id: 'hemoglobin_determination'
  },
  {
    label: 'Urine dipstick test',
    id: 'urine_dipstick_test'
  },
  {
    label: 'Urinalysis',
    id: 'urinalysis'
  },
  {
    label: 'Urine glucose',
    id: 'urine_glucose_exam'
  },
  {
    label: 'Blood glucose',
    id: 'blood_glucose_exam'
  },
  {
    label: 'Blood typing',
    id: 'blood_typing'
  },
  {
    label: 'Malaria rapid diagnostic test',
    id: 'malaria_rapid_diagnostic_test'
  },
  {
    label: 'Syphilis rapid diagnostic test',
    id: 'syphilis_rapid_diagnostic_test'
  },
  {
    label: 'Hepatitis B diagnostic test',
    id: 'hepatitis_b_diagnostic_test'
  },
  {
    label: 'HIV rapid diagnostic test',
    id: 'hiv_rapid_diagnostic_test'
  }
];

export const bhw = [
  {
    label: 'Active BHWs trained on Interpersonal Communication Skills',
    id: 'bhws_trained_in_ics_count'
  },
  {
    label: 'Active BHW trained on Community Tracking Tool',
    id: 'bhws_trained_in_community_tracking_tool_count'
  }
];

export const records = [
  {
    label: 'Maternal Health Record maintained',
    id: 'individual_client_records_are_maintained'
  },
  {
    label: 'Use any form of pregnancy tracking tool',
    id: 'pregnancy_tracking_tools_are_used'
  },
];
