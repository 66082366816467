import _ from 'lodash';
import {
  ADD_USER,
  AUTH_ERROR,
  EDIT_USER,
  FETCH_USER,
  FETCH_USERS,
  SIGN_OUT,
} from '../actions/types';

const usersReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_USER:
    case EDIT_USER:
    case FETCH_USER:
      return {
        ...state,
        [action.payload.id]: action.payload
      };

    case FETCH_USERS:
      return {
        ..._.mapKeys(action.payload, 'id')
      };

    case AUTH_ERROR:
    case SIGN_OUT:
      return {};

    default:
      return state;
  }
};

export default usersReducer;
