export default {
  vitamin_k_ampules_available: null,
  hepatitis_b_vaccine_available: null,
  bcg_vaccine_available: null,
  erythromycin_eye_ointment_available: null,
  injectable_ampicillin_available: null,
  gentamicin_available: null,
  amikacin_available: null,
  gential_violet_available: null,
  sterile_water_available: null,
  clean_gloves_available: null,
  dry_cotton_balls_or_swabs_available: null,
  newborn_screening_card_or_kit_available: null,
  newborn_screening_filter_available: null,
  sterile_lancets_3mm_available: null,
  warm_moist_towel_available: null,
  vitamin_k_ampules_rso: null,
  hepatitis_b_vaccine_rso: null,
  bcg_vaccine_rso: null,
  erythromycin_eye_ointment_rso: null,
  injectable_ampicillin_rso: null,
  gentamicin_rso: null,
  amikacin_rso: null,
  gential_violet_rso: null,
  sterile_water_rso: null,
  clean_gloves_rso: null,
  dry_cotton_balls_or_swabs_rso: null,
  newborn_screening_card_or_kit_rso: null,
  newborn_screening_filter_rso: null,
  sterile_lancets_3mm_rso: null,
  warm_moist_towel_rso: null
};
