import {
  CLEAR_MESSAGES,
  DONE_LOADING,
  LOADING_DATA,
  SIGN_IN,
  SUBMITTING_DATA,
  THROW_ERROR,
  THROW_MODAL_SUCCESS,
  THROW_SUCCESS
} from 'src/actions/types';

const INITIAL_STATE = {
  errors: {},
  success: '',
  showModal: false,
  payload: null,
  isSubmitting: false,
  isLoading: false,
};

const messageReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case THROW_ERROR:
      return {
        ...state,
        errors: action.payload,
        isLoading: false,
        isSubmitting: false
      };

    case THROW_SUCCESS:
      return {
        ...state,
        success: action.payload,
        isSubmitting: false
      };

    case THROW_MODAL_SUCCESS:
      return {
        ...state,
        showModal: true,
        payload: action.payload,
        isSubmitting: false
      };

    case CLEAR_MESSAGES:
      return {
        ...state,
        errors: {},
        success: '',
        showModal: false,
      };

    case SUBMITTING_DATA:
      return {
        ...state,
        isSubmitting: true,
        payload: action.payload
      };

    case SIGN_IN:
      return {
        ...state,
        isSubmitting: false
      };

    case LOADING_DATA:
      return {
        ...state,
        isLoading: true,
        payload: action.payload
      };

    case DONE_LOADING:
      return {
        ...state,
        isLoading: false,
        payload: action.payload
      };

    default: {
      return {
        ...state
      };
    }
  }
};

export default messageReducer;
