export const fields = [
  {
    label: 'Sign bearing the name of facilities',
    id: 'has_sign_with_facility_name'
  },
  {
    label: 'Sign indicating PhilHealth provider',
    id: 'has_sign_indicating_philhealth_membership'
  },
  {
    label: 'Sign enumerating services available',
    id: 'has_sign_with_list_of_services'
  },
  {
    label: 'Clean environment',
    id: 'is_generally_clean_inside'
  },
  {
    label: 'Visible sides prohibiting smoking',
    id: 'has_no_smoking_signs'
  },
  {
    label: 'Sufficient seating for clients in a well-ventilated area',
    id: 'has_sufficient_seating_in_ventilated_area'
  },
  {
    label: 'Adequate lighting / Naturally well-lighted',
    id: 'has_good_lighting'
  },
  {
    label: 'Adequate clean water supply from a reliable source',
    id: 'has_adequate_clean_water_supply_from_reliable_source'
  },
  {
    label: 'Consultation / Examination room / Cubicle with auditory and visual privacy',
    id: 'has_consultation_area_with_privacy'
  },
  {
    label: 'Delivery Room with area for cleaning/resuscitation of newborn',
    id: 'has_delivery_room_for_newborn_cleaning_or_resuscitation'
  },
  {
    label: 'Area for cleaning instruments',
    id: 'has_area_for_cleaning_instruments'
  },
  {
    label: 'Room for patients awaiting delivery or while recovering',
    id: 'has_delivery_waiting_or_recovery_room'
  },
  {
    label: 'Adequate signages (entrance, exits, laboratory, etc.)',
    id: 'has_adequate_signs'
  },
];

export const extraFields = [
  {
    label: 'Separate toilets for males and females',
    id: 'has_separate_toilets_for_males_and_females'
  },
  {
    label: 'Ramp entrance',
    id: 'has_ramp_entrace'
  },
  {
    label: 'Emergency escape plans in conspicuous places',
    id: 'has_emergency_exit_plans_in_conspicuous_places'
  }
];

export const inpatientToilet = {
  label: 'Functional toilet for in-patient use only',
  id: 'functional_toilet_for_inpatient_use'
};

export const outpatientToilet = {
  label: 'Functional toilet for out-patient use only',
  id: 'functional_toilet_for_outpatient_use'
};
