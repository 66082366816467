export default [
  {
    label: 'MNCHN Policy',
    id: 'mnchn_policy'
  },
  {
    label: 'Safe Motherhood',
    id: 'safe_motherhood_and_or_facility_based_deliveries'
  },
  {
    label: 'Contraceptive Self-Reliance Policy',
    id: 'contraceptive_self_reliance_policy'
  },
  {
    label: 'Breastfeeding and/or Milk Code',
    id: 'breastfeeding_or_milk_code'
  },
  {
    label: 'Newborn Screening',
    id: 'newborn_screening'
  },
  {
    label: 'PhilHealth enrollment of women about to give birth',
    id: 'philhealth_enrollment_for_expectant_women'
  },
  {
    label: 'COVID-19 Policies on Maternal Health',
    id: 'covid19_policies_on_maternal_health'
  }
];
