export default [
  {
    name: 'Infirmary',
    id: 'infirmary',
    value: 1
  },
  {
    name: 'Barangay Health Station',
    id: 'barangay_health_station',
    value: 2
  },
  {
    name: 'Safe Birthing Facility',
    id: 'safe_birthing_facility',
    value: 3
  },
  {
    name: 'Rural Health Unit',
    id: 'rural_health_unit',
    value: 5
  }
];
