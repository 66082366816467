import React from 'react';
import { connect } from 'react-redux';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material';

const RadioGroupFormControl = (props) => {
  const {
    disabled,
    isSubmitting,
    fieldName,
    fieldNameOther,
    fieldValue,
    fieldValueOther,
    handleChange,
    label,
    options,
    row,
    setFieldValue,
    subtitle
  } = props;

  return (
    <FormControl component="fieldset" fullWidth>
      <Grid container spacing={3} sx={{ py: 1 }}>
        <Grid item xs={12} sm={6} md={6}>
          <FormLabel component="legend">
            {label}
          </FormLabel>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Typography color="textSecondary" variant="subtitle1">
            {subtitle}
          </Typography>
          <RadioGroup
            disabled={isSubmitting}
            name={fieldName}
            onChange={(e, value) => {
              setFieldValue(fieldName, parseInt(value, 10), false);
            }}
            row={row}
            value={fieldValue}
          >
            {options.map((option) => (
              <div key={option.label}>
                <Box display="flex">
                  <FormControlLabel
                    control={<Radio size="small" />}
                    disabled={isSubmitting || disabled}
                    label={option.label}
                    value={option.value || ''}
                  />
                  {option.label === 'Other' && (
                    <TextField
                      disabled={isSubmitting || disabled}
                      name={fieldNameOther}
                      onChange={handleChange}
                      size="small"
                      value={fieldValueOther || ''}
                    />
                  )}
                </Box>
              </div>
            ))}
          </RadioGroup>
        </Grid>
      </Grid>
    </FormControl>
  );
};

const mapStateToProps = (state) => ({
  isSubmitting: state.messages.isSubmitting
});

export default connect(mapStateToProps)(RadioGroupFormControl);
