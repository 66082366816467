import React from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  IconButton,
  LinearProgress,
  Typography
} from '@mui/material';
import {
  Close as CloseIcon
} from '@mui/icons-material';
import AssignmentForm from './AssignmentForm';

const AssignmentModal = (props) => {
  const {
    assignments,
    facility,
    isSubmitting,
    open
  } = props;

  const assignment = assignments.find((assignment) => assignment.facility_id === facility.id);

  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <div>
      <Dialog
        maxWidth="xs"
        open={open}
        onClose={handleClose}
      >
        <DialogTitle sx={{ p: 1 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Box sx={{ p: 3 }}>
              <Typography variant="h4" gutterBottom>
                {facility.name}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                {assignment ? 'Assigned' : 'Pending Assignment'}
              </Typography>
            </Box>
            <IconButton
              onClick={() => handleClose()}
              sx={{ alignItems: 'flex-start', display: 'inline-flex', height: '100%' }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <AssignmentForm
            facility={facility}
            handleClose={handleClose}
            assessor={props.assessor}
            deadline={props.deadline}
            startDate={props.startDate}
            onSubmit={props.onSubmit}
            users={props.users}
            isSubmitting={isSubmitting}
          />
        </DialogTitle>
        {isSubmitting && <LinearProgress open />}
      </Dialog>
    </div>
  );
};

export default AssignmentModal;
