import React from 'react';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  LinearProgress,
  Typography
} from '@mui/material';
import {
  Edit as EditIcon,
  Save as SaveIcon
} from '@mui/icons-material';
import {
  BARANGAY_HEALTH_STATION,
  SAFE_BIRTHING_FACILITY,
  NUTRITION_POST,
  RURAL_HEALTH_UNIT,
  AUX_FACILITY
} from 'src/constants/facilityTypes';
import YesOrNoFormControl from '../formSections/YesOrNoFormControl';

const LiscensingAndCertification = (props) => {
  const {
    facilityType,
    isLoading,
    isSubmitting,
    liscensingAndCertification,
    readOnly,
    setReadOnly
  } = props;

  const initialValues = {
    ...liscensingAndCertification,
  };

  const handleEditClick = () => {
    setReadOnly(false);
  };

  const handleSaveClick = (handleSubmit) => {
    handleSubmit();
    setReadOnly(true);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => {
        props.onSubmit('licensing_and_certification', values);
      }}
    >
      {({
        handleSubmit,
        setFieldValue,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardContent>
              <Box className="form-header">
                <Typography variant="h4" gutterBottom>
                  3.1.1 Liscensing and Certification
                </Typography>
                {readOnly ? (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleEditClick()}
                  >
                    <EditIcon size="medium" />
                  </IconButton>
                ) : (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleSaveClick(handleSubmit)}
                  >
                    <SaveIcon size="medium" />
                  </IconButton>
                )}
              </Box>
            </CardContent>
            <Divider />
            {(facilityType !== BARANGAY_HEALTH_STATION && facilityType !== NUTRITION_POST && facilityType !== AUX_FACILITY) ? (
              <>
                <CardContent sx={{ p: 5 }}>
                  <Typography
                    color="textSecondary"
                    variant="body1"
                    sx={{ marginBottom: 3 }}
                  >
                    Does this facility have licensing and certification from the DOH and PhilHealth?
                  </Typography>
                  <Typography variant="h5">
                    Department of Health (DOH)
                  </Typography>
                  <Box sx={{ p: 2 }}>
                    {facilityType !== RURAL_HEALTH_UNIT && (
                      <>
                        <YesOrNoFormControl
                          disabled={readOnly}
                          label="BEMONC Certified Only"
                          fieldName="doh_bemonc_certified_only"
                          fieldValue={values.doh_bemonc_certified_only || false}
                          setFieldValue={setFieldValue}
                        />
                        <YesOrNoFormControl
                          disabled={readOnly}
                          label="BEMONC + License to Operate"
                          fieldName="doh_bemonc_plus_license_to_operate"
                          fieldValue={values.doh_bemonc_plus_license_to_operate || false}
                          setFieldValue={setFieldValue}
                        />
                      </>
                    )}
                    {facilityType === RURAL_HEALTH_UNIT && (
                      <YesOrNoFormControl
                        disabled={readOnly}
                        label="License to Operate"
                        fieldName="license_to_operate"
                        fieldValue={values.license_to_operate || false}
                        setFieldValue={setFieldValue}
                      />
                    )}
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Mother-Baby Friendly Facility"
                      fieldName="doh_mother_baby_friendly"
                      fieldValue={values.doh_mother_baby_friendly || false}
                      setFieldValue={setFieldValue}
                    />
                    {facilityType !== SAFE_BIRTHING_FACILITY && (
                      <YesOrNoFormControl
                        disabled={readOnly}
                        label="Adolescent-Friendly Facility (Level 1, 2, or 3)"
                        fieldName="doh_adolescent_friendly"
                        fieldValue={values.doh_adolescent_friendly || false}
                        setFieldValue={setFieldValue}
                      />
                    )}
                  </Box>
                  <span className="whitespace" />
                  {facilityType !== SAFE_BIRTHING_FACILITY && (
                    <>
                      <Typography variant="h5">
                        Philippine Health Insurance Corporation (PhilHealth)
                      </Typography>
                      <Box sx={{ p: 2 }}>
                        <YesOrNoFormControl
                          disabled={readOnly}
                          label="TSeKaP or PCB 1 (Primary Care Benefit 1) Package"
                          fieldName="philhealth_tsekap_or_pcb1_package"
                          fieldValue={values.philhealth_tsekap_or_pcb1_package || false}
                          setFieldValue={setFieldValue}
                        />
                        {facilityType !== RURAL_HEALTH_UNIT && (
                          <YesOrNoFormControl
                            disabled={readOnly}
                            label="MCP (Maternity Care Package)"
                            fieldName="philhealth_maternity_care_package"
                            fieldValue={values.philhealth_maternity_care_package || false}
                            setFieldValue={setFieldValue}
                          />
                        )}
                        <YesOrNoFormControl
                          disabled={readOnly}
                          label="TB-DOTS Package"
                          fieldName="philhealth_tb_dots_package"
                          fieldValue={values.philhealth_tb_dots_package || false}
                          setFieldValue={setFieldValue}
                        />
                        {facilityType !== RURAL_HEALTH_UNIT && (
                          <YesOrNoFormControl
                            disabled={readOnly}
                            label="Newborn Screening"
                            fieldName="philhealth_newborn_screening"
                            fieldValue={values.philhealth_newborn_screening || false}
                            setFieldValue={setFieldValue}
                          />
                        )}
                        <YesOrNoFormControl
                          disabled={readOnly}
                          label="Animal Bite Treatment Package"
                          fieldName="philhealth_animal_bite_treatment_package"
                          fieldValue={values.philhealth_animal_bite_treatment_package || false}
                          setFieldValue={setFieldValue}
                        />
                        <YesOrNoFormControl
                          disabled={readOnly}
                          label="Out-Patient Malaria Package"
                          fieldName="philhealth_outpatient_malaria_package"
                          fieldValue={values.philhealth_outpatient_malaria_package || false}
                          setFieldValue={setFieldValue}
                        />
                      </Box>
                    </>
                  )}
                </CardContent>
                <Divider />
                <Box className="box-button">
                  <Button
                    disabled={isLoading || isSubmitting || !readOnly}
                    color="primary"
                    onClick={() => handleEditClick()}
                    variant="contained"
                    sx={{ mx: 2 }}
                  >
                    Edit
                  </Button>
                  <Button
                    disabled={isLoading || isSubmitting || readOnly}
                    color="primary"
                    onClick={() => handleSaveClick(handleSubmit)}
                    type="submit"
                    variant="contained"
                  >
                    Save
                  </Button>
                </Box>
              </>
            ) : (
              <CardContent>
                <Typography variant="subtitle1" color="textSecondary">
                  This section is disabled for this facility.
                </Typography>
              </CardContent>
            )}
            {(isLoading || isSubmitting) && <LinearProgress />}
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default LiscensingAndCertification;
