import api from 'src/apis/api';
import {
  ADD_FACILITY,
  DONE_LOADING,
  EDIT_FACILITY,
  FETCH_FACILITIES,
  FETCH_FACILITY,
  LOADING_DATA,
  SUBMITTING_DATA,
  THROW_SUCCESS
} from 'src/actions/types';
import {
  getAccessToken,
  tokenConfig,
  throwError
} from 'src/actions';

export const addFacility = (form) => async (dispatch, getState) => {
  dispatch({
    type: SUBMITTING_DATA,
    payload: 'facility-form'
  });

  const { accessToken } = getState().auth;

  try {
    const response = await api.post(
      '/v1/facilities/',
      form,
      tokenConfig(accessToken)
    );

    dispatch({
      type: ADD_FACILITY,
      payload: response.data
    });

    dispatch({
      type: THROW_SUCCESS,
      payload: 'Successfully added new facility'
    });
  } catch (e) {
    if (e.response && e.response.status === 401) {
      await dispatch(getAccessToken());
      await dispatch(addFacility(form));
    } else {
      throwError(e, dispatch);
    }
  }
};

export const editFacility = (id, form) => async (dispatch, getState) => {
  dispatch({
    type: SUBMITTING_DATA,
    payload: 'facility-form'
  });

  const { accessToken } = getState().auth;

  try {
    const response = await api.patch(
      `/v1/facilities/${id}/`,
      form,
      tokenConfig(accessToken)
    );

    dispatch({
      type: EDIT_FACILITY,
      payload: response.data
    });

    dispatch({
      type: THROW_SUCCESS,
      payload: 'Facility details has been updated'
    });
  } catch (e) {
    if (e.response && e.response.status === 401) {
      await dispatch(getAccessToken());
      await dispatch(editFacility(id, form));
    } else {
      throwError(e, dispatch);
    }
  }
};

export const fetchFacilities = (province, term) => async (dispatch, getState) => {
  dispatch({ type: LOADING_DATA });

  const { accessToken } = getState().auth;
  let response;

  try {
    if (province && term) {
      response = await api.get(
        `/v1/facilities/?province=${province}&name=${term}`,
        tokenConfig(accessToken)
      );
    } else if (province) {
      response = await api.get(
        `/v1/facilities/?province=${province}`,
        tokenConfig(accessToken)
      );
    } else if (term) {
      response = await api.get(
        `/v1/facilities/?name=${term}`,
        tokenConfig(accessToken)
      );
    } else {
      response = await api.get(
        '/v1/facilities/',
        tokenConfig(accessToken)
      );
    }
    dispatch({
      type: FETCH_FACILITIES,
      payload: response.data
    });

    dispatch({ type: DONE_LOADING });
  } catch (e) {
    if (e.response && e.response.status === 401) {
      await dispatch(getAccessToken());
      await dispatch(fetchFacilities());
    } else {
      throwError(e, dispatch);
    }
  }
};

export const fetchFacility = (id) => async (dispatch, getState) => {
  const { accessToken } = getState().auth;

  try {
    const response = await api.get(
      `/v1/facilities/${id}/`,
      tokenConfig(accessToken)
    );

    dispatch({
      type: FETCH_FACILITY,
      payload: response.data
    });
  } catch (e) {
    if (e.response && e.response.status === 401) {
      await dispatch(getAccessToken());
      await dispatch(fetchFacility(id));
    } else {
      throwError(e, dispatch);
    }
  }
};
