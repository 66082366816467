import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography
} from '@mui/material';
import FamilyPlanning from 'src/components/assessment/section4/FamilyPlanning';
import SectionBreadcrumbs from 'src/components/assessment/SectionBreadcrumbs';

import { fetchAssessment, updateAssessment } from 'src/actions/assessment';

const useQuery = () => new URLSearchParams(useLocation().search);

const PostnatalCarePage = (props) => {
  const query = useQuery();
  const { id } = useParams();
  const { assessments, isLoading, isSubmitting } = props;

  const [assessment, setAssessment] = useState(null);
  const [readOnly, setReadOnly] = useState(true);

  useEffect(() => {
    props.fetchAssessment(id);
    const edit = query.get('edit');
    if (edit === 'true') setReadOnly(false);
  }, []);

  useEffect(() => {
    const tmpAssessment = assessments[id];

    if (tmpAssessment) {
      setAssessment(tmpAssessment);
    }
  }, [assessments]);

  const handleSubmit = (subsection, form) => {
    const assessmentForm = {
      ...assessment,
      facility_id: assessment.facility.id,
      [subsection]: form
    };
    props.updateAssessment(id, assessmentForm);
  };

  return (
    <>
      <Helmet>
        <title>
          {assessment && (
            `${assessment.facility.name} - Section 4.5  - Assessment - FReSAA`
          )}
        </title>
      </Helmet>
      <Box className="box-page">
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <SectionBreadcrumbs
              active="section4"
              assessmentId={id}
              next={`/assessments/${id}/sections/4/4.6`}
              previous={`/assessments/${id}/sections/4/4.4`}
            />
            <Grid item xs={12}>
              <Typography
                color="textPrimary"
                gutterBottom
                variant="h2"
              >
                Section 4: Service Specific Readiness
              </Typography>
            </Grid>
            {assessment ? (
              <Grid item xs={12}>
                <FamilyPlanning
                  formValues={assessment.family_planning}
                  isLoading={isLoading}
                  isSubmitting={isSubmitting}
                  onSubmit={handleSubmit}
                  readOnly={readOnly}
                  setReadOnly={setReadOnly}
                  facilityType={assessment.facility.type}
                />
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Box className="spinner-container">
                  <CircularProgress />
                </Box>
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  assessments: state.assessments,
  isLoading: state.messages.isLoading,
  isSubmitting: state.messages.isSubmitting,
});

export default connect(mapStateToProps, {
  fetchAssessment,
  updateAssessment
})(PostnatalCarePage);
