export default [
  {
    label: 'Maternal Deaths',
    id: 'maternal_deaths_count'
  },
  {
    label: 'Maternal Deaths Reviewed',
    id: 'neonatal_deaths_count'
  },
  {
    label: 'Neonatal deaths aged 0-28 days',
    id: 'reviewed_maternal_deaths_count'
  },
  {
    label: 'Neonatal deaths aged 0-28 days Reviewed',
    id: 'reviewed_neonatal_deaths_count'
  },
  {
    label: 'Infant deaths',
    id: 'infant_deaths_count'
  },
  {
    label: 'Infant deaths reviewed',
    id: 'reviewed_infant_deaths_count'
  },
  {
    label: 'Child deaths aged 0-59 months',
    id: 'child_under_5_deaths_count'
  },
  {
    label: 'Child deaths aged 0-59 months Reviewed',
    id: 'reviewed_child_under_5_deaths_count'
  },
  {
    label: 'Underweight children',
    id: 'child_under_5_low_weight_count'
  },
  {
    label: 'Wasting children',
    id: 'child_under_5_wasting_count'
  },
  {
    label: 'Stunting children',
    id: 'child_under_5_stunting_count'
  }
];
