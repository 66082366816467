export const services = [
  {
    label: 'BGC Vaccination',
    id: 'bcg_vaccination'
  },
  {
    label: 'DPT-Hib-HebB (Pentavalent) Vaccination)',
    id: 'pentavalent_vaccination'
  },
  {
    label: 'Inactivated Polio Vaccination',
    id: 'inactivated_polio_vaccination'
  },
  {
    label: 'Oral Polio Vaccination',
    id: 'oral_polio_vaccination'
  },
  {
    label: 'Pneumococcal Conjugate Vaccination',
    id: 'pneumococcal_conjugate_vaccination'
  },
  {
    label: 'Rotavirus Vaccination',
    id: 'rotavirus_vaccination'
  },
  {
    label: 'Measles-Rubella (MR) / Measles, Mumps, and Rubella (MMR) Vaccination',
    id: 'mr_or_mmr_vaccination'
  },
  {
    label: 'National guideline, job-aids, checklist, charts on Expanded Program on Immunizations/National Immunization Program',
    id: 'national_guidelines_present'
  },
  {
    label: 'Health workers trained in Expanded Program on Immunization/National Immunization Program within the past 3 years',
    id: 'training_in_the_last_3_years'
  }
];

export const schedule = {
  label: 'Schedule of vaccinations',
  id: 'vaccination_schedule'
};

export const observations = [
  {
    label: 'Individual child vaccination cards or booklets maintained',
    id: 'individual_records_for_child_vaccination'
  }
];
