import React from 'react';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  LinearProgress,
  Typography
} from '@mui/material';
import {
  Edit as EditIcon,
  Save as SaveIcon
} from '@mui/icons-material';
import {
  INFIRMARY,
  SAFE_BIRTHING_FACILITY,
  NUTRITION_POST,
  RURAL_HEALTH_UNIT
} from 'src/constants/facilityTypes';
import antenatalCareSupplies from 'src/fields/antenatalCareSupplies';
import AvailabilityStockOut from '../../formSections/AvailabilityStockOut';

const AntenatalCareSupplies = (props) => {
  const {
    facilityType,
    formValues,
    isLoading,
    isSubmitting,
    readOnly,
    setReadOnly
  } = props;

  const handleEditClick = () => {
    setReadOnly(false);
  };

  const handleSaveClick = (handleSubmit) => {
    handleSubmit();
    setReadOnly(true);
  };

  const initialValues = {
    ...antenatalCareSupplies,
    ...formValues
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => {
        props.onSubmit('antenatal_care_supplies', values);
      }}
    >
      {({
        handleSubmit,
        setFieldValue,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardContent>
              <Box className="form-header">
                <Typography variant="h4" gutterBottom>
                  Antenatal Care
                </Typography>
                {readOnly ? (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleEditClick()}
                  >
                    <EditIcon size="medium" />
                  </IconButton>
                ) : (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleSaveClick(handleSubmit)}
                  >
                    <SaveIcon size="medium" />
                  </IconButton>
                )}
              </Box>
            </CardContent>
            <Divider />
            <CardContent>
              <Box sx={{ p: 2 }}>
                {facilityType !== NUTRITION_POST && (
                  <>
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Iron + Folic Acid Tablet (1 box)"
                      fieldName="iron_and_folic_acid_tablet_available"
                      fieldValue={values.iron_and_folic_acid_tablet_available}
                      fieldStockOutName="iron_and_folic_acid_tablet_rso"
                      fieldStockOutValue={values.iron_and_folic_acid_tablet_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Tetanus Toxoid vaccine/Tetanus-diphtheria (Td)"
                      fieldName="tetanus_vaccine_available"
                      fieldValue={values.tetanus_vaccine_available}
                      fieldStockOutName="tetanus_vaccine_rso"
                      fieldStockOutValue={values.tetanus_vaccine_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Calcium Carbonate (1 box)"
                      fieldName="calcium_carbonate_available"
                      fieldValue={values.calcium_carbonate_available}
                      fieldStockOutName="calcium_carbonate_rso"
                      fieldStockOutValue={values.calcium_carbonate_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Methyldopa Tablet"
                      fieldName="methyldopa_tablet_available"
                      fieldValue={values.methyldopa_tablet_available}
                      fieldStockOutName="methyldopa_tablet_rso"
                      fieldStockOutValue={values.methyldopa_tablet_rso}
                      setFieldValue={setFieldValue}
                    />
                  </>
                )}
                {(facilityType === INFIRMARY || facilityType === SAFE_BIRTHING_FACILITY || facilityType === RURAL_HEALTH_UNIT) && (
                  <>
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Amoxicillin Capsule"
                      fieldName="amoxicillin_capsule_available"
                      fieldValue={values.amoxicillin_capsule_available}
                      fieldStockOutName="amoxicillin_capsule_rso"
                      fieldStockOutValue={values.amoxicillin_capsule_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Antibiotics Appropriate for Bacterial STI (e.g Caeftriaxone, Azithromycin"
                      fieldName="antibiotics_for_bacterial_sti_available"
                      fieldValue={values.antibiotics_for_bacterial_sti_available}
                      fieldStockOutName="antibiotics_for_bacterial_sti_rso"
                      fieldStockOutValue={values.antibiotics_for_bacterial_sti_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Anti-Malarials For Pregnant Women (for endemic areas only)"
                      fieldName="anti_malarials_for_pregnant_women_available"
                      fieldValue={values.anti_malarials_for_pregnant_women_available}
                      fieldStockOutName="anti_malarials_for_pregnant_women_rso"
                      fieldStockOutValue={values.anti_malarials_for_pregnant_women_rso}
                      setFieldValue={setFieldValue}
                    />
                    {facilityType !== SAFE_BIRTHING_FACILITY && (
                      <AvailabilityStockOut
                        disabled={readOnly}
                        label="Anti-Retrovirals"
                        fieldName="anti_retrovirals_available"
                        fieldValue={values.anti_retrovirals_available}
                        fieldStockOutName="anti_retrovirals_rso"
                        fieldStockOutValue={values.anti_retrovirals_rso}
                        setFieldValue={setFieldValue}
                      />
                    )}
                  </>
                )}
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Medical examination gloves"
                  fieldName="medical_exam_gloves_available"
                  fieldValue={values.medical_exam_gloves_available}
                  fieldStockOutName="medical_exam_gloves_rso"
                  fieldStockOutValue={values.medical_exam_gloves_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Lubricant (water-based)"
                  fieldName="water_based_lubricant_available"
                  fieldValue={values.water_based_lubricant_available}
                  fieldStockOutName="water_based_lubricant_rso"
                  fieldStockOutValue={values.water_based_lubricant_rso}
                  setFieldValue={setFieldValue}
                />
              </Box>
            </CardContent>
            <Divider />
            <Box className="box-button">
              <Button
                disabled={isLoading || isSubmitting || !readOnly}
                color="primary"
                onClick={() => handleEditClick()}
                variant="contained"
                sx={{ mx: 2 }}
              >
                Edit
              </Button>
              <Button
                disabled={isLoading || isSubmitting || readOnly}
                color="primary"
                onClick={() => handleSaveClick(handleSubmit)}
                type="submit"
                variant="contained"
              >
                Save
              </Button>
            </Box>
            {(isLoading || isSubmitting) && <LinearProgress />}
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default AntenatalCareSupplies;
