import React, { forwardRef, useState, memo } from 'react';
import {
  AppBar,
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PlaceSidebar from 'src/components/dashboard/PlaceSidebar';
import SectionFilterNav from './SectionFilterNav';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const ReportModal = (props) => {
  const {
    handleSelectPlace,
    menuPlaces,
    state,
    setState,
    generateReports,
    subsections2,
    subsections3,
    subsections4
  } = props;
  const [selectAll, setSelectAll] = useState(false);

  const handleClose = () => {
    props.setOpenModal(false);
  };

  const handleSectionChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked
    });
  };

  const handleParentSectionChange = (event) => {
    const parent = event.target.name;

    if (parent === 'section200') {
      setState({
        ...state,
        section200: event.target.checked,
        section201: event.target.checked,
        section202: event.target.checked,
        section203: event.target.checked,
      });
    } else if (parent === 'section300') {
      setState({
        ...state,
        section300: event.target.checked,
        section301: event.target.checked,
        section311: event.target.checked,
        section312: event.target.checked,
        section302: event.target.checked,
        section303: event.target.checked,
        section304: event.target.checked,
      });
    } else if (parent === 'section301') {
      setState({
        ...state,
        section301: event.target.checked,
        section311: event.target.checked,
        section312: event.target.checked,
      });
    } else if (parent === 'section400') {
      setState({
        ...state,
        section400: event.target.checked,
        section401: event.target.checked,
        section402: event.target.checked,
        section403: event.target.checked,
        section404: event.target.checked,
        section405: event.target.checked,
        section406: event.target.checked,
        section461: event.target.checked,
        section462: event.target.checked,
        section463: event.target.checked,
        section464: event.target.checked,
        section407: event.target.checked,
        section471: event.target.checked,
        section472: event.target.checked,
        section473: event.target.checked,
        section474: event.target.checked,
        section475: event.target.checked,
        section476: event.target.checked,
        section477: event.target.checked,
        section408: event.target.checked,
        section481: event.target.checked,
        section482: event.target.checked,
        section483: event.target.checked,
        section484: event.target.checked,
        section485: event.target.checked,
        section486: event.target.checked,
        section487: event.target.checked,
      });
    } else if (parent === 'section406') {
      setState({
        ...state,
        section406: event.target.checked,
        section461: event.target.checked,
        section462: event.target.checked,
        section463: event.target.checked,
        section464: event.target.checked,
      });
    } else if (parent === 'section407') {
      setState({
        ...state,
        section407: event.target.checked,
        section471: event.target.checked,
        section472: event.target.checked,
        section473: event.target.checked,
        section474: event.target.checked,
        section475: event.target.checked,
        section476: event.target.checked,
        section477: event.target.checked,
      });
    } else if (parent === 'section408') {
      setState({
        ...state,
        section408: event.target.checked,
        section481: event.target.checked,
        section482: event.target.checked,
        section483: event.target.checked,
        section484: event.target.checked,
        section485: event.target.checked,
        section486: event.target.checked,
        section487: event.target.checked,
      });
    }
  };

  const handleSelectAllChange = (event) => {
    setSelectAll(event.target.checked);
    setState({
      ...state,
      section100: event.target.checked,
      section200: event.target.checked,
      section201: event.target.checked,
      section202: event.target.checked,
      section203: event.target.checked,
      section300: event.target.checked,
      section301: event.target.checked,
      section311: event.target.checked,
      section312: event.target.checked,
      section302: event.target.checked,
      section303: event.target.checked,
      section304: event.target.checked,
      section400: event.target.checked,
      section401: event.target.checked,
      section402: event.target.checked,
      section403: event.target.checked,
      section404: event.target.checked,
      section405: event.target.checked,
      section406: event.target.checked,
      section461: event.target.checked,
      section462: event.target.checked,
      section463: event.target.checked,
      section464: event.target.checked,
      section407: event.target.checked,
      section471: event.target.checked,
      section472: event.target.checked,
      section473: event.target.checked,
      section474: event.target.checked,
      section475: event.target.checked,
      section476: event.target.checked,
      section477: event.target.checked,
      section408: event.target.checked,
      section481: event.target.checked,
      section482: event.target.checked,
      section483: event.target.checked,
      section484: event.target.checked,
      section485: event.target.checked,
      section486: event.target.checked,
      section487: event.target.checked,
    });
  };

  const handleGenerate = () => {
    generateReports();
    handleClose();
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={props.open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className="app-bar">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h5" component="div">
              Generate Report
            </Typography>
            <Button
              autoFocus
              color="secondary"
              onClick={handleGenerate}
              variant="contained"
            >
              Generate
            </Button>
          </Toolbar>
        </AppBar>
        <Box sx={{ p: 5, paddingTop: 15 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <PlaceSidebar
                places={menuPlaces}
                handleSelect={handleSelectPlace}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <SectionFilterNav
                selectAll={selectAll}
                state={state}
                handleSectionChange={handleSectionChange}
                handleParentSectionChange={handleParentSectionChange}
                handleSelectAllChange={handleSelectAllChange}
                subsections2={subsections2}
                subsections3={subsections3}
                subsections4={subsections4}
              />
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </div>
  );
};

export default memo(ReportModal);
