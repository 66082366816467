import {
  Checkbox,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
} from '@mui/material';

const EnhancedTableHead = (props) => {
  const {
    classes, order, orderBy, onRequestSort, numSelected,
    readOnly, rowCount, showCheckbox, onSelectAllClick
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const renderSortLabel = (headCell) => {
    if (headCell.id === 'name') {
      return (
        <TableSortLabel
          active={orderBy === headCell.id}
          direction={orderBy === headCell.id ? order : 'asc'}
          onClick={createSortHandler(headCell.id)}
        >
          {headCell.label}
          {orderBy === headCell.id ? (
            <span className={classes.visuallyHidden}>
              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
            </span>
          ) : null}
        </TableSortLabel>
      );
    }
    return (
      <div>{headCell.label}</div>
    );
  };

  return (
    <TableHead>
      <TableRow>
        {(showCheckbox && !readOnly) && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all' }}
            />
          </TableCell>
        )}
        {props.headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
            width={headCell.width}
          >
            {renderSortLabel(headCell)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
