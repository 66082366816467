import React from 'react';
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography
} from '@mui/material';
import sections from 'src/constants/sections';

const SectionFilterNav = (props) => {
  const {
    selectAll,
    state,
    handleSectionChange,
    handleParentSectionChange,
    handleSelectAllChange,
    subsections2,
    subsections3,
    subsections4
  } = props;

  const allAreChecked = (arr) => arr.every((element) => element === true);

  const checkSubsectionParent = (item) => {
    const { subsections, name } = item;
    if (subsections) {
      const subArr = subsections.map((a) => state[a.name]);
      return allAreChecked(subArr);
    }
    return state[name];
  };

  return (
    <Box sx={{ maxWidth: 1000 }}>
      <Grid item xs={12}>
        <Typography variant="h3" component="div">
          Choose sections to display
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4">
          Sections
        </Typography>
        <Typography variant="body1" color="textSecondary">
          You can opt to display only certain sections and subsections. Alternatively, click on &ldquo;Select All&rdquo; to show all sections on the report.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormGroup>
          <FormControlLabel
            control={(
              <Checkbox
                checked={selectAll}
                name="selectAll"
                onChange={handleSelectAllChange}
              />
            )}
            label="Select all sections"
          />
        </FormGroup>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ maxWidth: 600 }} />
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ paddingLeft: 5 }}>
          <FormGroup>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={state.section100}
                  name="section100"
                  onChange={handleSectionChange}
                />
              )}
              label="Section 1: Facility Identification"
            />
          </FormGroup>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ maxWidth: 600 }} />
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ paddingLeft: 5 }}>
          <FormGroup>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={allAreChecked(subsections2)}
                  name="section200"
                  onChange={handleParentSectionChange}
                />
              )}
              label="Section 2: General Service Availability"
            />
          </FormGroup>
          <Box sx={{ paddingLeft: 5 }}>
            <FormGroup>
              {sections.section2.map((item) => (
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={state[item.name]}
                      name={item.name}
                      onChange={handleSectionChange}
                    />
                  )}
                  label={item.label}
                  key={item.value}
                />
              ))}
            </FormGroup>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ maxWidth: 600 }} />
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ paddingLeft: 5 }}>
          <FormGroup>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={allAreChecked(subsections3)}
                  name="section300"
                  onChange={handleParentSectionChange}
                />
              )}
              label="Section 3: General Service Readiness"
            />
          </FormGroup>
          <Box sx={{ paddingLeft: 5 }}>
            <FormGroup>
              {sections.section3.map((item) => {
                const hasSubsections = item.subsections;
                return (
                  <Box key={item.value}>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={checkSubsectionParent(item)}
                          name={item.name}
                          onChange={hasSubsections ? handleParentSectionChange : handleSectionChange}
                        />
                      )}
                      label={item.label}
                      key={item.value}
                    />
                    {hasSubsections && (
                      <Box sx={{ paddingLeft: 5 }}>
                        <FormGroup>
                          {item.subsections.map((subItem) => (
                            <FormControlLabel
                              control={(
                                <Checkbox
                                  checked={state[subItem.name]}
                                  name={subItem.name}
                                  onChange={handleSectionChange}
                                />
                              )}
                              label={subItem.label}
                              key={subItem.value}
                            />
                          ))}
                        </FormGroup>
                      </Box>
                    )}
                  </Box>
                );
              })}
            </FormGroup>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ maxWidth: 600 }} />
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ paddingLeft: 5 }}>
          <FormGroup>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={allAreChecked(subsections4)}
                  name="section400"
                  onChange={handleParentSectionChange}
                />
              )}
              label="Section 4: Service-Specific Readiness"
            />
          </FormGroup>
          <Box sx={{ paddingLeft: 5 }}>
            <FormGroup>
              {sections.section4.map((item) => {
                const hasSubsections = item.subsections;
                return (
                  <Box key={item.value}>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={checkSubsectionParent(item)}
                          name={item.name}
                          onChange={hasSubsections ? handleParentSectionChange : handleSectionChange}
                        />
                      )}
                      label={item.label}
                      key={item.value}
                    />
                    {hasSubsections && (
                      <Box sx={{ paddingLeft: 5 }}>
                        <FormGroup>
                          {item.subsections.map((subItem) => (
                            <FormControlLabel
                              control={(
                                <Checkbox
                                  checked={state[subItem.name]}
                                  name={subItem.name}
                                  onChange={handleSectionChange}
                                />
                              )}
                              label={subItem.label}
                              key={subItem.value}
                            />
                          ))}
                        </FormGroup>
                      </Box>
                    )}
                  </Box>
                );
              })}
            </FormGroup>
          </Box>
        </Box>
      </Grid>
    </Box>
  );
};

export default SectionFilterNav;
