import React from 'react';
import { Box, TableContainer } from '@mui/material';
import AvailableFunctioningTable from 'src/components/dashboard/tables/AvailableFunctioningTable';
import { childPreventive } from 'src/constants/fields/section4/equipments';

const Section477 = (props) => {
  const { data, provinces } = props;

  return (
    <TableContainer>
      {provinces.map((province) => (
        <React.Fragment key={`4.7.7-preventive-curative-equipments-${province.id}`}>
          <AvailableFunctioningTable
            ariaLabel={`4.7.7 child preventive and curative care equipments ${province.name}`}
            province={province}
            rows={childPreventive}
            response={data}
            sectionKey="child_preventive_and_curative_care_equipment"
          />
          <Box sx={{ height: 40 }} />
        </React.Fragment>
      ))}
    </TableContainer>
  );
};

export default Section477;
