import React, { memo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material/';
import TableLink from 'src/components/common/TableLink';
import NotApplicable from 'src/components/common/NotApplicable';

const YesNoTable = (props) => {
  const {
    ariaLabel,
    provinces,
    rows,
    response,
    sectionKey,
    title
  } = props;

  return (
    <Table sx={{ minWidth: 650 }} size="small" aria-label={ariaLabel}>
      <TableHead>
        {title && (
          <TableRow>
            <TableCell colSpan={13}>
              {title}
            </TableCell>
          </TableRow>
        )}
        <TableRow>
          <TableCell>Item</TableCell>
          {provinces.map((province) => (
            <TableCell
              colSpan={2}
              align="center"
              key={`th-${province.id}`}
            >
              {province.name}
            </TableCell>
          ))}
        </TableRow>
        <TableRow>
          <TableCell />
          {provinces.map((province) => (
            <React.Fragment key={`th-${province.id}`}>
              <TableCell width="5%" align="right">Yes</TableCell>
              <TableCell width="5%" align="right">No</TableCell>
            </React.Fragment>
          ))}
        </TableRow>
      </TableHead>
      <TableBody sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        {rows.map((item) => (
          <TableRow
            hover
            key={item.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell>{item.label}</TableCell>
            {provinces.map((province) => {
              const fieldProvince = response[province.id];
              const field = fieldProvince ? fieldProvince[item.id] : {};

              return (
                <React.Fragment key={province.id}>
                  <TableCell align="right">
                    {field ? (
                      <TableLink
                        field={item.id}
                        title={item.label}
                        value="True"
                        readableValue="Yes"
                        province={province}
                        sectionKey={sectionKey}
                      >
                        {field.yes}
                      </TableLink>
                    ) : <NotApplicable />}
                  </TableCell>
                  <TableCell align="right">
                    {field ? (
                      <TableLink
                        field={item.id}
                        title={item.label}
                        value="False"
                        readableValue="No"
                        province={province}
                        sectionKey={sectionKey}
                      >
                        {field.no}
                      </TableLink>
                    ) : <NotApplicable />}
                  </TableCell>
                </React.Fragment>
              );
            })}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default memo(YesNoTable);
