export default [
  {
    name: 'Department of Health',
    value: 1
  },
  {
    name: 'Municipal Government',
    value: 2
  },
  {
    name: 'Provincial Government',
    value: 3
  },
  {
    name: 'City Government',
    value: 5
  },
];
