import React from 'react';
import { Box, TableContainer } from '@mui/material/';
import {
  services,
  schedule,
  observations
} from 'src/constants/fields/section4/4.6/childVaccination';
import YesNoTable from '../../tables/YesNoTable';
import ObservationTable from '../../tables/ObservationTable';
import ScheduleTable from '../../tables/ScheduleTable';

const scheduleHeaders = [
  'Everyday',
  'Once a week',
  'Once a month',
  'Other'
];

const Section461 = (props) => {
  const { provinces, data } = props;

  return (
    <TableContainer>
      <YesNoTable
        ariaLabel="4.6.1 child vaccination services"
        provinces={provinces}
        rows={services}
        response={data}
        title="Child vaccination services as part of Child Health Services"
        sectionKey="child_vaccination"
      />
      <Box sx={{ height: 40 }} />
      <ScheduleTable
        ariaLabel="4.6.1 child vaccination schedule"
        provinces={provinces}
        response={data}
        field={schedule}
        headers={scheduleHeaders}
        sectionKey="child_vaccination"
      />
      <Box sx={{ height: 40 }} />
      <ObservationTable
        ariaLabel="4.6.1 child vaccination observations"
        provinces={provinces}
        rows={observations}
        response={data}
        sectionKey="child_vaccination"
      />
    </TableContainer>
  );
};

export default Section461;
