import {
  AUTH_ERROR,
  REGISTER_SUCCESS,
  SIGN_IN,
  SIGN_OUT,
  USER_AUTHORIZED,
  GET_CURRENT_USER,
  USER_LOADING,
  EDIT_PROFILE
} from 'src/actions/types';

const INITIAL_STATE = {
  isSignedIn: null,
  refreshToken: localStorage.getItem('refreshToken'),
  accessToken: null,
  errors: {},
  user: null,
  isLoading: null
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SIGN_IN:
      localStorage.setItem('refreshToken', action.payload.refresh);
      return {
        ...state,
        isSignedIn: true,
        refreshToken: action.payload.refresh,
        accessToken: action.payload.access,
        isLoading: false
      };

    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoading: false
      };

    case USER_AUTHORIZED:
      return {
        ...state,
        isSignedIn: true,
        accessToken: action.payload.access,
        isLoading: false
      };

    case GET_CURRENT_USER:
    case EDIT_PROFILE:
      return {
        ...state,
        isLoading: false,
        user: action.payload,
      };

    case AUTH_ERROR:
    case SIGN_OUT:
      localStorage.removeItem('refreshToken');
      return {
        ...state,
        isSignedIn: false,
        refreshToken: null,
        accessToken: null,
        user: null,
        isLoading: false
      };

    case USER_LOADING:
      return {
        ...state,
        isLoading: true
      };

    default:
      return state;
  }
};

export default authReducer;
