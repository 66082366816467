import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardContent,
  LinearProgress,
  InputAdornment,
  SvgIcon,
  TextField,
} from '@mui/material';
import { Search as SearchIcon } from 'react-feather';

const ListToolbar = (props) => {
  const [term, setTerm] = useState('');

  const onFormSubmit = (e) => {
    e.preventDefault();
    props.searchTerm(term);
  };

  return (
    <Box>
      <Card>
        <CardContent className="list-toolbar">
          <form onSubmit={onFormSubmit}>
            <Box className="search-bar-container">
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder={`Search ${props.object}`}
                variant="outlined"
                className="search-bar"
                onChange={(e) => setTerm(e.target.value)}
                size="small"
              />
              <Button
                aria-label="add"
                color="primary"
                variant="contained"
                type="submit"
              >
                Search
              </Button>
            </Box>
          </form>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <Link to={props.redirect}>
              <Button
                aria-label="add"
                color="primary"
                variant="contained"
              >
                {props.specificbutton ? props.specificbutton : `Add ${props.object}`}
              </Button>
            </Link>
          </Box>
        </CardContent>
        {(props.isLoading || props.isSubmitting) && <LinearProgress />}
      </Card>
    </Box>
  );
};

export default ListToolbar;
