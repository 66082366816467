import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography
} from '@mui/material';
import AdolescentHealthServices from 'src/components/assessment/section4/4.6/AdolescentHealthServices';
import ChildVaccination from 'src/components/assessment/section4/4.6/ChildVaccination';
import ChildGrowthMonitoringServices from 'src/components/assessment/section4/4.6/ChildGrowthMonitoringServices';
import ChildPreventiveAndCurativeCareServices from 'src/components/assessment/section4/4.6/ChildPreventiveAndCurativeCareServices';
import SectionBreadcrumbs from 'src/components/assessment/SectionBreadcrumbs';

import { fetchAssessment, updateAssessment } from 'src/actions/assessment';

const useQuery = () => new URLSearchParams(useLocation().search);

const AssessmentSection4 = (props) => {
  const query = useQuery();
  const { id } = useParams();
  const { assessments, isLoading, isSubmitting } = props;
  const [assessment, setAssessment] = useState(null);
  const [readOnly, setReadOnly] = useState(true);

  useEffect(() => {
    props.fetchAssessment(id);
    const edit = query.get('edit');
    if (edit === 'true') setReadOnly(false);
  }, []);

  useEffect(() => {
    const tmpAssessment = assessments[id];

    if (tmpAssessment) {
      setAssessment(tmpAssessment);
    }
  }, [assessments]);

  const handleSubmit = (subsection, form) => {
    const assessmentForm = {
      ...assessment,
      facility_id: assessment.facility.id,
      [subsection]: form
    };
    props.updateAssessment(id, assessmentForm);
  };

  return (
    <>
      <Helmet>
        <title>
          {assessment && (
            `${assessment.facility.name} - Section 4.6  - Assessment - FReSAA`
          )}
        </title>
      </Helmet>
      <Box className="box-page">
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <SectionBreadcrumbs
              active="section4"
              assessmentId={id}
              next={`/assessments/${id}/sections/4/4.7`}
              previous={`/assessments/${id}/sections/4/4.5`}
            />
            <Grid item xs={12}>
              <Typography
                color="textPrimary"
                gutterBottom
                variant="h2"
              >
                Section 4: Service Specific Readiness
              </Typography>
              <Typography
                color="textPrimary"
                gutterBottom
                variant="h4"
              >
                4.6 Child and Adolescent Health Services
              </Typography>
            </Grid>
            {assessment ? (
              <>
                <Grid item xs={12} id="4.6.1">
                  <ChildVaccination
                    facilityType={assessment.facility.type}
                    formValues={assessment.child_vaccination}
                    isLoading={isLoading}
                    isSubmitting={isSubmitting}
                    onSubmit={handleSubmit}
                    readOnly={readOnly}
                    setReadOnly={setReadOnly}
                  />
                </Grid>
                <Grid item xs={12} id="4.6.2">
                  <ChildGrowthMonitoringServices
                    facilityType={assessment.facility.type}
                    formValues={assessment.child_growth_monitoring}
                    isLoading={isLoading}
                    isSubmitting={isSubmitting}
                    onSubmit={handleSubmit}
                    readOnly={readOnly}
                    setReadOnly={setReadOnly}
                  />
                </Grid>
                <Grid item xs={12} id="4.6.3">
                  <ChildPreventiveAndCurativeCareServices
                    facilityType={assessment.facility.type}
                    formValues={assessment.child_preventive_and_curative_care}
                    isLoading={isLoading}
                    isSubmitting={isSubmitting}
                    onSubmit={handleSubmit}
                    readOnly={readOnly}
                    setReadOnly={setReadOnly}
                  />
                </Grid>
                <Grid item xs={12} id="4.6.4">
                  <AdolescentHealthServices
                    facilityType={assessment.facility.type}
                    formValues={assessment.adolescent_health}
                    isLoading={isLoading}
                    isSubmitting={isSubmitting}
                    onSubmit={handleSubmit}
                    readOnly={readOnly}
                    setReadOnly={setReadOnly}
                  />
                </Grid>
              </>
            ) : (
              <Grid item xs={12}>
                <Box className="spinner-container">
                  <CircularProgress />
                </Box>
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  assessments: state.assessments,
  isLoading: state.messages.isLoading,
  isSubmitting: state.messages.isSubmitting,
});

export default connect(mapStateToProps, {
  fetchAssessment,
  updateAssessment
})(AssessmentSection4);
