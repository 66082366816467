const facilityTypes = [
  {
    name: 'Unknown',
    value: 0,
  },
  {
    name: 'Infirmary',
    value: 1
  },
  {
    name: 'Barangay Health Station',
    value: 2
  },
  {
    name: 'Safe Birthing Facility',
    value: 3
  },
  {
    name: 'Nutrition Post',
    value: 4
  },
  {
    name: 'Rural Health Unit',
    value: 5
  },
  {
    name: 'Auxiliary Facility',
    value: 6
  },
];

export const {
  1: { value: INFIRMARY },
  2: { value: BARANGAY_HEALTH_STATION },
  3: { value: SAFE_BIRTHING_FACILITY },
  4: { value: NUTRITION_POST },
  5: { value: RURAL_HEALTH_UNIT },
  6: { value: AUX_FACILITY }
} = facilityTypes;

export default facilityTypes;
