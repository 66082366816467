import _ from 'lodash';
import {
  AUTH_ERROR,
  FETCH_IMAGES,
  UPLOAD_IMAGE,
  SIGN_OUT
} from 'src/actions/types';

const imageReducer = (state = {}, action) => {
  switch (action.type) {
    case UPLOAD_IMAGE:
      return {
        ...state,
        [action.payload.id]: action.payload
      };

    case FETCH_IMAGES:
      return {
        ..._.mapKeys(action.payload, 'id')
      };

    case AUTH_ERROR:
    case SIGN_OUT:
      return {};

    default:
      return state;
  }
};

export default imageReducer;
