import React, { memo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import TableLink from 'src/components/common/TableLink';
import NotApplicable from 'src/components/common/NotApplicable';

const HealthPersonnelTable = (props) => {
  const {
    province,
    response,
    rows,
    sectionKey,
  } = props;

  return (
    <Table sx={{ minWidth: 650 }} size="small" aria-label="health-care-personnel">
      <TableHead>
        <TableRow>
          <TableCell colSpan={8}>
            {province.name}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Personnel</TableCell>
          <TableCell width="10%" align="right">Full time Plantilla</TableCell>
          <TableCell width="10%" align="right">Full time Job Order</TableCell>
          <TableCell width="10%" align="right">Full time by DOH</TableCell>
          <TableCell width="10%" align="right">Part-time</TableCell>
          <TableCell width="10%" align="right">Volunteer</TableCell>
          <TableCell width="10%" align="right">Working Hours</TableCell>
        </TableRow>
      </TableHead>
      <TableBody sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        {rows.map((item) => {
          const fieldProvince = response[province.id];

          const plantillaField = `${item.id}_plantilla_count`;
          const casualField = `${item.id}_casual_count`;
          const dohField = `${item.id}_deployed_by_doh_count`;
          const partTimeField = `${item.id}_part_time_count`;
          const volunteerField = `${item.id}_volunteer_count`;
          const hoursField = `${item.id}_parttimers_weekly_hour_count`;

          const plantilla = fieldProvince && fieldProvince[plantillaField];
          const casual = fieldProvince && fieldProvince[casualField];
          const doh = fieldProvince && fieldProvince[dohField];
          const partTime = fieldProvince && fieldProvince[partTimeField];
          const volunteer = fieldProvince && fieldProvince[volunteerField];
          const hours = fieldProvince && fieldProvince[hoursField];

          return (
            <TableRow
              key={`hcp-${item.id}-${province.id}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {item.label}
              </TableCell>
              <TableCell align="right">
                {plantilla != null ? (
                  <TableLink
                    field={plantillaField}
                    title={`${item.label} - Full-time Plantilla`}
                    province={province}
                    sectionKey={sectionKey}
                  >
                    {plantilla}
                  </TableLink>
                ) : <NotApplicable />}
              </TableCell>
              <TableCell align="right">
                {casual != null ? (
                  <TableLink
                    field={casualField}
                    title={`${item.label} Full-time Job Order / Casual`}
                    province={province}
                    sectionKey={sectionKey}
                  >
                    {casual}
                  </TableLink>
                ) : <NotApplicable />}
              </TableCell>
              <TableCell align="right">
                {doh != null ? (
                  <TableLink
                    field={dohField}
                    title={`${item.label} - Full-time Deployed by DOH`}
                    province={province}
                    sectionKey={sectionKey}
                  >
                    {doh}
                  </TableLink>
                ) : <NotApplicable />}
              </TableCell>
              <TableCell align="right">
                {partTime != null ? (
                  <TableLink
                    field={partTimeField}
                    title={`${item.label} - Part-time`}
                    province={province}
                    sectionKey={sectionKey}
                  >
                    {partTime}
                  </TableLink>
                ) : <NotApplicable />}
              </TableCell>
              <TableCell align="right">
                {volunteer != null ? (
                  <TableLink
                    field={volunteerField}
                    title={`${item.label} - Volunteer`}
                    province={province}
                    sectionKey={sectionKey}
                  >
                    {volunteer}
                  </TableLink>
                ) : <NotApplicable />}
              </TableCell>
              <TableCell align="right">
                {hours != null ? (
                  <TableLink
                    field={hoursField}
                    title={`${item.label} - Total Number of Hours in a Facility per Week`}
                    province={province}
                    sectionKey={sectionKey}
                  >
                    {hours}
                  </TableLink>
                ) : <NotApplicable />}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default memo(HealthPersonnelTable);
