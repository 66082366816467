import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography
} from '@mui/material';
import FacilityForm from 'src/components/facility/FacilityForm';
import SectionBreadcrumbs from 'src/components/assessment/SectionBreadcrumbs';

import { fetchAssessment } from 'src/actions/assessment';
import { editFacility, fetchFacility } from 'src/actions/facility';
import { fetchBarangays } from 'src/actions/places';
import { getLocalStorageItem } from 'src/utils/localStorage';

const useQuery = () => new URLSearchParams(useLocation().search);

const AssessmentSection1 = (props) => {
  const query = useQuery();
  const { id } = useParams();
  const {
    assessments,
    barangays,
    facilities,
    isLoading,
    isSubmitting,
    payload
  } = props;

  const [assessment, setAssessment] = useState(null);
  const [facility, setFacility] = useState(null);
  const [readOnly, setReadOnly] = useState(true);
  const [barangayList, setBarangayList] = useState([]);
  const [isBarangayListInStorage, setIsBarangayListInStorage] = useState(false);

  useEffect(() => {
    const edit = query.get('edit');
    if (edit === 'true') setReadOnly(false);
    props.fetchAssessment(id);

    const storedBarangays = JSON.parse(getLocalStorageItem('barangays'));
    if (storedBarangays) {
      setIsBarangayListInStorage(true);
      setBarangayList([...barangayList, ...storedBarangays]);
    } else {
      props.fetchBarangays();
    }
  }, []);

  useEffect(() => {
    const assessmentTmp = assessments[id];
    if (assessmentTmp) {
      setAssessment(assessmentTmp);
      props.fetchFacility(assessmentTmp.facility.id);
    }
  }, [assessments]);

  useEffect(() => {
    if (assessment) {
      setFacility(facilities[assessment.facility.id]);
    }
  }, [facilities]);

  const handleEditFacility = (form) => {
    props.editFacility(id, form);
  };

  return (
    <>
      <Helmet>
        <title>
          {assessment && (
            `${assessment.facility.name} - Section 1  - Assessment - FReSAA`
          )}
        </title>
      </Helmet>
      <Box className="box-page">
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <SectionBreadcrumbs
              assessmentId={id}
              previous={`/assessments/${id}`}
              next={`/assessments/${id}/sections/2/2.1`}
              active="section1"
            />
            <Grid item xs={12}>
              <Typography
                color="textPrimary"
                gutterBottom
                variant="h2"
              >
                Section 1: Facility Identification
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {facility ? (
                <FacilityForm
                  barangays={isBarangayListInStorage ? barangayList : barangays}
                  isLoading={isLoading}
                  isSubmitting={isSubmitting}
                  onSubmit={handleEditFacility}
                  payload={payload}
                  facility={facility}
                  readOnly={readOnly}
                  setReadOnly={setReadOnly}
                />
              ) : (
                <Box className="spinner-container">
                  <CircularProgress />
                </Box>
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>

    </>
  );
};

const mapStateToProps = (state) => ({
  assessments: state.assessments,
  barangays: Object.values(state.barangays),
  isLoading: state.messages.isLoading,
  isSubmitting: state.messages.isSubmitting,
  facilities: state.facilities,
  payload: state.messages.payload
});

export default connect(mapStateToProps, {
  editFacility,
  fetchAssessment,
  fetchBarangays,
  fetchFacility
})(AssessmentSection1);
