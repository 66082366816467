import React from 'react';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  LinearProgress,
  Typography
} from '@mui/material';
import {
  Edit as EditIcon,
  Save as SaveIcon
} from '@mui/icons-material';
import {
  BARANGAY_HEALTH_STATION,
  NUTRITION_POST,
  AUX_FACILITY
} from 'src/constants/facilityTypes';
import RadioGroupFormControl from '../formSections/RadioGroupFormControl';
import YesOrNoFormControl from '../formSections/YesOrNoFormControl';

const availability = [
  {
    label: 'Yes, observed',
    value: 1
  },
  {
    label: 'Reported, not observed',
    value: 2
  },
  {
    label: 'No records',
    value: 3
  }
];

const PostnatalCare = (props) => {
  const {
    facilityType,
    formValues,
    isLoading,
    isSubmitting,
    readOnly,
    setReadOnly
  } = props;

  const handleEditClick = () => {
    setReadOnly(false);
  };

  const handleSaveClick = (handleSubmit) => {
    handleSubmit();
    setReadOnly(true);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formValues}
      onSubmit={(values) => {
        props.onSubmit('postnatal_care', values);
      }}
    >
      {({
        handleSubmit,
        setFieldValue,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardContent>
              <Box className="form-header">
                <Typography variant="h4" gutterBottom>
                  4.3 Postnatal Care
                </Typography>
                {readOnly ? (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleEditClick()}
                  >
                    <EditIcon size="medium" />
                  </IconButton>
                ) : (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleSaveClick(handleSubmit)}
                  >
                    <SaveIcon size="medium" />
                  </IconButton>
                )}
              </Box>
            </CardContent>
            <Divider />
            <CardContent sx={{ p: 5 }}>
              { facilityType !== NUTRITION_POST && (
                <>
                  <Typography color="textSecondary" variant="h5" gutterBottom>
                    Are the following routinely (always, for every client) carried out by providers in this facility as part of postnatal care?
                  </Typography>
                  <Box sx={{ p: 2 }}>
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Hemoglobin postnatal"
                      fieldName="hemoglobin_postnatal"
                      fieldValue={values.hemoglobin_postnatal}
                      setFieldValue={setFieldValue}
                    />
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Iron supplementation"
                      fieldName="iron_supplementation"
                      fieldValue={values.iron_supplementation}
                      setFieldValue={setFieldValue}
                    />
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Vitamin A supplementation"
                      fieldName="vitamin_a_supplementation"
                      fieldValue={values.vitamin_a_supplementation}
                      setFieldValue={setFieldValue}
                    />
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Postpartum checkup at 24 hours after delivery"
                      fieldName="postpartum_check_at_24_hours"
                      fieldValue={values.postpartum_check_at_24_hours}
                      setFieldValue={setFieldValue}
                    />
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Postpartum checkup at 48-72 hours after delivery"
                      fieldName="postpartum_check_at_48_to_72_hours"
                      fieldValue={values.postpartum_check_at_48_to_72_hours}
                      setFieldValue={setFieldValue}
                    />
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Postpartum checkup within 1 week of delivery"
                      fieldName="postpartum_check_within_1_week"
                      fieldValue={values.postpartum_check_within_1_week}
                      setFieldValue={setFieldValue}
                    />
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Postpartum checkup within 4-6 weeks of delivery"
                      fieldName="postpartum_check_within_4_to_6_weeks"
                      fieldValue={values.postpartum_check_within_4_to_6_weeks}
                      setFieldValue={setFieldValue}
                    />
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Blood pressure monitoring postpartum"
                      fieldName="blood_pressure_monitoring"
                      fieldValue={values.blood_pressure_monitoring}
                      setFieldValue={setFieldValue}
                    />
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Counseling on family planning"
                      fieldName="counseling_on_family_planning"
                      fieldValue={values.counseling_on_family_planning}
                      setFieldValue={setFieldValue}
                    />
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Counseling on breastfeeding"
                      fieldName="counseling_on_breastfeeding"
                      fieldValue={values.counseling_on_breastfeeding}
                      setFieldValue={setFieldValue}
                    />
                  </Box>
                  <span className="whitespace" />
                </>
              )}
              <Typography color="textSecondary" variant="h5" gutterBottom>
                Are the following national guidelines, job-aids, checklist, charts for postpartum care in the facility today?
              </Typography>
              <Box sx={{ p: 2 }}>
                { (facilityType !== BARANGAY_HEALTH_STATION && facilityType !== NUTRITION_POST && facilityType !== AUX_FACILITY) && (
                  <YesOrNoFormControl
                    disabled={readOnly}
                    label="Quality Assurance Package Manual / Pregnancy, Childbirth, Postpartum and Newborn Care Manual"
                    fieldName="qap_manual_or_equivalent"
                    fieldValue={values.qap_manual_or_equivalent}
                    setFieldValue={setFieldValue}
                  />
                )}
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Early Childhood Care and Development Card"
                  fieldName="early_childhood_care_development_card"
                  fieldValue={values.early_childhood_care_development_card}
                  setFieldValue={setFieldValue}
                />
              </Box>
              <span className="whitespace" />
              { (facilityType !== NUTRITION_POST && facilityType !== AUX_FACILITY) && (
                <>
                  <Typography color="textSecondary" variant="h5" gutterBottom>
                    Have you or any health worker assigned in this facility been trained in the following within the past 5 years?
                  </Typography>
                  <Box sx={{ p: 2 }}>
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Kangaroo Mother Care for Premature / Very Small Babies"
                      fieldName="kangaroo_care_training"
                      fieldValue={values.kangaroo_care_training}
                      setFieldValue={setFieldValue}
                    />
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Quality Assurance Package / Pregnancy, Childbirth, Postpartum and Newborn Care"
                      fieldName="qap_or_equivalent_training"
                      fieldValue={values.qap_or_equivalent_training}
                      setFieldValue={setFieldValue}
                    />
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Infant and Young Child Feeding / Lactation Management Training / Management of Breastfeeding Difficulties (any one)"
                      fieldName="feeding_training"
                      fieldValue={values.feeding_training}
                      setFieldValue={setFieldValue}
                    />
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Modern Methods of Family Planning"
                      fieldName="modern_methods_of_family_planning_training"
                      fieldValue={values.modern_methods_of_family_planning_training}
                      setFieldValue={setFieldValue}
                    />
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Postpartum Intrauterine Device Insertion"
                      fieldName="intrauterine_device_insertion_training"
                      fieldValue={values.intrauterine_device_insertion_training}
                      setFieldValue={setFieldValue}
                    />
                  </Box>
                  <RadioGroupFormControl
                    disabled={readOnly}
                    label="Do you have a Maternal Health Record for postpartum visits with schedule of visits?"
                    fieldName="maternal_health_records_for_postpartum_visits"
                    fieldValue={values.maternal_health_records_for_postpartum_visits}
                    options={availability}
                    setFieldValue={setFieldValue}
                  />
                </>
              )}
            </CardContent>
            <Divider />
            <Box className="box-button">
              <Button
                disabled={isLoading || isSubmitting || !readOnly}
                color="primary"
                onClick={() => handleEditClick()}
                variant="contained"
                sx={{ mx: 2 }}
              >
                Edit
              </Button>
              <Button
                disabled={isLoading || isSubmitting || readOnly}
                color="primary"
                onClick={() => handleSaveClick(handleSubmit)}
                type="submit"
                variant="contained"
              >
                Save
              </Button>
            </Box>
            {(isLoading || isSubmitting) && <LinearProgress />}
          </Card>
        </form>
      )}

    </Formik>
  );
};

export default PostnatalCare;
