export default {
  partograph: null,
  thermal_protection_immediate_drying_and_skin_to_skin_contact: null,
  delayed_cord_cutting: null,
  hygienic_cord_care: null,
  immediate_and_exclusive_breastfeeding: null,
  normal_delivery: null,
  assisted_vaginal_delivery: null,
  parenteral_administration_of_uterotonic: null,
  manual_removal_of_placenta: null,
  removal_of_retained_products_after_delivery: null,
  corticosteroids_for_preterm_labor: null,
  parenteral_administration_of_iv_antibiotics: null,
  parenteral_administration_of_anticonvulsant: null,
  basic_emergency_obstetric_care: null,
  neonatal_resuscitation: null,
  qap_manual_or_equivalent: null,
  einc_clinical_practical_pocket_guide: null,
  bemonc_training: null,
  einc_training: null,
  lmt_training: null,
  bls_training: null,
  physician_trained_in_bemonc: null,
  philhealth_accredited_professional_provider: null,
  midwife_with_bs_diploma: null,
  maternal_clinic_charts: null,
  newborn_patient_charts: null,
  referral_forms: null
};
