export const services = [
  {
    label: 'Education and counselling on health effects of tobacco/smoking, diet, oral hygiene, and ASRH',
    id: 'education_on_effects_of_smoking_etc_and_asrh'
  },
  {
    label: 'Basic Oral Health Care',
    id: 'basic_oral_health_care'
  },
];

export const guidelines = [
  {
    label: 'Adolescent Job Aid (AJA)',
    id: 'adolescent_job_aid'
  },
  {
    label: 'Competency Training on Adolescent Health for Health Service Workers: A Reference Material',
    id: 'ctoahfhsw_reference_material'
  }
];

export const trainings = [
  {
    label: 'Health workers assigned trained on ADEPT or AJA within the past 3 years',
    id: 'adept_or_aja_training'
  },
  {
    label: 'Designated health point persons trained in Comprehensive Management on Adolescent Health',
    id: 'designated_point_person_with_training'
  }
];

export const space = {
  label: 'Adolescent-friendly space designated in the facility',
  id: 'adolescent_friendly_space'
};
