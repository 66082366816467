export const services = [
  {
    label: 'Diagnosis and/or treatment of Pneumonia, Diarrhea, Dengue, and Measles (e.g. Provision of ORS + Zinc)',
    id: 'diagnosis_andor_treatment_of_pneumonia_diarrhea_dengue_measles'
  },
  {
    label: 'Diagnosis and/or treatment of Malaria',
    id: 'diagnosis_andor_treatment_of_malaria'
  },
  {
    label: 'Diagnosis and/or treatment of Malnutrition',
    id: 'diagnosis_andor_treatment_of_malnutrition'
  },
  {
    label: 'Basic Oral Health Care',
    id: 'basic_oral_health_care'
  },
  {
    label: 'Provide Vitamin A supplementation to children',
    id: 'vitamin_a_supplementation_to_children'
  },
  {
    label: 'Provide Iron supplementation to low-birthweight infants',
    id: 'iron_supplementation_to_low_birthweight_infants'
  },
  {
    label: 'Provide Iron supplementation for children (6-11) with anemia',
    id: 'iron_supplementation_for_children_with_anemia'
  },
  {
    label: 'Provide Iron supplementation to sick children with diarrhea',
    id: 'zinc_supplementation_for_children_with_diarrhea'
  },
  {
    label: 'Diagnosis and/or treatment of TB in children / Isoniazid Preventive Therapy',
    id: 'diagnosis_andor_treatment_of_tb'
  },
  {
    label: 'Deworming',
    id: 'deworming'
  },
  {
    label: 'Micronutrient supplementation to children',
    id: 'micronutrient_supplementation'
  },
];

export const schedule = {
  label: 'Schedule for delivery of child preventive and curative care services',
  id: 'care_schedule'
};

export const guidelines = [
  {
    label: 'Integrated Management of Child Illness Chart Booklet',
    id: 'integrated_management_of_child_illness_chart_booklet'
  },
  {
    label: 'Micronutrient Supplementation Program Manual of Operations',
    id: 'micronutrient_supplementation_program_manual_of_operations'
  },
  {
    label: 'Infant and Young Child Feeding Manual',
    id: 'infant_and_young_child_feeding_manual'
  },
  {
    label: 'Community-Based Management of Acute Malnutrition (CMAM) Guidelines',
    id: 'pimam_guidelines'
  },
  {
    label: 'Guidelines in the Implementation of Oral Health Program for Public Helath Services in the Philippines',
    id: 'oral_health_program_guidelines'
  },
];

export const trainings = [
  {
    label: 'Growth Monitoring or Training Course on Child Growth Standards',
    id: 'integrated_management_of_child_illness_training'
  },
  {
    label: 'Management of TB in Children',
    id: 'management_of_tb_in_children_training'
  },
  {
    label: 'Training on the Philippine Integrated Management of Acute Malnutrition',
    id: 'management_of_acute_malnutrition_training'
  },
  {
    label: 'Dentist trained in Basic Oral Health Care',
    id: 'dentist_trained_in_basic_oral_health_care'
  }
];

export const examinations = [
  {
    label: 'Hemoglobin determination',
    id: 'hemoglobin_determination'
  },
  {
    label: 'Test parasite in stool (general microscopy)',
    id: 'test_parasite_in_stool'
  },
  {
    label: 'Malaria diagnostic capacity',
    id: 'malaria_diagnostic_capacity'
  }
];

export const observations = [
  {
    label: 'Individual child records for management of childhood illness maintained',
    id: 'individual_records_for_child_illnesses'
  }
];
