import React from 'react';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  LinearProgress,
  Typography
} from '@mui/material';
import {
  Edit as EditIcon,
  Save as SaveIcon
} from '@mui/icons-material';
import { SAFE_BIRTHING_FACILITY, RURAL_HEALTH_UNIT } from 'src/constants/facilityTypes';
import YesOrNoFormControl from '../formSections/YesOrNoFormControl';

const GeneralServices = (props) => {
  const {
    generalMchnServcies, isLoading, isSubmitting,
    readOnly, setReadOnly, facilityType
  } = props;

  const handleEditClick = () => {
    setReadOnly(false);
  };

  const handleSaveClick = (handleSubmit) => {
    handleSubmit();
    setReadOnly(true);
  };

  return (
    <Formik
      initialValues={generalMchnServcies}
      onSubmit={(values) => {
        props.onSubmit('general_mnchn_services', values);
      }}
      enableReinitialize
    >
      {({
        handleSubmit,
        setFieldValue,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardContent>
              <Box className="form-header">
                <Typography variant="h4" gutterBottom>
                  2.1 General Maternal, Newborn, Child Health, and Nutrition (MNCHN) Services
                </Typography>
                {readOnly ? (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleEditClick()}
                  >
                    <EditIcon size="medium" />
                  </IconButton>
                ) : (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleSaveClick(handleSubmit)}
                  >
                    <SaveIcon size="medium" />
                  </IconButton>
                )}
              </Box>
            </CardContent>
            <Divider />
            <CardContent sx={{ p: 5 }}>
              <Typography color="textSecondary" variant="h5" gutterBottom>
                Does this facility offer any of the following client services? Is there any location in this facility where clients can receive any of the following services?
              </Typography>
              <Box sx={{ p: 2 }}>
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Health Education"
                  fieldName="health_education"
                  fieldValue={values.health_education}
                  setFieldValue={setFieldValue}
                />
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Natural Method of Family Planning"
                  fieldName="natural_method_of_family_planning"
                  fieldValue={values.natural_method_of_family_planning}
                  setFieldValue={setFieldValue}
                />
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Artificial Method of Family Planning"
                  fieldName="artificial_method_of_family_planning"
                  fieldValue={values.artificial_method_of_family_planning}
                  setFieldValue={setFieldValue}
                />
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Antenatal Care"
                  fieldName="ante_natal_care"
                  fieldValue={values.ante_natal_care}
                  setFieldValue={setFieldValue}
                />
                {facilityType !== RURAL_HEALTH_UNIT && (
                  <>
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Normal Delivery"
                      fieldName="normal_delivery"
                      fieldValue={values.normal_delivery}
                      setFieldValue={setFieldValue}
                    />
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Assissted Delivery"
                      fieldName="assisted_delivery"
                      fieldValue={values.assisted_delivery}
                      setFieldValue={setFieldValue}
                    />
                  </>
                )}
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Post-natal Care"
                  fieldName="post_natal_delivery"
                  fieldValue={values.post_natal_delivery}
                  setFieldValue={setFieldValue}
                />
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Breastfeeding Education"
                  fieldName="breastfeeding_education"
                  fieldValue={values.breastfeeding_education}
                  setFieldValue={setFieldValue}
                />
                {facilityType !== RURAL_HEALTH_UNIT && (
                  <>
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Newborn Care"
                      fieldName="newborn_care"
                      fieldValue={values.newborn_care}
                      setFieldValue={setFieldValue}
                    />
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Newborn Screening - Blood"
                      fieldName="newborn_screening_blood"
                      fieldValue={values.newborn_screening_blood}
                      setFieldValue={setFieldValue}
                    />
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Newborn Hearing Screening"
                      fieldName="newborn_screening_hearing"
                      fieldValue={values.newborn_screening_hearing}
                      setFieldValue={setFieldValue}
                    />
                  </>
                )}
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Child Vaccinations"
                  fieldName="child_vaccinations"
                  fieldValue={values.child_vaccinations}
                  setFieldValue={setFieldValue}
                />
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Child Growth Monitoring"
                  fieldName="child_growth_monitoring"
                  fieldValue={values.child_growth_monitoring}
                  setFieldValue={setFieldValue}
                />
                {facilityType !== SAFE_BIRTHING_FACILITY && (
                  <>
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Integrated Management of Child Illnesses"
                      fieldName="integrated_management_of_child_illnesses"
                      fieldValue={values.integrated_management_of_child_illnesses}
                      setFieldValue={setFieldValue}
                    />
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Micronutrient Supplementation"
                      fieldName="micronutrient_supplementation"
                      fieldValue={values.micronutrient_supplementation}
                      setFieldValue={setFieldValue}
                    />
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Management of TB in Children"
                      fieldName="management_of_tb_in_children"
                      fieldValue={values.management_of_tb_in_children}
                      setFieldValue={setFieldValue}
                    />
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Basic Oral Health Care"
                      fieldName="basic_oral_health_care"
                      fieldValue={values.basic_oral_health_care}
                      setFieldValue={setFieldValue}
                    />
                  </>
                )}
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Education and counselling on health effects of tobacco/smoking, diet, and oral hygiene to adolescents"
                  fieldName="education_on_health_effects_to_adolescents"
                  fieldValue={values.education_on_health_effects_to_adolescents}
                  setFieldValue={setFieldValue}
                />
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Adolescent Sexual and Reproductive Health (ASRH)"
                  fieldName="adolescent_sexual_and_reproductive_health"
                  fieldValue={values.adolescent_sexual_and_reproductive_health}
                  setFieldValue={setFieldValue}
                />
                {facilityType !== SAFE_BIRTHING_FACILITY && (
                  <>
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Laboratory Diagnostic Services"
                      fieldName="laboratory_diagnostic_services"
                      fieldValue={values.laboratory_diagnostic_services}
                      setFieldValue={setFieldValue}
                    />
                  </>
                )}
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Point-of-care / Point-of-service enrollment to PhilHealth"
                  fieldName="point_of_service_enrollment_to_philhealth"
                  fieldValue={values.point_of_service_enrollment_to_philhealth}
                  setFieldValue={setFieldValue}
                />
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Are the health workers practicing minimum health standards like wearing of face mask and face shield during provision of services?"
                  fieldName="practicing_minimum_health_standards"
                  fieldValue={values.practicing_minimum_health_standards}
                  setFieldValue={setFieldValue}
                />
              </Box>
            </CardContent>
            <Divider />
            <Box className="box-button">
              <Button
                disabled={isLoading || isSubmitting || !readOnly}
                color="primary"
                onClick={() => handleEditClick()}
                variant="contained"
                sx={{ mx: 2 }}
              >
                Edit
              </Button>
              <Button
                disabled={isLoading || isSubmitting || readOnly}
                color="primary"
                onClick={() => handleSaveClick(handleSubmit)}
                type="submit"
                variant="contained"
              >
                Save
              </Button>
            </Box>
            {(isLoading || isSubmitting) && <LinearProgress />}
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default GeneralServices;
