export default {
  antenatal_physical_examination: null,
  iron_folic_acid_supplementation: null,
  calcium_carbonate: null,
  basic_oral_health_care: null,
  intermittent_preventive_treatment_for_malaria: null,
  tetanus_vaccination: null,
  monitoring_for_hypertensive_disorder_of_pregnancy: null,
  counseling_on_pregnancy_dangers: null,
  counseling_on_having_a_birth_plan_and_facility_based_delivery: null,
  health_education_on_newborn_screening: null,
  counseling_on_family_planning: null,
  counseling_on_breastfeeding: null,
  counseling_on_sti_from_mother_to_child: null,
  mental_health_screening: null,
  antenatal_care_services_schedule: null,
  antenatal_care_services_schedule_other_value: '',
  qap_manual_or_pcpnc_manual: null,
  mother_baby_book: null,
  maternal_health_record: null,
  qap_or_pcpnc_training: null,
  ics_training: null,
  community_tracking_tool_training: null,
  supportive_supervision_training: null,
  lactation_management_training: null,
  diagnosis_and_treatment_of_malaria_in_pregnancy_training: null,
  diagnosis_treatment_of_sti: null,
  hiv_counseling_training: null,
  newborn_screening_training: null,
  hemoglobin_determination_using_copper_sulfate_training: null,
  bhws_trained_in_ics_count: null,
  bhws_trained_in_community_tracking_tool_count: null,
  hemoglobin_determination: null,
  urine_dipstick_test: null,
  urinalysis: null,
  urine_glucose_exam: null,
  blood_glucose_exam: null,
  blood_typing: null,
  malaria_rapid_diagnostic_test: null,
  syphilis_rapid_diagnostic_test: null,
  hepatitis_b_diagnostic_test: null,
  hiv_rapid_diagnostic_test: null,
  individual_client_records_are_maintained: null,
  pregnancy_tracking_tools_are_used: null
};
