import React from 'react';
import TableContainer from '@mui/material/TableContainer';
import policiesFields from 'src/constants/fields/section3/policies';
import ObservationTable from '../tables/ObservationTable';

const Section304 = (props) => {
  const { data, provinces } = props;

  return (
    <TableContainer>
      <ObservationTable
        ariaLabel="3.4 policies"
        provinces={provinces}
        rows={policiesFields}
        response={data}
        sectionKey="policies"
      />
    </TableContainer>
  );
};

export default Section304;
