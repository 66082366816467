import React from 'react';
import { Box, TableContainer } from '@mui/material';
import { vaccination } from 'src/constants/fields/section4/supplies';
import AvailableStockoutTable from '../../tables/AvailableStockoutTable';

const Section486 = (props) => {
  const { data, provinces } = props;

  return (
    <TableContainer>
      {provinces.map((province) => (
        <React.Fragment key={`4.8.6-child-vaccination-supplies${province.id}`}>
          <AvailableStockoutTable
            ariaLabel={`4.8.6 child vaccination supplies ${province.name}`}
            province={province}
            rows={vaccination}
            response={data}
            sectionKey="child_vaccination_supplies"
          />
          <Box sx={{ height: 40 }} />
        </React.Fragment>
      ))}
    </TableContainer>
  );
};

export default Section486;
