import React from 'react';
import { Box, TableContainer } from '@mui/material';
import { delivery } from 'src/constants/fields/section4/supplies';
import AvailableStockoutTable from '../../tables/AvailableStockoutTable';

const Section482 = (props) => {
  const { data, provinces } = props;

  return (
    <TableContainer>
      {provinces.map((province) => (
        <React.Fragment key={`4.8.2-delivery-services-supplies-${province.id}`}>
          <AvailableStockoutTable
            ariaLabel={`4.8.2 delivery services supplies ${province.name}`}
            province={province}
            rows={delivery}
            response={data}
            sectionKey="delivery_services_supplies"
          />
          <Box sx={{ height: 40 }} />
        </React.Fragment>
      ))}
    </TableContainer>
  );
};

export default Section482;
