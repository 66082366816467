export const antenatal = [
  {
    label: 'Non-mercury Sphygmomanometer',
    available: 'non_mercury_sphygmomanometer_available',
    functioning: 'non_mercury_sphygmomanometer_functioning'
  },
  {
    label: 'Stethoscope',
    available: 'stethoscope_available',
    functioning: 'stethoscope_functioning'
  },
  {
    label: 'Adult Weighing Scale',
    available: 'adult_weighing_scale_available',
    functioning: 'adult_weighing_scale_functioning'
  },
  {
    label: 'Tape Measure',
    available: 'tape_measure_available',
    functioning: 'tape_measure_functioning'
  },
  {
    label: 'Fetal Doppler',
    available: 'fetal_doppler_available',
    functioning: 'fetal_doppler_functioning'
  },
  {
    label: 'Examination table with/without stirrups',
    available: 'examination_table_available',
    functioning: 'examination_table_functioning'
  },
  {
    label: 'Ultrasound machine',
    available: 'ultrasound_machine_available',
    functioning: 'ultrasound_machine_functioning'
  },
  {
    label: 'Digital thermometer',
    available: 'digital_thermometer_available',
    functioning: 'digital_thermometer_functioning'
  },
];

export const delivery = [
  {
    label: 'Adult Weighing Scale',
    available: 'adult_weighing_scale_available',
    functioning: 'adult_weighing_scale_functioning'
  },
  {
    label: 'Infant Weighing Scale',
    available: 'infant_weighing_scale_available',
    functioning: 'infant_weighing_scale_functioning'
  },
  {
    label: 'Sphygmomanometer, Non-Mercury',
    available: 'non_mercury_sphygmomanometer_available',
    functioning: 'non_mercury_sphygmomanometer_functioning'
  },
  {
    label: 'Stethoscope, Adult',
    available: 'stethoscope_for_adults_available',
    functioning: 'stethoscope_for_adults_functioning'
  },
  {
    label: 'Stethoscope, Pediatric Use',
    available: 'stethoscope_for_pediatric_use_available',
    functioning: 'stethoscope_for_pediatric_use_functioning'
  },
  {
    label: 'Delivery Table with pail',
    available: 'delivery_table_with_pail_available',
    functioning: 'delivery_table_with_pail_functioning'
  },
  {
    label: 'Newborn Resuscitation Table or equivalent',
    available: 'newborn_resuscitation_table_available',
    functioning: 'newborn_resuscitation_table_functioning'
  },
  {
    label: 'Examination or Patient Bed w/ clean sheets and footstool',
    available: 'examination_or_patient_bed_available',
    functioning: 'examination_or_patient_bed_functioning'
  },
  {
    label: 'Delivery Pack (Consists of 2 Hemostat Forceps, 1 Needle Holder, 1 Surgical Scissor, 1 Tissue Forceps)',
    available: 'delivery_pack_available',
    functioning: 'delivery_pack_functioning'
  },
  {
    label: 'Bandage scissors',
    available: 'bandage_scissors_available',
    functioning: 'bandage_scissors_functioning'
  },
  {
    label: 'Thumb forceps',
    available: 'thumb_forceps_available',
    functioning: 'thumb_forceps_functioning'
  },
  {
    label: 'Umbilical cord scissors',
    available: 'umbilical_cord_scissors_available',
    functioning: 'umbilical_cord_scissors_functioning'
  },
  {
    label: 'Uterine Sound',
    available: 'uterine_sound_available',
    functioning: 'uterine_sound_functioning'
  },
  {
    label: 'Disposable delivery kit (sterile cord clip or ties, plastic sheet to place under mother and sterile blade)',
    available: 'disposable_delivery_kit_available',
    functioning: 'disposable_delivery_kit_functioning'
  },
  {
    label: 'Instrument Tray with Cover',
    available: 'instrument_tray_with_cover_available',
    functioning: 'instrument_tray_with_cover_functioning'
  },
  {
    label: 'Kidney basin',
    available: 'kidney_basin_available',
    functioning: 'kidney_basin_functioning'
  },
  {
    label: 'Instrument Cabinet',
    available: 'instrument_cabinet_available',
    functioning: 'instrument_cabinet_functioning'
  },
  {
    label: 'Instrument Table or equivalent',
    available: 'instrument_table_available',
    functioning: 'instrument_table_functioning'
  },
  {
    label: 'Fetal Doppler',
    available: 'fetal_doppler_available',
    functioning: 'fetal_doppler_functioning'
  },
  {
    label: 'Vaginal speculum (medium or large)',
    available: 'vaginal_speculum_available',
    functioning: 'vaginal_speculum_functioning'
  },
  {
    label: 'Sponge Forceps',
    available: 'sponge_forceps_available',
    functioning: 'sponge_forceps_functioning'
  },
  {
    label: 'Suction Apparatus',
    available: 'suction_apparatus_available',
    functioning: 'suction_apparatus_functioning'
  },
  {
    label: 'Suction Catheter',
    available: 'suction_catheter_available',
    functioning: 'suction_catheter_functioning'
  },
  {
    label: 'Rubber Suction Bulb / Penguin',
    available: 'rubber_suction_bulb_available',
    functioning: 'rubber_suction_bulb_functioning'
  },
  {
    label: 'Ambu bag or self-inflating bag',
    available: 'ambu_bag_available',
    functioning: 'ambu_bag_functioning'
  },
  {
    label: 'Oxygen Tank with Regulator and Humidifier',
    available: 'o2_tank_with_regulator_and_humidifier_available',
    functioning: 'o2_tank_with_regulator_and_humidifier_functioning'
  },
  {
    label: 'Cone Mask, may be improvised',
    available: 'cone_mask_available',
    functioning: 'cone_mask_functioning'
  },
  {
    label: 'Newborn Size Mask 0 and 1',
    available: 'newborn_size_mask_0_and_1_available',
    functioning: 'newborn_size_mask_0_and_1_functioning'
  },
  {
    label: 'Nebulizer (portable)',
    available: 'portable_nebulizer_available',
    functioning: 'portable_nebulizer_functioning'
  },
  {
    label: 'RX Box or similar integrated technology',
    available: 'rx_box_available',
    functioning: 'rx_box_functioning'
  },
  {
    label: 'Examination Light (Gooseneck Lamp or equivalent)',
    available: 'examination_light_available',
    functioning: 'examination_light_functioning'
  },
  {
    label: 'Room Thermometer',
    available: 'romm_thermometer_available',
    functioning: 'romm_thermometer_functioning'
  },
  {
    label: 'IV Stand or equivalent',
    available: 'iv_stand_available',
    functioning: 'iv_stand_functioning'
  },
  {
    label: 'Thermometer, Non-Mercury',
    available: 'non_mercury_thermometer_available',
    functioning: 'non_mercury_thermometer_functioning'
  },
  {
    label: 'Tape Measure',
    available: 'tape_measure_available',
    functioning: 'tape_measure_functioning'
  },
  {
    label: 'Storage Containers with Cover for Dry Cotton Balls',
    available: 'storage_containers_with_cove_for_dry_cotton_balls_available',
    functioning: 'storage_containers_with_cove_for_dry_cotton_balls_functioning'
  },
  {
    label: 'Jar with cover and without cover',
    available: 'jar_available',
    functioning: 'jar_functioning'
  },
  {
    label: 'Wall Clock with seconds hand',
    available: 'wall_clock_with_seconds_hand_available',
    functioning: 'wall_clock_with_seconds_hand_functioning'
  },
  {
    label: 'Slippers for use in Delivery Area',
    available: 'slippers_for_delivery_area_available',
    functioning: 'slippers_for_delivery_area_functioning'
  },
];

export const newborn = [
  {
    label: 'Apgar and Ballard Scoring Chart',
    available: 'apgar_and_ballard_scoring_chart_available',
    functioning: 'apgar_and_ballard_scoring_chart_functioning'
  },
  {
    label: 'Drying rack',
    available: 'drying_rack_available',
    functioning: 'drying_rack_functioning'
  },
];

export const family = [
  {
    label: 'BP Apparatus',
    available: 'bp_apparatus_available',
    functioning: 'bp_apparatus_functioning'
  },
  {
    label: 'Stethoscope',
    available: 'stethoscope_available',
    functioning: 'stethoscope_functioning'
  },
  {
    label: 'Tenaculum Forceps',
    available: 'tenaculum_forceps_available',
    functioning: 'tenaculum_forceps_functioning'
  },
  {
    label: 'Mayo Scissors',
    available: 'mayo_scissors_available',
    functioning: 'mayo_scissors_functioning'
  },
  {
    label: 'Placental Forceps',
    available: 'placental_forceps_available',
    functioning: 'placental_forceps_functioning'
  },
  {
    label: '10" Ovum Forceps',
    available: 'ten_inch_ovum_forceps_available',
    functioning: 'ten_inch_ovum_forceps_functioning'
  },
  {
    label: '10" Straight Forceps',
    available: 'ten_inch_straight_forceps_available',
    functioning: 'ten_inch_straight_forceps_functioning'
  },
  {
    label: '10" Narrow or Alligator Forceps',
    available: 'ten_inch_alligator_forceps_available',
    functioning: 'ten_inch_alligator_forceps_functioning'
  },
  {
    label: 'Uterine Sound',
    available: 'uterine_sound_available',
    functioning: 'uterine_sound_functioning'
  },
  {
    label: 'Vaginal Speculum, Different sizes',
    available: 'vaginal_speculum_available',
    functioning: 'vaginal_speculum_functioning'
  },
  {
    label: 'Kelly Pad',
    available: 'kelly_pad_available',
    functioning: 'kelly_pad_functioning'
  },
  {
    label: 'Gooseneck Lamp (or equivalent)',
    available: 'gooseneck_lamp_available',
    functioning: 'gooseneck_lamp_functioning'
  },
  {
    label: 'Printed materials/posters for patient education',
    available: 'printed_materials_for_patient_education_available',
    functioning: 'printed_materials_for_patient_education_functioning'
  },
];

export const vaccination = [
  {
    label: 'EPI monitoring chart',
    available: 'epi_monitoring_chart_available',
    functioning: 'epi_monitoring_chart_functioning'
  },
  {
    label: 'Vaccine Carriers',
    available: 'vaccine_carriers_available',
    functioning: 'vaccine_carriers_functioning'
  },
  {
    label: 'Ice packs',
    available: 'ice_packs_available',
    functioning: 'ice_packs_functioning'
  },
  {
    label: 'Cold-Chain Temperature Monitoring Chart',
    available: 'cold_chain_temperature_monitoring_chart_available',
    functioning: 'cold_chain_temperature_monitoring_chart_functioning'
  },
  {
    label: 'Vaccine Refrigerator or Freezer',
    available: 'vaccine_ref_or_freezer_available',
    functioning: 'vaccine_ref_or_freezer_functioning'
  },
  {
    label: 'Temperature monitoring devices (thermometer)',
    available: 'thermometer_available',
    functioning: 'thermometer_functioning'
  },
  {
    label: 'Sharps container ("safety box")',
    available: 'sharps_container_available',
    functioning: 'sharps_container_functioning'
  },
];

export const childMonitoring = [
  {
    label: 'Child Weighing Scale (250-gram gradiation)',
    available: 'child_weighing_scale_available',
    functioning: 'child_weighing_scale_functioning'
  },
  {
    label: 'Infant Weighing Scale (100-gram gradiation)',
    available: 'infant_weighing_scale_available',
    functioning: 'infant_weighing_scale_functioning'
  },
  {
    label: 'Height or Length board',
    available: 'height_or_length_board_available',
    functioning: 'height_or_length_board_functioning'
  },
  {
    label: 'Mid-Upper Arm Circumference (MUAC) Tape',
    available: 'midupper_arm_circumference_tape_available',
    functioning: 'midupper_arm_circumference_tape_functioning'
  },
];

export const childPreventive = [
  {
    label: 'Digital Thermometer',
    available: 'digital_thermometer_available',
    functioning: 'digital_thermometer_functioning'
  },
  {
    label: 'Pediatric BP apparatus',
    available: 'pediatric_bp_apparatus_available',
    functioning: 'pediatric_bp_apparatus_functioning'
  },
  {
    label: 'Pediatric Stethoscope',
    available: 'pediatric_stethoscope_available',
    functioning: 'pediatric_stethoscope_functioning'
  },
  {
    label: 'Other device (e.g. cellphone) that can measure seconds',
    available: 'other_device_that_can_measure_secs_available',
    functioning: 'other_device_that_can_measure_secs_functioning'
  },
  {
    label: 'Dental Chair',
    available: 'dental_chair_available',
    functioning: 'dental_chair_functioning'
  },
  {
    label: 'Air Compressor',
    available: 'air_compressor_available',
    functioning: 'air_compressor_functioning'
  },
  {
    label: 'Ultrasonic Scaler',
    available: 'ultrasonic_scaler_available',
    functioning: 'ultrasonic_scaler_functioning'
  },
  {
    label: 'Light Curing Machine',
    available: 'light_curing_machine_available',
    functioning: 'light_curing_machine_functioning'
  },
  {
    label: 'Dental Instruments',
    available: 'dental_instruments_available',
    functioning: 'dental_instruments_functioning'
  },
  {
    label: 'Calibrated 1/2 of 1L measuring jar for ORS',
    available: 'calibrated_jar_for_ors_available',
    functioning: 'calibrated_jar_for_ors_functioning'
  },
  {
    label: 'Oxygen tank with regulator and humidifier',
    available: 'o2_tank_with_regulator_and_humidifier_available',
    functioning: 'o2_tank_with_regulator_and_humidifier_functioning'
  },
  {
    label: 'Nebulizer',
    available: 'nebulizer_available',
    functioning: 'nebulizer_functioning'
  }
];
