import React from 'react';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  LinearProgress,
  Typography
} from '@mui/material';
import {
  Edit as EditIcon,
  Save as SaveIcon
} from '@mui/icons-material';
import {
  BARANGAY_HEALTH_STATION,
  NUTRITION_POST,
  AUX_FACILITY
} from 'src/constants/facilityTypes';
import RadioGroupFormControl from '../formSections/RadioGroupFormControl';

const availability = [
  {
    label: 'Yes, observed',
    value: 1
  },
  {
    label: 'Reported, not observed',
    value: 2
  },
  {
    label: 'None',
    value: 3
  }
];

const Policies = (props) => {
  const {
    facilityType,
    formValues,
    isLoading,
    isSubmitting,
    readOnly,
    setReadOnly
  } = props;

  const handleEditClick = () => {
    setReadOnly(false);
  };

  const handleSaveClick = (handleSubmit) => {
    handleSubmit();
    setReadOnly(true);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formValues}
      onSubmit={(values) => {
        props.onSubmit('policies', values);
      }}
    >
      {({
        handleSubmit,
        setFieldValue,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardContent>
              <Box className="form-header">
                <Typography variant="h4" gutterBottom>
                  3.4 Policies
                </Typography>
                {readOnly ? (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleEditClick()}
                  >
                    <EditIcon size="medium" />
                  </IconButton>
                ) : (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleSaveClick(handleSubmit)}
                  >
                    <SaveIcon size="medium" />
                  </IconButton>
                )}
              </Box>
            </CardContent>
            <Divider />
            { (facilityType !== BARANGAY_HEALTH_STATION && facilityType !== NUTRITION_POST && facilityType !== AUX_FACILITY) ? (
              <>
                <CardContent sx={{ p: 5 }}>
                  <Typography
                    color="textSecondary"
                    variant="h5"
                    gutterBottom
                  >
                    Does the municipality/city/barangay have ordinances, executive orders, or resolutions pertaining to the following:
                  </Typography>
                  <Box sx={{ p: 2 }}>
                    <RadioGroupFormControl
                      disabled={readOnly}
                      label="Maternal, Neonatal, Child Health and Nutrition (MNCHN) Policy"
                      fieldName="mnchn_policy"
                      fieldValue={values.mnchn_policy}
                      setFieldValue={setFieldValue}
                      options={availability}
                    />
                    <RadioGroupFormControl
                      disabled={readOnly}
                      label="Safe Motherhood and/or Faciilty-based Deliveries"
                      fieldName="safe_motherhood_and_or_facility_based_deliveries"
                      fieldValue={values.safe_motherhood_and_or_facility_based_deliveries}
                      setFieldValue={setFieldValue}
                      options={availability}
                    />
                    <RadioGroupFormControl
                      disabled={readOnly}
                      label="Contraceptive Self-Reliance Policy"
                      fieldName="contraceptive_self_reliance_policy"
                      fieldValue={values.contraceptive_self_reliance_policy}
                      setFieldValue={setFieldValue}
                      options={availability}
                    />
                    <RadioGroupFormControl
                      disabled={readOnly}
                      label="Breastfeeding and/or Milk Code"
                      fieldName="breastfeeding_or_milk_code"
                      fieldValue={values.breastfeeding_or_milk_code}
                      setFieldValue={setFieldValue}
                      options={availability}
                    />
                    <RadioGroupFormControl
                      disabled={readOnly}
                      label="Newborn Screening"
                      fieldName="newborn_screening"
                      fieldValue={values.newborn_screening}
                      setFieldValue={setFieldValue}
                      options={availability}
                    />
                    <RadioGroupFormControl
                      disabled={readOnly}
                      label="PhilHealth enrollment of women about to give birth"
                      fieldName="philhealth_enrollment_for_expectant_women"
                      fieldValue={values.philhealth_enrollment_for_expectant_women}
                      setFieldValue={setFieldValue}
                      options={availability}
                    />
                    <RadioGroupFormControl
                      disabled={readOnly}
                      label="COVID-19 Policies on Maternal Health"
                      fieldName="covid19_policies_on_maternal_health"
                      fieldValue={values.covid19_policies_on_maternal_health}
                      setFieldValue={setFieldValue}
                      options={availability}
                    />
                  </Box>
                </CardContent>
                <Divider />
                <Box className="box-button">
                  <Button
                    disabled={isLoading || isSubmitting || !readOnly}
                    color="primary"
                    onClick={() => handleEditClick()}
                    variant="contained"
                    sx={{ mx: 2 }}
                  >
                    Edit
                  </Button>
                  <Button
                    disabled={isLoading || isSubmitting || readOnly}
                    color="primary"
                    onClick={() => handleSaveClick(handleSubmit)}
                    type="submit"
                    variant="contained"
                  >
                    Save
                  </Button>
                </Box>
              </>
            ) : (
              <CardContent>
                <Typography variant="subtitle1" color="textSecondary">
                  This section is disabled for this facility.
                </Typography>
              </CardContent>
            )}
            {(isLoading || isSubmitting) && <LinearProgress />}
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default Policies;
