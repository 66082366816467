import React from 'react';
import { connect } from 'react-redux';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup
} from '@mui/material';

const YesOrNoFormControl = (props) => {
  const {
    disabled, isSubmitting, label,
    fieldName, fieldValue, setFieldValue
  } = props;

  return (
    <FormControl
      component="fieldset"
      fullWidth
      sx={{ py: 1 }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={8} md={6}>
          <FormLabel component="legend" className="radio-legend">
            {label}
          </FormLabel>
        </Grid>
        <Grid item xs={12} sm={4} md={6}>
          <RadioGroup
            aria-label={label}
            name={fieldName}
            onChange={(e, value) => {
              const isTrue = (value === 'true' || value === true);
              setFieldValue(fieldName, isTrue, false);
            }}
            value={fieldValue || false}
            row
          >
            <FormControlLabel
              className="radio-label"
              control={<Radio size="small" />}
              disabled={isSubmitting || disabled}
              label="Yes"
              value={true}
            />
            <FormControlLabel
              control={<Radio size="small" />}
              disabled={isSubmitting || disabled}
              label="No"
              value={false}
            />
          </RadioGroup>
        </Grid>
      </Grid>
    </FormControl>
  );
};

const mapStateToProps = (state) => ({
  isSubmitting: state.messages.isSubmitting
});

export default connect(mapStateToProps)(YesOrNoFormControl);
