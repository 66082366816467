import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { red } from '@mui/material/colors';
import moment from 'moment';

const ReportList = ({ fetchReports, reports }) => {
  const NEW = 0;
  const PROCESSING = 1;
  const DONE = 2;
  const FAILED = 3;
  const [refreshInterval, setRefreshInterval] = useState();

  const hasProcessingReport = () => reports.some((report) => report.status === PROCESSING || report.status === NEW);

  const setUpPeriodicRefresh = () => {
    if (hasProcessingReport()) {
      if (!refreshInterval) {
        setRefreshInterval(setInterval(fetchReports, 10000));
      }
    } else if (refreshInterval) {
      clearInterval(refreshInterval);
      setRefreshInterval(0);
    }
  };

  setUpPeriodicRefresh();

  return (
    <>
      {reports.map((report) => (
        <Link
          to={`/dashboard/${report.id}`}
          key={report.id}
          style={{ color: report.status !== DONE ? 'gray' : 'black' }}
          className={report.status !== DONE ? 'disabled-link' : null}
        >
          <Grid className="report">
            <Grid item>
              <Typography variant="h4">
                {`Report #${report.id} - ${report.place !== null ? report.place.name : ''}`}
              </Typography>
              <Typography sx={{ letterSpacing: 1.16662, lineHeight: 2 }}>
                {`REQUESTED ON: ${moment(report.created).format('ll h:mm A')}`}
                {report.status === FAILED ? <Typography sx={{ color: red[500] }}>An error occured while generating the report. Please try again.</Typography> : null}
              </Typography>
            </Grid>
            <Grid item sx={{ display: 'flex' }}>
              {report.status === PROCESSING || report.status === NEW ? <Typography>Processing...</Typography> : null}
              <ArrowForwardIosSharpIcon sx={{ ml: 2 }} />
            </Grid>
          </Grid>
        </Link>
      ))}
    </>
  );
};

export default ReportList;
