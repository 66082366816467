import React from 'react';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  LinearProgress,
  Typography
} from '@mui/material';
import {
  Edit as EditIcon,
  Save as SaveIcon
} from '@mui/icons-material';
import {
  INFIRMARY,
  SAFE_BIRTHING_FACILITY,
  NUTRITION_POST,
  RURAL_HEALTH_UNIT
} from 'src/constants/facilityTypes';
import familyPlanningSupplies from 'src/fields/familyPlanningSupplies';
import AvailabilityStockOut from '../../formSections/AvailabilityStockOut';

const ChildVaccinationSupplies = (props) => {
  const {
    facilityType,
    formValues,
    isLoading,
    isSubmitting,
    readOnly,
    setReadOnly
  } = props;

  const handleEditClick = () => {
    setReadOnly(false);
  };

  const handleSaveClick = (handleSubmit) => {
    handleSubmit();
    setReadOnly(true);
  };

  const initialValues = {
    ...familyPlanningSupplies,
    ...formValues,
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => {
        props.onSubmit('child_vaccination_supplies', values);
      }}
    >
      {({
        handleSubmit,
        setFieldValue,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardContent>
              <Box className="form-header">
                <Typography variant="h4" gutterBottom>
                  Child Vaccination
                </Typography>
                {readOnly ? (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleEditClick()}
                  >
                    <EditIcon size="medium" />
                  </IconButton>
                ) : (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleSaveClick(handleSubmit)}
                  >
                    <SaveIcon size="medium" />
                  </IconButton>
                )}
              </Box>
            </CardContent>
            <Divider />
            <CardContent>
              <Box sx={{ p: 2 }}>
                {facilityType !== NUTRITION_POST && (
                  <>
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="BCG Vaccine and diluent"
                      fieldName="bcg_vaccine_and_diluent_available"
                      fieldValue={values.bcg_vaccine_and_diluent_available}
                      fieldStockOutName="bcg_vaccine_and_diluent_rso"
                      fieldStockOutValue={values.bcg_vaccine_and_diluent_rso}
                      setFieldValue={setFieldValue}
                    />
                    {facilityType === SAFE_BIRTHING_FACILITY && (
                      <AvailabilityStockOut
                        disabled={readOnly}
                        label="Hepatitis B Vaccination (Birth Dose)"
                        fieldName="hepatitis_b_vaccination_available"
                        fieldValue={values.hepatitis_b_vaccination_available}
                        fieldStockOutName="hepatitis_b_vaccination_rso"
                        fieldStockOutValue={values.hepatitis_b_vaccination_rso}
                        setFieldValue={setFieldValue}
                      />
                    )}
                    {facilityType !== SAFE_BIRTHING_FACILITY && (
                      <AvailabilityStockOut
                        disabled={readOnly}
                        label="DPT-Hib+HepB (Pentavalent)"
                        fieldName="pentavalent_available"
                        fieldValue={values.pentavalent_available}
                        fieldStockOutName="pentavalent_rso"
                        fieldStockOutValue={values.pentavalent_rso}
                        setFieldValue={setFieldValue}
                      />
                    )}
                  </>
                )}
                {(facilityType === INFIRMARY || facilityType === RURAL_HEALTH_UNIT) && (
                  <>
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Oral Polio Vaccine"
                      fieldName="oral_polio_vaccine_available"
                      fieldValue={values.oral_polio_vaccine_available}
                      fieldStockOutName="oral_polio_vaccine_rso"
                      fieldStockOutValue={values.oral_polio_vaccine_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Inactivated Polio Vaccine"
                      fieldName="inactivated_polio_vaccine_available"
                      fieldValue={values.inactivated_polio_vaccine_available}
                      fieldStockOutName="inactivated_polio_vaccine_rso"
                      fieldStockOutValue={values.inactivated_polio_vaccine_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Pneumococcal Conjugate Vaccine"
                      fieldName="pneumococcal_conjugate_vaccine_available"
                      fieldValue={values.pneumococcal_conjugate_vaccine_available}
                      fieldStockOutName="pneumococcal_conjugate_vaccine_rso"
                      fieldStockOutValue={values.pneumococcal_conjugate_vaccine_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Rotavirus Vaccine"
                      fieldName="rotavirus_vaccine_available"
                      fieldValue={values.rotavirus_vaccine_available}
                      fieldStockOutName="rotavirus_vaccine_rso"
                      fieldStockOutValue={values.rotavirus_vaccine_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="MR/MMR Vaccine"
                      fieldName="mr_or_mmr_vaccine_available"
                      fieldValue={values.mr_or_mmr_vaccine_available}
                      fieldStockOutName="mr_or_mmr_vaccine_rso"
                      fieldStockOutValue={values.mr_or_mmr_vaccine_rso}
                      setFieldValue={setFieldValue}
                    />
                  </>
                )}
                {(facilityType === INFIRMARY || facilityType === SAFE_BIRTHING_FACILITY || facilityType === RURAL_HEALTH_UNIT) && (
                  <AvailabilityStockOut
                    disabled={readOnly}
                    label="ECCD/Mother-Baby Book"
                    fieldName="eccd_or_mother_baby_book_available"
                    fieldValue={values.eccd_or_mother_baby_book_available}
                    fieldStockOutName="eccd_or_mother_baby_book_rso"
                    fieldStockOutValue={values.eccd_or_mother_baby_book_rso}
                    setFieldValue={setFieldValue}
                  />
                )}
                {(facilityType === INFIRMARY || facilityType === RURAL_HEALTH_UNIT) && (
                  <>
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Blank/unused individual child vaccination cards or booklets"
                      fieldName="blank_child_vaccination_cards_or_booklets_available"
                      fieldValue={values.blank_child_vaccination_cards_or_booklets_available}
                      fieldStockOutName="blank_child_vaccination_cards_or_booklets_rso"
                      fieldStockOutValue={values.blank_child_vaccination_cards_or_booklets_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Index cards (summary forms)"
                      fieldName="index_cards_available"
                      fieldValue={values.index_cards_available}
                      fieldStockOutName="index_cards_rso"
                      fieldStockOutValue={values.index_cards_rso}
                      setFieldValue={setFieldValue}
                    />
                  </>
                )}
                {(facilityType === INFIRMARY || facilityType === SAFE_BIRTHING_FACILITY || facilityType === RURAL_HEALTH_UNIT) && (
                  <>
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Single-use standard disposabe syringes with needles or auto-disable syringes with needles"
                      fieldName="syringes_with_needles_available"
                      fieldValue={values.syringes_with_needles_available}
                      fieldStockOutName="syringes_with_needles_rso"
                      fieldStockOutValue={values.syringes_with_needles_rso}
                      setFieldValue={setFieldValue}
                    />
                  </>
                )}
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Disposable Latex Gloves"
                  fieldName="disposable_latex_gloves_available"
                  fieldValue={values.disposable_latex_gloves_available}
                  fieldStockOutName="disposable_latex_gloves_rso"
                  fieldStockOutValue={values.disposable_latex_gloves_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Cotton Balls"
                  fieldName="cotton_balls_available"
                  fieldValue={values.cotton_balls_available}
                  fieldStockOutName="cotton_balls_rso"
                  fieldStockOutValue={values.cotton_balls_rso}
                  setFieldValue={setFieldValue}
                />
              </Box>
            </CardContent>
            <Divider />
            <Box className="box-button">
              <Button
                disabled={isLoading || isSubmitting || !readOnly}
                color="primary"
                onClick={() => handleEditClick()}
                variant="contained"
                sx={{ mx: 2 }}
              >
                Edit
              </Button>
              <Button
                disabled={isLoading || isSubmitting || readOnly}
                color="primary"
                onClick={() => handleSaveClick(handleSubmit)}
                type="submit"
                variant="contained"
              >
                Save
              </Button>
            </Box>
            {(isLoading || isSubmitting) && <LinearProgress />}
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default ChildVaccinationSupplies;
