import { combineReducers } from 'redux';
import assessmentReducer from 'src/reducers/assessmentReducer';
import assignmentReducer from 'src/reducers/assignmentReducer';
import authReducer from 'src/reducers/authReducer';
import barangayReducer from 'src/reducers/barangayReducer';
import facilityReducer from 'src/reducers/facilityReducer';
import imageReducer from 'src/reducers/imageReducer';
import messageReducer from 'src/reducers/messageReducer';
import provinceReducer from 'src/reducers/provinceReducer';
import usersReducer from 'src/reducers/usersReducer';

export default combineReducers({
  assessments: assessmentReducer,
  assignments: assignmentReducer,
  auth: authReducer,
  barangays: barangayReducer,
  facilities: facilityReducer,
  images: imageReducer,
  messages: messageReducer,
  provinces: provinceReducer,
  users: usersReducer,
});
