import React from 'react';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  LinearProgress,
  Typography
} from '@mui/material';
import {
  Edit as EditIcon,
  Save as SaveIcon
} from '@mui/icons-material';
import { SAFE_BIRTHING_FACILITY, NUTRITION_POST } from 'src/constants/facilityTypes';
import YesOrNoFormControl from '../../formSections/YesOrNoFormControl';
import RadioGroupFormControl from '../../formSections/RadioGroupFormControl';

const availabilityLevels = [
  {
    label: 'Yes, Level 1',
    value: 1
  },
  {
    label: 'Yes, Level 2',
    value: 2
  },
  {
    label: 'Yes, Level 3',
    value: 3
  },
  {
    label: 'No',
    value: 4
  }
];

const AdolescentHealthServices = (props) => {
  const {
    facilityType,
    formValues,
    isLoading,
    isSubmitting,
    readOnly,
    setReadOnly
  } = props;

  const handleEditClick = () => {
    setReadOnly(false);
  };

  const handleSaveClick = (handleSubmit) => {
    handleSubmit();
    setReadOnly(true);
  };

  const initialValues = {
    education_on_effects_of_smoking_etc_and_asrh: null,
    basic_oral_health_care: null,
    adolescent_job_aid: null,
    ctoahfhsw_reference_material: null,
    adept_or_aja_training: null,
    designated_point_person_with_training: null,
    adolescent_friendly_space: null,
    ...formValues
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => {
        props.onSubmit('adolescent_health', values);
      }}
    >
      {({
        handleSubmit,
        setFieldValue,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardContent>
              <Box className="form-header">
                <Typography variant="h4" gutterBottom>
                  4.6.4 Adolescent Health Services
                </Typography>
                {readOnly ? (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleEditClick()}
                  >
                    <EditIcon size="medium" />
                  </IconButton>
                ) : (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleSaveClick(handleSubmit)}
                  >
                    <SaveIcon size="medium" />
                  </IconButton>
                )}
              </Box>
            </CardContent>
            <Divider />
            {facilityType !== SAFE_BIRTHING_FACILITY && facilityType !== NUTRITION_POST ? (
              <>
                <CardContent sx={{ p: 5 }}>
                  <Typography color="textSecondary" variant="h5" gutterBottom>
                    Are the following services available in this facility as part of Adolescent Health Services?
                  </Typography>
                  <Box sx={{ p: 2 }}>
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Education and counselling on health effects of tobacco/smoking, diet, oral hygiene, and Adolescent Sexual and Reproductive Health (ASRH)"
                      fieldName="education_on_effects_of_smoking_etc_and_asrh"
                      fieldValue={values.education_on_effects_of_smoking_etc_and_asrh}
                      setFieldValue={setFieldValue}
                    />
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Basic Oral Health Care"
                      fieldName="basic_oral_health_care"
                      fieldValue={values.basic_oral_health_care}
                      setFieldValue={setFieldValue}
                    />
                  </Box>
                  <Typography color="textSecondary" variant="h5" gutterBottom>
                    Are the following national guidelines, job-aids, checklist, charts on adolescent health services in the facility today?
                  </Typography>
                  <Box sx={{ p: 2 }}>
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Adolescent Job Aid (AJA)"
                      fieldName="adolescent_job_aid"
                      fieldValue={values.adolescent_job_aid}
                      setFieldValue={setFieldValue}
                    />
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Competency Training on Adolescent Health for Health Service Workers: A Reference Material"
                      fieldName="ctoahfhsw_reference_material"
                      fieldValue={values.ctoahfhsw_reference_material}
                      setFieldValue={setFieldValue}
                    />
                  </Box>
                  <YesOrNoFormControl
                    disabled={readOnly}
                    label="Are health workers assigned in this facility trained on Adolescent Health, Education and Practical Training (ADEPT) or Adolescent Job Aid (AJA) within the past 5 years?"
                    fieldName="adept_or_aja_training"
                    fieldValue={values.adept_or_aja_training}
                    setFieldValue={setFieldValue}
                  />
                  <YesOrNoFormControl
                    disabled={readOnly}
                    label="Is there a designated health point person that trained in Comprehensive Management on Adolescent Health?"
                    fieldName="designated_point_person_with_training"
                    fieldValue={values.designated_point_person_with_training}
                    setFieldValue={setFieldValue}
                  />
                  <RadioGroupFormControl
                    disabled={readOnly}
                    label="Is there an adolescent-friendly space designated in the facility?"
                    fieldName="adolescent_friendly_space"
                    fieldValue={values.adolescent_friendly_space}
                    options={availabilityLevels}
                    setFieldValue={setFieldValue}
                  />
                </CardContent>
                <Divider />
                <Box className="box-button">
                  <Button
                    disabled={isLoading || isSubmitting || !readOnly}
                    color="primary"
                    onClick={() => handleEditClick()}
                    variant="contained"
                    sx={{ mx: 2 }}
                  >
                    Edit
                  </Button>
                  <Button
                    disabled={isLoading || isSubmitting || readOnly}
                    color="primary"
                    onClick={() => handleSaveClick(handleSubmit)}
                    type="submit"
                    variant="contained"
                  >
                    Save
                  </Button>
                </Box>
              </>
            ) : (
              <CardContent>
                <Typography variant="subtitle1" color="textSecondary">
                  This section is disabled for this facility.
                </Typography>
              </CardContent>
            )}
            {(isLoading || isSubmitting) && <LinearProgress />}
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default AdolescentHealthServices;
