import React from 'react';
import { connect } from 'react-redux';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material';

const availability = [
  {
    label: 'Observed',
    value: 1
  },
  {
    label: 'Reported, Not Observed',
    value: 2
  },
  {
    label: 'Not Available',
    value: 3
  }
];

const functioning = [
  {
    label: 'Yes',
    value: 1
  },
  {
    label: 'No',
    value: 2
  },
  {
    label: 'Do not Know',
    value: 3
  }
];

const AvailabilityFunctioningFormGroup = (props) => {
  const {
    disabled,
    isSubmitting,
    fieldName,
    fieldValue,
    fieldFunctioningName,
    fieldFunctioningValue,
    label,
    setFieldValue
  } = props;

  return (
    <FormControl component="fieldset" fullWidth>
      <Grid container spacing={3} sx={{ py: 3 }}>
        <Grid item xs={12} sm={5} md={6}>
          <FormLabel component="legend">
            {label}
          </FormLabel>
        </Grid>
        <Grid item xs={12} sm={7} md={6}>
          <Typography color="textSecondary" variant="subtitle1">
            Availability
          </Typography>
          <Box sx={{ py: 2 }}>
            <RadioGroup
              name={fieldName}
              onChange={(e, value) => {
                setFieldValue(fieldName, parseInt(value, 10), false);
              }}
              value={fieldValue}
            >
              {availability.map((option) => (
                <FormControlLabel
                  className="radio-label"
                  control={<Radio size="small" />}
                  disabled={isSubmitting || disabled}
                  label={option.label}
                  key={option.value}
                  value={option.value || ''}
                />
              ))}
            </RadioGroup>
          </Box>
          <hr className="dashed" />
          <Box sx={{ py: 2 }}>
            <Typography color="textSecondary" variant="subtitle1">
              Functioning
            </Typography>
            <RadioGroup
              name={fieldFunctioningName}
              onChange={(e, value) => {
                setFieldValue(fieldFunctioningName, parseInt(value, 10), false);
              }}
              row
              value={fieldFunctioningValue}
            >
              {functioning.map((option) => (
                <FormControlLabel
                  className="radio-label"
                  control={<Radio size="small" />}
                  disabled={isSubmitting || disabled || (fieldValue === 3)}
                  label={option.label}
                  key={option.value}
                  value={option.value || ''}
                />
              ))}
            </RadioGroup>
          </Box>
        </Grid>
      </Grid>
    </FormControl>
  );
};

const mapStateToProps = (state) => ({
  isSubmitting: state.messages.isSubmitting
});

export default connect(mapStateToProps)(AvailabilityFunctioningFormGroup);
