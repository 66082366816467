// account
export const EDIT_PROFILE = 'EDIT_PROFILE';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';

// assessments
export const FETCH_ASSESSMENT = 'FETCH_ASSESSMENT';
export const FETCH_ASSESSMENTS = 'FETCH_ASSESSMENTS';
export const UPDATE_ASSESSMENT = 'UPDATE_ASSESSMENT';

// assignments
export const ADD_ASSIGNMENT = 'ADD_ASSIGNMENT';
export const EDIT_ASSIGNMENT = 'EDIT_ASSIGNMENT';
export const FETCH_ASSIGNMENT = 'FETCH_ASSIGNMENT';
export const FETCH_ASSIGNMENTS = 'FETCH_ASSIGNMENTS';

// authentication
export const AUTH_ERROR = 'AUTH_ERROR';
export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const USER_AUTHORIZED = 'USER_AUTHORIZED';
export const USER_LOADING = 'LOADING';

// user
export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USER = 'FETCH_USER';
export const ADD_USER = 'ADD_USER';
export const EDIT_USER = 'EDIT_USER';

// facility
export const ADD_FACILITY = 'ADD_FACILITY';
export const EDIT_FACILITY = 'EDIT_FACILITY';
export const FETCH_FACILITIES = 'FETCH_FACILITIES';
export const FETCH_FACILITY = 'FETCH_FACILITY';

// messages
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';
export const THROW_ERROR = 'THROW_ERROR';
export const THROW_SUCCESS = 'THROW_SUCCESS';
export const THROW_MODAL_SUCCESS = 'THROW_MODAL_SUCCESS';
export const LOADING_DATA = 'LOADING_DATA';
export const SUBMITTING_DATA = 'SUBMITTING_DATA';
export const DONE_LOADING = 'DONE_LOADING';

// places
export const FETCH_BARANGAY = 'FETCH_BARANGAY';
export const FETCH_BARANGAYS = 'FETCH_BARANGAYS';
export const FETCH_PROVINCES = 'FETCH_PROVINCES';

// uploads
export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const FETCH_IMAGES = 'FETCH_IMAGES';
