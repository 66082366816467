import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography
} from '@mui/material';
import AccountDetailsForm from 'src/components/account/AccountDetailsForm';
import { editUser, fetchUser } from 'src/actions/user';
import UserAssignmentsTable from 'src/components/user/UserAssignmentsTable';

const StaffDetail = (props) => {
  const { id } = useParams();
  const {
    users,
    isLoading,
    isSubmitting
  } = props;
  const [staff, setStaff] = useState(null);

  useEffect(() => {
    props.fetchUser(id);
  }, []);

  useEffect(() => {
    setStaff(users[id]);
  }, [users]);

  const handleSubmit = (form) => {
    props.editUser(id, form);
  };

  if (!staff) {
    return (
      <Box className="spinner-container">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      {staff && (
        <Helmet>
          <title>{`${staff.last_name} - Staff - FReSAA`}</title>
        </Helmet>
      )}
      <Box className="box-page">
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography
                color="textPrimary"
                gutterBottom
                variant="h2"
              >
                {`${staff.first_name} ${staff.last_name} - Staff`}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <AccountDetailsForm
                firstName={staff.first_name}
                lastName={staff.last_name}
                email={staff.email}
                phone={staff.phone}
                isActive={staff.is_active}
                isStaff={staff.is_staff}
                isDashboardUser={staff.is_dashboard_user}
                isLoading={isLoading}
                isSubmitting={isSubmitting}
                onSubmit={handleSubmit}
                showPermissions
              />
            </Grid>
            <Grid item xs={12}>
              <UserAssignmentsTable assignments={staff.assignments} />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.messages.isLoading,
  isSubmitting: state.messages.isSubmitting,
  users: state.users
});

export default connect(mapStateToProps, {
  editUser,
  fetchUser
})(StaffDetail);
