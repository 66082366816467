import React from 'react';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  LinearProgress,
  Typography
} from '@mui/material';
import {
  Edit as EditIcon,
  Save as SaveIcon
} from '@mui/icons-material';
import {
  BARANGAY_HEALTH_STATION,
  SAFE_BIRTHING_FACILITY,
  NUTRITION_POST,
  AUX_FACILITY
} from 'src/constants/facilityTypes';
import AvailabilityFunctioningFormGroup from '../formSections/AvailabilityFunctioningFormGroup';

const BasicSupplies = (props) => {
  const {
    basicSuppliesAndEquipments,
    facilityType,
    isLoading,
    isSubmitting,
    readOnly,
    setReadOnly
  } = props;

  const handleEditClick = () => {
    setReadOnly(false);
  };

  const handleSaveClick = (handleSubmit) => {
    handleSubmit();
    setReadOnly(true);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={basicSuppliesAndEquipments}
      onSubmit={(values) => {
        props.onSubmit('basic_supplies_and_equipment', values);
      }}
    >
      {({
        handleSubmit,
        setFieldValue,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardContent>
              <Box className="form-header">
                <Typography variant="h4" gutterBottom>
                  3.2 Basic Supplies and Equipment
                </Typography>
                {readOnly ? (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleEditClick()}
                  >
                    <EditIcon size="medium" />
                  </IconButton>
                ) : (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleSaveClick(handleSubmit)}
                  >
                    <SaveIcon size="medium" />
                  </IconButton>
                )}
              </Box>
            </CardContent>
            <Divider />
            <CardContent sx={{ p: 5 }}>
              <Typography
                color="textSecondary"
                variant="h5"
                sx={{ marginBottom: 3 }}
              >
                Are the following items available today in the main service area and are functioning?
              </Typography>
              <Box sx={{ p: 2 }}>
                <AvailabilityFunctioningFormGroup
                  disabled={readOnly}
                  label="Adult Weighing Scale"
                  fieldName="adult_weighing_scale_available"
                  fieldValue={values.adult_weighing_scale_available}
                  fieldFunctioningName="adult_weighing_scale_functioning"
                  fieldFunctioningValue={values.adult_weighing_scale_functioning}
                  setFieldValue={setFieldValue}
                />
                <Divider />
                { facilityType !== SAFE_BIRTHING_FACILITY && (
                  <>
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Child Weighing Scale (250 Gram Gradation)"
                      fieldName="child_weighing_scale_available"
                      fieldValue={values.child_weighing_scale_available}
                      fieldFunctioningName="child_weighing_scale_functioning"
                      fieldFunctioningValue={values.child_weighing_scale_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <Divider />
                  </>
                )}
                <AvailabilityFunctioningFormGroup
                  disabled={readOnly}
                  label="Infant Weighing Scale (100 Gram Gradation)"
                  fieldName="infant_weighing_scale_available"
                  fieldValue={values.infant_weighing_scale_available}
                  fieldFunctioningName="infant_weighing_scale_functioning"
                  fieldFunctioningValue={values.infant_weighing_scale_functioning}
                  setFieldValue={setFieldValue}
                />
                <Divider />
                <AvailabilityFunctioningFormGroup
                  disabled={readOnly}
                  label="Stadiometer (or Height Rod) For Measuring Height"
                  fieldName="stadiometer_available"
                  fieldValue={values.stadiometer_available}
                  fieldFunctioningName="stadiometer_functioning"
                  fieldFunctioningValue={values.stadiometer_functioning}
                  setFieldValue={setFieldValue}
                />
                <Divider />
                <AvailabilityFunctioningFormGroup
                  disabled={readOnly}
                  label="Thermometer"
                  fieldName="thermometer_available"
                  fieldValue={values.thermometer_available}
                  fieldFunctioningName="thermometer_functioning"
                  fieldFunctioningValue={values.thermometer_functioning}
                  setFieldValue={setFieldValue}
                />
                <Divider />
                <AvailabilityFunctioningFormGroup
                  disabled={readOnly}
                  label="Stethoscope"
                  fieldName="stethoscope_available"
                  fieldValue={values.stethoscope_available}
                  fieldFunctioningName="stethoscope_functioning"
                  fieldFunctioningValue={values.stethoscope_functioning}
                  setFieldValue={setFieldValue}
                />
                <Divider />
                <AvailabilityFunctioningFormGroup
                  disabled={readOnly}
                  label="Sphygmomanometer"
                  fieldName="sphygmomanometer_available"
                  fieldValue={values.sphygmomanometer_available}
                  fieldFunctioningName="sphygmomanometer_functioning"
                  fieldFunctioningValue={values.sphygmomanometer_functioning}
                  setFieldValue={setFieldValue}
                />
                <Divider />
                { (facilityType !== BARANGAY_HEALTH_STATION && facilityType !== NUTRITION_POST && facilityType !== AUX_FACILITY) && (
                  <>
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Light Source for examination (or equivalent)"
                      fieldName="light_source_for_examinations_available"
                      fieldValue={values.light_source_for_examinations_available}
                      fieldFunctioningName="light_source_for_examinations_functioning"
                      fieldFunctioningValue={values.light_source_for_examinations_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <Divider />
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Stretcher or Examining Table"
                      fieldName="stretcher_or_examining_table_available"
                      fieldValue={values.stretcher_or_examining_table_available}
                      fieldFunctioningName="stretcher_or_examining_table_functioning"
                      fieldFunctioningValue={values.stretcher_or_examining_table_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <Divider />
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Wheelchair"
                      fieldName="wheelchair_available"
                      fieldValue={values.wheelchair_available}
                      fieldFunctioningName="wheelchair_functioning"
                      fieldFunctioningValue={values.wheelchair_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <Divider />
                  </>
                )}
                { facilityType !== SAFE_BIRTHING_FACILITY && (
                  <>
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Mid-upper arm circumference tape (Adult)"
                      fieldName="adult_midupper_arm_circumference_tape_available"
                      fieldValue={values.adult_midupper_arm_circumference_tape_available}
                      fieldFunctioningName="adult_midupper_arm_circumference_tape_functioning"
                      fieldFunctioningValue={values.adult_midupper_arm_circumference_tape_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <Divider />
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Mid-upper arm circumference tape (Child)"
                      fieldName="child_midupper_arm_circumference_tape_available"
                      fieldValue={values.child_midupper_arm_circumference_tape_available}
                      fieldFunctioningName="child_midupper_arm_circumference_tape_functioning"
                      fieldFunctioningValue={values.child_midupper_arm_circumference_tape_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <Divider />
                  </>
                )}
                { (facilityType !== BARANGAY_HEALTH_STATION && facilityType !== NUTRITION_POST && facilityType !== AUX_FACILITY) && (
                  <>
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Emergency/Alternative Light Source"
                      fieldName="emergency_light_source_available"
                      fieldValue={values.emergency_light_source_available}
                      fieldFunctioningName="emergency_light_source_functioning"
                      fieldFunctioningValue={values.emergency_light_source_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <Divider />
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Wall Clock with Second Hand"
                      fieldName="wall_clock_with_sec_hand_available"
                      fieldValue={values.wall_clock_with_sec_hand_available}
                      fieldFunctioningName="wall_clock_with_sec_hand_functioning"
                      fieldFunctioningValue={values.wall_clock_with_sec_hand_functioning}
                      setFieldValue={setFieldValue}
                    />
                  </>
                )}
              </Box>
            </CardContent>
            <Divider />
            <Box className="box-button">
              <Button
                disabled={isLoading || isSubmitting || !readOnly}
                color="primary"
                onClick={() => handleEditClick()}
                variant="contained"
                sx={{ mx: 2 }}
              >
                Edit
              </Button>
              <Button
                disabled={isLoading || isSubmitting || readOnly}
                color="primary"
                onClick={() => handleSaveClick(handleSubmit)}
                type="submit"
                variant="contained"
              >
                Save
              </Button>
            </Box>
            {(isLoading || isSubmitting) && <LinearProgress />}
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default BasicSupplies;
