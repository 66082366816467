import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import {
  Box,
  Card,
  CardContent,
  Container,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import AssessmentListResults from 'src/components/assessment/AssessmentListResults';
import facilityTypes from 'src/constants/facilityTypes';

import { fetchAssessments } from 'src/actions/assessment';
import { fetchProvinces } from 'src/actions/places';

const AssessmentList = (props) => {
  const {
    assessments,
    isLoading,
    isSubmitting,
    payload,
    provinces
  } = props;

  const [filterFacilityType, setFilterFacilityType] = useState('all');
  const [filterLocation, setFilterLocation] = useState('all');
  const [page, setPage] = useState(0);

  useEffect(() => {
    props.fetchProvinces();
  }, []);

  useEffect(() => {
    setPage(0);
    let facilityType;
    let province;

    if (filterFacilityType === 'all') facilityType = '';
    else facilityType = filterFacilityType;

    if (filterLocation === 'all') province = '';
    else province = filterLocation;

    props.fetchAssessments(facilityType, province);
  }, [filterFacilityType, filterLocation]);

  return (
    <>
      <Helmet>
        <title>Assessments - FReSAA</title>
      </Helmet>
      <Box className="box-page">
        <Container maxWidth={false}>
          <Card>
            <CardContent>
              <Typography gutterBottom variant="h4">
                Assessments
              </Typography>
              <Box className="assessment-header">
                <Grid container>
                  <Grid item xs={12} sm={2} md={2} sx={{ py: 1 }}>
                    <Typography gutterBottom variant="overline">
                      Filter by:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={5} md={4} sx={{ p: 1 }}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="type-label">
                        Facility Type
                      </InputLabel>
                      <Select
                        disabled={(isSubmitting && payload === 'assessment-list')}
                        label="Facility Type"
                        labelId="type-label"
                        name="type"
                        onChange={(e) => setFilterFacilityType(e.target.value)}
                        value={filterFacilityType}
                      >
                        <MenuItem value="all">
                          All
                        </MenuItem>
                        {facilityTypes.map((ft) => (
                          <MenuItem value={ft.value} key={ft.name}>
                            {ft.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={5} md={4} sx={{ p: 1 }}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="location-label">
                        Location
                      </InputLabel>
                      <Select
                        disabled={(isSubmitting && payload === 'assessment-list')}
                        label="Location"
                        labelId="location-label"
                        name="location"
                        onChange={(e) => setFilterLocation(e.target.value)}
                        value={filterLocation}
                      >
                        <MenuItem value="all">
                          All
                        </MenuItem>
                        {provinces.map((province) => (
                          <MenuItem value={province.name} key={province.id}>
                            {province.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
            {isLoading && <LinearProgress />}
          </Card>
          <Box sx={{ pt: 3 }}>
            <AssessmentListResults
              isLoading={isLoading}
              assessments={assessments}
              page={page}
              setPage={setPage}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  assessments: Object.values(state.assessments),
  isLoading: state.messages.isLoading,
  provinces: Object.values(state.provinces)
});

export default connect(mapStateToProps, {
  fetchAssessments,
  fetchProvinces,
})(AssessmentList);
