import React from 'react';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  LinearProgress,
  Typography
} from '@mui/material';
import {
  Edit as EditIcon,
  Save as SaveIcon
} from '@mui/icons-material';
import {
  BARANGAY_HEALTH_STATION,
  SAFE_BIRTHING_FACILITY,
  NUTRITION_POST,
  AUX_FACILITY
} from 'src/constants/facilityTypes';
import RadioGroupFormControl from '../../formSections/RadioGroupFormControl';
import YesOrNoFormControl from '../../formSections/YesOrNoFormControl';

const availability = [
  {
    label: 'Yes, observed',
    value: 1
  },
  {
    label: 'Reported, not observed',
    value: 2
  },
  {
    label: 'No records kept',
    value: 3
  }
];

const schedule = [
  {
    label: 'Everyday',
    value: 1
  },
  {
    label: 'Once a week',
    value: 2
  },
  {
    label: 'Once a month',
    value: 3
  },
  {
    label: 'Other',
    value: 4
  }
];

const ChildVaccination = (props) => {
  const {
    facilityType,
    formValues,
    isLoading,
    isSubmitting,
    readOnly,
    setReadOnly
  } = props;

  const handleEditClick = () => {
    setReadOnly(false);
  };

  const handleSaveClick = (handleSubmit) => {
    handleSubmit();
    setReadOnly(true);
  };

  const initialValues = {
    bcg_vaccination: null,
    hepatitis_b_vaccination: null,
    pentavalent_vaccination: null,
    inactivated_polio_vaccination: null,
    oral_polio_vaccination: null,
    pneumococcal_conjugate_vaccination: null,
    rotavirus_vaccination: null,
    mr_or_mmr_vaccination: null,
    vaccination_schedule: null,
    vaccination_schedule_other_value: '',
    national_guidelines_present: null,
    training_in_the_last_3_years: null,
    individual_records_for_child_vaccination: null,
    ...formValues
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => {
        props.onSubmit('child_vaccination', values);
      }}
    >
      {({
        handleChange,
        handleSubmit,
        setFieldValue,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardContent>
              <Box className="form-header">
                <Typography variant="h4" gutterBottom>
                  4.6.1 Child Vaccination
                </Typography>
                {readOnly ? (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleEditClick()}
                  >
                    <EditIcon size="medium" />
                  </IconButton>
                ) : (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleSaveClick(handleSubmit)}
                  >
                    <SaveIcon size="medium" />
                  </IconButton>
                )}
              </Box>
            </CardContent>
            <Divider />
            { facilityType !== BARANGAY_HEALTH_STATION && facilityType !== NUTRITION_POST && facilityType !== AUX_FACILITY ? (
              <>
                <CardContent sx={{ p: 5 }}>
                  <Typography color="textSecondary" variant="h5" gutterBottom>
                    Are the following child vaccination services offered in this facility as part of Child Health Services?
                  </Typography>
                  <Box sx={{ p: 2 }}>
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="BCG Vaccination"
                      fieldName="bcg_vaccination"
                      fieldValue={values.bcg_vaccination}
                      setFieldValue={setFieldValue}
                    />
                    {facilityType === SAFE_BIRTHING_FACILITY && (
                      <YesOrNoFormControl
                        disabled={readOnly}
                        label="Hepatitis B Vaccination (Birth Dose)"
                        fieldName="hepatitis_b_vaccination"
                        fieldValue={values.hepatitis_b_vaccination}
                        setFieldValue={setFieldValue}
                      />
                    )}
                    {facilityType !== SAFE_BIRTHING_FACILITY && (
                      <>
                        <YesOrNoFormControl
                          disabled={readOnly}
                          label="DPT-Hib-HepB (Pentavalent) Vaccination"
                          fieldName="pentavalent_vaccination"
                          fieldValue={values.pentavalent_vaccination}
                          setFieldValue={setFieldValue}
                        />
                        <YesOrNoFormControl
                          disabled={readOnly}
                          label="Inactivated Polio Vaccination"
                          fieldName="inactivated_polio_vaccination"
                          fieldValue={values.inactivated_polio_vaccination}
                          setFieldValue={setFieldValue}
                        />
                        <YesOrNoFormControl
                          disabled={readOnly}
                          label="Oral Polio Vaccination"
                          fieldName="oral_polio_vaccination"
                          fieldValue={values.oral_polio_vaccination}
                          setFieldValue={setFieldValue}
                        />
                        <YesOrNoFormControl
                          disabled={readOnly}
                          label="Pneumococcal Conjugate Vaccination"
                          fieldName="pneumococcal_conjugate_vaccination"
                          fieldValue={values.pneumococcal_conjugate_vaccination}
                          setFieldValue={setFieldValue}
                        />
                        <YesOrNoFormControl
                          disabled={readOnly}
                          label="Rotavirus Vaccination"
                          fieldName="rotavirus_vaccination"
                          fieldValue={values.rotavirus_vaccination}
                          setFieldValue={setFieldValue}
                        />
                        <YesOrNoFormControl
                          disabled={readOnly}
                          label="Measles-Rubella (MR) / Measles, Mumps, and Rubella (MMR) Vaccination"
                          fieldName="mr_or_mmr_vaccination"
                          fieldValue={values.mr_or_mmr_vaccination}
                          setFieldValue={setFieldValue}
                        />
                      </>
                    )}
                  </Box>
                  { facilityType !== SAFE_BIRTHING_FACILITY && (
                    <>
                      <RadioGroupFormControl
                        disabled={readOnly}
                        label="What is the schedule for vaccinations in this facility?"
                        fieldName="vaccination_schedule"
                        fieldValue={values.vaccination_schedule}
                        fieldNameOther="vaccination_schedule_other_value"
                        fieldValueOther={values.vaccination_schedule_other_value}
                        options={schedule}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                      />
                      <YesOrNoFormControl
                        disabled={readOnly}
                        label="Is there national guideline, job-aids, checklist, charts on Expanded Program on Immunization/National Immunization Program (e.g. Basic EPI Manual) in the facility"
                        fieldName="national_guidelines_present"
                        fieldValue={values.national_guidelines_present}
                        setFieldValue={setFieldValue}
                      />
                      <YesOrNoFormControl
                        disabled={readOnly}
                        label="Have you or any health worker assigned in this facility been trained in Expanded Program on Immunization/National Immunizaiton Program within the past 5 years?"
                        fieldName="training_in_the_last_3_years"
                        fieldValue={values.training_in_the_last_3_years}
                        setFieldValue={setFieldValue}
                      />
                      <RadioGroupFormControl
                        disabled={readOnly}
                        label="Are individual child vaccination cards or booklest (Child Health Record) maintained in this facility?"
                        fieldName="individual_records_for_child_vaccination"
                        fieldValue={values.individual_records_for_child_vaccination}
                        options={availability}
                        setFieldValue={setFieldValue}
                      />
                    </>
                  )}
                </CardContent>
                <Divider />
                <Box className="box-button">
                  <Button
                    disabled={isLoading || isSubmitting || !readOnly}
                    color="primary"
                    onClick={() => handleEditClick()}
                    variant="contained"
                    sx={{ mx: 2 }}
                  >
                    Edit
                  </Button>
                  <Button
                    disabled={isLoading || isSubmitting || readOnly}
                    color="primary"
                    onClick={() => handleSaveClick(handleSubmit)}
                    type="submit"
                    variant="contained"
                  >
                    Save
                  </Button>
                </Box>
              </>
            ) : (
              <CardContent>
                <Typography variant="subtitle1" color="textSecondary">
                  This section is disabled for this facility.
                </Typography>
              </CardContent>
            )}
            {(isLoading || isSubmitting) && <LinearProgress />}
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default ChildVaccination;
