import React from 'react';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  LinearProgress,
  Typography
} from '@mui/material';
import {
  Edit as EditIcon,
  Save as SaveIcon
} from '@mui/icons-material';
import { INFIRMARY, RURAL_HEALTH_UNIT } from 'src/constants/facilityTypes';
import AvailabilityFunctioningFormGroup from '../../formSections/AvailabilityFunctioningFormGroup';

const ChildPreventiveCareEquipment = (props) => {
  const {
    facilityType,
    formValues,
    isLoading,
    isSubmitting,
    readOnly,
    setReadOnly
  } = props;

  const handleEditClick = () => {
    setReadOnly(false);
  };

  const handleSaveClick = (handleSubmit) => {
    handleSubmit();
    setReadOnly(true);
  };

  const initialValues = {
    digital_thermometer_available: null,
    digital_thermometer_functioning: null,
    pediatric_bp_apparatus_available: null,
    pediatric_bp_apparatus_functioning: null,
    pediatric_stethoscope_available: null,
    pediatric_stethoscope_functioning: null,
    other_device_that_can_measure_secs_available: null,
    other_device_that_can_measure_secs_functioning: null,
    dental_chair_available: null,
    dental_chair_functioning: null,
    air_compressor_available: null,
    air_compressor_functioning: null,
    ultrasonic_scaler_available: null,
    ultrasonic_scaler_functioning: null,
    light_curing_machine_available: null,
    light_curing_machine_functioning: null,
    dental_instruments_available: null,
    dental_instruments_functioning: null,
    calibrated_jar_for_ors_available: null,
    calibrated_jar_for_ors_functioning: null,
    o2_tank_with_regulator_and_humidifier_available: null,
    o2_tank_with_regulator_and_humidifier_functioning: null,
    nebulizer_available: null,
    nebulizer_functioning: null,
    ...formValues
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => {
        props.onSubmit('child_preventive_and_curative_care_equipment', values);
      }}
    >
      {({
        handleSubmit,
        setFieldValue,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardContent>
              <Box className="form-header">
                <Typography variant="h4" gutterBottom>
                  Child Preventive and Curative Care Services
                </Typography>
                {readOnly ? (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleEditClick()}
                  >
                    <EditIcon size="medium" />
                  </IconButton>
                ) : (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleSaveClick(handleSubmit)}
                  >
                    <SaveIcon size="medium" />
                  </IconButton>
                )}
              </Box>
            </CardContent>
            <Divider />
            <CardContent>
              <Box sx={{ p: 2 }}>
                <AvailabilityFunctioningFormGroup
                  disabled={readOnly}
                  label="Digital Thermometer"
                  fieldName="digital_thermometer_available"
                  fieldValue={values.digital_thermometer_available}
                  fieldFunctioningName="digital_thermometer_functioning"
                  fieldFunctioningValue={values.digital_thermometer_functioning}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityFunctioningFormGroup
                  disabled={readOnly}
                  label="Pediatric BP apparatus"
                  fieldName="pediatric_bp_apparatus_available"
                  fieldValue={values.pediatric_bp_apparatus_available}
                  fieldFunctioningName="pediatric_bp_apparatus_functioning"
                  fieldFunctioningValue={values.pediatric_bp_apparatus_functioning}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityFunctioningFormGroup
                  disabled={readOnly}
                  label="Pediatric Stethoscope"
                  fieldName="pediatric_stethoscope_available"
                  fieldValue={values.pediatric_stethoscope_available}
                  fieldFunctioningName="pediatric_stethoscope_functioning"
                  fieldFunctioningValue={values.pediatric_stethoscope_functioning}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityFunctioningFormGroup
                  disabled={readOnly}
                  label="Other device (e.g. cellphone) that can measure seconds"
                  fieldName="other_device_that_can_measure_secs_available"
                  fieldValue={values.other_device_that_can_measure_secs_available}
                  fieldFunctioningName="other_device_that_can_measure_secs_functioning"
                  fieldFunctioningValue={values.other_device_that_can_measure_secs_functioning}
                  setFieldValue={setFieldValue}
                />
                {(facilityType === INFIRMARY || facilityType === RURAL_HEALTH_UNIT) && (
                  <>
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Dental Chair"
                      fieldName="dental_chair_available"
                      fieldValue={values.dental_chair_available}
                      fieldFunctioningName="dental_chair_functioning"
                      fieldFunctioningValue={values.dental_chair_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Air COmpressor"
                      fieldName="air_compressor_available"
                      fieldValue={values.air_compressor_available}
                      fieldFunctioningName="air_compressor_functioning"
                      fieldFunctioningValue={values.air_compressor_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Ultrasonic Scaler"
                      fieldName="ultrasonic_scaler_available"
                      fieldValue={values.ultrasonic_scaler_available}
                      fieldFunctioningName="ultrasonic_scaler_functioning"
                      fieldFunctioningValue={values.ultrasonic_scaler_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Light Curing Machine"
                      fieldName="light_curing_machine_available"
                      fieldValue={values.light_curing_machine_available}
                      fieldFunctioningName="light_curing_machine_functioning"
                      fieldFunctioningValue={values.light_curing_machine_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Dental Instruments"
                      fieldName="dental_instruments_available"
                      fieldValue={values.dental_instruments_available}
                      fieldFunctioningName="dental_instruments_functioning"
                      fieldFunctioningValue={values.dental_instruments_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Calibrated 1/2 or 1-liter measuring jar for ORS"
                      fieldName="calibrated_jar_for_ors_available"
                      fieldValue={values.calibrated_jar_for_ors_available}
                      fieldFunctioningName="calibrated_jar_for_ors_functioning"
                      fieldFunctioningValue={values.calibrated_jar_for_ors_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Oxygen tank with regulator and humidifier"
                      fieldName="o2_tank_with_regulator_and_humidifier_available"
                      fieldValue={values.o2_tank_with_regulator_and_humidifier_available}
                      fieldFunctioningName="o2_tank_with_regulator_and_humidifier_functioning"
                      fieldFunctioningValue={values.o2_tank_with_regulator_and_humidifier_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Nebulizer"
                      fieldName="nebulizer_available"
                      fieldValue={values.nebulizer_available}
                      fieldFunctioningName="nebulizer_functioning"
                      fieldFunctioningValue={values.nebulizer_functioning}
                      setFieldValue={setFieldValue}
                    />
                  </>
                )}
              </Box>
            </CardContent>
            <Divider />
            <Box className="box-button">
              <Button
                disabled={isLoading || isSubmitting || !readOnly}
                color="primary"
                onClick={() => handleEditClick()}
                variant="contained"
                sx={{ mx: 2 }}
              >
                Edit
              </Button>
              <Button
                disabled={isLoading || isSubmitting || readOnly}
                color="primary"
                onClick={() => handleSaveClick(handleSubmit)}
                type="submit"
                variant="contained"
              >
                Save
              </Button>
            </Box>
            {(isLoading || isSubmitting) && <LinearProgress />}
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default ChildPreventiveCareEquipment;
