import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { Box, CircularProgress } from '@mui/material';

const AuthRoute = ({ component: Component, auth, ...rest }) => {
  const navigate = useNavigate();
  const [renderComponent, setRenderComponent] = useState(false);

  useEffect(() => {
    if (auth.isSignedIn) {
      setRenderComponent(true);
    } else if (!auth.isSignedIn && !auth.isLoading && !auth.refreshToken) {
      navigate('/login', { replace: true });
    }
  }, [auth]);

  if (renderComponent) {
    return <Component {...rest} />;
  }

  return (
    <Box className="spinner-container">
      <CircularProgress />
    </Box>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(AuthRoute);
