import React from 'react';
import {
  Card,
  Box,
  Button,
  Container,
  LinearProgress,
  TextField,
  Typography,
  CardContent,
  Grid
} from '@mui/material';
import { Formik } from 'formik';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { resetPassword } from 'src/actions/auth';

const ForgotPassword = (props) => {
  const { isSubmitting } = props;
  return (
    <>
      <Helmet>
        <title>Forgot Password - FReSAA</title>
      </Helmet>
      {isSubmitting && <LinearProgress />}
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Card>
            <CardContent>
              <Formik
                initialValues={{ email: '' }}
                onSubmit={(values) => props.resetPassword(values)}
              >
                {({
                  handleChange,
                  handleSubmit,
                  values
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={3} sx={{ p: 2 }}>
                      <Grid item xs={12}>
                        <Typography
                          color="textPrimary"
                          variant="h2"
                        >
                          Password reset
                        </Typography>
                        <Typography
                          color="textSecondary"
                          variant="subtitle1"
                        >
                          Instructions on how to change your password will be sent to this email address.
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Email address"
                          name="email"
                          onChange={handleChange}
                          type="email"
                          value={values.email}
                          variant="outlined"
                          disabled={isSubmitting}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Link to="/login">
                          <Button
                            aria-label="submit"
                            disabled={isSubmitting}
                            fullWidth
                            size="large"
                            variant="contained"
                            color="tertiary"
                          >
                            Cancel
                          </Button>
                        </Link>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          aria-label="submit"
                          color="primary"
                          disabled={isSubmitting}
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Formik>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  isSubmitting: state.messages.isSubmitting,
  errors: Object.values(state.auth.errors)
});

export default connect(mapStateToProps, {
  resetPassword
})(ForgotPassword);
