import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import {
  Box,
  CircularProgress,
  Container,
  Tab,
  Tabs
} from '@mui/material';
import AssignmentModal from 'src/components/facility/AssignmentModal';
import ListToolbar from 'src/components/common/ListToolBar';
import FacilityListResults from 'src/components/facility/FacilityListResults';

import { addAssignment, editAssignment, fetchAssignments } from 'src/actions/assignment';
import { fetchFacilities } from 'src/actions/facility';
import { fetchProvinces } from 'src/actions/places';
import { fetchUsers } from 'src/actions/user';

const TabPanel = (props) => {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index) => ({
  id: `tab-${index}`,
  'aria-controls': `tabpanel-${index}`,
});

const FacilityList = (props) => {
  const {
    assignments,
    facilities,
    isLoading,
    isSubmitting,
    provinces,
    users
  } = props;

  const initialAssignment = {
    id: '',
    assessor: null,
    startDate: null,
    deadline: null
  };

  const [activeTab, setActiveTab] = useState(0);
  const [assignment, setAssignment] = useState(initialAssignment);
  const [assignmentModalOpen, setAssignmentModalOpen] = useState(false);
  const [isAssigned, setIsAssigned] = useState(false);
  const [selectedFacility, setSelectedFacility] = useState(null);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [province, setProvince] = useState('');
  const [term, setTerm] = useState('');
  const [page, setPage] = useState(0);

  useEffect(() => {
    props.fetchAssignments();
    props.fetchProvinces();
    props.fetchUsers();
  }, []);

  useEffect(() => {
    const activeProvince = provinces[0];
    if (activeProvince && !hasLoaded) {
      setProvince(activeProvince.name);
      setHasLoaded(true);
    }
  }, [provinces]);

  useEffect(() => {
    setPage(0);
    if (hasLoaded) props.fetchFacilities(province, term);
  }, [province, term]);

  const handleAssignment = (facility) => {
    const tmp = assignments.find((assignment) => assignment.facility_id === facility.id);
    if (tmp) {
      const tmpAssessor = users.find((user) => user.id === tmp.assessor_id);
      setAssignment({
        ...assignment,
        id: tmp.id,
        assessor: tmpAssessor,
        startDate: tmp.date
      });
      setIsAssigned(true);
    } else {
      setAssignment(initialAssignment);
      setIsAssigned(false);
    }
    setSelectedFacility(facility);
    setAssignmentModalOpen(true);
  };

  const handleAssignmentSubmit = (form) => {
    if (isAssigned) {
      props.editAssignment(assignment.id, form);
    } else {
      props.addAssignment(form);
    }
  };

  if (hasLoaded) {
    return (
      <>
        <Helmet>
          <title>Facilities - FReSAA</title>
        </Helmet>
        <Box className="box-page">
          <Container maxWidth={false}>
            <ListToolbar
              object="Facility"
              redirect="/facilities/add"
              searchTerm={setTerm}
              isLoading={isLoading}
              isSubmitting={isSubmitting}
            />
            <Box sx={{ pt: 3 }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  aria-label="location tabs"
                  onChange={(e, value) => {
                    setActiveTab(value);
                    setProvince(e.target.textContent);
                  }}
                  scrollButtons="auto"
                  value={activeTab}
                  variant="scrollable"
                >
                  {provinces.map((province, index) => (
                    <Tab label={province.name} {...a11yProps(index)} key={province.id} />
                  ))}
                </Tabs>
              </Box>
              {provinces.map((province, index) => (
                <TabPanel value={activeTab} index={index} key={province.id}>
                  <div>
                    <FacilityListResults
                      assignments={assignments}
                      facilities={facilities}
                      handleAssignment={handleAssignment}
                      isLoading={isLoading}
                      page={page}
                      setPage={setPage}
                    />
                  </div>
                </TabPanel>
              ))}
            </Box>
          </Container>
        </Box>
        {selectedFacility && (
          <AssignmentModal
            assignments={assignments}
            assessor={assignment.assessor}
            deadline={assignment.deadline}
            startDate={assignment.startDate}
            facility={selectedFacility}
            isSubmitting={isSubmitting}
            onSubmit={handleAssignmentSubmit}
            open={assignmentModalOpen}
            setOpen={setAssignmentModalOpen}
            users={users}
          />
        )}
      </>
    );
  }

  return (
    <Box className="spinner-container">
      <CircularProgress />
    </Box>
  );
};

const mapStateToProps = (state) => ({
  assignments: Object.values(state.assignments),
  facilities: Object.values(state.facilities),
  isLoading: state.messages.isLoading,
  isSubmitting: state.messages.isSubmitting,
  provinces: Object.values(state.provinces),
  users: Object.values(state.users),
});

export default connect(mapStateToProps, {
  addAssignment,
  editAssignment,
  fetchAssignments,
  fetchFacilities,
  fetchProvinces,
  fetchUsers
})(FacilityList);
