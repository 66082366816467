export const fields = [
  {
    label: 'Clean Running Water',
    id: 'clean_running_water'
  },
  {
    label: 'Hand-Washing Soap',
    id: 'handwashing_soap'
  },
  {
    label: '70% Isopropyl Alcohol',
    id: 'seventy_percent_isopropyl_alcohol'
  },
  {
    label: 'Disposable Latex Gloves',
    id: 'disposable_latex_gloves'
  },
  {
    label: 'Waste Receptable',
    id: 'water_receptacle_with_lid_and_liner'
  },
  {
    label: 'Segregated, Labeled Garbage Containers',
    id: 'segregated_labeled_garbage_containers'
  },
  {
    label: 'Sharps Container',
    id: 'sharps_container'
  },
  {
    label: 'Infectious Wastes Container',
    id: 'infectious_wastes_container'
  },
  {
    label: 'Environmental Disinfectant, Cleaning Materials',
    id: 'env_disinfectant_and_facilitity_cleaning_materials'
  },
  {
    label: 'Disposable Syringes',
    id: 'disposable_syringes_with_disposable_needles'
  },
  {
    label: 'Auto-Disable Syringes',
    id: 'autodisable_syringes'
  }
];

export const equipmentReuse = [
  {
    label: 'Wash with soap and water only',
    id: 'soap_and_water_only'
  },
  {
    label: 'Soak in chemical solution then wash with soap and water',
    id: 'chemicals_then_soap_and_water'
  },
  {
    label: 'Sterilizer',
    id: 'sterilizer'
  },
  {
    label: 'Autoclave',
    id: 'autoclave'
  },
];

export const sharpDisposal = [
  {
    label: 'Incineration',
    id: 'incineration'
  },
  {
    label: 'Open burning in protected area',
    id: 'open_burning_in_protected_area'
  },
  {
    label: 'Dump without burning in protected area',
    id: 'dump_without_burning'
  },
  {
    label: 'Remove offsite with protected storage',
    id: 'remove_with_protected_storage'
  },
  {
    label: 'Remove offsite and dump in general landfill',
    id: 'remove_and_dump_in_landfill'
  },
  {
    label: 'Septic vault',
    id: 'septic_vault'
  },
];
