import React, { memo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import NotApplicable from 'src/components/common/NotApplicable';
import TableLink from 'src/components/common/TableLink';

const CountTable = (props) => {
  const {
    ariaLabel,
    provinces,
    rows,
    response,
    sectionKey,
    title
  } = props;

  return (
    <Table sx={{ minWidth: 650 }} size="small" aria-label={ariaLabel}>
      <TableHead>
        {title && (
          <TableRow>
            <TableCell colSpan={13}>
              {title}
            </TableCell>
          </TableRow>
        )}
        <TableRow>
          <TableCell>Item</TableCell>
          {provinces.map((province) => (
            <TableCell align="right" key={province.id}>
              {province.name}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        {rows.map((item) => (
          <TableRow
            key={item.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell>
              {item.label}
            </TableCell>
            {provinces.map((province) => {
              const fieldProvince = response[province.id];
              const field = fieldProvince ? fieldProvince[item.id] : 'N/A';

              return (
                <TableCell
                  align="right"
                  key={`${item.id}-${province.id}`}
                >
                  {field != null ? (
                    <TableLink
                      field={item.id}
                      title={item.label}
                      readableValue="Count"
                      province={province}
                      sectionKey={sectionKey}
                    >
                      {field}
                    </TableLink>
                  ) : <NotApplicable />}
                </TableCell>
              );
            })}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default memo(CountTable);
