import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography
} from '@mui/material';
import SectionBreadcrumbs from 'src/components/assessment/SectionBreadcrumbs';
import LiscensingAndCertification from 'src/components/assessment/section3/LiscensingAndCertification';
import PhysicalStructure from 'src/components/assessment/section3/PhysicalStructure';

import { fetchAssessment, updateAssessment } from 'src/actions/assessment';

const useQuery = () => new URLSearchParams(useLocation().search);

const BasicAmenitiesPage = (props) => {
  const query = useQuery();
  const { id } = useParams();
  const { assessments, isLoading, isSubmitting } = props;
  const [assessment, setAssessment] = useState(null);
  const [liscensingAndCertification, setLiscensingAndCertification] = useState(null);
  const [physicalStructure, setPhysicalStructure] = useState(null);
  const [readOnly, setReadOnly] = useState(true);

  useEffect(() => {
    props.fetchAssessment(id);
    const edit = query.get('edit');
    if (edit === 'true') setReadOnly(false);
  }, []);

  useEffect(() => {
    const tmpAssessment = assessments[id];

    if (tmpAssessment) {
      setAssessment(tmpAssessment);
      setLiscensingAndCertification(tmpAssessment.licensing_and_certification);
      setPhysicalStructure(tmpAssessment.physical_structure);
    }
  }, [assessments]);

  const handleSubmit = (subsection, form) => {
    const assessmentForm = {
      ...assessment,
      facility_id: assessment.facility.id,
      [subsection]: form
    };
    props.updateAssessment(id, assessmentForm);
  };

  return (
    <>
      <Helmet>
        <title>
          {assessment && (
            `${assessment.facility.name} - Section 3.1  - Assessment - FReSAA`
          )}
        </title>
      </Helmet>
      <Box className="box-page">
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <SectionBreadcrumbs
              active="section3"
              assessmentId={id}
              next={`/assessments/${id}/sections/3/3.2`}
              previous={`/assessments/${id}/sections/2/2.3`}
            />
            <Grid item xs={12}>
              <Typography
                color="textPrimary"
                gutterBottom
                variant="h2"
              >
                Section 3: Basic Amenities
              </Typography>
            </Grid>
            {assessment ? (
              <>
                <Grid item xs={12} id="3.1.1">
                  <LiscensingAndCertification
                    facilityType={assessment.facility.type}
                    isLoading={isLoading}
                    isSubmitting={isSubmitting}
                    liscensingAndCertification={liscensingAndCertification}
                    onSubmit={handleSubmit}
                    readOnly={readOnly}
                    setReadOnly={setReadOnly}
                  />
                </Grid>
                <Grid item xs={12} id="3.1.2">
                  <PhysicalStructure
                    facilityType={assessment.facility.type}
                    isLoading={isLoading}
                    isSubmitting={isSubmitting}
                    physicalStructure={physicalStructure}
                    onSubmit={handleSubmit}
                    readOnly={readOnly}
                    setReadOnly={setReadOnly}
                  />
                </Grid>
              </>
            ) : (
              <Grid item xs={12}>
                <Box className="spinner-container">
                  <CircularProgress />
                </Box>
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  assessments: state.assessments,
  isLoading: state.messages.isLoading,
  isSubmitting: state.messages.isSubmitting,
});

export default connect(mapStateToProps, {
  fetchAssessment,
  updateAssessment
})(BasicAmenitiesPage);
