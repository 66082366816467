import React, { memo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material/';
import TableLink from 'src/components/common/TableLink';
import NotApplicable from 'src/components/common/NotApplicable';

const ScheduleTable = (props) => {
  const {
    ariaLabel,
    field,
    headers,
    provinces,
    response,
    sectionKey
  } = props;

  return (
    <Table sx={{ minWidth: 650 }} size="small" aria-label={ariaLabel}>
      <TableHead>
        {field && (
          <TableRow>
            <TableCell colSpan={5}>
              {field.label}
            </TableCell>
          </TableRow>
        )}
        <TableRow>
          <TableCell width="30%">Province</TableCell>
          {headers.map((header) => (
            <React.Fragment key={header}>
              <TableCell>{header}</TableCell>
            </React.Fragment>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {provinces.map((province) => {
          const fieldProvince = response[province.id];
          const fieldItem = fieldProvince ? fieldProvince[field.id] : {};

          return (
            <TableRow
              key={`${field}-${province.id}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>{province.name}</TableCell>
              <TableCell>
                {fieldItem ? (
                  <TableLink
                    field={field.id}
                    title={field.label}
                    value="1"
                    readableValue={headers[0]}
                    province={province}
                    sectionKey={sectionKey}
                  >
                    {fieldItem[1]}
                  </TableLink>
                ) : <NotApplicable />}
              </TableCell>
              <TableCell>
                {fieldItem ? (
                  <TableLink
                    field={field.id}
                    title={field.label}
                    value="2"
                    readableValue={headers[1]}
                    province={province}
                    sectionKey={sectionKey}
                  >
                    {fieldItem[2]}
                  </TableLink>
                ) : <NotApplicable />}
              </TableCell>
              <TableCell>
                {fieldItem ? (
                  <TableLink
                    field={field.id}
                    title={field.label}
                    value="3"
                    readableValue={headers[2]}
                    province={province}
                    sectionKey={sectionKey}
                  >
                    {fieldItem[3]}
                  </TableLink>
                ) : <NotApplicable />}
              </TableCell>
              <TableCell>
                {fieldItem ? (
                  <TableLink
                    field={field.id}
                    title={field.label}
                    value="4"
                    readableValue={headers[3]}
                    province={province}
                    sectionKey={sectionKey}
                  >
                    {fieldItem[4]}
                  </TableLink>
                ) : <NotApplicable />}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default memo(ScheduleTable);
