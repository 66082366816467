import React from 'react';
import { TableContainer } from '@mui/material';
import facilityTypes from 'src/constants/fields/section1/facilityTypes';
import FacilityCountTable from '../tables/FacilityCountTable';

const AuxiliaryFacilities = (props) => {
  const { provinces, data } = props;

  return (
    <TableContainer>
      <FacilityCountTable
        ariaLabel="auxiliary facilities"
        provinces={provinces}
        rows={facilityTypes}
        response={data}
        sectionKey="auxiliary"
      />
    </TableContainer>
  );
};

export default AuxiliaryFacilities;
