import React from 'react';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  LinearProgress,
  Typography
} from '@mui/material';
import {
  Edit as EditIcon,
  Save as SaveIcon
} from '@mui/icons-material';
import familyPlanning from 'src/fields/familyPlanning';
import { BARANGAY_HEALTH_STATION, RURAL_HEALTH_UNIT } from 'src/constants/facilityTypes';
import RadioGroupFormControl from '../formSections/RadioGroupFormControl';
import YesOrNoFormControl from '../formSections/YesOrNoFormControl';

const availability = [
  {
    label: 'Yes, observed',
    value: 1
  },
  {
    label: 'Reported, not observed',
    value: 2
  },
  {
    label: 'No records',
    value: 3
  }
];

const FamilyPlanning = (props) => {
  const {
    formValues,
    isLoading,
    isSubmitting,
    readOnly,
    setReadOnly,
    facilityType
  } = props;

  const handleEditClick = () => {
    setReadOnly(false);
  };

  const handleSaveClick = (handleSubmit) => {
    handleSubmit();
    setReadOnly(true);
  };

  const initialValues = {
    ...familyPlanning,
    ...formValues
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => {
        props.onSubmit('family_planning', values);
      }}
    >
      {({
        handleSubmit,
        setFieldValue,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardContent>
              <Box className="form-header">
                <Typography variant="h4" gutterBottom>
                  4.5 Family Planning
                </Typography>
                {readOnly ? (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleEditClick()}
                  >
                    <EditIcon size="medium" />
                  </IconButton>
                ) : (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleSaveClick(handleSubmit)}
                  >
                    <SaveIcon size="medium" />
                  </IconButton>
                )}
              </Box>
            </CardContent>
            <Divider />
            <CardContent sx={{ p: 5 }}>
              <Typography color="textSecondary" variant="h5" gutterBottom>
                Are the following services available in the facility as part of its Family Planning services?
              </Typography>
              <Box sx={{ p: 2 }}>
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Counselling on Family Planning"
                  fieldName="counselling_on_family_planning"
                  fieldValue={values.counselling_on_family_planning}
                  setFieldValue={setFieldValue}
                />
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Provision of Combined oral contraceptive pills"
                  fieldName="combined_oral_contraceptive_pills_provision"
                  fieldValue={values.combined_oral_contraceptive_pills_provision}
                  setFieldValue={setFieldValue}
                />
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Provision of Progestin only contraceptive pills"
                  fieldName="progestin_only_contraceptive_pills_provision"
                  fieldValue={values.progestin_only_contraceptive_pills_provision}
                  setFieldValue={setFieldValue}
                />
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Provision of Progestin only injectable contraceptives"
                  fieldName="progestin_only_injectable_contraceptives_provision"
                  fieldValue={values.progestin_only_injectable_contraceptives_provision}
                  setFieldValue={setFieldValue}
                />
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Provision of Male condom"
                  fieldName="male_condom_provision"
                  fieldValue={values.male_condom_provision}
                  setFieldValue={setFieldValue}
                />
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Intrauterine device insertion"
                  fieldName="intrauterine_device_insertion"
                  fieldValue={values.intrauterine_device_insertion}
                  setFieldValue={setFieldValue}
                />
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Subdermal Implants"
                  fieldName="subdermal_implants"
                  fieldValue={values.subdermal_implants}
                  setFieldValue={setFieldValue}
                />
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Provision of Cycle beads for standard days method"
                  fieldName="cycle_beads_for_standard_days_method_provision"
                  fieldValue={values.cycle_beads_for_standard_days_method_provision}
                  setFieldValue={setFieldValue}
                />
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Visual Inspection using Acetic Acid"
                  fieldName="visual_inspection_using_acetic_acid"
                  fieldValue={values.visual_inspection_using_acetic_acid}
                  setFieldValue={setFieldValue}
                />
              </Box>
              <span className="whitespace" />
              <Typography color="textSecondary" variant="h5" gutterBottom>
                If the following national guidelin and checklist for family planning available in the facility today?
              </Typography>
              <Box sx={{ p: 2 }}>
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="FP Clinical Standards Manual (2014 Edition)"
                  fieldName="fp_clinical_standards_manual_2014_edition"
                  fieldValue={values.fp_clinical_standards_manual_2014_edition}
                  setFieldValue={setFieldValue}
                />
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="FP Form 1"
                  fieldName="fp_form1"
                  fieldValue={values.fp_form1}
                  setFieldValue={setFieldValue}
                />
              </Box>
              <span className="whitespace" />
              <Typography color="textSecondary" variant="h5" gutterBottom>
                Have you or any health worker assigned in this facility been trained in the following within the past 5 years?
              </Typography>
              <Box sx={{ p: 2 }}>
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Basic Family Planning or Family Planning Competency-Based Training (FPBCT) Level I (e.g. Standard Days Method [SDM], Family Awareness-based Method [FAM], and Artificial method of Family Planning)"
                  fieldName="family_planning_training"
                  fieldValue={values.family_planning_training}
                  setFieldValue={setFieldValue}
                />
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Interpersonal Communication Skills"
                  fieldName="interpersonal_communication_skills_training"
                  fieldValue={values.interpersonal_communication_skills_training}
                  setFieldValue={setFieldValue}
                />
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Counseling on Family Planning"
                  fieldName="counselling_on_family_planning_training"
                  fieldValue={values.counselling_on_family_planning_training}
                  setFieldValue={setFieldValue}
                />
                {facilityType !== BARANGAY_HEALTH_STATION && facilityType !== RURAL_HEALTH_UNIT && (
                  <YesOrNoFormControl
                    disabled={readOnly}
                    label="Community Based Monitoring Information System (CBMIS)"
                    fieldName="cbmis_training"
                    fieldValue={values.cbmis_training}
                    setFieldValue={setFieldValue}
                  />
                )}
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Orientation on Contraceptive Self Reliance (CSR) for Family Planning"
                  fieldName="csr_for_family_planning_training"
                  fieldValue={values.csr_for_family_planning_training}
                  setFieldValue={setFieldValue}
                />
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Comprehensive FP Training or FPCBT Level 2 (e.g Intrauterine Device Insertion / Removal Skills and Natural Family Planning"
                  fieldName="fp_or_fpcbt_level2_training"
                  fieldValue={values.fp_or_fpcbt_level2_training}
                  setFieldValue={setFieldValue}
                />
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="No-Scalpel Vasectomy (Training for Physicians)"
                  fieldName="no_scalpel_vasectomy_training"
                  fieldValue={values.no_scalpel_vasectomy_training}
                  setFieldValue={setFieldValue}
                />
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Bilateral Tubal Ligation-Mini-Laparotomy"
                  fieldName="bilateral_tubal_ligation_mini_laparotomy"
                  fieldValue={values.bilateral_tubal_ligation_mini_laparotomy}
                  setFieldValue={setFieldValue}
                />
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Subdermal Implant"
                  fieldName="subdermal_implant_training"
                  fieldValue={values.subdermal_implant_training}
                  setFieldValue={setFieldValue}
                />
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Visual Inspection using Acetic Acid (VIA)"
                  fieldName="via_training"
                  fieldValue={values.via_training}
                  setFieldValue={setFieldValue}
                />
              </Box>
              <RadioGroupFormControl
                disabled={readOnly}
                label="Are individual records or cards maintained in this facility for family planning clients?"
                fieldName="individual_records_for_family_planning_clients"
                fieldValue={values.individual_records_for_family_planning_clients}
                options={availability}
                setFieldValue={setFieldValue}
              />
            </CardContent>
            <Divider />
            <Box className="box-button">
              <Button
                disabled={isLoading || isSubmitting || !readOnly}
                color="primary"
                onClick={() => handleEditClick()}
                variant="contained"
                sx={{ mx: 2 }}
              >
                Edit
              </Button>
              <Button
                disabled={isLoading || isSubmitting || readOnly}
                color="primary"
                onClick={() => handleSaveClick(handleSubmit)}
                type="submit"
                variant="contained"
              >
                Save
              </Button>
            </Box>
            {(isLoading || isSubmitting) && <LinearProgress />}
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default FamilyPlanning;
