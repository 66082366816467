import React from 'react';
import { Box, TableContainer } from '@mui/material';
import AvailableFunctioningTable from 'src/components/dashboard/tables/AvailableFunctioningTable';
import { delivery } from 'src/constants/fields/section4/equipments';

const Section472 = (props) => {
  const { data, provinces } = props;

  return (
    <TableContainer>
      {provinces.map((province) => (
        <React.Fragment key={`4.7.2-delivery-equipments-${province.id}`}>
          <AvailableFunctioningTable
            ariaLabel={`4.7.2 delivery equipments ${province.id}`}
            province={province}
            rows={delivery}
            response={data}
            sectionKey="delivery_services_equipment"
          />
          <Box sx={{ height: 40 }} />
        </React.Fragment>
      ))}
    </TableContainer>
  );
};

export default Section472;
