export default [
  {
    label: 'Adult Weighing Scale',
    available: 'adult_weighing_scale_available',
    functioning: 'adult_weighing_scale_functioning'
  },
  {
    label: 'Child Weighing Scale',
    available: 'child_weighing_scale_available',
    functioning: 'child_weighing_scale_functioning'
  },
  {
    label: 'Infant Weighing Scale',
    available: 'infant_weighing_scale_available',
    functioning: 'infant_weighing_scale_functioning'
  },
  {
    label: 'Stadiometer',
    available: 'stadiometer_available',
    functioning: 'stadiometer_functioning'
  },
  {
    label: 'Thermometer',
    available: 'thermometer_available',
    functioning: 'thermometer_functioning'
  },
  {
    label: 'Stethoscope',
    available: 'stethoscope_available',
    functioning: 'stethoscope_functioning'
  },
  {
    label: 'Sphygmomanometer',
    available: 'sphygmomanometer_available',
    functioning: 'sphygmomanometer_functioning'
  },
  {
    label: 'Mid-upper arm circumference table (Adult)',
    available: 'adult_midupper_arm_circumference_tape_available',
    functioning: 'adult_midupper_arm_circumference_tape_functioning'
  },
  {
    label: 'Mid-upper arm circumference tape (Child)',
    available: 'child_midupper_arm_circumference_tape_available',
    functioning: 'child_midupper_arm_circumference_tape_functioning'
  }
];
