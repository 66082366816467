import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography
} from '@mui/material';
import { connect } from 'react-redux';
import {
  BarChart as BarChartIcon,
  FolderPlus as FolderIcon,
  Globe as GlobeIcon,
  Lock as LockIcon,
  User as UserIcon,
  Users as UsersIcon
} from 'react-feather';
import NavItem from './NavItem';

const menuItems = [
  {
    href: '/account',
    icon: UserIcon,
    title: 'Profile'
  }
];

const staffMenuItems = [
  {
    href: '/dashboard',
    icon: BarChartIcon,
    title: 'Dashboard'
  },
  {
    href: '/facilities',
    icon: GlobeIcon,
    title: 'Facilities'
  },
  {
    href: '/assessments',
    icon: FolderIcon,
    title: 'Assessments'
  },
  {
    href: '/staff',
    icon: UsersIcon,
    title: 'Staff'
  },
];

const DashboardSidebar = ({
  onMobileClose,
  openMobile,
  isSignedIn,
  user
}) => {
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const renderAuthItem = () => {
    if (isSignedIn) {
      return (
        <NavItem
          href="/logout"
          key="logout"
          title="Log out"
          icon={LockIcon}
        />
      );
    }
    return (
      <NavItem
        href="/login"
        key="login"
        title="Log in"
        icon={LockIcon}
      />
    );
  };

  const renderInfo = () => {
    if (user) {
      return (
        <Box className="user-info">
          <RouterLink to="/account">
            <Typography
              color="textPrimary"
              variant="h5"
            >
              {`${user.first_name} ${user.last_name}`}
            </Typography>
            <Typography
              color="textSecondary"
              variant="body2"
            >
              {user.email}
            </Typography>
          </RouterLink>
        </Box>
      );
    }
    return null;
  };

  const renderStaffMenu = () => (
    staffMenuItems.map((item) => (
      <NavItem
        href={item.href}
        key={item.title}
        title={item.title}
        icon={item.icon}
      />
    ))
  );

  const renderMenu = () => (
    menuItems.map((item) => (
      <NavItem
        href={item.href}
        key={item.title}
        title={item.title}
        icon={item.icon}
      />
    ))
  );

  const renderFooter = () => (
    <Box className="sidebar-footer">
      <Divider />
      <RouterLink to="/privacy_policy">
        <Typography variant="body1" sx={{ fontSize: 14 }}>
          Privacy Policy
        </Typography>
      </RouterLink>
    </Box>
  );

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      {renderInfo()}
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          {user && user.is_dashboard_user && renderStaffMenu()}
          {renderMenu()}
          {renderAuthItem()}
        </List>
      </Box>
      {renderFooter()}
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{ sx: { width: 256 } }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)'
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false
};

const mapStateToProps = (state) => ({
  isSignedIn: state.auth.isSignedIn,
  user: state.auth.user
});

export default connect(mapStateToProps)(DashboardSidebar);
