import React from 'react';
import { Box, TableContainer } from '@mui/material';
import AvailableFunctioningTable from 'src/components/dashboard/tables/AvailableFunctioningTable';
import { vaccination } from 'src/constants/fields/section4/equipments';

const Section475 = (props) => {
  const { data, provinces } = props;

  return (
    <TableContainer>
      {provinces.map((province) => (
        <React.Fragment key={`4.7.5-vaccination-equipments-${province.id}`}>
          <AvailableFunctioningTable
            ariaLabel={`4.7.5 vaccination equipments ${province.name}`}
            province={province}
            rows={vaccination}
            response={data}
            sectionKey="child_vaccination_equipment"
          />
          <Box sx={{ height: 40 }} />
        </React.Fragment>
      ))}
    </TableContainer>
  );
};

export default Section475;
