import React from 'react';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  LinearProgress,
  Typography
} from '@mui/material';
import {
  Edit as EditIcon,
  Save as SaveIcon
} from '@mui/icons-material';
import { SAFE_BIRTHING_FACILITY } from 'src/constants/facilityTypes';
import RadioGroupFormControl from '../../formSections/RadioGroupFormControl';
import YesOrNoFormControl from '../../formSections/YesOrNoFormControl';

const availability = [
  {
    label: 'Yes, observed',
    value: 1
  },
  {
    label: 'Reported, not observed',
    value: 2
  },
  {
    label: 'No records kept',
    value: 3
  }
];

const schedule = [
  {
    label: 'Everyday',
    value: 1
  },
  {
    label: 'Once a week',
    value: 2
  },
  {
    label: 'Once a month',
    value: 3
  },
  {
    label: 'Other',
    value: 4
  }
];

const ChildGrowthMonitoringServices = (props) => {
  const {
    facilityType,
    formValues,
    isLoading,
    isSubmitting,
    readOnly,
    setReadOnly
  } = props;

  const handleEditClick = () => {
    setReadOnly(false);
  };

  const handleSaveClick = (handleSubmit) => {
    handleSubmit();
    setReadOnly(true);
  };

  const initialValues = {
    weight_measurement: null,
    height_measurement: null,
    midupper_arm_circumference_measurement: null,
    measurement_schedule: null,
    measurement_schedule_other_value: '',
    national_guidelines_present: null,
    training_in_the_last_3_years: null,
    individual_records_for_child_vaccination: null,
    ...formValues
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => {
        props.onSubmit('child_growth_monitoring', values);
      }}
    >
      {({
        handleChange,
        handleSubmit,
        setFieldValue,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardContent>
              <Box className="form-header">
                <Typography variant="h4" gutterBottom>
                  4.6.2 Child Growth Monitoring Services
                </Typography>
                {readOnly ? (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleEditClick()}
                  >
                    <EditIcon size="medium" />
                  </IconButton>
                ) : (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleSaveClick(handleSubmit)}
                  >
                    <SaveIcon size="medium" />
                  </IconButton>
                )}
              </Box>
            </CardContent>
            <Divider />
            { facilityType !== SAFE_BIRTHING_FACILITY ? (
              <>
                <CardContent sx={{ p: 5 }}>
                  <Typography color="textSecondary" variant="h5" gutterBottom>
                    Are Child Growth Monitoring services offered in this facility?
                  </Typography>
                  <Box sx={{ p: 2 }}>
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Measurement of weight"
                      fieldName="weight_measurement"
                      fieldValue={values.weight_measurement}
                      setFieldValue={setFieldValue}
                    />
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Measurement of height"
                      fieldName="height_measurement"
                      fieldValue={values.height_measurement}
                      setFieldValue={setFieldValue}
                    />
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Measurement of mid-upper arm circumference (MUAC)"
                      fieldName="midupper_arm_circumference_measurement"
                      fieldValue={values.midupper_arm_circumference_measurement}
                      setFieldValue={setFieldValue}
                    />
                  </Box>
                  <RadioGroupFormControl
                    disabled={readOnly}
                    label="What is the schedule for conducting child growth monitoring in this facility?"
                    fieldName="measurement_schedule"
                    fieldNameOther="measurement_schedule_other_value"
                    fieldValue={values.measurement_schedule}
                    fieldValueOther={values.measurement_schedule_other_value}
                    options={schedule}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                  />
                  <YesOrNoFormControl
                    disabled={readOnly}
                    label="Are any national guidelines, job-aids, checklist, charts for growth monitoring (e.g. Child Growth Standards Manual) in the facility today?"
                    fieldName="national_guidelines_present"
                    fieldValue={values.national_guidelines_present}
                    setFieldValue={setFieldValue}
                  />
                  <YesOrNoFormControl
                    disabled={readOnly}
                    label="Have you or any health worker assigned in this facility been trained in Growth Monitoring or Training Course on Child Growth Standards within the past 5 years?"
                    fieldName="training_in_the_last_3_years"
                    fieldValue={values.training_in_the_last_3_years}
                    setFieldValue={setFieldValue}
                  />
                  <RadioGroupFormControl
                    disabled={readOnly}
                    label="Are individual child records for growth monitoring maintained in this facility?"
                    fieldName="individual_records_for_child_vaccination"
                    fieldValue={values.individual_records_for_child_vaccination}
                    options={availability}
                    setFieldValue={setFieldValue}
                  />
                </CardContent>
                <Divider />
                <Box className="box-button">
                  <Button
                    disabled={isLoading || isSubmitting || !readOnly}
                    color="primary"
                    onClick={() => handleEditClick()}
                    variant="contained"
                    sx={{ mx: 2 }}
                  >
                    Edit
                  </Button>
                  <Button
                    disabled={isLoading || isSubmitting || readOnly}
                    color="primary"
                    onClick={() => handleSaveClick(handleSubmit)}
                    type="submit"
                    variant="contained"
                  >
                    Save
                  </Button>
                </Box>
              </>
            ) : (
              <CardContent>
                <Typography variant="subtitle1" color="textSecondary">
                  This section is disabled for this facility.
                </Typography>
              </CardContent>
            )}
            {(isLoading || isSubmitting) && <LinearProgress />}
          </Card>
        </form>
      )}

    </Formik>
  );
};

export default ChildGrowthMonitoringServices;
