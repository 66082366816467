import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  LinearProgress,
  Typography
} from '@mui/material';
import {
  Edit as EditIcon,
  Save as SaveIcon
} from '@mui/icons-material';
import { SAFE_BIRTHING_FACILITY, NUTRITION_POST } from 'src/constants/facilityTypes';
import HCPFormControl from 'src/components/assessment/formSections/HCPFormControl';

const MNCHNOutcomeIndicators = (props) => {
  const {
    facilityType,
    isLoading,
    isSubmitting,
    mnchnOutcomeIndications,
    readOnly,
    setReadOnly
  } = props;

  const handleEditClick = () => {
    setReadOnly(false);
  };

  const handleSaveClick = (handleSubmit) => {
    handleSubmit();
    setReadOnly(true);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={mnchnOutcomeIndications}
      onSubmit={(values) => {
        props.onSubmit('mnchn_outcome_indicators', values);
      }}
      validationSchema={Yup.object().shape({
        maternal_deaths_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        neonatal_deaths_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        reviewed_maternal_deaths_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        reviewed_neonatal_deaths_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        infant_deaths_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        reviewed_infant_deaths_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        child_under_5_deaths_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        child_under_5_low_weight_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        child_under_5_wasting_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        child_under_5_stunting_count: Yup.number().integer().min(0, 'Must be greater than 0'),
      })}
    >
      {({
        errors,
        handleChange,
        handleSubmit,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardContent>
              <Box className="form-header">
                <Typography variant="h4" gutterBottom>
                  2.2 MNCHN-Related Impact/Outcome Indicators
                </Typography>
                {readOnly ? (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleEditClick()}
                  >
                    <EditIcon size="medium" />
                  </IconButton>
                ) : (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleSaveClick(handleSubmit)}
                  >
                    <SaveIcon size="medium" />
                  </IconButton>
                )}
              </Box>
            </CardContent>
            <Divider />
            {facilityType !== NUTRITION_POST ? (
              <>
                <CardContent sx={{ px: 5 }}>
                  <Box sx={{ p: 2 }}>
                    <HCPFormControl
                      disabled={readOnly}
                      label="How many maternal deaths were registered in the past completed year?"
                      name="maternal_deaths_count"
                      handleChange={handleChange}
                      value={values.maternal_deaths_count}
                      error={Boolean(touched.maternal_deaths_count && errors.maternal_deaths_count)}
                      helperText={touched.maternal_deaths_count && errors.maternal_deaths_count}
                    />
                    <HCPFormControl
                      disabled={readOnly}
                      label="Of those who were registered, how many deaths were reviewed?"
                      name="reviewed_maternal_deaths_count"
                      handleChange={handleChange}
                      value={values.reviewed_maternal_deaths_count}
                      error={Boolean(touched.reviewed_maternal_deaths_count && errors.reviewed_maternal_deaths_count)}
                      helperText={touched.reviewed_maternal_deaths_count && errors.reviewed_maternal_deaths_count}
                    />
                    <HCPFormControl
                      disabled={readOnly}
                      label="How many neonatal deaths aged 0-28 days were registered in the past completed year?"
                      name="neonatal_deaths_count"
                      handleChange={handleChange}
                      value={values.neonatal_deaths_count}
                      error={Boolean(touched.neonatal_deaths_count && errors.neonatal_deaths_count)}
                      helperText={touched.neonatal_deaths_count && errors.neonatal_deaths_count}
                    />
                    <HCPFormControl
                      disabled={readOnly}
                      label="Of those who were registered, how many deaths were reviewed?"
                      name="reviewed_neonatal_deaths_count"
                      handleChange={handleChange}
                      value={values.reviewed_neonatal_deaths_count}
                      error={Boolean(touched.reviewed_neonatal_deaths_count && errors.reviewed_neonatal_deaths_count)}
                      helperText={touched.reviewed_neonatal_deaths_count && errors.reviewed_neonatal_deaths_count}
                    />
                    {facilityType !== SAFE_BIRTHING_FACILITY && (
                      <>
                        <HCPFormControl
                          disabled={readOnly}
                          label="How many infant deaths were registered in the past completed year?"
                          handleChange={handleChange}
                          name="infant_deaths_count"
                          value={values.infant_deaths_count}
                          error={Boolean(touched.infant_deaths_count && errors.infant_deaths_count)}
                          helperText={touched.infant_deaths_count && errors.infant_deaths_count}
                        />
                        <HCPFormControl
                          disabled={readOnly}
                          label="Of those who were registered, how many deaths were reviewed?"
                          handleChange={handleChange}
                          name="reviewed_infant_deaths_count"
                          value={values.reviewed_infant_deaths_count}
                          error={Boolean(touched.reviewed_infant_deaths_count && errors.reviewed_infant_deaths_count)}
                          helperText={touched.reviewed_infant_deaths_count && errors.reviewed_infant_deaths_count}
                        />
                        <HCPFormControl
                          disabled={readOnly}
                          label="How many child deaths aged 0-59 months were registered in the past completed year?"
                          handleChange={handleChange}
                          name="child_under_5_deaths_count"
                          value={values.child_under_5_deaths_count}
                          error={Boolean(touched.child_under_5_deaths_count && errors.child_under_5_deaths_count)}
                          helperText={touched.child_under_5_deaths_count && errors.child_under_5_deaths_count}
                        />
                        <HCPFormControl
                          disabled={readOnly}
                          label="Of those who were registered, how many deaths were reviewed?"
                          handleChange={handleChange}
                          name="reviewed_child_under_5_deaths_count"
                          value={values.reviewed_child_under_5_deaths_count}
                          error={Boolean(touched.reviewed_child_under_5_deaths_count && errors.reviewed_child_under_5_deaths_count)}
                          helperText={touched.reviewed_child_under_5_deaths_count && errors.reviewed_child_under_5_deaths_count}
                        />
                        <HCPFormControl
                          disabled={readOnly}
                          label="How many children 0-59 months who have manifested low weight for age (underweight) were recorded in the current year?"
                          handleChange={handleChange}
                          name="child_under_5_low_weight_count"
                          value={values.child_under_5_low_weight_count}
                          error={Boolean(touched.child_under_5_low_weight_count && errors.child_under_5_low_weight_count)}
                          helperText={touched.child_under_5_low_weight_count && errors.child_under_5_low_weight_count}
                        />
                        <HCPFormControl
                          disabled={readOnly}
                          label="How many children 0-59 months who have manifested low weight for height (wasting) were recorded in the current year?"
                          handleChange={handleChange}
                          name="child_under_5_wasting_count"
                          value={values.child_under_5_wasting_count}
                          error={Boolean(touched.child_under_5_wasting_count && errors.child_under_5_wasting_count)}
                          helperText={touched.child_under_5_wasting_count && errors.child_under_5_wasting_count}
                        />
                        <HCPFormControl
                          disabled={readOnly}
                          label="How many children 0-59 months who have manifested low height for age (stunting) were recorded in the current year?"
                          handleChange={handleChange}
                          name="child_under_5_stunting_count"
                          value={values.child_under_5_stunting_count}
                          error={Boolean(touched.child_under_5_stunting_count && errors.child_under_5_stunting_count)}
                          helperText={touched.child_under_5_stunting_count && errors.child_under_5_stunting_count}
                        />
                      </>
                    )}
                  </Box>
                </CardContent>
                <Divider />
                <Box className="box-button">
                  <Button
                    disabled={isLoading || isSubmitting || !readOnly}
                    color="primary"
                    onClick={() => handleEditClick()}
                    variant="contained"
                    sx={{ mx: 2 }}
                  >
                    Edit
                  </Button>
                  <Button
                    disabled={isLoading || isSubmitting || readOnly}
                    color="primary"
                    onClick={() => handleSaveClick(handleSubmit)}
                    type="submit"
                    variant="contained"
                  >
                    Save
                  </Button>
                </Box>
              </>
            ) : (
              <CardContent>
                <Typography variant="subtitle1" color="textSecondary">
                  This section is disabled for this facility.
                </Typography>
              </CardContent>
            )}
            {(isLoading || isSubmitting) && <LinearProgress />}
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default MNCHNOutcomeIndicators;
