export default {
  vitamin_k_injection: null,
  eye_prophylaxis: null,
  hepatitis_b_injection_within_24_hrs: null,
  bcg_injection_within_24_hrs: null,
  bcg_injection_after_24_hrs: null,
  weighing_of_the_newborn: null,
  physical_exam_of_baby: null,
  parenteral_antibiotics_for_moms_with_premature_membrane_rupture: null,
  parenteral_antibiotics_for_meconium_stained_babies: null,
  parenteral_antibiotics_for_neonatal_sepsis: null,
  newborn_screening: null,
  newborn_hearing_screening: null,
  apgar_scoring: null,
  ballard_scoring: null,
  einc_pocket_guide_or_equivalent: null,
  newborn_screening_training: null,
  infant_and_young_child_feeding_training: null,
  expanded_program_on_immunization_training: null
};
