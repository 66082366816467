import React from 'react';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  LinearProgress,
  Typography
} from '@mui/material';
import {
  Edit as EditIcon,
  Save as SaveIcon
} from '@mui/icons-material';
import {
  BARANGAY_HEALTH_STATION,
  SAFE_BIRTHING_FACILITY,
  RURAL_HEALTH_UNIT
} from 'src/constants/facilityTypes';
import newbornCareSupplies from 'src/fields/newbornCareSupplies';
import AvailabilityStockOut from '../../formSections/AvailabilityStockOut';

const NewbornCareSupplies = (props) => {
  const {
    formValues,
    isLoading,
    isSubmitting,
    readOnly,
    setReadOnly,
    facilityType
  } = props;

  const handleEditClick = () => {
    setReadOnly(false);
  };

  const handleSaveClick = (handleSubmit) => {
    handleSubmit();
    setReadOnly(true);
  };

  const initialValues = {
    ...newbornCareSupplies,
    ...formValues
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => {
        props.onSubmit('newborn_care_supplies', values);
      }}
    >
      {({
        handleSubmit,
        setFieldValue,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardContent>
              <Box className="form-header">
                <Typography variant="h4" gutterBottom>
                  Newborn Care
                </Typography>
                {readOnly ? (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleEditClick()}
                  >
                    <EditIcon size="medium" />
                  </IconButton>
                ) : (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleSaveClick(handleSubmit)}
                  >
                    <SaveIcon size="medium" />
                  </IconButton>
                )}
              </Box>
            </CardContent>
            <Divider />
            <CardContent>
              <Box sx={{ p: 2 }}>
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Vitamin K Ampules (at least 2 ampules)"
                  fieldName="vitamin_k_ampules_available"
                  fieldValue={values.vitamin_k_ampules_available}
                  fieldStockOutName="vitamin_k_ampules_rso"
                  fieldStockOutValue={values.vitamin_k_ampules_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Hepatitis B Vaccine (stored inside ref at temp. between 2-8 degree Celsius)"
                  fieldName="hepatitis_b_vaccine_available"
                  fieldValue={values.hepatitis_b_vaccine_available}
                  fieldStockOutName="hepatitis_b_vaccine_rso"
                  fieldStockOutValue={values.hepatitis_b_vaccine_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="BCG Vaccine"
                  fieldName="bcg_vaccine_available"
                  fieldValue={values.bcg_vaccine_available}
                  fieldStockOutName="bcg_vaccine_rso"
                  fieldStockOutValue={values.bcg_vaccine_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Erythromycin Eye Ointment 0.5%"
                  fieldName="erythromycin_eye_ointment_available"
                  fieldValue={values.erythromycin_eye_ointment_available}
                  fieldStockOutName="erythromycin_eye_ointment_rso"
                  fieldStockOutValue={values.erythromycin_eye_ointment_rso}
                  setFieldValue={setFieldValue}
                />
                {facilityType !== BARANGAY_HEALTH_STATION && (
                  <>
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Injectable Ampicillin"
                      fieldName="injectable_ampicillin_available"
                      fieldValue={values.injectable_ampicillin_available}
                      fieldStockOutName="injectable_ampicillin_rso"
                      fieldStockOutValue={values.injectable_ampicillin_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Gentamicin"
                      fieldName="gentamicin_available"
                      fieldValue={values.gentamicin_available}
                      fieldStockOutName="gentamicin_rso"
                      fieldStockOutValue={values.gentamicin_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Amikacin"
                      fieldName="amikacin_available"
                      fieldValue={values.amikacin_available}
                      fieldStockOutName="amikacin_rso"
                      fieldStockOutValue={values.amikacin_rso}
                      setFieldValue={setFieldValue}
                    />
                    {facilityType !== SAFE_BIRTHING_FACILITY && (
                      <AvailabilityStockOut
                        disabled={readOnly}
                        label="Gentian Violet"
                        fieldName="gential_violet_available"
                        fieldValue={values.gential_violet_available}
                        fieldStockOutName="gential_violet_rso"
                        fieldStockOutValue={values.gential_violet_rso}
                        setFieldValue={setFieldValue}
                      />
                    )}
                  </>
                )}
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Sterile water"
                  fieldName="sterile_water_available"
                  fieldValue={values.sterile_water_available}
                  fieldStockOutName="sterile_water_rso"
                  fieldStockOutValue={values.sterile_water_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Clean gloves"
                  fieldName="clean_gloves_available"
                  fieldValue={values.clean_gloves_available}
                  fieldStockOutName="clean_gloves_rso"
                  fieldStockOutValue={values.clean_gloves_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Dry cotton balls/swabs"
                  fieldName="dry_cotton_balls_or_swabs_available"
                  fieldValue={values.dry_cotton_balls_or_swabs_available}
                  fieldStockOutName="dry_cotton_balls_or_swabs_rso"
                  fieldStockOutValue={values.dry_cotton_balls_or_swabs_rso}
                  setFieldValue={setFieldValue}
                />
                {facilityType !== BARANGAY_HEALTH_STATION && facilityType !== RURAL_HEALTH_UNIT && (
                  <>
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Newborn Screening Card/Kit"
                      fieldName="newborn_screening_card_or_kit_available"
                      fieldValue={values.newborn_screening_card_or_kit_available}
                      fieldStockOutName="newborn_screening_card_or_kit_rso"
                      fieldStockOutValue={values.newborn_screening_card_or_kit_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Newborn screening filter"
                      fieldName="newborn_screening_filter_available"
                      fieldValue={values.newborn_screening_filter_available}
                      fieldStockOutName="newborn_screening_filter_rso"
                      fieldStockOutValue={values.newborn_screening_filter_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Sterile Lancets 3mm"
                      fieldName="sterile_lancets_3mm_available"
                      fieldValue={values.sterile_lancets_3mm_available}
                      fieldStockOutName="sterile_lancets_3mm_rso"
                      fieldStockOutValue={values.sterile_lancets_3mm_rso}
                      setFieldValue={setFieldValue}
                    />
                    {facilityType !== SAFE_BIRTHING_FACILITY && (
                      <AvailabilityStockOut
                        disabled={readOnly}
                        label="Warm moist towel"
                        fieldName="warm_moist_towel_available"
                        fieldValue={values.warm_moist_towel_available}
                        fieldStockOutName="warm_moist_towel_rso"
                        fieldStockOutValue={values.warm_moist_towel_rso}
                        setFieldValue={setFieldValue}
                      />
                    )}
                  </>
                )}
              </Box>
            </CardContent>
            <Divider />
            <Box className="box-button">
              <Button
                disabled={isLoading || isSubmitting || !readOnly}
                color="primary"
                onClick={() => handleEditClick()}
                variant="contained"
                sx={{ mx: 2 }}
              >
                Edit
              </Button>
              <Button
                disabled={isLoading || isSubmitting || readOnly}
                color="primary"
                onClick={() => handleSaveClick(handleSubmit)}
                type="submit"
                variant="contained"
              >
                Save
              </Button>
            </Box>
            {(isLoading || isSubmitting) && <LinearProgress />}
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default NewbornCareSupplies;
