export const doh = [
  {
    label: 'BEMONC certified only',
    id: 'doh_bemonc_certified_only'
  },
  {
    label: 'BEMONC + License to Operate',
    id: 'doh_bemonc_plus_license_to_operate'
  },
  {
    label: 'License to Operate',
    id: 'license_to_operate'
  },
  {
    label: 'Mother-Baby Friendly to Facility (for hospital and birthing facility',
    id: 'doh_mother_baby_friendly'
  },
  {
    label: 'Adolescent-Friendly Facility (Level 1, 2 or 3)',
    id: 'doh_adolescent_friendly'
  }
];

export const philhealth = [
  {
    label: 'TSeKAP or PCB 1 (Primary Care Benefit 1) Package',
    id: 'philhealth_tsekap_or_pcb1_package'
  },
  {
    label: 'MCP (Maternity Care Package)',
    id: 'philhealth_maternity_care_package'
  },
  {
    label: 'TB-DOTS Package',
    id: 'philhealth_tb_dots_package'
  },
  {
    label: 'Newborn Screening',
    id: 'philhealth_newborn_screening'
  },
  {
    label: 'Animal Bite Treatment Package',
    id: 'philhealth_animal_bite_treatment_package'
  },
  {
    label: 'Out-Patient Malaria Package',
    id: 'philhealth_outpatient_malaria_package'
  },
];
