import { useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link } from 'react-router-dom';
import {
  Avatar,
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { makeStyles } from '@mui/styles';
import getInitials from 'src/utils/getInitials';
import EnhancedTableToolbar from '../common/EnhancedTableToolbar';
import EnhancedTableHead from '../common/EnhancedTableHead';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => (
  order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
);

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const UserListResults = ({
  handleDeleteClick, isLoading, readOnly, selected, setSelected, setHeight,
  showActions, showCheckbox, showToolbar, toolbarTitle, users, page, setPage
}) => {
  const classes = useStyles();
  const [limit, setLimit] = useState(50);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const numOfResultsOnPage = Math.min(limit, users.length - page * limit);

  const headCells = [
    {
      id: 'avatar',
      numeric: false,
      disablePadding: true,
      label: ''
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Name',
      width: '40%',
    },
    {
      id: 'username',
      numeric: false,
      disablePadding: false,
      label: 'Username',
    },
    {
      id: 'is_active',
      numeric: false,
      disablePadding: false,
      label: 'Active Account',
      width: '10%',
    },
    {
      id: 'is_dashboard_user',
      numeric: false,
      disablePadding: false,
      label: 'Dashboard User',
      width: '10%',
    },
    {
      id: 'is_admin_user',
      numeric: false,
      disablePadding: false,
      label: 'Admin User',
      width: '10%',
    },
  ];

  if (showActions) {
    headCells.push({
      id: 'actions',
      numeric: false,
      disablePadding: true,
      label: '',
    });
  }

  return (
    <>
      <PerfectScrollbar>
        <Box>
          { (showToolbar && !readOnly) && (
            <>
              <EnhancedTableToolbar
                numSelected={selected.length}
                title={toolbarTitle}
                handleDelete={handleDeleteClick}
              />
              <Divider />
            </>
          )}
          <Table>
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
              rowCount={users.length}
              headCells={headCells}
              showCheckbox={showCheckbox}
              readOnly={readOnly}
            />
            <TableBody>
              {stableSort(users, getComparator(order, orderBy))
                .slice(page * limit, page * limit + limit)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      { (showCheckbox && !readOnly) && (
                        <TableCell align="center" className="checkbox" padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            onClick={(event) => handleClick(event, row.id)}
                          />
                        </TableCell>
                      )}
                      <TableCell width="5%">
                        <Box align="right" className="cs-right-box">
                          <Avatar
                            src={row.avatarUrl}
                            sx={{ mr: 2 }}
                            className="cs-avatar"
                          >
                            {getInitials(`${row.first_name} ${row.last_name}`)}
                          </Avatar>
                        </Box>
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" width={row.width}>
                        <Link to={`/staff/${row.id}`}>
                          <Typography
                            color="textPrimary"
                            variant="body1"
                          >
                            {`${row.first_name} ${row.last_name}`}
                          </Typography>
                        </Link>
                      </TableCell>
                      <TableCell>
                        {row.username || row.email}
                      </TableCell>
                      <TableCell width={row.width}>
                        {row.is_active ? ('YES') : ('NO')}
                      </TableCell>
                      <TableCell width={row.width}>
                        {row.is_dashboard_user ? ('YES') : ('NO')}
                      </TableCell>
                      <TableCell width={row.width}>
                        {row.is_staff ? ('YES') : ('NO')}
                      </TableCell>
                      {showActions && (
                        <TableCell>
                          <IconButton aria-label="delete">
                            <RemoveCircleIcon color="primary" />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              {numOfResultsOnPage < 10 && setHeight && (
                <TableRow style={{ height: 53 * (10 - numOfResultsOnPage) }}>
                  <TableCell colSpan={6}>
                    {isLoading && (
                      <Box className="spinner-container">
                        <CircularProgress />
                      </Box>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={users.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[50, 100, 200]}
      />
    </>
  );
};

UserListResults.propTypes = {
  users: PropTypes.array.isRequired
};

export default UserListResults;
