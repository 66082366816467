import React from 'react';
import { Box, TableContainer } from '@mui/material/';
import {
  services,
  schedule,
  guidelines,
  trainings,
  examinations,
  observations
} from 'src/constants/fields/section4/4.6/childPreventiveCurativeCare';
import YesNoTable from '../../tables/YesNoTable';
import ObservationTable from '../../tables/ObservationTable';
import ScheduleTable from '../../tables/ScheduleTable';

const scheduleHeaders = [
  'Everyday',
  'Once a week',
  'Once a month',
  'Other'
];

const Section463 = (props) => {
  const { provinces, data } = props;

  return (
    <TableContainer>
      <YesNoTable
        ariaLabel="4.6.3 child preventive and curative care services"
        provinces={provinces}
        rows={services}
        response={data}
        title="Child Preventive and Curative Care Services"
        sectionKey="child_preventive_and_curative_care"
      />
      <Box sx={{ height: 40 }} />
      <ScheduleTable
        ariaLabel="4.6.3 child preventive and curative care schedule"
        provinces={provinces}
        response={data}
        field={schedule}
        headers={scheduleHeaders}
        sectionKey="child_preventive_and_curative_care"
      />
      <Box sx={{ height: 40 }} />
      <YesNoTable
        ariaLabel="4.6.3 child preventive and curative care guidelines"
        provinces={provinces}
        rows={guidelines}
        response={data}
        title="Available Guidelines"
        sectionKey="child_preventive_and_curative_care"
      />
      <Box sx={{ height: 40 }} />
      <YesNoTable
        ariaLabel="4.6.3 child preventive and curative care trained personnels"
        provinces={provinces}
        rows={trainings}
        response={data}
        title="Health workers trained within the past 3 years"
        sectionKey="child_preventive_and_curative_care"
      />
      <Box sx={{ height: 40 }} />
      <YesNoTable
        ariaLabel="4.6.3 child preventive and curative care examinations"
        provinces={provinces}
        rows={examinations}
        response={data}
        title="Examinations as part of child preventive and curative care services"
        sectionKey="child_preventive_and_curative_care"
      />
      <Box sx={{ height: 40 }} />
      <ObservationTable
        ariaLabel="4.6.2 child preventive and curative care observations"
        provinces={provinces}
        rows={observations}
        response={data}
        sectionKey="child_preventive_and_curative_care"
      />
    </TableContainer>
  );
};

export default Section463;
