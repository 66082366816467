export default {
  fuji_9_gic_available: null,
  fluoride_varnish_available: null,
  pit_and_fissure_sealant_available: null,
  tooth_brush_available: null,
  poly_paste_toothpaste_available: null,
  amoxicillin_suspension_drops_and_syrup_available: null,
  cefalexin_syrup_available: null,
  cotrimoxazole_suspension_available: null,
  flucloxacillin_syrup_available: null,
  injectable_benzathine_benzylpenicillin_available: null,
  injectable_ceftriaxone_available: null,
  injectable_erythromycin_available: null,
  injectable_azithromycin_available: null,
  streptomycin_injectable_available: null,
  epinephrine_available: null,
  paracetamol_syrup_available: null,
  mefenamic_acid_available: null,
  tranexamic_acid_available: null,
  injectable_adrenaline_available: null,
  injectable_diazepram_available: null,
  lidocaine_available: null,
  prednisone_tablet_available: null,
  salbutamol_nebulizer_solution_available: null,
  metered_dose_inhaler_available: null,
  vitamin_a_capsules_available: null,
  zinc_supplements_available: null,
  reformulated_ors_with_zinc_available: null,
  fe_so4_drops_and_syrup_available: null,
  syrup_with_30mg_elemental_iron_available: null,
  tablet_with_60mg_elemental_iron_and_400mcg_folic_acid_available: null,
  iodized_oil_capsule_available: null,
  ten_percent_glucose_available: null,
  ten_percent_glucose_infusion_available: null,
  f75_available: null,
  rutf_available: null,
  albendazole_tablet_available: null,
  mebendazole_tablet_available: null,
  nystatin_available: null,
  chloramphenicol_ointment_available: null,
  povidone_iodine_cream_or_ointment_available: null,
  chlorhexidine_or_salt_water_available: null,
  calamine_lotion_available: null,
  hydrocortisone_acetate_cream_available: null,
  imidazole_cream_available: null,
  acyclovir_cream_or_ointment_available: null,
  podophyllum_resin_and_salicylic_acid_ointment_available: null,
  petroleum_jelly_available: null,
  selenium_sulfide_suspension_available: null,
  sulfur_ointment_available: null,
  ethambutol_available: null,
  isoniazid_available: null,
  pyrazinamide_available: null,
  rifampicin_available: null,
  isoniazid_plus_rifampicin_available: null,
  isoniazid_plus_ethambutol_available: null,
  isoniazid_plus_rifampicin_plus_pyrazinamide_available: null,
  isoniazid_plus_rifampicin_plus_ethambutol_available: null,
  cup_abd_spoon_available: null,
  nasal_cannula_or_nasal_prongs_available: null,
  fg8_nasogastric_tube_available: null,
  blood_glucose_testing_strips_available: null,
  pure_protein_derivative_available: null,
  fuji_9_gic_rso: null,
  fluoride_varnish_rso: null,
  pit_and_fissure_sealant_rso: null,
  tooth_brush_rso: null,
  poly_paste_toothpaste_rso: null,
  amoxicillin_suspension_drops_and_syrup_rso: null,
  cefalexin_syrup_rso: null,
  cotrimoxazole_suspension_rso: null,
  flucloxacillin_syrup_rso: null,
  injectable_benzathine_benzylpenicillin_rso: null,
  injectable_ceftriaxone_rso: null,
  injectable_erythromycin_rso: null,
  injectable_azithromycin_rso: null,
  streptomycin_injectable_rso: null,
  epinephrine_rso: null,
  paracetamol_syrup_rso: null,
  mefenamic_acid_rso: null,
  tranexamic_acid_rso: null,
  injectable_adrenaline_rso: null,
  injectable_diazepram_rso: null,
  lidocaine_rso: null,
  prednisone_tablet_rso: null,
  salbutamol_nebulizer_solution_rso: null,
  metered_dose_inhaler_rso: null,
  vitamin_a_capsules_rso: null,
  zinc_supplements_rso: null,
  reformulated_ors_with_zinc_rso: null,
  fe_so4_drops_and_syrup_rso: null,
  syrup_with_30mg_elemental_iron_rso: null,
  tablet_with_60mg_elemental_iron_and_400mcg_folic_acid_rso: null,
  iodized_oil_capsule_rso: null,
  ten_percent_glucose_rso: null,
  ten_percent_glucose_infusion_rso: null,
  f75_rso: null,
  rutf_rso: null,
  albendazole_tablet_rso: null,
  mebendazole_tablet_rso: null,
  nystatin_rso: null,
  chloramphenicol_ointment_rso: null,
  povidone_iodine_cream_or_ointment_rso: null,
  chlorhexidine_or_salt_water_rso: null,
  calamine_lotion_rso: null,
  hydrocortisone_acetate_cream_rso: null,
  imidazole_cream_rso: null,
  acyclovir_cream_or_ointment_rso: null,
  podophyllum_resin_and_salicylic_acid_ointment_rso: null,
  petroleum_jelly_rso: null,
  selenium_sulfide_suspension_rso: null,
  sulfur_ointment_rso: null,
  ethambutol_rso: null,
  isoniazid_rso: null,
  pyrazinamide_rso: null,
  rifampicin_rso: null,
  isoniazid_plus_rifampicin_rso: null,
  isoniazid_plus_ethambutol_rso: null,
  isoniazid_plus_rifampicin_plus_pyrazinamide_rso: null,
  isoniazid_plus_rifampicin_plus_ethambutol_rso: null,
  cup_abd_spoon_rso: null,
  nasal_cannula_or_nasal_prongs_rso: null,
  fg8_nasogastric_tube_rso: null,
  blood_glucose_testing_strips_rso: null,
  pure_protein_derivative_rso: null
};
