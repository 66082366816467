import React from 'react';
import { Box, TableContainer } from '@mui/material';
import { postnatal } from 'src/constants/fields/section4/supplies';
import AvailableStockoutTable from '../../tables/AvailableStockoutTable';

const Section483 = (props) => {
  const { data, provinces } = props;

  return (
    <TableContainer>
      {provinces.map((province) => (
        <React.Fragment key={`4.8.3-postnatal-supplies${province.id}`}>
          <AvailableStockoutTable
            ariaLabel={`4.8.3-postnatal care supplies${province.name}`}
            province={province}
            rows={postnatal}
            response={data}
            sectionKey="postnatal_care_supplies"
          />
          <Box sx={{ height: 40 }} />
        </React.Fragment>
      ))}
    </TableContainer>
  );
};

export default Section483;
