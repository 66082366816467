import {
  REGISTER_SUCCESS,
  SIGN_IN,
  SIGN_OUT,
  SUBMITTING_DATA,
  THROW_SUCCESS
} from 'src/actions/types';
import {
  throwError,
  tokenConfig,
  getUser
} from 'src/actions';
import api from 'src/apis/api';

export const signIn = (form) => async (dispatch) => {
  dispatch({ type: SUBMITTING_DATA });

  try {
    const response = await api.post('/v1/auth/token/', form);

    await dispatch({
      type: SIGN_IN,
      payload: response.data
    });

    dispatch(getUser);
  } catch (e) {
    throwError(e, dispatch);
  }
};

export const register = (form) => async (dispatch) => {
  dispatch({ type: SUBMITTING_DATA });

  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const response = await api.post('/v1/auth/registration/', form, config);

    dispatch({
      type: REGISTER_SUCCESS,
      payload: response.data
    });

    const formValues = {
      username: form.email,
      password: form.password1
    };
    dispatch(signIn(formValues));
  } catch (e) {
    throwError(e, dispatch);
  }
};

export const signOut = () => async (dispatch, getState) => {
  try {
    const { accessToken } = getState().auth;
    api.post('/v1/auth/logout/', null, tokenConfig(accessToken));

    dispatch({ type: SIGN_OUT });
  } catch (e) {
    throwError(e, dispatch);
  }
};

export const resetPassword = (form) => async (dispatch) => {
  dispatch({ type: SUBMITTING_DATA });

  try {
    const response = await api.post('/v1/auth/password/reset/', form);

    await dispatch({
      type: THROW_SUCCESS,
      payload: response.data.detail
    });

    dispatch(getUser);
  } catch (e) {
    throwError(e, dispatch);
  }
};

export const confirmResetPassword = (form) => async (dispatch) => {
  dispatch({ type: SUBMITTING_DATA });

  try {
    const response = await api.post('/v1/auth/password/reset/confirm/', form);

    await dispatch({
      type: THROW_SUCCESS,
      payload: response.data.detail
    });

    dispatch(getUser);
  } catch (e) {
    throwError(e, dispatch);
  }
};
