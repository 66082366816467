import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  TextField,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import { Close as CloseIcon } from '@mui/icons-material';

const AssessmentReviewerModal = (props) => {
  const {
    isSubmitting,
    open,
    user,
    users
  } = props;
  const [userReviewer, setUserReviewer] = useState(user);

  const handleClose = () => {
    props.setOpen(false);
  };

  const handleSave = () => {
    if (userReviewer) {
      const form = {
        reviewer_id: userReviewer.id
      };
      props.onSubmit(props.id, 'assign', form);
    }
  };

  return (
    <div>
      <Dialog
        maxWidth="sm"
        open={open}
        onClose={handleClose}
      >
        <DialogTitle sx={{ p: 1 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              minWidth: 500
            }}
          >
            <Box sx={{ p: 3 }}>
              <Typography variant="h4" gutterBottom>
                Assign a Reviewer
              </Typography>
            </Box>
            <IconButton
              onClick={() => handleClose()}
              sx={{ alignItems: 'flex-start', display: 'inline-flex', height: '100%' }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ p: 2 }}>
            <Autocomplete
              name="reviewer"
              options={users}
              value={userReviewer}
              onChange={(event, newValue) => {
                setUserReviewer(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Reviewer"
                  variant="outlined"
                  padding="none"
                  required
                />
              )}
              disableClearable
              disabled={isSubmitting}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  {...props}
                >
                  <div style={{ fontWeight: 400, width: '100%' }}>
                    {`${option.first_name} ${option.last_name}`}
                  </div>
                  <Typography variant="body2" color="textSecondary">
                    {option.email}
                  </Typography>
                </Box>
              )}
              getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button disabled={isSubmitting} onClick={handleClose}>
            Cancel
          </Button>
          <Button disabled={isSubmitting} onClick={handleSave}>
            Save
          </Button>
        </DialogActions>
        {isSubmitting && <LinearProgress open />}
      </Dialog>
    </div>
  );
};

export default AssessmentReviewerModal;
