export default {
  combined_oral_contraceptive_pills_available: null,
  progestin_only_contraceptive_pills_available: null,
  progestin_only_injectable_contraceptives_available: null,
  male_condom_available: null,
  intrauterine_device_available: null,
  implant_available: null,
  cycle_beads_for_standard_days_method_available: null,
  acetic_acid_available: null,
  bleach_available: null,
  mops_and_rags_available: null,
  cotton_available: null,
  gauze_available: null,
  pregnancy_test_kit_available: null,
  combined_oral_contraceptive_pills_rso: null,
  progestin_only_contraceptive_pills_rso: null,
  progestin_only_injectable_contraceptives_rso: null,
  male_condom_rso: null,
  intrauterine_device_rso: null,
  implant_rso: null,
  cycle_beads_for_standard_days_method_rso: null,
  acetic_acid_rso: null,
  bleach_rso: null,
  mops_and_rags_rso: null,
  cotton_rso: null,
  gauze_rso: null,
  pregnancy_test_kit_rso: null,
  anesthesia_available: null,
  anesthesia_rso: null
};
