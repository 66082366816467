import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Grid,
  Typography
} from '@mui/material';
import AccountDetailsForm from 'src/components/account/AccountDetailsForm';
import { addUser } from 'src/actions/user';

const StaffCreate = (props) => {
  const {
    isLoading,
    isSubmitting,
    success
  } = props;
  const navigate = useNavigate();

  useEffect(() => {
    if (success) {
      navigate('/staff', { replace: true });
    }
  }, [success]);

  return (
    <>
      <Helmet>
        <title>New Staff - FReSAA</title>
      </Helmet>
      <Box className="box-page">
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography
                color="textPrimary"
                gutterBottom
                variant="h2"
              >
                New Staff User
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <AccountDetailsForm
                isLoading={isLoading}
                isSubmitting={isSubmitting}
                onSubmit={props.addUser}
                showPermissions
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.messages.isLoading,
  isSubmitting: state.messages.isSubmitting,
  success: state.messages.success
});

export default connect(mapStateToProps, {
  addUser
})(StaffCreate);
