import React from 'react';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  LinearProgress,
  Typography
} from '@mui/material';
import {
  Edit as EditIcon,
  Save as SaveIcon
} from '@mui/icons-material';
import {
  BARANGAY_HEALTH_STATION,
  SAFE_BIRTHING_FACILITY,
  NUTRITION_POST,
  AUX_FACILITY
} from 'src/constants/facilityTypes';
import RadioGroupFormControl from '../../formSections/RadioGroupFormControl';
import YesOrNoFormControl from '../../formSections/YesOrNoFormControl';

const availability = [
  {
    label: 'Yes, observed',
    value: 1
  },
  {
    label: 'Reported, not observed',
    value: 2
  },
  {
    label: 'No records kept',
    value: 3
  }
];

const schedule = [
  {
    label: 'Everyday',
    value: 1
  },
  {
    label: 'Once a week',
    value: 2
  },
  {
    label: 'Once a month',
    value: 3
  },
  {
    label: 'Other',
    value: 4
  }
];

const ChildPreventiveAndCurativeCareServices = (props) => {
  const {
    facilityType,
    formValues,
    isLoading,
    isSubmitting,
    readOnly,
    setReadOnly
  } = props;

  const handleEditClick = () => {
    setReadOnly(false);
  };

  const handleSaveClick = (handleSubmit) => {
    handleSubmit();
    setReadOnly(true);
  };

  const initialValues = {
    diagnosis_andor_treatment_of_pneumonia_diarrhea_dengue_measles: null,
    diagnosis_andor_treatment_of_malaria: null,
    diagnosis_andor_treatment_of_malnutrition: null,
    basic_oral_health_care: null,
    vitamin_a_supplementation_to_children: null,
    iron_supplementation_to_low_birthweight_infants: null,
    iron_supplementation_for_children_with_anemia: null,
    zinc_supplementation_for_children_with_diarrhea: null,
    diagnosis_andor_treatment_of_tb: null,
    deworming: null,
    micronutrient_supplementation: null,
    care_schedule: null,
    care_schedule_other_value: '',
    integrated_management_of_child_illness_chart_booklet: null,
    micronutrient_supplementation_program_manual_of_operations: null,
    infant_and_young_child_feeding_manual: null,
    pimam_guidelines: null,
    oral_health_program_guidelines: null,
    integrated_management_of_child_illness_training: null,
    management_of_tb_in_children_training: null,
    management_of_acute_malnutrition_training: null,
    infant_and_young_child_feeding_training: null,
    dentist_trained_in_basic_oral_health_care: null,
    hemoglobin_determination: null,
    test_parasite_in_stool: null,
    malaria_diagnostic_capacity: null,
    individual_records_for_child_illnesses: null,
    ...formValues
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => {
        props.onSubmit('child_preventive_and_curative_care', values);
      }}
    >
      {({
        handleChange,
        handleSubmit,
        setFieldValue,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardContent>
              <Box className="form-header">
                <Typography variant="h4" gutterBottom>
                  4.6.3 Child Preventive and Curative Care Services
                </Typography>
                {readOnly ? (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleEditClick()}
                  >
                    <EditIcon size="medium" />
                  </IconButton>
                ) : (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleSaveClick(handleSubmit)}
                  >
                    <SaveIcon size="medium" />
                  </IconButton>
                )}
              </Box>
            </CardContent>
            <Divider />
            {facilityType !== SAFE_BIRTHING_FACILITY ? (
              <>
                <CardContent sx={{ p: 5 }}>
                  <Typography color="textSecondary" variant="h5" gutterBottom>
                    Are the following carried out in this facility as part of child preventive and curative care services?
                  </Typography>
                  <Box sx={{ p: 2 }}>
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Diagnosis and/or treatment of Pneumonia, Diarrhea, Dengue, and Measles (e.g. Provision of ORS + Zinc)"
                      fieldName="diagnosis_andor_treatment_of_pneumonia_diarrhea_dengue_measles"
                      fieldValue={values.diagnosis_andor_treatment_of_pneumonia_diarrhea_dengue_measles}
                      setFieldValue={setFieldValue}
                    />
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Diagnosis and/or treatment of Malaria"
                      fieldName="diagnosis_andor_treatment_of_malaria"
                      fieldValue={values.diagnosis_andor_treatment_of_malaria}
                      setFieldValue={setFieldValue}
                    />
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Diagnosis and/or treatment of Malnutrition (Moderate Acute Malnutrition and Sever Acute Malnutrition)"
                      fieldName="diagnosis_andor_treatment_of_malnutrition"
                      fieldValue={values.diagnosis_andor_treatment_of_malnutrition}
                      setFieldValue={setFieldValue}
                    />
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Basic Oral Health Care"
                      fieldName="basic_oral_health_care"
                      fieldValue={values.basic_oral_health_care}
                      setFieldValue={setFieldValue}
                    />
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Provide Vitamin A supplementation to children"
                      fieldName="vitamin_a_supplementation_to_children"
                      fieldValue={values.vitamin_a_supplementation_to_children}
                      setFieldValue={setFieldValue}
                    />
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Provide Iron supplementation to low-birthweight infants"
                      fieldName="iron_supplementation_to_low_birthweight_infants"
                      fieldValue={values.iron_supplementation_to_low_birthweight_infants}
                      setFieldValue={setFieldValue}
                    />
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Provide Iron supplementation for children (6-11) with anemia"
                      fieldName="iron_supplementation_for_children_with_anemia"
                      fieldValue={values.iron_supplementation_for_children_with_anemia}
                      setFieldValue={setFieldValue}
                    />
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Provide Zinc supplementation to sick children with diarrhea"
                      fieldName="zinc_supplementation_for_children_with_diarrhea"
                      fieldValue={values.zinc_supplementation_for_children_with_diarrhea}
                      setFieldValue={setFieldValue}
                    />
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Diagnosis and/or treatment of TB in children / Isoniazid Preventive Therapy"
                      fieldName="diagnosis_andor_treatment_of_tb"
                      fieldValue={values.diagnosis_andor_treatment_of_tb}
                      setFieldValue={setFieldValue}
                    />
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Deworming"
                      fieldName="deworming"
                      fieldValue={values.deworming}
                      setFieldValue={setFieldValue}
                    />
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Micronutrient (Vitamin A, Iron and Iodine) supplementation to children"
                      fieldName="micronutrient_supplementation"
                      fieldValue={values.micronutrient_supplementation}
                      setFieldValue={setFieldValue}
                    />
                  </Box>
                  <RadioGroupFormControl
                    disabled={readOnly}
                    label="What is the schedule for delivery of child preventive and curative care services in this facility?"
                    fieldName="care_schedule"
                    fieldNameOther="care_schedule_other_value"
                    fieldValue={values.care_schedule}
                    fieldValueOther={values.care_schedule_other_value}
                    options={schedule}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                  />
                  <span className="whitespace" />
                  <Typography color="textSecondary" variant="h5" gutterBottom>
                    Are the following national guidelines available in the facility today?
                  </Typography>
                  <Box sx={{ p: 2 }}>
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Integrated Management of Child Illness Chart Booklet"
                      fieldName="integrated_management_of_child_illness_chart_booklet"
                      fieldValue={values.integrated_management_of_child_illness_chart_booklet}
                      setFieldValue={setFieldValue}
                    />
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Micronutrient Supplementation Program Manual of Operations"
                      fieldName="micronutrient_supplementation_program_manual_of_operations"
                      fieldValue={values.micronutrient_supplementation_program_manual_of_operations}
                      setFieldValue={setFieldValue}
                    />
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Infant and Young Child Feeding Manual"
                      fieldName="infant_and_young_child_feeding_manual"
                      fieldValue={values.infant_and_young_child_feeding_manual}
                      setFieldValue={setFieldValue}
                    />
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Philippine Integrated Management of Acute Malnutrition (PIMAM) Guidelines"
                      fieldName="pimam_guidelines"
                      fieldValue={values.pimam_guidelines}
                      setFieldValue={setFieldValue}
                    />
                    {facilityType !== BARANGAY_HEALTH_STATION && (
                      <YesOrNoFormControl
                        disabled={readOnly}
                        label="Guidelines in the Implementation of Oral Health Program for Public Health Services sin the Philippines"
                        fieldName="oral_health_program_guidelines"
                        fieldValue={values.oral_health_program_guidelines}
                        setFieldValue={setFieldValue}
                      />
                    )}
                  </Box>
                  <span className="whitespace" />
                  <Typography color="textSecondary" variant="h5" gutterBottom>
                    Have any health worker assigned in this facility been trained in the following within the past 5 years?
                  </Typography>
                  <Box sx={{ p: 2 }}>
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Integrated Management of Child Illness"
                      fieldName="integrated_management_of_child_illness_training"
                      fieldValue={values.integrated_management_of_child_illness_training}
                      setFieldValue={setFieldValue}
                    />
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Management of TB in Children"
                      fieldName="management_of_tb_in_children_training"
                      fieldValue={values.management_of_tb_in_children_training}
                      setFieldValue={setFieldValue}
                    />
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Training on the Philippine Integrated Management of Acute Malnutrition"
                      fieldName="management_of_acute_malnutrition_training"
                      fieldValue={values.management_of_acute_malnutrition_training}
                      setFieldValue={setFieldValue}
                    />
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Infant and Young Child Feeding (IYCF)"
                      fieldName="infant_and_young_child_feeding_training"
                      fieldValue={values.infant_and_young_child_feeding_training}
                      setFieldValue={setFieldValue}
                    />
                    {(facilityType !== BARANGAY_HEALTH_STATION && facilityType !== NUTRITION_POST && facilityType !== AUX_FACILITY) && (
                      <>
                        <YesOrNoFormControl
                          disabled={readOnly}
                          label="Dentist trained in Basic Oral Health Care"
                          fieldName="dentist_trained_in_basic_oral_health_care"
                          fieldValue={values.dentist_trained_in_basic_oral_health_care}
                          setFieldValue={setFieldValue}
                        />
                        <span className="whitespace" />
                        <Typography color="textSecondary" variant="h5" gutterBottom>
                          Do you conduct the following diagnostic examinations in this facility at any time as part of child preventive and curative care services?
                        </Typography>
                        <Box sx={{ p: 2 }}>
                          <YesOrNoFormControl
                            disabled={readOnly}
                            label="Hemoglobin determination"
                            fieldName="hemoglobin_determination"
                            fieldValue={values.hemoglobin_determination}
                            setFieldValue={setFieldValue}
                          />
                          <YesOrNoFormControl
                            disabled={readOnly}
                            label="Test parasite in stool (general microscopy)"
                            fieldName="test_parasite_in_stool"
                            fieldValue={values.test_parasite_in_stool}
                            setFieldValue={setFieldValue}
                          />
                          <YesOrNoFormControl
                            disabled={readOnly}
                            label="Malaria diagnostic capacity"
                            fieldName="malaria_diagnostic_capacity"
                            fieldValue={values.malaria_diagnostic_capacity}
                            setFieldValue={setFieldValue}
                          />
                        </Box>
                      </>
                    )}
                  </Box>
                  {facilityType !== SAFE_BIRTHING_FACILITY && (
                    <RadioGroupFormControl
                      disabled={readOnly}
                      label="Are individual child records (Child Health REcord) for management of childhood illnesses maintained in this facility?"
                      fieldName="individual_records_for_child_illnesses"
                      fieldValue={values.individual_records_for_child_illnesses}
                      options={availability}
                      setFieldValue={setFieldValue}
                    />
                  )}
                </CardContent>
                <Divider />
                <Box className="box-button">
                  <Button
                    disabled={isLoading || isSubmitting || !readOnly}
                    color="primary"
                    onClick={() => handleEditClick()}
                    variant="contained"
                    sx={{ mx: 2 }}
                  >
                    Edit
                  </Button>
                  <Button
                    disabled={isLoading || isSubmitting || readOnly}
                    color="primary"
                    onClick={() => handleSaveClick(handleSubmit)}
                    type="submit"
                    variant="contained"
                  >
                    Save
                  </Button>
                </Box>
              </>
            ) : (
              <CardContent>
                <Typography variant="subtitle1" color="textSecondary">
                  This section is disabled for this facility.
                </Typography>
              </CardContent>
            )}
            {(isLoading || isSubmitting) && <LinearProgress />}
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default ChildPreventiveAndCurativeCareServices;
