import { useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  LinearProgress,
  Link,
  TextField,
  Typography
} from '@mui/material';
import { connect } from 'react-redux';
import { register } from 'src/actions/auth';

const Register = (props) => {
  const navigate = useNavigate();
  const { isSubmitting } = props;

  useEffect(() => {
    if (props.isSignedIn) {
      navigate('/dashboard', { replace: true });
    }
  });

  const renderErrors = props.errors.map((error) => (
    <div key={error} className="cs-error">
      {error}
    </div>
  ));

  return (
    <>
      <Helmet>
        <title>Register - FReSAA</title>
      </Helmet>
      {isSubmitting ? <LinearProgress /> : null}
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              password1: '',
              password2: '',
              policy: false
            }}
            validationSchema={
              Yup.object().shape({
                email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                password1: Yup.string().max(255).required('Password is required'),
                password2: Yup.string().max(255).required('Confirm password is required'),
                policy: Yup.boolean().oneOf([true], 'This field must be checked')
              })
            }
            onSubmit={(values) => {
              const form = {
                ...values,
                organization: 1
              };
              props.register(form);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Create new account
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Use your email to create new account
                  </Typography>
                </Box>
                <TextField
                  disabled={isSubmitting}
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  disabled={isSubmitting}
                  error={Boolean(touched.password1 && errors.password1)}
                  fullWidth
                  helperText={touched.password1 && errors.password1}
                  label="Password"
                  margin="normal"
                  name="password1"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password1}
                  variant="outlined"
                />
                <TextField
                  disabled={isSubmitting}
                  error={Boolean(touched.password2 && errors.password2)}
                  fullWidth
                  helperText={touched.password2 && errors.password2}
                  label="Confirm Password"
                  margin="normal"
                  name="password2"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password2}
                  variant="outlined"
                />
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    ml: -1
                  }}
                >
                  <Checkbox
                    disabled={isSubmitting}
                    checked={values.policy}
                    name="policy"
                    onChange={handleChange}
                  />
                  <Typography
                    color="textSecondary"
                    variant="body1"
                  >
                    I have read the
                    {' '}
                    <Link
                      color="primary"
                      component={RouterLink}
                      to="#"
                      underline="always"
                      variant="h6"
                    >
                      Terms and Conditions
                    </Link>
                  </Typography>
                </Box>
                {Boolean(touched.policy && errors.policy) && (
                  <FormHelperText error>
                    {errors.policy}
                  </FormHelperText>
                )}
                { props.errors.length !== 0 ? renderErrors : ''}
                <Box sx={{ py: 2 }}>
                  <Button
                    aria-label="sign up"
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign up now
                  </Button>
                </Box>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Have an account?
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/login"
                    variant="h6"
                  >
                    Sign in
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  isSignedIn: state.auth.isSignedIn,
  isSubmitting: state.messages.isSubmitting,
  errors: Object.values(state.auth.errors),
});

export default connect(mapStateToProps, { register })(Register);
