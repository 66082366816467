import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  LinearProgress,
  TextField,
  Typography
} from '@mui/material';

const AccountDetailsForm = (props) => {
  const {
    isSubmitting,
    showPermissions
  } = props;

  const initialValues = {
    firstName: props.firstName || '',
    lastName: props.lastName || '',
    email: props.email || '',
    phone: props.phone || '',
    isActive: props.isActive || false,
    isStaff: props.isStaff || false,
    isDashboardUser: props.isDashboardUser || false,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        let form = {
          first_name: values.firstName,
          last_name: values.lastName,
          email: values.email,
          pone: values.phone,
        };

        if (showPermissions) {
          form = {
            ...form,
            is_active: values.isActive,
            is_staff: values.isStaff,
            is_dashboard_user: values.isDashboardUser
          };
        }
        props.onSubmit(form);
      }}
    >
      {({
        errors,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <Card>
            <CardHeader
              subheader="The information can be edited"
              title="Profile"
            />
            <Divider />
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    disabled={isSubmitting}
                    fullWidth
                    label="First name"
                    name="firstName"
                    onChange={handleChange}
                    required
                    value={values.firstName}
                    variant="outlined"
                    error={Boolean(touched.firstName && errors.firstName)}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    disabled={isSubmitting}
                    fullWidth
                    label="Last name"
                    name="lastName"
                    onChange={handleChange}
                    required
                    value={values.lastName}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    disabled={isSubmitting}
                    fullWidth
                    label="Email Address"
                    name="email"
                    onChange={handleChange}
                    required
                    value={values.email}
                    variant="outlined"
                    type="email"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    disabled={isSubmitting}
                    fullWidth
                    label="Phone Number"
                    name="phone"
                    onChange={handleChange}
                    type="text"
                    value={values.phone}
                    variant="outlined"
                  />
                </Grid>
                {showPermissions && (
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column'
                      }}
                    >
                      <Typography variant="subtitle1" color="textSecondary">
                        Permissions
                      </Typography>
                      <FormControlLabel
                        label="Active Account"
                        control={(
                          <Checkbox
                            checked={values.isActive}
                            name="isActive"
                            onChange={handleChange}
                          />
                        )}
                      />
                      <FormControlLabel
                        label="Dashboard User"
                        control={(
                          <Checkbox
                            checked={values.isDashboardUser}
                            name="isDashboardUser"
                            onChange={handleChange}
                          />
                        )}
                      />
                      <FormControlLabel
                        label="Admin User"
                        control={(
                          <Checkbox
                            checked={values.isStaff}
                            name="isStaff"
                            onChange={handleChange}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                )}
              </Grid>
            </CardContent>
            <Divider />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                p: 2
              }}
            >
              <Button
                aria-label="save-details"
                color="primary"
                variant="contained"
                type="submit"
                disabled={isSubmitting}
              >
                Save details
              </Button>
            </Box>
            {isSubmitting ? <LinearProgress open /> : null}
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default AccountDetailsForm;
