import React from 'react';
import TableContainer from '@mui/material/TableContainer';
import {
  fields,
  equipmentReuse,
  sharpDisposal
} from 'src/constants/fields/section3/standardPrecautions';
import { Box } from '@mui/material';
import ObservationTable from '../tables/ObservationTable';
import YesNoTable from '../tables/YesNoTable';

const Section303 = (props) => {
  const { data, provinces } = props;

  return (
    <TableContainer>
      <ObservationTable
        ariaLabel="3.3 standard precautions and waste management supplies"
        provinces={provinces}
        rows={fields}
        response={data}
        sectionKey="precautions_and_waste_management"
      />
      <Box sx={{ height: 40 }} />
      <YesNoTable
        ariaLabel="3.3 standard precautions and waste management equipment reuse"
        provinces={provinces}
        rows={equipmentReuse}
        response={data}
        title="Medical equipment re-use process"
        sectionKey="precautions_and_waste_management"
      />
      <Box sx={{ height: 40 }} />
      <YesNoTable
        ariaLabel="3.3 standard precautions and waste management equipment disposal"
        provinces={provinces}
        rows={sharpDisposal}
        response={data}
        title="Sharps disposal"
        sectionKey="precautions_and_waste_management"
      />
    </TableContainer>
  );
};

export default Section303;
