export default {
  section2: [
    {
      label: '2.1 General MNCHN Services',
      name: 'section201',
      value: 201
    },
    {
      label: '2.2 MNCHN-Related Impact/Outcome Indicators',
      name: 'section202',
      value: 202
    },
    {
      label: '2.3 Health Care Personnel',
      name: 'section203',
      value: 203
    }
  ],
  section3: [
    {
      label: '3.1 Basic Amenities',
      name: 'section301',
      value: 301,
      subsections: [
        {
          label: '3.1.1 Liscensing and Certification',
          name: 'section311',
          value: 311
        },
        {
          label: '3.1.2 Physical Structure of Facility',
          name: 'section312',
          value: 312
        },
      ]
    },
    {
      label: '3.2 Basic Supplies and Equipments',
      name: 'section302',
      value: 302
    },
    {
      label: '3.3 Standard Precautions and Waste Management',
      name: 'section303',
      value: 303
    },
    {
      label: '3.4 Policies',
      name: 'section304',
      value: 304
    },
  ],
  section4: [
    {
      label: '4.1 Antenatal Care',
      name: 'section401',
      value: 401
    },
    {
      label: '4.2 Delivery Services',
      name: 'section402',
      value: 402
    },
    {
      label: '4.3 Postnatal Care',
      name: 'section403',
      value: 403
    },
    {
      label: '4.4 Newborn Care',
      name: 'section404',
      value: 404
    },
    {
      label: '4.5 Family Planning',
      name: 'section405',
      value: 405
    },
    {
      label: '4.6 Child and Adolescent Health Services',
      name: 'section406',
      value: 406,
      subsections: [
        {
          label: '4.6.1 Child Vaccination',
          name: 'section461',
          value: 461
        },
        {
          label: '4.6.2 Child Growth Monitoring Services',
          name: 'section462',
          value: 462
        },
        {
          label: '4.6.3 Child Preventive and Curative Care Services',
          name: 'section463',
          value: 463
        },
        {
          label: '4.6.4 Adolescent Health Services',
          name: 'section464',
          value: 464
        },
      ]
    },
    {
      label: '4.7 Equipment, Instruments, and Other Fixtures',
      name: 'section407',
      value: 407,
      subsections: [
        {
          label: '4.7.1 Antenatal Care',
          name: 'section471',
          value: 471
        },
        {
          label: '4.7.2 Delivery Services',
          name: 'section472',
          value: 472
        },
        {
          label: '4.7.3 Newborn Care',
          name: 'section473',
          value: 473
        },
        {
          label: '4.7.4 Family Planning',
          name: 'section474',
          value: 474
        },
        {
          label: '4.7.5 Child Vaccination',
          name: 'section475',
          value: 475
        },
        {
          label: '4.7.6 Child Growth Monitoring Services',
          name: 'section476',
          value: 476
        },
        {
          label: '4.7.7 Child Preventive and Curative Care Services',
          name: 'section477',
          value: 477
        },
      ]
    },
    {
      label: '4.8 Medicines, Supplies, and Commodities',
      name: 'section408',
      value: 408,
      subsections: [
        {
          label: '4.8.1 Antenatal Care',
          name: 'section481',
          value: 481
        },
        {
          label: '4.8.2 Delivery Services',
          name: 'section482',
          value: 482
        },
        {
          label: '4.8.3 Postnatal Care',
          name: 'section483',
          value: 483
        },
        {
          label: '4.8.4 Newborn Care',
          name: 'section484',
          value: 484
        },
        {
          label: '4.8.5 Family Planning',
          name: 'section485',
          value: 485
        },
        {
          label: '4.8.6 Child Vaccination',
          name: 'section486',
          value: 486
        },
        {
          label: '4.8.7 Child Preventive and Curative Care Services',
          name: 'section487',
          value: 487
        },
      ]
    },
  ]
};
