import React from 'react';
import { Box, TableContainer } from '@mui/material/';
import {
  examinations,
  guidelines,
  training,
  personnel,
  observations
} from 'src/constants/fields/section4/deliveryServices';
import YesNoTable from '../tables/YesNoTable';
import ObservationTable from '../tables/ObservationTable';

const Section402 = (props) => {
  const { provinces, data } = props;

  return (
    <TableContainer>
      <YesNoTable
        ariaLabel="4.2 delivery examinations"
        provinces={provinces}
        rows={examinations}
        response={data}
        sectionKey="delivery_services"
      />
      <Box sx={{ height: 40 }} />
      <YesNoTable
        ariaLabel="4.2 delivery guidelines"
        provinces={provinces}
        rows={guidelines}
        response={data}
        title="Are the following national guidelines, job-aids, checklist, charts for delivery services in the facility today?"
        sectionKey="delivery_services"
      />
      <Box sx={{ height: 40 }} />
      <YesNoTable
        ariaLabel="4.2 delivery training"
        provinces={provinces}
        rows={training}
        response={data}
        title="Health workers assigned trained in the following within the past 5 years"
        sectionKey="delivery_services"
      />
      <Box sx={{ height: 40 }} />
      <YesNoTable
        ariaLabel="4.2 delivery personnels"
        provinces={provinces}
        rows={personnel}
        response={data}
        title="Health workers assigned trained in the following within the past 5 years"
        sectionKey="delivery_services"
      />
      <Box sx={{ height: 40 }} />
      <ObservationTable
        ariaLabel="4.2 delivery observations"
        provinces={provinces}
        rows={observations}
        response={data}
        sectionKey="delivery_services"
      />
    </TableContainer>
  );
};

export default Section402;
