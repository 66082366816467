import React, { memo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import TableLink from 'src/components/common/TableLink';
import NotApplicable from 'src/components/common/NotApplicable';

const AvailableStockoutTable = (props) => {
  const {
    ariaLabel,
    province,
    rows,
    response,
    sectionKey
  } = props;

  return (
    <Table
      sx={{ minWidth: 650 }}
      size="small"
      aria-label={ariaLabel}
    >
      <TableHead>
        <TableRow>
          <TableCell colSpan={7}>
            {province.name}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell width="30%">Item</TableCell>
          <TableCell colSpan={3} align="center" width="35%">
            Availability
          </TableCell>
          <TableCell colSpan={3} align="center" width="35%">
            Stockout Last 3 Months
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell />
          <TableCell align="right" width="10%">Valid, Not Expired</TableCell>
          <TableCell align="right" width="10%">Valid, Expired</TableCell>
          <TableCell align="right" width="10%">No Stock</TableCell>
          <TableCell align="right" width="10%">Yes</TableCell>
          <TableCell align="right" width="10%">No</TableCell>
        </TableRow>
      </TableHead>
      <TableBody sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        {rows.map((item) => {
          const fieldProvince = response[province.id];
          const available = fieldProvince && fieldProvince[item.available];
          const rso = fieldProvince && fieldProvince[item.rso];

          return (
            <TableRow
              hover
              key={item.label}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>{item.label}</TableCell>
              <TableCell align="right">
                {available ? (
                  <TableLink
                    field={item.available}
                    title={item.label}
                    value="1"
                    readableValue="Valid, Not Expired"
                    province={province}
                    sectionKey={sectionKey}
                  >
                    {available[1]}
                  </TableLink>
                ) : <NotApplicable />}
              </TableCell>
              <TableCell align="right">
                {available ? (
                  <TableLink
                    field={item.available}
                    title={item.label}
                    value="2"
                    readableValue="Valid, Expired"
                    province={province}
                    sectionKey={sectionKey}
                  >
                    {available[2]}
                  </TableLink>
                ) : <NotApplicable />}
              </TableCell>
              <TableCell align="right">
                {available ? (
                  <TableLink
                    field={item.available}
                    title={item.label}
                    value="3"
                    readableValue="Not Stock"
                    province={province}
                    sectionKey={sectionKey}
                  >
                    {available[3]}
                  </TableLink>
                ) : <NotApplicable />}
              </TableCell>
              <TableCell align="right">
                {rso ? (
                  <TableLink
                    field={item.rso}
                    title={item.label}
                    value="True"
                    readableValue="Yes, Stockout Last 3 Months"
                    province={province}
                    sectionKey={sectionKey}
                  >
                    {rso.yes}
                  </TableLink>
                ) : <NotApplicable />}
              </TableCell>
              <TableCell align="right">
                {rso ? (
                  <TableLink
                    field={item.rso}
                    title={item.label}
                    value="False"
                    readableValue="No Stockout Last 3 Months"
                    province={province}
                    sectionKey={sectionKey}
                  >
                    {rso.no}
                  </TableLink>
                ) : <NotApplicable />}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default memo(AvailableStockoutTable);
