import React from 'react';
import { TableContainer } from '@mui/material';
import mnchnServices from 'src/constants/fields/section2/mnchnServices';
import YesNoTable from '../tables/YesNoTable';

const Section201 = (props) => {
  const { provinces, data } = props;

  return (
    <TableContainer>
      <YesNoTable
        ariaLabel="2.1 general mnchn services"
        provinces={provinces}
        rows={mnchnServices}
        response={data}
        sectionKey="general_mnchn_services"
      />
    </TableContainer>
  );
};

export default Section201;
