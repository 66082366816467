import React from 'react';
import { TableContainer } from '@mui/material';
import facilityTypes from 'src/constants/fields/section1/facilityTypes';
import AllFacilitiesCountTable from '../tables/AllFacilitiesCountTable';

const AllFacilities = (props) => {
  const { provinces, data } = props;

  return (
    <TableContainer>
      <AllFacilitiesCountTable
        ariaLabel="all facilities"
        provinces={provinces}
        rows={facilityTypes}
        response={data}
        sectionKey="all"
      />
    </TableContainer>
  );
};

export default AllFacilities;
