import React from 'react';
import { connect } from 'react-redux';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material';

const availability = [
  {
    label: 'At least one complete dose available today. Stocks are NOT EXPIRED',
    value: 1
  },
  {
    label: 'At least one complete dose available today. Stocks are EXPIRED',
    value: 2
  },
  {
    label: 'No stock. (Prescribe / Refer)',
    value: 3
  }
];

const AvailabilityStockOut = (props) => {
  const {
    disabled,
    isSubmitting,
    fieldName,
    fieldValue,
    fieldStockOutName,
    fieldStockOutValue,
    label,
    setFieldValue
  } = props;

  return (
    <FormControl component="fieldset" fullWidth>
      <Grid container spacing={3} sx={{ py: 3 }}>
        <Grid item xs={12} sm={5} md={6}>
          <FormLabel component="legend" sx={{ p: 1 }}>
            {label}
          </FormLabel>
        </Grid>
        <Grid item xs={12} sm={7} md={6}>
          <Typography color="textSecondary" variant="subtitle1">
            Availability
          </Typography>
          <Box sx={{ py: 2 }}>
            <RadioGroup
              name={fieldName}
              onChange={(e, value) => {
                setFieldValue(fieldName, parseInt(value, 10), false);
              }}
              value={fieldValue}
            >
              {availability.map((option) => (
                <FormControlLabel
                  className="radio-label"
                  control={<Radio size="small" />}
                  disabled={isSubmitting || disabled}
                  label={option.label}
                  key={option.value}
                  value={option.value || ''}
                />
              ))}
            </RadioGroup>
          </Box>
          <hr className="dashed" />
          <Box sx={{ py: 2 }}>
            <Typography color="textSecondary" variant="subtitle1">
              Has there been a stock-out in the last 3 months?
            </Typography>
            <RadioGroup
              name={fieldStockOutName}
              onChange={(e, value) => {
                const isTrue = (value === 'true' || value === true);
                setFieldValue(fieldStockOutName, isTrue, false);
              }}
              row
              value={fieldStockOutValue}
            >
              <FormControlLabel
                className="radio-label"
                control={<Radio size="small" />}
                disabled={isSubmitting || disabled}
                label="Yes"
                value={true}
              />
              <FormControlLabel
                control={<Radio size="small" />}
                disabled={isSubmitting || disabled}
                label="No"
                value={false}
              />
            </RadioGroup>
          </Box>
        </Grid>
      </Grid>
    </FormControl>
  );
};

const mapStateToProps = (state) => ({
  isSubmitting: state.messages.isSubmitting
});

export default connect(mapStateToProps)(AvailabilityStockOut);
