import React, { memo } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const SectionAccordion = (props) => {
  const {
    data,
    id,
    provinces,
    title,
    ChildComponent
  } = props;

  return (
    <Grid item xs={12}>
      <Accordion TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          id={id}
        >
          <Typography variant="h5" gutterBottom>
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ChildComponent data={data} provinces={provinces} />
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default memo(SectionAccordion);
