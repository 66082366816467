import React from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import EnhancedTableHead from 'src/components/common/EnhancedTableHead';

const assessmentStatus = [
  'New',
  'For Review',
  'Under Review',
  'Reviewed'
];

const headCells = [
  {
    id: 'facility_name',
    label: 'Facility Name'
  },
  {
    id: 'date',
    label: 'Date',
  },
  {
    id: 'assessment',
    label: 'Assessment',
    width: '15%'
  }
];

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => (
  order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
);

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const UserAssignmentsTable = (props) => {
  const { assignments } = props;

  return (
    <Card>
      <CardHeader
        subheader="List of assigned facility assessments"
        title="Assignments"
      />
      <Divider />
      <CardContent>
        {assignments && assignments.length > 0 ? (
          <Table>
            <EnhancedTableHead
              order="asc"
              rowCount={assignments && assignments.count}
              headCells={headCells}
            />
            <TableBody>
              {assignments && stableSort(assignments, getComparator('asc', 'date'))
                .map((row) => {
                  const assessment = row.assessment_id;
                  const rowStatus = row.assessment_status;
                  const status = rowStatus ? assessmentStatus[rowStatus - 1] : '';

                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell>
                        {row.facility_name}
                      </TableCell>
                      <TableCell>
                        {row.date}
                      </TableCell>
                      <TableCell>
                        {status && (
                          <Link to={`/assessments/${assessment}`}>
                            <Chip
                              color="secondary"
                              label={status}
                              onClick={() => null}
                            />
                          </Link>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        ) : (
          <Typography variant="caption" color="textSecondary">
            No assignments found for this staff.
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default UserAssignmentsTable;
