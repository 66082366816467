import {
  AUTH_ERROR,
  USER_AUTHORIZED,
  GET_CURRENT_USER,
  USER_LOADING,
  CLEAR_MESSAGES,
  THROW_ERROR
} from 'src/actions/types';
import api from 'src/apis/api';

export const throwError = (e, dispatch) => {
  if (e.response) {
    dispatch({
      type: THROW_ERROR,
      payload: e.response.data
    });
  } else {
    dispatch({
      type: THROW_ERROR,
      payload: ['Network connection error']
    });
  }
};

export const getAccessToken = () => async (dispatch, getState) => {
  try {
    const body = {
      refresh: getState().auth.refreshToken || 'somerandomstring'
    };
    const response = await api.post('v1/auth/token/refresh/', body);

    dispatch({
      type: USER_AUTHORIZED,
      payload: response.data
    });
  } catch (e) {
    if (e.response && e.response.status === 401) {
      dispatch({ type: AUTH_ERROR });
      dispatch({
        type: THROW_ERROR,
        payload: e.response.data
      });
    } else {
      throwError(e, dispatch);
    }
  }
};

export const tokenConfig = (accessToken) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
  return config;
};

export const getUser = () => async (dispatch, getState) => {
  dispatch({ type: USER_LOADING });

  const { accessToken } = getState().auth;

  try {
    const response = await api.get('v1/auth/user/', tokenConfig(accessToken));

    dispatch({
      type: GET_CURRENT_USER,
      payload: response.data
    });
  } catch (e) {
    if (e.response && e.response.status === 401) {
      if (e.response.data.code !== 'user_inactive') {
        await dispatch(getAccessToken());
        await dispatch(getUser());
      } else {
        dispatch({
          type: THROW_ERROR,
          payload: ['Sorry, this user account was deactivated.']
        });
      }
    } else {
      throwError(e, dispatch);
    }
  }
};

export const clearErrors = () => (dispatch) => {
  dispatch({ type: CLEAR_MESSAGES });
};
