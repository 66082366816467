export const routines = [
  {
    label: 'Hemoglobin postnatal',
    id: 'hemoglobin_postnatal'
  },
  {
    label: 'Iron supplementation',
    id: 'iron_supplementation'
  },
  {
    label: 'Vitamin A supplementation',
    id: 'vitamin_a_supplementation'
  },
  {
    label: 'Postpartum checkup at 24 hours after delivery',
    id: 'postpartum_check_at_24_hours'
  },
  {
    label: 'Postpartum checkup at 48-72 hours after delivery',
    id: 'postpartum_check_at_48_to_72_hours'
  },
  {
    label: 'Postpartum checkup within 1 week of delivery',
    id: 'postpartum_check_within_1_week'
  },
  {
    label: 'Postpartum checkup within 4-6 weeks of delivery',
    id: 'postpartum_check_within_4_to_6_weeks'
  },
  {
    label: 'Blood pressure monitoring postpartum',
    id: 'blood_pressure_monitoring'
  },
  {
    label: 'Counseling on family planning',
    id: 'counseling_on_family_planning'
  },
  {
    label: 'Counseling on breastfeeding',
    id: 'counseling_on_breastfeeding'
  },
];

export const guidelines = [
  {
    label: 'QAP Manual / Pregnancy, Childbirth, Postpartum and Newborn Care Manual',
    id: 'qap_manual_or_equivalent'
  },
  {
    label: 'Early Childhood Care and Development Card',
    id: 'early_childhood_care_development_card'
  },
  {
    label: 'MNIYCF Job Aid',
    id: 'mniycf_job_aid'
  },
];

export const training = [
  {
    label: 'Kangaroo Mother care for Premature Babies',
    id: 'kangaroo_care_training'
  },
  {
    label: 'QAP / Pregnancy, Childbirth, Postpartum and Newborn Care',
    id: 'qap_or_equivalent_training'
  },
  {
    label: 'Infant and Young Child Feeding / Lactation Management Training / Management of Breastfeeding Difficulties (any one)',
    id: 'feeding_training'
  },
  {
    label: 'Modern Methods of Family Planning',
    id: 'modern_methods_of_family_planning_training'
  },
  {
    label: 'Postpartum Intrauterine Device Insertion',
    id: 'intrauterine_device_insertion_training'
  },
];

export const observations = [
  {
    label: 'Maternal Health Record for postpartum visits',
    id: 'maternal_health_records_for_postpartum_visits'
  },
];
