import _ from 'lodash';
import {
  AUTH_ERROR,
  FETCH_PROVINCES,
  SIGN_OUT
} from 'src/actions/types';

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_PROVINCES:
      return {
        ..._.mapKeys(action.payload, 'id')
      };

    case AUTH_ERROR:
    case SIGN_OUT:
      return {};

    default:
      return state;
  }
};
