export default {
  emergency_kit_available: null,
  injectable_uteronic_available: null,
  injectable_broad_spectrum_antibiotic_available: null,
  injectable_magnesium_sulphate_available: null,
  oral_antibiotics_available: null,
  methyldopa_available: null,
  tetracycline_eye_ointment_available: null,
  malaria_medication_available: null,
  injectable_diazepam_available: null,
  iv_infusion_set_available: null,
  iv_cannula_available: null,
  injectable_calcium_gluconate_available: null,
  injectable_hydralazine_available: null,
  nifedipine_tablet_available: null,
  diphenhydramine_available: null,
  corticosteroids_available: null,
  paracetamol_tablet_available: null,
  epinephrine_or_adrenaline_available: null,
  atropine_available: null,
  ligonocaine_available: null,
  sterile_cord_clamp_available: null,
  sterile_gloves_available: null,
  disposable_syringes_with_needles_available: null,
  plaster_available: null,
  iv_fluids_available: null,
  sterile_absorbable_suture_available: null,
  sterile_cutting_needle_available: null,
  sterile_round_needle_available: null,
  sterile_cotton_balls_available: null,
  sterile_cotton_pledgets_available: null,
  sterile_gauze_available: null,
  nasal_cannula_for_adult_available: null,
  inhaled_bronchodilators_available: null,
  partograph_available: null,
  surgical_cap_available: null,
  surgical_mask_available: null,
  patient_gown_available: null,
  scrub_suit_available: null,
  sterile_drape_available: null,
  plastic_apron_available: null,
  kelly_pad_available: null,
  urinary_catheter_with_urine_container_available: null,
  cloth_for_drying_and_warming_newborn_available: null,
  clean_towels_available: null,
  sanitary_pads_available: null,
  baby_feeding_cup_available: null,
  soap_available: null,
  antiseptic_solution_available: null,
  bleach_power_or_tablet_available: null,
  emergency_kit_rso: null,
  injectable_uteronic_rso: null,
  injectable_broad_spectrum_antibiotic_rso: null,
  injectable_magnesium_sulphate_rso: null,
  oral_antibiotics_rso: null,
  methyldopa_rso: null,
  tetracycline_eye_ointment_rso: null,
  malaria_medication_rso: null,
  injectable_diazepam_rso: null,
  iv_infusion_set_rso: null,
  iv_cannula_rso: null,
  injectable_calcium_gluconate_rso: null,
  injectable_hydralazine_rso: null,
  nifedipine_tablet_rso: null,
  diphenhydramine_rso: null,
  corticosteroids_rso: null,
  paracetamol_tablet_rso: null,
  epinephrine_or_adrenaline_rso: null,
  atropine_rso: null,
  ligonocaine_rso: null,
  sterile_cord_clamp_rso: null,
  sterile_gloves_rso: null,
  disposable_syringes_with_needles_rso: null,
  plaster_rso: null,
  iv_fluids_rso: null,
  sterile_absorbable_suture_rso: null,
  sterile_cutting_needle_rso: null,
  sterile_round_needle_rso: null,
  sterile_cotton_balls_rso: null,
  sterile_cotton_pledgets_rso: null,
  sterile_gauze_rso: null,
  nasal_cannula_for_adult_rso: null,
  inhaled_bronchodilators_rso: null,
  partograph_rso: null,
  surgical_cap_rso: null,
  surgical_mask_rso: null,
  patient_gown_rso: null,
  scrub_suit_rso: null,
  sterile_drape_rso: null,
  plastic_apron_rso: null,
  kelly_pad_rso: null,
  urinary_catheter_with_urine_container_rso: null,
  cloth_for_drying_and_warming_newborn_rso: null,
  clean_towels_rso: 2,
  sanitary_pads_rso: null,
  baby_feeding_cup_rso: null,
  soap_rso: null,
  antiseptic_solution_rso: null,
  bleach_power_or_tablet_rso: null
};
