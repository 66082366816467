import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  LinearProgress,
  TextField
} from '@mui/material';
import { changePassword } from 'src/actions/account';

const AccountPassword = (props) => (
  <Formik
    initialValues={{
      password1: '',
      password2: ''
    }}
    validationSchema={Yup.object().shape({
      password1: Yup.string().required('New password is required'),
      password2: Yup.string()
        .required('Confirm password is required')
        .oneOf([Yup.ref('password1'), null], 'Passwords must match')
    })}
    onSubmit={(values) => {
      props.changePassword(values);
    }}
  >
    {({
      errors,
      handleChange,
      handleSubmit,
      touched,
      values
    }) => (
      <form
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <Card>
          <CardHeader
            subheader="Update password"
            title="Password"
          />
          <Divider />
          <CardContent>
            <TextField
              disabled={props.isSubmitting && props.payload === 'passwordForm'}
              fullWidth
              label="New Password"
              margin="normal"
              name="password1"
              onChange={handleChange}
              type="password"
              value={values.password1}
              variant="outlined"
              error={Boolean(touched.password1 && errors.password1)}
              helperText={touched.password1 && errors.password1}
            />
            <TextField
              disabled={props.isSubmitting && props.payload === 'passwordForm'}
              fullWidth
              label="Confirm new password"
              margin="normal"
              name="password2"
              onChange={handleChange}
              type="password"
              value={values.password2}
              variant="outlined"
              error={Boolean(touched.password2 && errors.password2)}
              helperText={touched.password2 && errors.password2}
            />
          </CardContent>
          <Divider />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              p: 2
            }}
          >
            <Button
              aria-label="update"
              color="primary"
              disabled={props.isSubmitting && props.payload === 'passwordForm'}
              variant="contained"
              type="submit"
            >
              Update
            </Button>
          </Box>
          { props.isSubmitting && props.payload === 'passwordForm'
            ? <LinearProgress open />
            : null }
        </Card>
      </form>
    )}
  </Formik>
);

const mapStateToProps = (state) => ({
  isSubmitting: state.messages.isSubmitting,
  payload: state.messages.payload
});

export default connect(mapStateToProps, {
  changePassword
})(AccountPassword);
