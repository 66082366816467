import React from 'react';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  LinearProgress,
  Typography
} from '@mui/material';
import {
  Edit as EditIcon,
  Save as SaveIcon
} from '@mui/icons-material';
import { BARANGAY_HEALTH_STATION, AUX_FACILITY } from 'src/constants/facilityTypes';
import familyPlanningSupplies from 'src/fields/familyPlanningSupplies';
import AvailabilityStockOut from '../../formSections/AvailabilityStockOut';

const FamilyPlanningSupplies = (props) => {
  const {
    facilityType,
    formValues,
    isLoading,
    isSubmitting,
    readOnly,
    setReadOnly
  } = props;

  const handleEditClick = () => {
    setReadOnly(false);
  };

  const handleSaveClick = (handleSubmit) => {
    handleSubmit();
    setReadOnly(true);
  };

  const initialValues = {
    ...familyPlanningSupplies,
    ...formValues
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => {
        props.onSubmit('family_planning_supplies', values);
      }}
    >
      {({
        handleSubmit,
        setFieldValue,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardContent>
              <Box className="form-header">
                <Typography variant="h4" gutterBottom>
                  Family Planning
                </Typography>
                {readOnly ? (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleEditClick()}
                  >
                    <EditIcon size="medium" />
                  </IconButton>
                ) : (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleSaveClick(handleSubmit)}
                  >
                    <SaveIcon size="medium" />
                  </IconButton>
                )}
              </Box>
            </CardContent>
            <Divider />
            <CardContent>
              <Box sx={{ p: 2 }}>
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Combined oral contraceptive pills"
                  fieldName="combined_oral_contraceptive_pills_available"
                  fieldValue={values.combined_oral_contraceptive_pills_available}
                  fieldStockOutName="combined_oral_contraceptive_pills_rso"
                  fieldStockOutValue={values.combined_oral_contraceptive_pills_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Progestin-only contraceptive pills"
                  fieldName="progestin_only_contraceptive_pills_available"
                  fieldValue={values.progestin_only_contraceptive_pills_available}
                  fieldStockOutName="progestin_only_contraceptive_pills_rso"
                  fieldStockOutValue={values.progestin_only_contraceptive_pills_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Progestin-only injectable contraceptives"
                  fieldName="progestin_only_injectable_contraceptives_available"
                  fieldValue={values.progestin_only_injectable_contraceptives_available}
                  fieldStockOutName="progestin_only_injectable_contraceptives_rso"
                  fieldStockOutValue={values.progestin_only_injectable_contraceptives_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Male condom"
                  fieldName="male_condom_available"
                  fieldValue={values.male_condom_available}
                  fieldStockOutName="male_condom_rso"
                  fieldStockOutValue={values.male_condom_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Intrauterine device"
                  fieldName="intrauterine_device_available"
                  fieldValue={values.intrauterine_device_available}
                  fieldStockOutName="intrauterine_device_rso"
                  fieldStockOutValue={values.intrauterine_device_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Implant"
                  fieldName="implant_available"
                  fieldValue={values.implant_available}
                  fieldStockOutName="implant_rso"
                  fieldStockOutValue={values.implant_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Cycle beads for standard days method"
                  fieldName="cycle_beads_for_standard_days_method_available"
                  fieldValue={values.cycle_beads_for_standard_days_method_available}
                  fieldStockOutName="cycle_beads_for_standard_days_method_rso"
                  fieldStockOutValue={values.cycle_beads_for_standard_days_method_rso}
                  setFieldValue={setFieldValue}
                />
                {(facilityType !== BARANGAY_HEALTH_STATION && facilityType !== AUX_FACILITY) && (
                  <>
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Anesthesia"
                      fieldName="anesthesia_available"
                      fieldValue={values.anesthesia_available}
                      fieldStockOutName="anesthesia_rso"
                      fieldStockOutValue={values.anesthesia_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Acetic Acid"
                      fieldName="acetic_acid_available"
                      fieldValue={values.acetic_acid_available}
                      fieldStockOutName="acetic_acid_rso"
                      fieldStockOutValue={values.acetic_acid_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Bleach for Preparing 0.5% Decontaminating Solution"
                      fieldName="bleach_available"
                      fieldValue={values.bleach_available}
                      fieldStockOutName="bleach_rso"
                      fieldStockOutValue={values.bleach_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Mops and Rags"
                      fieldName="mops_and_rags_available"
                      fieldValue={values.mops_and_rags_available}
                      fieldStockOutName="mops_and_rags_rso"
                      fieldStockOutValue={values.mops_and_rags_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Cottons"
                      fieldName="cotton_available"
                      fieldValue={values.cotton_available}
                      fieldStockOutName="cotton_rso"
                      fieldStockOutValue={values.cotton_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Gauze"
                      fieldName="gauze_available"
                      fieldValue={values.gauze_available}
                      fieldStockOutName="gauze_rso"
                      fieldStockOutValue={values.gauze_rso}
                      setFieldValue={setFieldValue}
                    />
                  </>
                )}
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Pregnancy Test Kit"
                  fieldName="pregnancy_test_kit_available"
                  fieldValue={values.pregnancy_test_kit_available}
                  fieldStockOutName="pregnancy_test_kit_rso"
                  fieldStockOutValue={values.pregnancy_test_kit_rso}
                  setFieldValue={setFieldValue}
                />
              </Box>
            </CardContent>
            <Divider />
            <Box className="box-button">
              <Button
                disabled={isLoading || isSubmitting || !readOnly}
                color="primary"
                onClick={() => handleEditClick()}
                variant="contained"
                sx={{ mx: 2 }}
              >
                Edit
              </Button>
              <Button
                disabled={isLoading || isSubmitting || readOnly}
                color="primary"
                onClick={() => handleSaveClick(handleSubmit)}
                type="submit"
                variant="contained"
              >
                Save
              </Button>
            </Box>
            {(isLoading || isSubmitting) && <LinearProgress />}
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default FamilyPlanningSupplies;
