import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Card,
  Container
} from '@mui/material';
import UserListResults from 'src/components/user/UserListResults';
import { fetchUsers } from 'src/actions/user';
import { connect } from 'react-redux';
import ListToolbar from 'src/components/common/ListToolBar';

const StaffList = (props) => {
  const { isLoading, users } = props;
  const [selected, setSelected] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);

  useEffect(() => {
    props.fetchUsers();
  }, []);

  useEffect(() => {
    setPage(0);
    props.fetchUsers(searchTerm);
  }, [searchTerm]);

  return (
    <>
      <Helmet>
        <title>Staff - FReSAA</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <ListToolbar
            object="Staff"
            redirect="/staff/add"
            searchTerm={setSearchTerm}
            isLoading={isLoading}
          />
          <Box sx={{ pt: 3 }}>
            <Card>
              <UserListResults
                isLoading={isLoading}
                selected={selected}
                setSelected={setSelected}
                setHeight
                users={users}
                page={page}
                setPage={setPage}
              />
            </Card>
          </Box>
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.messages.isLoading,
  users: Object.values(state.users)
});

export default connect(mapStateToProps, {
  fetchUsers
})(StaffList);
