import React from 'react';
import {
  Grid,
  TableContainer,
} from '@mui/material';
import healthPersonnel from 'src/constants/fields/section2/healthPersonnel';
import HealthPersonnelTable from '../tables/HealthPersonnelTable';

const Section203 = (props) => {
  const { provinces, data } = props;

  return (
    <Grid container spacing={5}>
      {provinces.map((province) => (
        <Grid item xs={12} key={`hcp-${province.id}`}>
          <TableContainer>
            <HealthPersonnelTable
              rows={healthPersonnel}
              response={data}
              province={province}
              sectionKey="healthcare_personnel"
            />
          </TableContainer>
        </Grid>
      ))}
    </Grid>
  );
};

export default Section203;
