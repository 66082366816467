import React, { memo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import TableLink from 'src/components/common/TableLink';
import NotApplicable from 'src/components/common/NotApplicable';

const AllFacilitiesCountTable = (props) => {
  const {
    ariaLabel,
    provinces,
    rows,
    response,
    title,
    sectionKey
  } = props;

  return (
    <Table sx={{ minWidth: 650 }} size="small" aria-label={ariaLabel}>
      <TableHead>
        {title && (
          <TableRow>
            <TableCell colSpan={13}>
              {title}
            </TableCell>
          </TableRow>
        )}
        <TableRow>
          <TableCell>Facility Type</TableCell>
          {provinces.map((province) => (
            <TableCell align="right" key={province.id}>
              {province.name}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        {rows.map((item) => (
          <TableRow
            key={item.name}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell>
              {item.name}
            </TableCell>
            {provinces.map((province) => {
              const fieldProvince = response[province.id];
              const field = fieldProvince
                ? fieldProvince.standalone_facilities[item.id] + fieldProvince.auxiliary_facilities[item.id]
                : 'N/A';

              return (
                <TableCell
                  align="right"
                  key={`${item.id}-${province.id}`}
                >
                  {!Number.isNaN(field) ? (
                    <TableLink
                      field="type"
                      title={item.name}
                      readableValue="Count"
                      province={province}
                      sectionKey={sectionKey}
                      value={item.value}
                    >
                      {field}
                    </TableLink>
                  )
                    : <NotApplicable />}
                </TableCell>
              );
            })}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default memo(AllFacilitiesCountTable);
