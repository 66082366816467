import React from 'react';
import { connect } from 'react-redux';
import {
  FormControl,
  FormLabel,
  Grid,
  TextField
} from '@mui/material';

const HCPFormControl = (props) => {
  const {
    disabled, error, handleChange,
    helperText, indent, isSubmitting,
    label, name, value
  } = props;

  return (
    <>
      <FormControl
        className="form-field-container"
        component="fieldset"
        fullWidth
      >
        <Grid
          container
          spacing={3}
          sx={{ py: 1, display: 'flex', alignItems: 'center' }}
        >
          <Grid item xs={12} sm={8} md={6}>
            <FormLabel
              className={indent ? 'indent field-label' : 'field-label'}
              component="legend"
            >
              {label}
            </FormLabel>
          </Grid>
          <Grid item xs={12} sm={4} md={6}>
            <TextField
              disabled={isSubmitting || disabled}
              error={error}
              helperText={helperText}
              name={name}
              onChange={handleChange}
              size="small"
              type="number"
              value={value || ''}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </FormControl>
    </>
  );
};

const mapStateToProps = (state) => ({
  isSubmitting: state.messages.isSubmitting
});

export default connect(mapStateToProps)(HCPFormControl);
