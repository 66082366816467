import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography
} from '@mui/material';
import SectionBreadcrumbs from 'src/components/assessment/SectionBreadcrumbs';
import AntenatalCareEquipment from 'src/components/assessment/section4/4.7/AntenatalCareEquipment';
import DeliveryServicesEquipment from 'src/components/assessment/section4/4.7/DeliveryServicesEquipment';
import NewbornCareEquipment from 'src/components/assessment/section4/4.7/NewbornCareEquipment';
import FamilyPlanningEquipment from 'src/components/assessment/section4/4.7/FamilyPlanningEquipment';
import ChildVaccinationEquipment from 'src/components/assessment/section4/4.7/ChildVaccinationEquipment';
import ChildPreventiveCareEquipment from 'src/components/assessment/section4/4.7/ChildPreventiveCareEquipment';
import ChildGrowthMonitoringEquipment from 'src/components/assessment/section4/4.7/ChildGrowthMonitoringEquipment';

import { fetchAssessment, updateAssessment } from 'src/actions/assessment';

const useQuery = () => new URLSearchParams(useLocation().search);

const EquipmentsPage = (props) => {
  const query = useQuery();
  const { id } = useParams();
  const { assessments, isLoading, isSubmitting } = props;
  const [assessment, setAssessment] = useState(null);
  const [readOnly, setReadOnly] = useState(true);

  useEffect(() => {
    props.fetchAssessment(id);
    const edit = query.get('edit');
    if (edit === 'true') setReadOnly(false);
  }, []);

  useEffect(() => {
    const tmpAssessment = assessments[id];

    if (tmpAssessment) {
      setAssessment(tmpAssessment);
    }
  }, [assessments]);

  const handleSubmit = (subsection, form) => {
    const assessmentForm = {
      ...assessment,
      facility_id: assessment.facility.id,
      [subsection]: form
    };
    props.updateAssessment(id, assessmentForm);
  };

  return (
    <>
      <Helmet>
        <title>
          {assessment && (
            `${assessment.facility.name} - Section 4.7  - Assessment - FReSAA`
          )}
        </title>
      </Helmet>
      <Box className="box-page">
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <SectionBreadcrumbs
              active="section4"
              assessmentId={id}
              next={`/assessments/${id}/sections/4/4.8`}
              previous={`/assessments/${id}/sections/4/4.6`}
            />
            <Grid item xs={12}>
              <Typography
                color="textPrimary"
                gutterBottom
                variant="h2"
              >
                Section 4: Service Specific Readiness
              </Typography>
              <Typography
                color="textPrimary"
                gutterBottom
                variant="h4"
              >
                4.7 Equipments, Instruments, and Other Fixtures
              </Typography>
            </Grid>
            {assessment ? (
              <>
                <Grid item xs={12}>
                  <AntenatalCareEquipment
                    facilityType={assessment.facility.type}
                    formValues={assessment.antenatal_care_equipment}
                    isLoading={isLoading}
                    isSubmitting={isSubmitting}
                    onSubmit={handleSubmit}
                    readOnly={readOnly}
                    setReadOnly={setReadOnly}
                  />
                </Grid>
                {assessment.facility.type !== 5 && (
                  <Grid item xs={12}>
                    <DeliveryServicesEquipment
                      facilityType={assessment.facility.type}
                      formValues={assessment.delivery_services_equipment}
                      isLoading={isLoading}
                      isSubmitting={isSubmitting}
                      onSubmit={handleSubmit}
                      readOnly={readOnly}
                      setReadOnly={setReadOnly}
                    />
                  </Grid>
                )}
                {(assessment.facility.type === 1 || assessment.facility.type === 3) && (
                  <Grid item xs={12}>
                    <NewbornCareEquipment
                      formValues={assessment.newborn_care_equipment}
                      isLoading={isLoading}
                      isSubmitting={isSubmitting}
                      onSubmit={handleSubmit}
                      readOnly={readOnly}
                      setReadOnly={setReadOnly}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <FamilyPlanningEquipment
                    facilityType={assessment.facility.type}
                    formValues={assessment.family_planning_equipment}
                    isLoading={isLoading}
                    isSubmitting={isSubmitting}
                    onSubmit={handleSubmit}
                    readOnly={readOnly}
                    setReadOnly={setReadOnly}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ChildVaccinationEquipment
                    facilityType={assessment.facility.type}
                    formValues={assessment.child_vaccination_equipment}
                    isLoading={isLoading}
                    isSubmitting={isSubmitting}
                    onSubmit={handleSubmit}
                    readOnly={readOnly}
                    setReadOnly={setReadOnly}
                  />
                </Grid>
                {assessment.facility.type !== 3 && (
                  <>
                    <Grid item xs={12}>
                      <ChildGrowthMonitoringEquipment
                        facilityType={assessment.facility.type}
                        formValues={assessment.child_growth_monitoring_equipment}
                        isLoading={isLoading}
                        isSubmitting={isSubmitting}
                        onSubmit={handleSubmit}
                        readOnly={readOnly}
                        setReadOnly={setReadOnly}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ChildPreventiveCareEquipment
                        facilityType={assessment.facility.type}
                        formValues={assessment.child_preventive_and_curative_care_equipment}
                        isLoading={isLoading}
                        isSubmitting={isSubmitting}
                        onSubmit={handleSubmit}
                        readOnly={readOnly}
                        setReadOnly={setReadOnly}
                      />
                    </Grid>
                  </>
                )}
              </>
            ) : (
              <Grid item xs={12}>
                <Box className="spinner-container">
                  <CircularProgress />
                </Box>
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  assessments: state.assessments,
  isLoading: state.messages.isLoading,
  isSubmitting: state.messages.isSubmitting,
});

export default connect(mapStateToProps, {
  fetchAssessment,
  updateAssessment
})(EquipmentsPage);
