import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { connect, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import moment from 'moment';
import SectionAccordion from 'src/components/dashboard/SectionAccordion';
import AllFacilities from 'src/components/dashboard/section1/AllFacilities';
import AuxiliaryFacilities from 'src/components/dashboard/section1/AuxiliaryFacilities';
import NonAuxiliaryFacilities from 'src/components/dashboard/section1/NonAuxiliaryFacilities';
import Section201 from 'src/components/dashboard/section2/Section201';
import Section202 from 'src/components/dashboard/section2/Section202';
import Section203 from 'src/components/dashboard/section2/Section203';
import Section311 from 'src/components/dashboard/section3/Section311';
import Section312 from 'src/components/dashboard/section3/Section312';
import Section302 from 'src/components/dashboard/section3/Section302';
import Section303 from 'src/components/dashboard/section3/Section303';
import Section304 from 'src/components/dashboard/section3/Section304';
import Section401 from 'src/components/dashboard/section4/Section401';
import Section402 from 'src/components/dashboard/section4/Section402';
import Section403 from 'src/components/dashboard/section4/Section403';
import Section404 from 'src/components/dashboard/section4/Section404';
import Section405 from 'src/components/dashboard/section4/Section405';
import Section461 from 'src/components/dashboard/section4/section4.6/Section461';
import Section462 from 'src/components/dashboard/section4/section4.6/Section462';
import Section463 from 'src/components/dashboard/section4/section4.6/Section463';
import Section464 from 'src/components/dashboard/section4/section4.6/Section464';
import Section471 from 'src/components/dashboard/section4/section4.7/Section471';
import Section472 from 'src/components/dashboard/section4/section4.7/Section472';
import Section473 from 'src/components/dashboard/section4/section4.7/Section473';
import Section474 from 'src/components/dashboard/section4/section4.7/Section474';
import Section475 from 'src/components/dashboard/section4/section4.7/Section475';
import Section476 from 'src/components/dashboard/section4/section4.7/Section476';
import Section477 from 'src/components/dashboard/section4/section4.7/Section477';
import Section481 from 'src/components/dashboard/section4/section4.8/Section481';
import Section482 from 'src/components/dashboard/section4/section4.8/Section482';
import Section483 from 'src/components/dashboard/section4/section4.8/Section483';
import Section484 from 'src/components/dashboard/section4/section4.8/Section484';
import Section485 from 'src/components/dashboard/section4/section4.8/Section485';
import Section486 from 'src/components/dashboard/section4/section4.8/Section486';
import Section487 from 'src/components/dashboard/section4/section4.8/Section487';
import api from 'src/apis/api';
import { getAccessToken, throwError, tokenConfig } from 'src/actions';
import FacilityListReportContext from 'src/components/dashboard/context';
import FacilityListReportModal from 'src/components/dashboard/FacilityListReportModal';

const SectionTitle = ({ title, style }) => (
  <Grid item xs={12}>
    <Box sx={style}>
      <Typography
        variant="h4"
        gutterBottom
        color="textPrimary"
      >
        {title}
      </Typography>
      <Divider />
    </Box>
  </Grid>
);

const ReportDetail = (props) => {
  const dispatch = useDispatch();
  const { accessToken, user } = props;

  const [loadingFacilities, setLoadingFacilities] = useState(false);
  const [openFacilityListModal, setOpenFacilityListModal] = useState(false);
  const [state, setState] = useState(new Set());
  const [provinces, setProvinces] = useState([]);
  const [facilityModalState, setFacilityModalState] = useState({
    title: '',
    field: '',
    province: {},
    readableValue: '',
    value: ''
  });

  const subsections2 = [
    'general_mnchn_services',
    'mnchn_outcome_indicators',
    'healthcare_personnel'
  ];

  const subsections3 = [
    'licensing_and_certification',
    'physical_structure',
    'basic_supplies_and_equipment',
    'precautions_and_waste_management',
    'policies'
  ];

  const subsections4 = [
    'antenatal_care',
    'delivery_services',
    'postnatal_care',
    'newborn_care',
    'family_planning',
    'child_vaccination',
    'child_growth_monitoring',
    'child_preventive_and_curative_care',
    'adolescent_health',
    'antenatal_care_equipment',
    'delivery_services_equipment',
    'newborn_care_equipment',
    'family_planning_equipment',
    'child_vaccination_equipment',
    'child_growth_monitoring_equipment',
    'child_preventive_and_curative_care_equipment',
    'antenatal_care_supplies',
    'delivery_services_supplies',
    'postnatal_care_supplies',
    'newborn_care_supplies',
    'family_planning_supplies',
    'child_vaccination_supplies',
    'child_preventive_and_curative_care_supplies'
  ];

  const [sectionData, setSectionData] = useState({});
  const [reportInfo, setReportInfo] = useState({});
  const [facilityList, setFacilityList] = useState([]);
  const [hasCount, setHasCount] = useState(false);
  const navigate = useNavigate();

  const getReport = async (id) => {
    try {
      const response = await api.get(`/v1/reports/${id}`, tokenConfig(accessToken));
      const {
        sections, status, data, place, created
      } = response.data;

      // Only process reports that are done
      if (status !== 2) {
        const errorMsg = status === 1 ? ['Report is still processing.'] : ['Report is unavailable.'];
        const error = new Error(errorMsg);
        error.response = {};
        error.response.data = errorMsg;
        throw error;
      }
      // API response needs to be transformed to be used by the web app
      // API sorts by sections then places, this code reformats to sort by place then sections
      const childData = data.children;
      const formattedResponse = {};
      sections.forEach((section) => {
        formattedResponse[section] = {};
        childData.forEach((child) => {
          formattedResponse[section][child.place.id] = child[section];
        });
      });

      setState(new Set(sections));
      setReportInfo({
        id,
        created,
        place: place.name
      });
      setSectionData(formattedResponse);
      const places = childData.map((child) => child.place);
      setProvinces(places);
    } catch (e) {
      if (e.response && e.response.state === 401) {
        getAccessToken();
        getReport(id);
      } else {
        throwError(e, dispatch);
      }
    }
  };

  const { id } = useParams();
  useEffect(() => getReport(id), []);

  useEffect(() => {
    if (user && !user.is_dashboard_user) {
      navigate('/account', { replace: true });
    }
  }, [user]);

  const getFacilityList = async (sectionKey, field, value, provinceId) => {
    setLoadingFacilities(true);

    if (value) setHasCount(false);
    else setHasCount(true);

    const url = value
      ? `v1/reports/${sectionKey}/facilities/?field=${field}&value=${value}&place_id=${provinceId}`
      : `v1/reports/${sectionKey}/facilities/?field=${field}&place_id=${provinceId}`;

    try {
      const response = await api.get(url, tokenConfig(accessToken));
      setFacilityList(response.data);
      setLoadingFacilities(false);
    } catch (e) {
      if (e.response && e.response.state === 401) {
        getAccessToken();
        getFacilityList(sectionKey, field, value, provinceId);
      } else {
        setFacilityList([]);
        setLoadingFacilities(false);
        throwError(e, dispatch);
      }
    }
  };

  const handleViewFacilityList = (values) => {
    const {
      title,
      field,
      province,
      readableValue,
      sectionKey,
      value
    } = values;
    getFacilityList(sectionKey, field, value, province.id);
    setOpenFacilityListModal(true);
    setFacilityModalState({
      ...facilityModalState,
      title,
      field,
      province,
      readableValue,
      value
    });
  };

  if (user && user.is_dashboard_user) {
    return (
      <FacilityListReportContext.Provider value={handleViewFacilityList}>
        <Helmet>
          <title>Report - FReSAA</title>
        </Helmet>
        <Box className="box-page">
          <Container>
            {state.size !== 0 && (
              <>
                <Typography variant="h2">{`Report #${reportInfo.id} - ${reportInfo.place}`}</Typography>
                <Typography
                  variant="h3"
                  sx={{ fontWeight: 'lighter', lineHeight: 2 }}
                >
                  {`REQUESTED ON: ${moment(reportInfo.created).format('ll h:mm A')}`}
                </Typography>
              </>
            )}
            <Grid container spacing={3} sx={{ mt: 3 }}>
              {state.has('facility_identification') && (
                <>
                  <SectionTitle title="Section 1: Facility Identification" />
                  <SectionAccordion
                    id="section1-1"
                    title="All Facilities"
                    ChildComponent={AllFacilities}
                    provinces={provinces}
                    data={sectionData.facility_identification}
                  />
                  <SectionAccordion
                    id="section1-2"
                    title="Auxiliary Facilities"
                    ChildComponent={AuxiliaryFacilities}
                    provinces={provinces}
                    data={sectionData.facility_identification}
                  />
                  <SectionAccordion
                    id="section1-3"
                    title="Non-Auxiliary Facilities"
                    ChildComponent={NonAuxiliaryFacilities}
                    provinces={provinces}
                    data={sectionData.facility_identification}
                  />
                </>
              )}
              {subsections2.some((subsection) => state.has(subsection)) && (
                <SectionTitle title="Section 2: General Service Availability" />
              )}
              {state.has('general_mnchn_services') && (
                <SectionAccordion
                  id="section2-1"
                  title="Section 2.1: General Maternal, Newborn, Child Health, and Nutrition (MNCHN Services)"
                  ChildComponent={Section201}
                  provinces={provinces}
                  data={sectionData.general_mnchn_services}
                />
              )}
              {state.has('mnchn_outcome_indicators') && (
                <SectionAccordion
                  id="section2-2"
                  title="Section 2.2: MNCHN-Related Impact/Outcome Indicators"
                  ChildComponent={Section202}
                  provinces={provinces}
                  data={sectionData.mnchn_outcome_indicators}
                />
              )}
              {state.has('healthcare_personnel') && (
                <SectionAccordion
                  id="section2-3"
                  title="Section 2.3: Health Care Personnel"
                  ChildComponent={Section203}
                  provinces={provinces}
                  data={sectionData.healthcare_personnel}
                />
              )}
              {subsections3.some((subsection) => state.has(subsection)) && (
                <SectionTitle title="Section 3: Basic Amenities" style={{ paddingTop: 5 }} />
              )}
              {state.has('licensing_and_certification') && (
                <SectionAccordion
                  id="section3-1-1"
                  title="Section 3.1.1: Liscensing and Certification"
                  ChildComponent={Section311}
                  provinces={provinces}
                  data={sectionData.licensing_and_certification}
                />
              )}
              {state.has('physical_structure') && (
                <SectionAccordion
                  id="section3-1-2"
                  title="Section 3.1.2: Physical Structure of Facility"
                  ChildComponent={Section312}
                  provinces={provinces}
                  data={sectionData.physical_structure}
                />
              )}
              {state.has('basic_supplies_and_equipment') && (
                <SectionAccordion
                  id="section3-0-2"
                  title="Section 3.2: Basic Supplies and Equipment"
                  ChildComponent={Section302}
                  provinces={provinces}
                  data={sectionData.basic_supplies_and_equipment}
                />
              )}
              {state.has('precautions_and_waste_management') && (
                <SectionAccordion
                  id="section3-0-3"
                  title="Section 3.3: Standard Precautions and Waste Management"
                  ChildComponent={Section303}
                  provinces={provinces}
                  data={sectionData.precautions_and_waste_management}
                />
              )}
              {state.has('policies') && (
                <SectionAccordion
                  id="section3-0-4"
                  title="Section 3.4: Policies"
                  ChildComponent={Section304}
                  provinces={provinces}
                  data={sectionData.policies}
                />
              )}
              {subsections4.some((subsection) => state.has(subsection)) && (
                <SectionTitle title="Section 4: Service Specific Readiness" style={{ paddingTop: 5 }} />
              )}
              {state.has('antenatal_care') && (
                <SectionAccordion
                  id="section4-0-1"
                  title="Section 4.1: Antenatal Care"
                  ChildComponent={Section401}
                  provinces={provinces}
                  data={sectionData.antenatal_care}
                />
              )}
              {state.has('delivery_services') && (
                <SectionAccordion
                  id="section4-0-2"
                  title="Section 4.2: Delivery Services"
                  ChildComponent={Section402}
                  provinces={provinces}
                  data={sectionData.delivery_services}
                />
              )}
              {state.has('postnatal_care') && (
                <SectionAccordion
                  id="section4-0-3"
                  title="Section 4.3: Postnatal Care"
                  ChildComponent={Section403}
                  provinces={provinces}
                  data={sectionData.postnatal_care}
                />
              )}
              {state.has('newborn_care') && (
                <SectionAccordion
                  id="section4-0-4"
                  title="Section 4.4: Newborn Care"
                  ChildComponent={Section404}
                  provinces={provinces}
                  data={sectionData.newborn_care}
                />
              )}
              {state.has('family_planning') && (
                <SectionAccordion
                  id="section4-0-5"
                  title="Section 4.5: Family Planning"
                  ChildComponent={Section405}
                  provinces={provinces}
                  data={sectionData.family_planning}
                />
              )}
              {state.has('child_vaccination') && (
                <SectionAccordion
                  id="section4-6-1"
                  title="Section 4.6.1: Child and Adolescent Health Services - Child Vaccination"
                  ChildComponent={Section461}
                  provinces={provinces}
                  data={sectionData.child_vaccination}
                />
              )}
              {state.has('child_growth_monitoring') && (
                <SectionAccordion
                  id="section4-6-2"
                  title="Section 4.6.2: Child Growth Monitoring Services"
                  ChildComponent={Section462}
                  provinces={provinces}
                  data={sectionData.child_growth_monitoring}
                />
              )}
              {state.has('child_preventive_and_curative_care') && (
                <SectionAccordion
                  id="section4-6-3"
                  title="Section 4.6.3: Child Preventive and Curative Care Services"
                  ChildComponent={Section463}
                  provinces={provinces}
                  data={sectionData.child_preventive_and_curative_care}
                />
              )}
              {state.has('adolescent_health') && (
                <SectionAccordion
                  id="section4-6-4"
                  title="Section 4.6.4: Adolescent Health Services"
                  ChildComponent={Section464}
                  provinces={provinces}
                  data={sectionData.adolescent_health}
                />
              )}
              {state.has('antenatal_care_equipment') && (
                <SectionAccordion
                  id="section4-7-1"
                  title="Section 4.7.1: Antenatal Care Equipments"
                  ChildComponent={Section471}
                  provinces={provinces}
                  data={sectionData.antenatal_care_equipment}
                />
              )}
              {state.has('delivery_services_equipment') && (
                <SectionAccordion
                  id="section4-7-2"
                  title="Section 4.7.2: Delivery Services Equipments"
                  ChildComponent={Section472}
                  provinces={provinces}
                  data={sectionData.delivery_services_equipment}
                />
              )}
              {state.has('newborn_care_equipment') && (
                <SectionAccordion
                  id="section4-7-3"
                  title="Section 4.7.3: Newborn Care Equipments"
                  ChildComponent={Section473}
                  provinces={provinces}
                  data={sectionData.newborn_care_equipment}
                />
              )}
              {state.has('family_planning_equipment') && (
                <SectionAccordion
                  id="section4-7-3"
                  title="Section 4.7.4: Family Planning Equipments"
                  ChildComponent={Section474}
                  provinces={provinces}
                  data={sectionData.family_planning_equipment}
                />
              )}
              {state.has('child_vaccination_equipment') && (
                <SectionAccordion
                  id="section4-7-3"
                  title="Section 4.7.5: Child Vaccination"
                  ChildComponent={Section475}
                  provinces={provinces}
                  data={sectionData.child_vaccination_equipment}
                />
              )}
              {state.has('child_growth_monitoring_equipment') && (
                <SectionAccordion
                  id="section4-7-3"
                  title="Section 4.7.6: Child Growth Monitoring Services"
                  ChildComponent={Section476}
                  provinces={provinces}
                  data={sectionData.child_growth_monitoring_equipment}
                />
              )}
              {state.has('child_preventive_and_curative_care_equipment') && (
                <SectionAccordion
                  id="section4-7-3"
                  title="Section 4.7.7: Child Preventive and Curative Care Services"
                  ChildComponent={Section477}
                  provinces={provinces}
                  data={sectionData.child_preventive_and_curative_care_equipment}
                />
              )}
              {state.has('antenatal_care_supplies') && (
                <SectionAccordion
                  id="section4-8-1"
                  title="Section 4.8: Medicines, Supplies, and Commodities - Antenatal Care"
                  ChildComponent={Section481}
                  provinces={provinces}
                  data={sectionData.antenatal_care_supplies}
                />
              )}
              {state.has('delivery_services_supplies') && (
                <SectionAccordion
                  id="section4-8-2"
                  title="Section 4.8: Medicines, Supplies, and Commodities - Delivery Services"
                  ChildComponent={Section482}
                  provinces={provinces}
                  data={sectionData.delivery_services_supplies}
                />
              )}
              {state.has('postnatal_care_supplies') && (
                <SectionAccordion
                  id="section4-8-3"
                  title="Section 4.8: Medicines, Supplies, and Commodities - Postnatal Care"
                  ChildComponent={Section483}
                  provinces={provinces}
                  data={sectionData.postnatal_care_supplies}
                />
              )}
              {state.has('newborn_care_supplies') && (
                <SectionAccordion
                  id="section4-8-4"
                  title="Section 4.8: Medicines, Supplies, and Commodities - Newborn Care"
                  ChildComponent={Section484}
                  provinces={provinces}
                  data={sectionData.newborn_care_supplies}
                />
              )}
              {state.has('family_planning_supplies') && (
                <SectionAccordion
                  id="section4-8-5"
                  title="Section 4.8: Medicines, Supplies, and Commodities - Family Planning"
                  ChildComponent={Section485}
                  provinces={provinces}
                  data={sectionData.family_planning_supplies}
                />
              )}
              {state.has('child_vaccination_supplies') && (
                <SectionAccordion
                  id="section4-8-6"
                  title="Section 4.8: Medicines, Supplies, and Commodities - Child Vaccination"
                  ChildComponent={Section486}
                  provinces={provinces}
                  data={sectionData.child_vaccination_supplies}
                />
              )}
              {state.has('child_preventive_and_curative_care_supplies') && (
                <SectionAccordion
                  id="section4-8-7"
                  title="Section 4.8: Medicines, Supplies, and Commodities - Child Preventive and Curative Care Services"
                  ChildComponent={Section487}
                  provinces={provinces}
                  data={sectionData.child_preventive_and_curative_care_supplies}
                />
              )}
            </Grid>
          </Container>
        </Box>
        <FacilityListReportModal
          facilities={facilityList}
          loading={loadingFacilities}
          open={openFacilityListModal}
          setOpen={setOpenFacilityListModal}
          state={facilityModalState}
          hasCount={hasCount}
        />
      </FacilityListReportContext.Provider>
    );
  }

  return (
    <Box className="spinner-container">
      <CircularProgress />
    </Box>
  );
};

const mapStateToProps = (state) => ({
  accessToken: state.auth.accessToken,
  user: state.auth.user
});

export default connect(mapStateToProps, {
  getAccessToken
})(ReportDetail);
