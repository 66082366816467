import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Divider,
  LinearProgress,
  TextField,
  Typography
} from '@mui/material';
import { connect } from 'react-redux';
import { signIn } from 'src/actions/auth';
import { clearErrors } from 'src/actions';

const Login = (props) => {
  const navigate = useNavigate();
  const { isSubmitting } = props;

  useEffect(() => {
    props.clearErrors();
  }, []);

  useEffect(() => {
    if (props.isSignedIn) {
      navigate('/dashboard', { replace: true });
    }
  });

  const renderErrors = props.errors.map((error) => (
    <div key={error} className="cs-error">
      {error}
    </div>
  ));

  return (
    <>
      <Helmet>
        <title>Sign in - FReSAA</title>
      </Helmet>
      {isSubmitting ? <LinearProgress /> : null}
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              username: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              username: Yup.string().email('Must be a valid email').max(255).required('Username email is required'),
              password: Yup.string().max(255).required('Password is required')
            })}
            onSubmit={(values) => {
              props.signIn(values);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Sign in
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.username && errors.username)}
                  fullWidth
                  helperText={touched.username && errors.username}
                  label="Username"
                  margin="normal"
                  name="username"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.username}
                  variant="outlined"
                  disabled={isSubmitting}
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                  disabled={isSubmitting}
                />
                { props.errors.length !== 0 ? renderErrors : ''}
                <Box sx={{ py: 2 }}>
                  <Button
                    aria-label="sign in"
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in now
                  </Button>
                </Box>
                <Link to="/password/reset">
                  <Typography variant="subtitle2">
                    Forgot password?
                  </Typography>
                </Link>
              </form>
            )}
          </Formik>
        </Container>
        <Container
          maxWidth="lg"
          sx={{
            position: 'absolute',
            top: 'auto',
            bottom: 0,
            left: 0,
            right: 0
          }}
        >
          <Box className="footer">
            <Divider />
            <Link to="/privacy_policy">
              <Typography variant="body1" sx={{ fontSize: 14, py: 1 }}>
                Privacy Policy
              </Typography>
            </Link>
          </Box>
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  isSignedIn: state.auth.isSignedIn,
  isSubmitting: state.messages.isSubmitting,
  errors: Object.values(state.auth.errors)
});

export default connect(mapStateToProps,
  { signIn, clearErrors })(Login);
