import React from 'react';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  LinearProgress,
  Typography
} from '@mui/material';
import {
  Edit as EditIcon,
  Save as SaveIcon
} from '@mui/icons-material';
import { NUTRITION_POST, AUX_FACILITY } from 'src/constants/facilityTypes';
import AvailabilityFunctioningFormGroup from '../../formSections/AvailabilityFunctioningFormGroup';

const FamilyPlanningEquipment = (props) => {
  const {
    facilityType,
    formValues,
    isLoading,
    isSubmitting,
    readOnly,
    setReadOnly
  } = props;

  const handleEditClick = () => {
    setReadOnly(false);
  };

  const handleSaveClick = (handleSubmit) => {
    handleSubmit();
    setReadOnly(true);
  };

  const initialValues = {
    bp_apparatus_available: null,
    bp_apparatus_functioning: null,
    stethoscope_available: null,
    stethoscope_functioning: null,
    tenaculum_forceps_available: null,
    tenaculum_forceps_functioning: null,
    mayo_scissors_available: null,
    mayo_scissors_functioning: null,
    placental_forceps_available: null,
    placental_forceps_functioning: null,
    ten_inch_ovum_forceps_available: null,
    ten_inch_ovum_forceps_functioning: null,
    ten_inch_straight_forceps_available: null,
    ten_inch_straight_forceps_functioning: null,
    ten_inch_alligator_forceps_available: null,
    ten_inch_alligator_forceps_functioning: null,
    uterine_sound_available: null,
    uterine_sound_functioning: null,
    vaginal_speculum_available: null,
    vaginal_speculum_functioning: null,
    kelly_pad_available: null,
    kelly_pad_functioning: null,
    gooseneck_lamp_available: null,
    gooseneck_lamp_functioning: null,
    printed_materials_for_patient_education_available: null,
    printed_materials_for_patient_education_functioning: null,
    ...formValues
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => {
        props.onSubmit('family_planning_equipment', values);
      }}
    >
      {({
        handleSubmit,
        setFieldValue,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardContent>
              <Box className="form-header">
                <Typography variant="h4" gutterBottom>
                  Family Planning
                </Typography>
                {readOnly ? (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleEditClick()}
                  >
                    <EditIcon size="medium" />
                  </IconButton>
                ) : (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleSaveClick(handleSubmit)}
                  >
                    <SaveIcon size="medium" />
                  </IconButton>
                )}
              </Box>
            </CardContent>
            <Divider />
            <CardContent>
              <Box sx={{ p: 2 }}>
                {(facilityType !== NUTRITION_POST && facilityType !== AUX_FACILITY) && (
                  <>
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="BP Apparatus"
                      fieldName="bp_apparatus_available"
                      fieldValue={values.bp_apparatus_available}
                      fieldFunctioningName="bp_apparatus_functioning"
                      fieldFunctioningValue={values.bp_apparatus_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Stethoscope"
                      fieldName="stethoscope_available"
                      fieldValue={values.stethoscope_available}
                      fieldFunctioningName="stethoscope_functioning"
                      fieldFunctioningValue={values.stethoscope_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Tenaculum Forceps"
                      fieldName="tenaculum_forceps_available"
                      fieldValue={values.tenaculum_forceps_available}
                      fieldFunctioningName="tenaculum_forceps_functioning"
                      fieldFunctioningValue={values.tenaculum_forceps_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Mayo Scissors"
                      fieldName="mayo_scissors_available"
                      fieldValue={values.mayo_scissors_available}
                      fieldFunctioningName="mayo_scissors_functioning"
                      fieldFunctioningValue={values.mayo_scissors_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Placental Forceps"
                      fieldName="placental_forceps_available"
                      fieldValue={values.placental_forceps_available}
                      fieldFunctioningName="placental_forceps_functioning"
                      fieldFunctioningValue={values.placental_forceps_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="10&#34; Ovum Forceps"
                      fieldName="ten_inch_ovum_forceps_available"
                      fieldValue={values.ten_inch_ovum_forceps_available}
                      fieldFunctioningName="ten_inch_ovum_forceps_functioning"
                      fieldFunctioningValue={values.ten_inch_ovum_forceps_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="10&#34; Straight Forceps"
                      fieldName="ten_inch_straight_forceps_available"
                      fieldValue={values.ten_inch_straight_forceps_available}
                      fieldFunctioningName="ten_inch_straight_forceps_functioning"
                      fieldFunctioningValue={values.ten_inch_straight_forceps_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="10&#34; Narrow or &#34;Alligator&#34; Forceps"
                      fieldName="ten_inch_alligator_forceps_available"
                      fieldValue={values.ten_inch_alligator_forceps_available}
                      fieldFunctioningName="ten_inch_alligator_forceps_functioning"
                      fieldFunctioningValue={values.ten_inch_alligator_forceps_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Uterine Sound"
                      fieldName="uterine_sound_available"
                      fieldValue={values.uterine_sound_available}
                      fieldFunctioningName="uterine_sound_functioning"
                      fieldFunctioningValue={values.uterine_sound_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Vaginal Speculum, Different Sizes"
                      fieldName="vaginal_speculum_available"
                      fieldValue={values.vaginal_speculum_available}
                      fieldFunctioningName="vaginal_speculum_functioning"
                      fieldFunctioningValue={values.vaginal_speculum_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Kelly Pad"
                      fieldName="kelly_pad_available"
                      fieldValue={values.kelly_pad_available}
                      fieldFunctioningName="kelly_pad_functioning"
                      fieldFunctioningValue={values.kelly_pad_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Goosenck Lamp (or equivalent)"
                      fieldName="gooseneck_lamp_available"
                      fieldValue={values.gooseneck_lamp_available}
                      fieldFunctioningName="gooseneck_lamp_functioning"
                      fieldFunctioningValue={values.gooseneck_lamp_functioning}
                      setFieldValue={setFieldValue}
                    />
                  </>
                )}
                <AvailabilityFunctioningFormGroup
                  disabled={readOnly}
                  label="Printed materials/posters for patien education"
                  fieldName="printed_materials_for_patient_education_available"
                  fieldValue={values.printed_materials_for_patient_education_available}
                  fieldFunctioningName="printed_materials_for_patient_education_functioning"
                  fieldFunctioningValue={values.printed_materials_for_patient_education_functioning}
                  setFieldValue={setFieldValue}
                />
              </Box>
            </CardContent>
            <Divider />
            <Box className="box-button">
              <Button
                disabled={isLoading || isSubmitting || !readOnly}
                color="primary"
                onClick={() => handleEditClick()}
                variant="contained"
                sx={{ mx: 2 }}
              >
                Edit
              </Button>
              <Button
                disabled={isLoading || isSubmitting || readOnly}
                color="primary"
                onClick={() => handleSaveClick(handleSubmit)}
                type="submit"
                variant="contained"
              >
                Save
              </Button>
            </Box>
            {(isLoading || isSubmitting) && <LinearProgress />}
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default FamilyPlanningEquipment;
