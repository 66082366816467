import React from 'react';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  LinearProgress,
  Typography
} from '@mui/material';
import {
  Edit as EditIcon,
  Save as SaveIcon
} from '@mui/icons-material';
import deliveryServices from 'src/fields/deliveryServices';
import {
  BARANGAY_HEALTH_STATION,
  SAFE_BIRTHING_FACILITY,
  NUTRITION_POST,
  RURAL_HEALTH_UNIT,
  AUX_FACILITY
} from 'src/constants/facilityTypes';
import RadioGroupFormControl from '../formSections/RadioGroupFormControl';
import YesOrNoFormControl from '../formSections/YesOrNoFormControl';

const availability = [
  {
    label: 'Yes, observed',
    value: 1
  },
  {
    label: 'Reported, not observed',
    value: 2
  },
  {
    label: 'No records',
    value: 3
  }
];

const DeliveryServices = (props) => {
  const {
    facilityType,
    formValues,
    isLoading,
    isSubmitting,
    readOnly,
    setReadOnly
  } = props;

  const initialValues = {
    ...deliveryServices,
    ...formValues
  };

  const handleEditClick = () => {
    setReadOnly(false);
  };

  const handleSaveClick = (handleSubmit) => {
    handleSubmit();
    setReadOnly(true);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => {
        props.onSubmit('delivery_services', values);
      }}
    >
      {({
        handleSubmit,
        setFieldValue,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardContent>
              <Box className="form-header">
                <Typography variant="h4" gutterBottom>
                  4.2 Delivery Services
                </Typography>
                {readOnly ? (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleEditClick()}
                  >
                    <EditIcon size="medium" />
                  </IconButton>
                ) : (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleSaveClick(handleSubmit)}
                  >
                    <SaveIcon size="medium" />
                  </IconButton>
                )}
              </Box>
            </CardContent>
            <Divider />
            { (facilityType !== BARANGAY_HEALTH_STATION && facilityType !== NUTRITION_POST) ? (
              <>
                <CardContent sx={{ p: 5 }}>
                  { facilityType !== RURAL_HEALTH_UNIT && facilityType !== AUX_FACILITY && (
                    <>
                      <Typography color="textSecondary" variant="h5" gutterBottom>
                        Do you conduct the following diagnostic examinations in this facility at any time as part of Delivery Services?
                      </Typography>
                      <Box sx={{ p: 2 }}>
                        <YesOrNoFormControl
                          disabled={readOnly}
                          label="Monitoring and Management of Labor using Partograph"
                          fieldName="partograph"
                          fieldValue={values.partograph}
                          setFieldValue={setFieldValue}
                        />
                        <YesOrNoFormControl
                          disabled={readOnly}
                          label="Thermal Protection, Immediate Drying and Skin-to-Skin Contact"
                          fieldName="thermal_protection_immediate_drying_and_skin_to_skin_contact"
                          fieldValue={values.thermal_protection_immediate_drying_and_skin_to_skin_contact}
                          setFieldValue={setFieldValue}
                        />
                        <YesOrNoFormControl
                          disabled={readOnly}
                          label="Delayed Cord Cutting"
                          fieldName="delayed_cord_cutting"
                          fieldValue={values.delayed_cord_cutting}
                          setFieldValue={setFieldValue}
                        />
                        <YesOrNoFormControl
                          disabled={readOnly}
                          label="Hygienic Cord Care"
                          fieldName="hygenic_cord_care"
                          fieldValue={values.hygenic_cord_care}
                          setFieldValue={setFieldValue}
                        />
                        <YesOrNoFormControl
                          disabled={readOnly}
                          label="Immediate and Exclusive Breastfeeding"
                          fieldName="immediate_and_exclusive_breastfeeding"
                          fieldValue={values.immediate_and_exclusive_breastfeeding}
                          setFieldValue={setFieldValue}
                        />
                        <YesOrNoFormControl
                          disabled={readOnly}
                          label="Normal Delivery"
                          fieldName="normal_delivery"
                          fieldValue={values.normal_delivery}
                          setFieldValue={setFieldValue}
                        />
                        <YesOrNoFormControl
                          disabled={readOnly}
                          label="Assisted Vaginal Delivery"
                          fieldName="assisted_vaginal_delivery"
                          fieldValue={values.assisted_vaginal_delivery}
                          setFieldValue={setFieldValue}
                        />
                        <YesOrNoFormControl
                          disabled={readOnly}
                          label="Parenteral Administration of Uterotonic (e.g. Oxytocin) Drug"
                          fieldName="parenteral_administration_of_uterotonic"
                          fieldValue={values.parenteral_administration_of_uterotonic}
                          setFieldValue={setFieldValue}
                        />
                        <YesOrNoFormControl
                          disabled={readOnly}
                          label="Manual Removal of Placenta"
                          fieldName="manual_removal_of_placenta"
                          fieldValue={values.manual_removal_of_placenta}
                          setFieldValue={setFieldValue}
                        />
                        <YesOrNoFormControl
                          disabled={readOnly}
                          label="Removal of Retained Products after Delivery"
                          fieldName="removal_of_retained_products_after_delivery"
                          fieldValue={values.removal_of_retained_products_after_delivery}
                          setFieldValue={setFieldValue}
                        />
                        <YesOrNoFormControl
                          disabled={readOnly}
                          label="Administration of Corticosteroids for Pre-Term Labor"
                          fieldName="corticosteroids_for_preterm_labor"
                          fieldValue={values.corticosteroids_for_preterm_labor}
                          setFieldValue={setFieldValue}
                        />
                        { facilityType !== SAFE_BIRTHING_FACILITY && (
                          <>
                            <YesOrNoFormControl
                              disabled={readOnly}
                              label="Parenteral Administration of Intravenous Antibiotics"
                              fieldName="parenteral_administration_of_iv_antibiotics"
                              fieldValue={values.parenteral_administration_of_iv_antibiotics}
                              setFieldValue={setFieldValue}
                            />
                            <YesOrNoFormControl
                              disabled={readOnly}
                              label="Parenteral Administration of Anticonvulsant"
                              fieldName="parenteral_administration_of_anticonvulsant"
                              fieldValue={values.parenteral_administration_of_anticonvulsant}
                              setFieldValue={setFieldValue}
                            />
                          </>
                        )}
                        <YesOrNoFormControl
                          disabled={readOnly}
                          label="Basic Emergency Obstetric Care"
                          fieldName="basic_emergency_obstetric_care"
                          fieldValue={values.basic_emergency_obstetric_care}
                          setFieldValue={setFieldValue}
                        />
                        <YesOrNoFormControl
                          disabled={readOnly}
                          label="Neonatal Resuscitation"
                          fieldName="neonatal_resuscitation"
                          fieldValue={values.neonatal_resuscitation}
                          setFieldValue={setFieldValue}
                        />
                      </Box>
                      <span className="whitespace" />
                    </>
                  )}
                  <Typography color="textSecondary" variant="h5" gutterBottom>
                    Are the following national guidelines, job-aids, checklist, charts for delivery services in the facility today?
                  </Typography>
                  <Box sx={{ p: 2 }}>
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Quality Assurance Package Manual / Pregnancy, Childbirth, Postpartum and Newborn Care Manual"
                      fieldName="qap_manual_or_equivalent"
                      fieldValue={values.qap_manual_or_equivalent}
                      setFieldValue={setFieldValue}
                    />
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="EINC Clinical Practical Pocket Guide"
                      fieldName="einc_clinical_practical_pocket_guide"
                      fieldValue={values.einc_clinical_practical_pocket_guide}
                      setFieldValue={setFieldValue}
                    />
                  </Box>
                  <span className="whitespace" />
                  <Typography color="textSecondary" variant="h5" gutterBottom>
                    Have you or any health worker assigned in this facility been trained in the following within the past 5 years?
                  </Typography>
                  <Box sx={{ p: 2 }}>
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Basic Emergency Obstetric and Neonatal Care (BEmONC)"
                      fieldName="bemonc_training"
                      fieldValue={values.bemonc_training}
                      setFieldValue={setFieldValue}
                    />
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Essential Intrapartum and Newborn Care (EINC)"
                      fieldName="einc_training"
                      fieldValue={values.einc_training}
                      setFieldValue={setFieldValue}
                    />
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Lactation Management Training"
                      fieldName="lmt_training"
                      fieldValue={values.lmt_training}
                      setFieldValue={setFieldValue}
                    />
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Basic Life Support (BLS)"
                      fieldName="bls_training"
                      fieldValue={values.bls_training}
                      setFieldValue={setFieldValue}
                    />
                  </Box>
                  <span className="whitespace" />
                  <Typography color="textSecondary" variant="h5" gutterBottom>
                    Does this facility have the following health personnel full time or on call 24/7?
                  </Typography>
                  <Box sx={{ p: 2 }}>
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Physician trained in Basic Emergency Obstetric and Neonatal Care"
                      fieldName="physician_trained_in_bemonc"
                      fieldValue={values.physician_trained_in_bemonc}
                      setFieldValue={setFieldValue}
                    />
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="PhilHealth Accredited Professional Provider"
                      fieldName="philhealth_accredited_professional_provider"
                      fieldValue={values.philhealth_accredited_professional_provider}
                      setFieldValue={setFieldValue}
                    />
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Bachelor of Science in Midwifery (Midwife with Diploma)"
                      fieldName="midwife_with_bs_diploma"
                      fieldValue={values.midwife_with_bs_diploma}
                      setFieldValue={setFieldValue}
                    />
                  </Box>
                  <RadioGroupFormControl
                    disabled={readOnly}
                    label="Does this facility have maternal clinical charts with properly accomplished consent forms and ICD-10 coded medical diagnosis and procedures performed?"
                    fieldName="maternal_clinic_charts"
                    fieldValue={values.maternal_clinic_charts}
                    options={availability}
                    setFieldValue={setFieldValue}
                  />
                  {facilityType !== RURAL_HEALTH_UNIT && (
                    <RadioGroupFormControl
                      disabled={readOnly}
                      label="Does this facility have newborn patient charts with properly and completely filled out birth certificate forms?"
                      fieldName="newborn_patient_charts"
                      fieldValue={values.newborn_patient_charts}
                      options={availability}
                      setFieldValue={setFieldValue}
                    />
                  )}
                  <RadioGroupFormControl
                    disabled={readOnly}
                    label="Does this facility have referral forms and compile return referrals"
                    fieldName="referral_forms"
                    fieldValue={values.referral_forms}
                    options={availability}
                    setFieldValue={setFieldValue}
                  />
                </CardContent>
                <Divider />
                <Box className="box-button">
                  <Button
                    disabled={isLoading || isSubmitting || !readOnly}
                    color="primary"
                    onClick={() => handleEditClick()}
                    variant="contained"
                    sx={{ mx: 2 }}
                  >
                    Edit
                  </Button>
                  <Button
                    disabled={isLoading || isSubmitting || readOnly}
                    color="primary"
                    onClick={() => handleSaveClick(handleSubmit)}
                    type="submit"
                    variant="contained"
                  >
                    Save
                  </Button>
                </Box>
              </>
            ) : (
              <CardContent>
                <Typography variant="subtitle1" color="textSecondary">
                  This section is disabled for this facility.
                </Typography>
              </CardContent>
            )}
            {(isLoading || isSubmitting) && <LinearProgress />}
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default DeliveryServices;
