export default {
  counselling_on_family_planning: null,
  combined_oral_contraceptive_pills_provision: null,
  progestin_only_contraceptive_pills_provision: null,
  progestin_only_injectable_contraceptives_provision: null,
  male_condom_provision: null,
  intrauterine_device_insertion: null,
  subdermal_implants: null,
  cycle_beads_for_standard_days_method_provision: null,
  visual_inspection_using_acetic_acid: null,
  fp_clinical_standards_manual_2014_edition: null,
  fp_form1: null,
  family_planning_training: null,
  interpersonal_communication_skills_training: null,
  counselling_on_family_planning_training: null,
  cbmis_training: null,
  csr_for_family_planning_training: null,
  fp_or_fpcbt_level2_training: null,
  no_scalpel_vasectomy_training: null,
  bilateral_tubal_ligation_mini_laparotomy: null,
  subdermal_implant_training: null,
  via_training: null,
  individual_records_for_family_planning_clients: 1
};
