export default [
  {
    label: 'Health Education',
    id: 'health_education'
  },
  {
    label: 'Natural Method of Family Planning',
    id: 'natural_method_of_family_planning'
  },
  {
    label: 'Artificial Method of Family Planning',
    id: 'artificial_method_of_family_planning'
  },
  {
    label: 'Antenatal Care',
    id: 'ante_natal_care'
  },
  {
    label: 'Normal Delivery',
    id: 'normal_delivery'
  },
  {
    label: 'Assissted Delivery',
    id: 'assisted_delivery'
  },
  {
    label: 'Post-natal Care',
    id: 'post_natal_delivery'
  },
  {
    label: 'Breastfeeding Education',
    id: 'breastfeeding_education'
  },
  {
    label: 'Newborn Care',
    id: 'newborn_care'
  },
  {
    label: 'Newborn Screening - Blood',
    id: 'newborn_screening_blood'
  },
  {
    label: 'Newborn Screening - Hearing',
    id: 'newborn_screening_hearing'
  },
  {
    label: 'Child Vaccinations',
    id: 'child_vaccinations'
  },
  {
    label: 'Child Growth Monitoring',
    id: 'child_growth_monitoring'
  },
  {
    label: 'Integrated Management of Child Illnesses',
    id: 'integrated_management_of_child_illnesses'
  },
  {
    label: 'Micronutrient Supplementation',
    id: 'micronutrient_supplementation'
  },
  {
    label: 'Management of TB in Children',
    id: 'management_of_tb_in_children'
  },
  {
    label: 'Basic Oral Health Care',
    id: 'basic_oral_health_care'
  },
  {
    label: 'Education and counselling on health effects of tobacco/smoking, diet, and oral hygiene to adolescents',
    id: 'education_on_health_effects_to_adolescents'
  },
  {
    label: 'Adolescent Sexual and Reproductive Health (ASRH)',
    id: 'adolescent_sexual_and_reproductive_health'
  },
  {
    label: 'Laboratory Diagnostic Services',
    id: 'laboratory_diagnostic_services'
  },
  {
    label: 'Point-of-care / Point-of-service enrollment to PhilHealth',
    id: 'point_of_service_enrollment_to_philhealth'
  },
  {
    label: 'Practicing minimum health standards',
    id: 'practicing_minimum_health_standards'
  }
];
