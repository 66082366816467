import { colors } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import shadows from './shadows';
import typography from './typography';

const theme = createTheme({
  palette: {
    background: {
      default: '#F4F6F8',
      paper: colors.common.white
    },
    primary: {
      contrastText: '#ffffff',
      main: '#108ac3'
    },
    secondary: {
      contrastText: '#ffffff',
      main: '#f5a700'
    },
    text: {
      primary: '#123644',
      secondary: '#576062'
    },
    tertiary: {
      contrastText: '#ffffff',
      main: '#92acbf'
    },
  },
  shadows,
  typography
});

export default theme;
