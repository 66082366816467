import { useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link } from 'react-router-dom';
import {
  Box,
  Card,
  Chip,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import EnhancedTableHead from 'src/components/common/EnhancedTableHead';
import facilityTypes from 'src/constants/facilityTypes';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => (
  order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
);

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const FacilityListResults = ({
  assignments, isLoading, facilities, handleAssignment, page, setPage, ...rest
}) => {
  const classes = useStyles();
  const [limit, setLimit] = useState(50);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const numOfResultsOnPage = Math.min(limit, facilities.length - page * limit);

  const headCells = [
    {
      id: 'code',
      numeric: false,
      label: 'Health Facility Code',
      width: '18%'
    },
    {
      id: 'gisCode',
      numeric: false,
      label: 'GIS Code',
      width: '15%'
    },
    {
      id: 'name',
      numeric: false,
      label: 'Facility Name',
      width: '20%'
    },
    {
      id: 'type',
      numeric: false,
      label: 'Facility Type',
      width: '18%'
    },
    {
      id: 'city',
      numeric: false,
      label: 'City or Municipality',
      width: '18%'
    },
    {
      id: 'assignment',
      numeric: false,
      label: 'Assignment'
    }
  ];

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={facilities.length}
              headCells={headCells}
            />
            <TableBody>
              {stableSort(facilities, getComparator(order, 'gis_code'))
                .slice(page * limit, page * limit + limit)
                .map((row, index) => {
                  const labelId = `facilities-table-${index}`;
                  const assignment = assignments.find((assignment) => assignment.facility_id === row.id);

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                    >
                      <TableCell id={labelId}>
                        <Link to={`/facilities/${row.id}`}>
                          {row.facility_code}
                        </Link>
                      </TableCell>
                      <TableCell>
                        {row.gis_code}
                      </TableCell>
                      <TableCell>
                        {row.name}
                      </TableCell>
                      <TableCell>
                        {(facilityTypes.find((ft) => ft.value === row.type)).name}
                      </TableCell>
                      <TableCell>
                        {row.barangay && row.barangay.city_or_municipality && row.barangay.city_or_municipality.name}
                      </TableCell>
                      <TableCell>
                        {assignment ? (
                          <Chip color="secondary" label="Assigned" onClick={() => handleAssignment(row)} />
                        ) : (
                          <Chip label="Unassigned" onClick={() => handleAssignment(row)} />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {numOfResultsOnPage < 10 && (
                <TableRow style={{ height: 53 * (10 - numOfResultsOnPage) }}>
                  <TableCell colSpan={6}>
                    {isLoading && (
                      <Box className="spinner-container">
                        <CircularProgress />
                      </Box>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={facilities.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[50, 100, 200]}
      />
    </Card>
  );
};

FacilityListResults.propTypes = {
  facilities: PropTypes.array.isRequired
};

export default FacilityListResults;
