import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';

import theme from 'src/theme';
import routes from 'src/routes';
import GlobalStyles from 'src/components/GlobalStyles';
import SnackbarAlert from 'src/components/common/SnackbarAlert';

import 'react-perfect-scrollbar/dist/css/styles.css';
import 'src/styles/App.css';

import { getUser, getAccessToken } from './actions';

const App = (props) => {
  const routing = useRoutes(routes);

  useEffect(() => {
    const refreshToken = localStorage.getItem('refreshToken');

    if (refreshToken) {
      props.getAccessToken();
    }
  }, []);

  useEffect(() => {
    if (props.accessToken) {
      props.getUser();
    }
  }, [props.accessToken]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {routing}
      <SnackbarAlert />
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  accessToken: state.auth.accessToken
});

export default connect(mapStateToProps, {
  getUser,
  getAccessToken,
})(App);
