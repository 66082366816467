import React from 'react';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  LinearProgress,
  Typography
} from '@mui/material';
import {
  Edit as EditIcon,
  Save as SaveIcon
} from '@mui/icons-material';
import {
  INFIRMARY,
  BARANGAY_HEALTH_STATION,
  NUTRITION_POST,
  RURAL_HEALTH_UNIT,
  AUX_FACILITY
} from 'src/constants/facilityTypes';
import RadioGroupFormControl from '../formSections/RadioGroupFormControl';
import TextFieldFormControl from '../formSections/TextFieldFormControl';
import YesOrNoFormControl from '../formSections/YesOrNoFormControl';

const availability = [
  {
    label: 'Observed',
    value: 1
  },
  {
    label: 'Reported but not seen',
    value: 2
  },
  {
    label: 'Not available',
    value: 3
  }
];

const PrecautionsAndWasteManagement = (props) => {
  const {
    facilityType,
    formValues,
    isLoading,
    isSubmitting,
    readOnly,
    setReadOnly
  } = props;

  const handleEditClick = () => {
    setReadOnly(false);
  };

  const handleSaveClick = (handleSubmit) => {
    handleSubmit();
    setReadOnly(true);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formValues}
      onSubmit={(values) => {
        props.onSubmit('precautions_and_waste_management', values);
      }}
    >
      {({
        handleChange,
        handleSubmit,
        setFieldValue,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardContent>
              <Box className="form-header">
                <Typography variant="h4" gutterBottom>
                  3.3 Standard Precautions and Waste Management
                </Typography>
                {readOnly ? (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleEditClick()}
                  >
                    <EditIcon size="medium" />
                  </IconButton>
                ) : (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleSaveClick(handleSubmit)}
                  >
                    <SaveIcon size="medium" />
                  </IconButton>
                )}
              </Box>
            </CardContent>
            <Divider />
            <CardContent sx={{ p: 5 }}>
              <Typography color="textSecondary" variant="h5" gutterBottom>
                Are the following resources/supplies available in this facility today?
              </Typography>
              <Box sx={{ p: 2 }}>
                <RadioGroupFormControl
                  disabled={readOnly}
                  label="Clean Running Water (Piped, Bucket with Tap or Pour Pitcher)"
                  fieldName="clean_running_water"
                  fieldValue={values.clean_running_water}
                  setFieldValue={setFieldValue}
                  options={availability}
                />
                <RadioGroupFormControl
                  disabled={readOnly}
                  label="Hand-Washing Soap (May Be Liquid Soap)"
                  fieldName="handwashing_soap"
                  fieldValue={values.handwashing_soap}
                  setFieldValue={setFieldValue}
                  options={availability}
                />
                <RadioGroupFormControl
                  disabled={readOnly}
                  label="70% Isopropyl Alcohol"
                  fieldName="seventy_percent_isopropyl_alcohol"
                  fieldValue={values.seventy_percent_isopropyl_alcohol}
                  setFieldValue={setFieldValue}
                  options={availability}
                />
                <RadioGroupFormControl
                  disabled={readOnly}
                  label="Disposable Latex Gloves"
                  fieldName="disposable_latex_gloves"
                  fieldValue={values.disposable_latex_gloves}
                  setFieldValue={setFieldValue}
                  options={availability}
                />
                <RadioGroupFormControl
                  disabled={readOnly}
                  label="Waste Receptable (Pedal Bin) with Lid and Plastic Liner"
                  fieldName="water_receptacle_with_lid_and_liner"
                  fieldValue={values.water_receptacle_with_lid_and_liner}
                  setFieldValue={setFieldValue}
                  options={availability}
                />
                <RadioGroupFormControl
                  disabled={readOnly}
                  label="Segregated, Properly Labeled Garbage Containers"
                  fieldName="segregated_labeled_garbage_containers"
                  fieldValue={values.segregated_labeled_garbage_containers}
                  setFieldValue={setFieldValue}
                  options={availability}
                />
                <RadioGroupFormControl
                  disabled={readOnly}
                  label="Sharps Container"
                  fieldName="sharps_container"
                  fieldValue={values.sharps_container}
                  setFieldValue={setFieldValue}
                  options={availability}
                />
                <RadioGroupFormControl
                  disabled={readOnly}
                  label="Infectious Wastes Container"
                  fieldName="infectious_wastes_container"
                  fieldValue={values.infectious_wastes_container}
                  setFieldValue={setFieldValue}
                  options={availability}
                />
                <RadioGroupFormControl
                  disabled={readOnly}
                  label="Environmental Disinfectant (Alcohol, Chlorine) and Facility Cleaning Materials"
                  fieldName="env_disinfectant_and_facilitity_cleaning_materials"
                  fieldValue={values.env_disinfectant_and_facilitity_cleaning_materials}
                  setFieldValue={setFieldValue}
                  options={availability}
                />
                <RadioGroupFormControl
                  disabled={readOnly}
                  label="Disposable Syringes (1 cc, 3 cc, 5 cc, 10 cc) with Disposable Needles"
                  fieldName="disposable_syringes_with_disposable_needles"
                  fieldValue={values.disposable_syringes_with_disposable_needles}
                  setFieldValue={setFieldValue}
                  options={availability}
                />
                <RadioGroupFormControl
                  disabled={readOnly}
                  label="Auto-Disable Syringes"
                  fieldName="autodisable_syringes"
                  fieldValue={values.autodisable_syringes}
                  setFieldValue={setFieldValue}
                  options={availability}
                />
                {(facilityType !== BARANGAY_HEALTH_STATION && facilityType !== NUTRITION_POST && facilityType !== AUX_FACILITY) && (
                  <>
                    <RadioGroupFormControl
                      disabled={readOnly}
                      label="Soaking Solution for Medical Instruments"
                      fieldName="soaking_solution_for_medical_instruments"
                      fieldValue={values.soaking_solution_for_medical_instruments}
                      setFieldValue={setFieldValue}
                      options={availability}
                    />
                    <RadioGroupFormControl
                      disabled={readOnly}
                      label="Autoclave or Sterilization Equipment"
                      fieldName="autoclave_or_sterilization_equipment"
                      fieldValue={values.autoclave_or_sterilization_equipment}
                      setFieldValue={setFieldValue}
                      options={availability}
                    />
                    <RadioGroupFormControl
                      disabled={readOnly}
                      label="Guidelines for Standard Precautions Posted"
                      fieldName="guidelines_for_standard_precautions_posted"
                      fieldValue={values.guidelines_for_standard_precautions_posted}
                      setFieldValue={setFieldValue}
                      options={availability}
                    />
                    <RadioGroupFormControl
                      disabled={readOnly}
                      label="Eye Protection"
                      fieldName="eye_protection"
                      fieldValue={values.eye_protection}
                      setFieldValue={setFieldValue}
                      options={availability}
                    />
                    <RadioGroupFormControl
                      disabled={readOnly}
                      label="Medical Mask"
                      fieldName="medical_mask"
                      fieldValue={values.medical_mask}
                      setFieldValue={setFieldValue}
                      options={availability}
                    />
                    <RadioGroupFormControl
                      disabled={readOnly}
                      label="Sterile Drapes"
                      fieldName="sterile_drapes"
                      fieldValue={values.sterile_drapes}
                      setFieldValue={setFieldValue}
                      options={availability}
                    />
                    <RadioGroupFormControl
                      disabled={readOnly}
                      label="Surgical Cap"
                      fieldName="surgical_cap"
                      fieldValue={values.surgical_cap}
                      setFieldValue={setFieldValue}
                      options={availability}
                    />
                    <RadioGroupFormControl
                      disabled={readOnly}
                      label="Gown"
                      fieldName="gown"
                      fieldValue={values.gown}
                      setFieldValue={setFieldValue}
                      options={availability}
                    />
                  </>
                )}
                <Typography color="textSecondary" variant="h5" gutterBottom>
                  How does this facility process its medical equipment for re-use? (Select all that applies.)
                </Typography>
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Wash with soap and water only"
                  fieldName="soap_and_water_only"
                  fieldValue={values.soap_and_water_only}
                  setFieldValue={setFieldValue}
                />
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Soak in chemical solution then wash with soap and water"
                  fieldName="chemicals_then_soap_and_water"
                  fieldValue={values.chemicals_then_soap_and_water}
                  setFieldValue={setFieldValue}
                />
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Sterilizer"
                  fieldName="sterilizer"
                  fieldValue={values.sterilizer}
                  setFieldValue={setFieldValue}
                />
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Autoclave"
                  fieldName="autoclave"
                  fieldValue={values.autoclave}
                  setFieldValue={setFieldValue}
                />
                <TextFieldFormControl
                  disabled={readOnly}
                  indent
                  label="If process elsewhere:"
                  fieldName="medical_equipment_reuse_process_elsewhere_value"
                  fieldValue={values.medical_equipment_reuse_process_elsewhere_value}
                  handleChange={handleChange}
                />
                <Typography color="textSecondary" variant="h5" gutterBottom>
                  How does the facility finally dispose of its used sharps? (Select all that applies.)
                </Typography>
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Incineration"
                  fieldName="incineration"
                  fieldValue={values.incineration}
                  setFieldValue={setFieldValue}
                />
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Open burning in protected area"
                  fieldName="open_burning_in_protected_area"
                  fieldValue={values.open_burning_in_protected_area}
                  setFieldValue={setFieldValue}
                />
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Dump without burning in protected area"
                  fieldName="dump_without_burning"
                  fieldValue={values.dump_without_burning}
                  setFieldValue={setFieldValue}
                />
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Remove offsite with protected storage"
                  fieldName="remove_with_protected_storage"
                  fieldValue={values.remove_with_protected_storage}
                  setFieldValue={setFieldValue}
                />
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Remove offsite and dump in general landfill"
                  fieldName="remove_and_dump_in_landfill"
                  fieldValue={values.remove_and_dump_in_landfill}
                  setFieldValue={setFieldValue}
                />
                {facilityType !== INFIRMARY && facilityType !== RURAL_HEALTH_UNIT && (
                  <YesOrNoFormControl
                    disabled={readOnly}
                    label="Septic vault"
                    fieldName="septic_vault"
                    fieldValue={values.septic_vault}
                    setFieldValue={setFieldValue}
                  />
                )}
              </Box>
            </CardContent>
            <Divider />
            <Box className="box-button">
              <Button
                disabled={isLoading || isSubmitting || !readOnly}
                color="primary"
                onClick={() => handleEditClick()}
                variant="contained"
                sx={{ mx: 2 }}
              >
                Edit
              </Button>
              <Button
                disabled={isLoading || isSubmitting || readOnly}
                color="primary"
                onClick={() => handleSaveClick(handleSubmit)}
                type="submit"
                variant="contained"
              >
                Save
              </Button>
            </Box>
            {(isLoading || isSubmitting) && <LinearProgress />}
          </Card>
        </form>
      )}

    </Formik>
  );
};

export default PrecautionsAndWasteManagement;
