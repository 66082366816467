import React from 'react';
import {
  Box,
  TableContainer,
} from '@mui/material';
import { doh, philhealth } from 'src/constants/fields/section3/liscensing';
import YesNoTable from '../tables/YesNoTable';

const Section311 = (props) => {
  const { data, provinces } = props;

  return (
    <TableContainer>
      <YesNoTable
        ariaLabel="3.1.1 licensing certification from DOH"
        provinces={provinces}
        rows={doh}
        response={data}
        title="Liscensing from Department of Health (DOH)"
        sectionKey="licensing_and_certification"
      />
      <Box sx={{ height: 40 }} />
      <YesNoTable
        ariaLabel="3.1.1 licensing certification from philhealth"
        provinces={provinces}
        rows={philhealth}
        response={data}
        title="Liscensing from PhilHealth"
        sectionKey="licensing_and_certification"
      />
    </TableContainer>
  );
};

export default Section311;
