export default [
  {
    label: 'Generalist Medical Doctor',
    id: 'genmd'
  },
  {
    label: 'Registered Nurse',
    id: 'nurse'
  },
  {
    label: 'Registered Midwife',
    id: 'midwife'
  },
  {
    label: 'Registered Pharmacist',
    id: 'pharmacist'
  },
  {
    label: 'Medical Technologist',
    id: 'medtech'
  },
  {
    label: 'Dentist',
    id: 'dentist'
  },
  {
    label: 'Dental Aide',
    id: 'dentalaide'
  },
  {
    label: 'Active Barangay Health Worker',
    id: 'bhw'
  },
  {
    label: 'Active Barangay Nutrition Scholar',
    id: 'bns'
  },
  {
    label: 'Community Health Volunteers',
    id: 'chv'
  },
  {
    label: 'Ambulance Driver',
    id: 'driver'
  },
  {
    label: 'Nutritionist',
    id: 'nutritionist'
  }
];
