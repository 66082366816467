import React from 'react';
import { Box, TableContainer } from '@mui/material';
import AvailableFunctioningTable from 'src/components/dashboard/tables/AvailableFunctioningTable';
import { family } from 'src/constants/fields/section4/equipments';

const Section474 = (props) => {
  const { data, provinces } = props;

  return (
    <TableContainer>
      {provinces.map((province) => (
        <React.Fragment key={`4.7.4-family-planning-equipments-${province.id}`}>
          <AvailableFunctioningTable
            ariaLabel={`4.7.4 family planning equipments ${province.name}`}
            province={province}
            rows={family}
            response={data}
            sectionKey="family_planning_equipment"
          />
          <Box sx={{ height: 40 }} />
        </React.Fragment>
      ))}
    </TableContainer>
  );
};

export default Section474;
