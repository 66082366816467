import React from 'react';
import { connect } from 'react-redux';
import {
  FormControl,
  FormLabel,
  Grid,
  TextField
} from '@mui/material';

const TextFieldFormControl = (props) => {
  const {
    disabled, isSubmitting, label,
    fieldName, fieldValue, handleChange, indent
  } = props;

  return (
    <FormControl
      component="fieldset"
      fullWidth
      sx={{ py: 1 }}
      variant="outlined"
    >
      <Grid container>
        <Grid item xs={12} sm={6} md={6}>
          <FormLabel
            component="legend"
            className={indent ? 'indent field-label' : 'field-label'}
          >
            {label}
          </FormLabel>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            disabled={isSubmitting || disabled}
            name={fieldName}
            onChange={handleChange}
            size="small"
            sx={{ width: '90%' }}
            value={fieldValue || ''}
            variant="outlined"
          />
        </Grid>
      </Grid>
    </FormControl>
  );
};

const mapStateToProps = (state) => ({
  isSubmitting: state.messages.isSubmitting
});

export default connect(mapStateToProps)(TextFieldFormControl);
