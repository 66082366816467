import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Box,
  CircularProgress,
  Container,
  Grid
} from '@mui/material';
import FacilityForm from 'src/components/facility/FacilityForm';
import { editFacility, fetchFacility } from 'src/actions/facility';
import { fetchBarangays } from 'src/actions/places';
import { getLocalStorageItem } from 'src/utils/localStorage';

const FacilityDetail = (props) => {
  const { id } = useParams();
  const {
    barangays,
    isLoading,
    isSubmitting,
    facilities,
    payload,
    edit
  } = props;

  const [facility, setFacility] = useState(null);
  const [readOnly, setReadOnly] = useState(true);
  const [barangayList, setBarangayList] = useState([]);
  const [isBarangayListInStorage, setIsBarangayListInStorage] = useState(false);

  useEffect(() => {
    props.fetchFacility(id);
    if (edit === 'true') setReadOnly(false);

    const storedBarangays = JSON.parse(getLocalStorageItem('barangays'));
    if (storedBarangays) {
      setIsBarangayListInStorage(true);
      setBarangayList([...barangayList, ...storedBarangays]);
    } else {
      props.fetchBarangays();
    }
  }, []);

  useEffect(() => {
    setFacility(facilities[id]);
  }, [facilities]);

  const handleEditFacility = (form) => {
    props.editFacility(id, form);
  };

  if (!facility) {
    return (
      <Box className="spinner-container">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      {facility && (
        <Helmet>
          <title>{`${facility.name} - FReSAA`}</title>
        </Helmet>
      )}
      <Box className="box-page">
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FacilityForm
                barangays={isBarangayListInStorage ? barangayList : barangays}
                isLoading={isLoading}
                isSubmitting={isSubmitting}
                onSubmit={handleEditFacility}
                payload={payload}
                facility={facility}
                readOnly={readOnly}
                setReadOnly={setReadOnly}
                fetchAssessment={true}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  barangays: Object.values(state.barangays),
  isLoading: state.messages.isLoading,
  isSubmitting: state.messages.isSubmitting,
  facilities: state.facilities,
  payload: state.messages.payload
});

export default connect(mapStateToProps, {
  editFacility,
  fetchBarangays,
  fetchFacility
})(FacilityDetail);
