import React, { useEffect } from 'react';
import {
  Card,
  Box,
  Button,
  Container,
  LinearProgress,
  TextField,
  Typography,
  CardContent,
  Grid
} from '@mui/material';
import { Formik } from 'formik';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { confirmResetPassword } from 'src/actions/auth';

const ConfirmPasswordReset = (props) => {
  const { isSubmitting, success } = props;
  const { uid, token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (success) {
      navigate('/login', { replace: true });
    }
  }, [success]);

  return (
    <>
      <Helmet>
        <title>Forgot Password - FReSAA</title>
      </Helmet>
      {isSubmitting && <LinearProgress />}
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Card>
            <CardContent>
              <Formik
                initialValues={{ password1: '', password2: '' }}
                onSubmit={(values) => {
                  const form = {
                    uid,
                    token,
                    new_password1: values.password1,
                    new_password2: values.password2
                  };
                  props.confirmResetPassword(form);
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={3} sx={{ p: 2 }}>
                      <Grid item xs={12}>
                        <Typography
                          color="textPrimary"
                          variant="h2"
                        >
                          Reset your password
                        </Typography>
                        <Typography
                          color="textSecondary"
                          variant="subtitle1"
                        >
                          Please enter and confirm your new password.
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="New Password"
                          name="password1"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="password"
                          value={values.password1}
                          variant="outlined"
                          disabled={isSubmitting}
                          error={Boolean(touched.password1 && errors.password1)}
                          helperText={touched.password1 && errors.password1}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Confirm Password"
                          name="password2"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="password"
                          value={values.password2}
                          variant="outlined"
                          disabled={isSubmitting}
                          error={Boolean(touched.password2 && errors.password2)}
                          helperText={touched.password2 && errors.password2}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Link to="/login">
                          <Button
                            aria-label="submit"
                            disabled={isSubmitting}
                            fullWidth
                            size="large"
                            variant="contained"
                            color="tertiary"
                          >
                            Cancel
                          </Button>
                        </Link>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          aria-label="submit"
                          color="primary"
                          disabled={isSubmitting}
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Formik>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  isSubmitting: state.messages.isSubmitting,
  errors: Object.values(state.auth.errors),
  success: state.messages.success
});

export default connect(mapStateToProps, {
  confirmResetPassword
})(ConfirmPasswordReset);
