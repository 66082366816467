import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography
} from '@mui/material';
import SectionBreadcrumbs from 'src/components/assessment/SectionBreadcrumbs';
import AntenatalCareSupplies from 'src/components/assessment/section4/4.8/AntenatalCareSupplies';
import DeliveryServicesSupplies from 'src/components/assessment/section4/4.8/DeliveryServicesSupplies';
import PostNatalCareSupplies from 'src/components/assessment/section4/4.8/PostNatalCareSupplies';
import NewbornCareSupplies from 'src/components/assessment/section4/4.8/NewbornCareSupplies';
import FamilyPlanningSupplies from 'src/components/assessment/section4/4.8/FamilyPlanningSupplies';
import ChildVaccinationSupplies from 'src/components/assessment/section4/4.8/ChildVaccinationSupplies';
import ChildPreventiveCareSupplies from 'src/components/assessment/section4/4.8/ChildPreventiveCareSupplies';

import { fetchAssessment, updateAssessment } from 'src/actions/assessment';

const useQuery = () => new URLSearchParams(useLocation().search);

const SuppliesPage = (props) => {
  const query = useQuery();
  const { id } = useParams();
  const { assessments, isLoading, isSubmitting } = props;
  const [readOnly, setReadOnly] = useState(true);

  const [assessment, setAssessment] = useState(null);

  useEffect(() => {
    props.fetchAssessment(id);
    const edit = query.get('edit');
    if (edit === 'true') setReadOnly(false);
  }, []);

  useEffect(() => {
    const tmpAssessment = assessments[id];

    if (tmpAssessment) {
      setAssessment(tmpAssessment);
    }
  }, [assessments]);

  const handleSubmit = (subsection, form) => {
    const assessmentForm = {
      ...assessment,
      facility_id: assessment.facility.id,
      [subsection]: form
    };
    props.updateAssessment(id, assessmentForm);
  };

  return (
    <>
      <Helmet>
        <title>
          {assessment && (
            `${assessment.facility.name} - Section 4.8  - Assessment - FReSAA`
          )}
        </title>
      </Helmet>
      <Box className="box-page">
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <SectionBreadcrumbs
              active="section4"
              assessmentId={id}
              previous={`/assessments/${id}/sections/4/4.7`}
            />
            <Grid item xs={12}>
              <Typography
                color="textPrimary"
                gutterBottom
                variant="h2"
              >
                Section 4: Service Specific Readiness
              </Typography>
              <Typography
                color="textPrimary"
                gutterBottom
                variant="h4"
              >
                4.8 Medicines, Supplies, and Commodities
              </Typography>
            </Grid>
            {assessment ? (
              <>
                <Grid item xs={12}>
                  <AntenatalCareSupplies
                    facilityType={assessment.facility.type}
                    formValues={assessment.antenatal_care_supplies}
                    isLoading={isLoading}
                    isSubmitting={isSubmitting}
                    onSubmit={handleSubmit}
                    readOnly={readOnly}
                    setReadOnly={setReadOnly}
                  />
                </Grid>
                {(assessment.facility.type === 1 || assessment.facility.type === 3 || assessment.facility.type === 5) && (
                  <Grid item xs={12}>
                    <DeliveryServicesSupplies
                      facilityType={assessment.facility.type}
                      formValues={assessment.delivery_services_supplies}
                      isLoading={isLoading}
                      isSubmitting={isSubmitting}
                      onSubmit={handleSubmit}
                      readOnly={readOnly}
                      setReadOnly={setReadOnly}
                    />
                  </Grid>
                )}
                {assessment.facility.type !== 4 && (
                  <>
                    <Grid item xs={12}>
                      <PostNatalCareSupplies
                        facilityType={assessment.facility.type}
                        formValues={assessment.postnatal_care_supplies}
                        isLoading={isLoading}
                        isSubmitting={isSubmitting}
                        onSubmit={handleSubmit}
                        readOnly={readOnly}
                        setReadOnly={setReadOnly}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <NewbornCareSupplies
                        facilityType={assessment.facility.type}
                        formValues={assessment.newborn_care_supplies}
                        isLoading={isLoading}
                        isSubmitting={isSubmitting}
                        onSubmit={handleSubmit}
                        readOnly={readOnly}
                        setReadOnly={setReadOnly}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FamilyPlanningSupplies
                        facilityType={assessment.facility.type}
                        formValues={assessment.family_planning_supplies}
                        isLoading={isLoading}
                        isSubmitting={isSubmitting}
                        onSubmit={handleSubmit}
                        readOnly={readOnly}
                        setReadOnly={setReadOnly}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <ChildVaccinationSupplies
                    facilityType={assessment.facility.type}
                    formValues={assessment.child_vaccination_supplies}
                    isLoading={isLoading}
                    isSubmitting={isSubmitting}
                    onSubmit={handleSubmit}
                    readOnly={readOnly}
                    setReadOnly={setReadOnly}
                  />
                </Grid>
                {(assessment.facility.type === 1 || assessment.facility.type === 2 || assessment.facility.type === 5) && (
                  <Grid item xs={12}>
                    <ChildPreventiveCareSupplies
                      facilityType={assessment.facility.type}
                      formValues={assessment.child_preventive_and_curative_care_supplies}
                      isLoading={isLoading}
                      isSubmitting={isSubmitting}
                      onSubmit={handleSubmit}
                      readOnly={readOnly}
                      setReadOnly={setReadOnly}
                    />
                  </Grid>
                )}
              </>
            ) : (
              <Grid item xs={12}>
                <Box className="spinner-container">
                  <CircularProgress />
                </Box>
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  assessments: state.assessments,
  isLoading: state.messages.isLoading,
  isSubmitting: state.messages.isSubmitting,
});

export default connect(mapStateToProps, {
  fetchAssessment,
  updateAssessment
})(SuppliesPage);
