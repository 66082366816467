import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  LinearProgress,
  TextField,
  Typography
} from '@mui/material';
import {
  Edit as EditIcon,
  Save as SaveIcon
} from '@mui/icons-material';
import {
  INFIRMARY,
  BARANGAY_HEALTH_STATION,
  SAFE_BIRTHING_FACILITY,
  NUTRITION_POST,
  AUX_FACILITY
} from 'src/constants/facilityTypes';
import HCPFormControl from 'src/components/assessment/formSections/HCPFormControl';
import RadioGroupFormControl from '../formSections/RadioGroupFormControl';

const hcpAvailability = [
  {
    label: 'Yes, schedule is posted publicly',
    value: 1
  },
  {
    label: 'Yes, but no schedule/list is posted',
    value: 2
  },
  {
    label: 'No 24-hr staff',
    value: 3
  },
  {
    label: 'Do not know',
    value: 4
  }
];

const facilityAvailability = [
  {
    label: '24 hrs, 7 days a week',
    value: 1
  },
  {
    label: '24 hrs, 5 days a week (close weekends)',
    value: 2
  },
  {
    label: '8 hrs/day, 5 days a week (40 hrs)',
    value: 3
  },
  {
    label: '2-4 days per week (< 40 hrs)',
    value: 4
  },
  {
    label: '2-4 days per month',
    value: 5
  },
  {
    label: 'Once a month',
    value: 6
  }
];

const HealthCarePersonnel = (props) => {
  const {
    healthCarePersonnel,
    facilityType,
    isLoading,
    isSubmitting,
    readOnly,
    setReadOnly
  } = props;

  const handleEditClick = () => {
    setReadOnly(false);
  };

  const handleSaveClick = (handleSubmit) => {
    handleSubmit();
    setReadOnly(true);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={healthCarePersonnel}
      onSubmit={(values) => {
        props.onSubmit('healthcare_personnel', values);
      }}
      validationSchema={Yup.object().shape({
        genmd_plantilla_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        genmd_casual_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        genmd_deployed_by_doh_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        genmd_part_time_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        genmd_volunteer_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        genmd_parttimers_weekly_hour_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        nurse_plantilla_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        nurse_casual_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        nurse_deployed_by_doh_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        nurse_part_time_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        nurse_volunteer_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        nurse_parttimers_weekly_hour_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        midwife_plantilla_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        midwife_casual_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        midwife_deployed_by_doh_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        midwife_part_time_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        midwife_volunteer_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        midwife_parttimers_weekly_hour_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        pharmacist_plantilla_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        pharmacist_casual_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        pharmacist_deployed_by_doh_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        pharmacist_part_time_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        pharmacist_volunteer_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        pharmacist_parttimers_weekly_hour_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        medtech_plantilla_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        medtech_casual_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        medtech_deployed_by_doh_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        medtech_part_time_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        medtech_volunteer_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        medtech_parttimers_weekly_hour_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        dentist_plantilla_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        dentist_casual_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        dentist_deployed_by_doh_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        dentist_part_time_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        dentist_volunteer_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        dentist_parttimers_weekly_hour_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        dentalaide_plantilla_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        dentalaide_casual_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        dentalaide_deployed_by_doh_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        dentalaide_part_time_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        dentalaide_volunteer_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        dentalaide_parttimers_weekly_hour_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        bhw_plantilla_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        bhw_casual_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        bhw_deployed_by_doh_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        bhw_part_time_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        bhw_volunteer_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        bhw_parttimers_weekly_hour_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        bns_plantilla_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        bns_casual_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        bns_deployed_by_doh_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        bns_part_time_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        bns_volunteer_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        bns_parttimers_weekly_hour_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        chv_plantilla_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        chv_casual_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        chv_deployed_by_doh_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        chv_part_time_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        chv_volunteer_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        chv_parttimers_weekly_hour_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        driver_plantilla_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        driver_casual_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        driver_deployed_by_doh_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        driver_part_time_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        driver_volunteer_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        driver_parttimers_weekly_hour_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        nutritionist_plantilla_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        nutritionist_casual_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        nutritionist_deployed_by_doh_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        nutritionist_part_time_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        nutritionist_volunteer_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        nutritionist_parttimers_weekly_hour_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        other1_plantilla_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        other1_casual_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        other1_deployed_by_doh_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        other1_part_time_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        other1_volunteer_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        other1_parttimers_weekly_hour_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        other2_plantilla_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        other2_casual_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        other2_deployed_by_doh_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        other2_part_time_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        other2_volunteer_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        other2_parttimers_weekly_hour_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        other3_plantilla_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        other3_casual_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        other3_deployed_by_doh_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        other3_part_time_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        other3_volunteer_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        other3_parttimers_weekly_hour_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        other4_plantilla_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        other4_casual_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        other4_deployed_by_doh_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        other4_part_time_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        other4_volunteer_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        other4_parttimers_weekly_hour_count: Yup.number().integer().min(0, 'Must be greater than 0'),
      })}
    >
      {({
        errors,
        handleChange,
        handleSubmit,
        setFieldValue,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardContent>
              <Box className="form-header">
                <Typography variant="h4" gutterBottom>
                  2.3 Health Care Personnel
                </Typography>
                {readOnly ? (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleEditClick()}
                  >
                    <EditIcon size="medium" />
                  </IconButton>
                ) : (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleSaveClick(handleSubmit)}
                  >
                    <SaveIcon size="medium" />
                  </IconButton>
                )}
              </Box>
            </CardContent>
            <Divider />
            <CardContent sx={{ p: 5 }}>
              <Typography color="textSecondary" variant="body1" gutterBottom>
                How many staff with each of the following qualifications is currently assigned to, employed by, or seconded to this facility, whether full time or part time.
              </Typography>
              {/* ----- generalist medical doctors ----- */}
              { ((facilityType !== BARANGAY_HEALTH_STATION) && (facilityType !== NUTRITION_POST) && (facilityType !== AUX_FACILITY)) && (
                <>
                  <Box sx={{ p: 2 }}>
                    <Typography variant="h5" gutterBottom>
                      Generalist Medical Doctors (Non-Specialist)
                    </Typography>
                    <HCPFormControl
                      disabled={readOnly}
                      indent
                      label="Full-time Plantilla"
                      name="genmd_plantilla_count"
                      handleChange={handleChange}
                      value={values.genmd_plantilla_count}
                      error={Boolean(touched.genmd_plantilla_count && errors.genmd_plantilla_count)}
                      helperText={touched.genmd_plantilla_count && errors.genmd_plantilla_count}
                    />
                    <HCPFormControl
                      disabled={readOnly}
                      indent
                      label="Full-time Job Order/Casual"
                      name="genmd_casual_count"
                      handleChange={handleChange}
                      value={values.genmd_casual_count}
                      error={Boolean(touched.genmd_casual_count && errors.genmd_casual_count)}
                      helperText={touched.genmd_casual_count && errors.genmd_casual_count}
                    />
                    <HCPFormControl
                      disabled={readOnly}
                      indent
                      label="Full-time Deployed by DOH"
                      name="genmd_deployed_by_doh_count"
                      handleChange={handleChange}
                      value={values.genmd_deployed_by_doh_count}
                      error={Boolean(touched.genmd_deployed_by_doh_count && errors.genmd_deployed_by_doh_count)}
                      helperText={touched.genmd_deployed_by_doh_count && errors.genmd_deployed_by_doh_count}
                    />
                    <HCPFormControl
                      disabled={readOnly}
                      indent
                      label="Part-time"
                      name="genmd_part_time_count"
                      handleChange={handleChange}
                      value={values.genmd_part_time_count}
                      error={Boolean(touched.genmd_part_time_count && errors.genmd_part_time_count)}
                      helperText={touched.genmd_part_time_count && errors.genmd_part_time_count}
                    />
                    <HCPFormControl
                      disabled={readOnly}
                      indent
                      label="Volunteer"
                      name="genmd_volunteer_count"
                      handleChange={handleChange}
                      value={values.genmd_volunteer_count}
                      error={Boolean(touched.genmd_volunteer_count && errors.genmd_volunteer_count)}
                      helperText={touched.genmd_volunteer_count && errors.genmd_volunteer_count}
                    />
                    <HCPFormControl
                      disabled={readOnly}
                      label="Total Number of Hours in a Facility per Week"
                      name="genmd_parttimers_weekly_hour_count"
                      handleChange={handleChange}
                      value={values.genmd_parttimers_weekly_hour_count}
                      error={Boolean(touched.genmd_parttimers_weekly_hour_count && errors.genmd_parttimers_weekly_hour_count)}
                      helperText={touched.genmd_parttimers_weekly_hour_count && errors.genmd_parttimers_weekly_hour_count}
                    />
                  </Box>
                  <Divider />
                </>
              )}
              {/* ----- registered nurse ----- */}
              <Box sx={{ p: 2 }}>
                <Typography variant="h5" gutterBottom>
                  Registered Nurse
                </Typography>
                <HCPFormControl
                  disabled={readOnly}
                  indent
                  label="Full-time Plantilla"
                  name="nurse_plantilla_count"
                  handleChange={handleChange}
                  value={values.nurse_plantilla_count}
                  error={Boolean(touched.nurse_plantilla_count && errors.nurse_plantilla_count)}
                  helperText={touched.nurse_plantilla_count && errors.nurse_plantilla_count}
                />
                <HCPFormControl
                  disabled={readOnly}
                  indent
                  label="Full-time Job Order/Casual"
                  name="nurse_casual_count"
                  handleChange={handleChange}
                  value={values.nurse_casual_count}
                  error={Boolean(touched.nurse_casual_count && errors.nurse_casual_count)}
                  helperText={touched.nurse_casual_count && errors.nurse_casual_count}
                />
                <HCPFormControl
                  disabled={readOnly}
                  indent
                  label="Full-time Deployed by DOH"
                  name="nurse_deployed_by_doh_count"
                  handleChange={handleChange}
                  value={values.nurse_deployed_by_doh_count}
                  error={Boolean(touched.nurse_deployed_by_doh_count && errors.nurse_deployed_by_doh_count)}
                  helperText={touched.nurse_deployed_by_doh_count && errors.nurse_deployed_by_doh_count}
                />
                <HCPFormControl
                  disabled={readOnly}
                  indent
                  label="Part-time"
                  name="nurse_part_time_count"
                  handleChange={handleChange}
                  value={values.nurse_part_time_count}
                  error={Boolean(touched.nurse_part_time_count && errors.nurse_part_time_count)}
                  helperText={touched.nurse_part_time_count && errors.nurse_part_time_count}
                />
                <HCPFormControl
                  disabled={readOnly}
                  indent
                  label="Volunteer"
                  name="nurse_volunteer_count"
                  handleChange={handleChange}
                  value={values.nurse_volunteer_count}
                  error={Boolean(touched.nurse_volunteer_count && errors.nurse_volunteer_count)}
                  helperText={touched.nurse_volunteer_count && errors.nurse_volunteer_count}
                />
                <HCPFormControl
                  disabled={readOnly}
                  label="Total Number of Hours in a Facility per Week"
                  name="nurse_parttimers_weekly_hour_count"
                  handleChange={handleChange}
                  value={values.nurse_parttimers_weekly_hour_count}
                  error={Boolean(touched.nurse_parttimers_weekly_hour_count && errors.nurse_parttimers_weekly_hour_count)}
                  helperText={touched.nurse_parttimers_weekly_hour_count && errors.nurse_parttimers_weekly_hour_count}
                />
              </Box>
              <Divider />
              {/* ----- registered midwife ------ */}
              <Box sx={{ p: 2 }}>
                <Typography variant="h5" gutterBottom>
                  Registered Midwife
                </Typography>
                <HCPFormControl
                  disabled={readOnly}
                  indent
                  label="Full-time Plantilla"
                  name="midwife_plantilla_count"
                  handleChange={handleChange}
                  value={values.midwife_plantilla_count}
                  error={Boolean(touched.midwife_plantilla_count && errors.midwife_plantilla_count)}
                  helperText={touched.midwife_plantilla_count && errors.midwife_plantilla_count}
                />
                <HCPFormControl
                  disabled={readOnly}
                  indent
                  label="Full-time Job Order/Casual"
                  name="midwife_casual_count"
                  handleChange={handleChange}
                  value={values.midwife_casual_count}
                  error={Boolean(touched.midwife_casual_count && errors.midwife_casual_count)}
                  helperText={touched.midwife_casual_count && errors.midwife_casual_count}
                />
                <HCPFormControl
                  disabled={readOnly}
                  indent
                  label="Full-time Deployed by DOH"
                  name="midwife_deployed_by_doh_count"
                  handleChange={handleChange}
                  value={values.midwife_deployed_by_doh_count}
                  error={Boolean(touched.midwife_deployed_by_doh_count && errors.midwife_deployed_by_doh_count)}
                  helperText={touched.midwife_deployed_by_doh_count && errors.midwife_deployed_by_doh_count}
                />
                <HCPFormControl
                  disabled={readOnly}
                  indent
                  label="Part-time"
                  name="midwife_part_time_count"
                  handleChange={handleChange}
                  value={values.midwife_part_time_count}
                  error={Boolean(touched.midwife_part_time_count && errors.midwife_part_time_count)}
                  helperText={touched.midwife_part_time_count && errors.midwife_part_time_count}
                />
                <HCPFormControl
                  disabled={readOnly}
                  indent
                  label="Volunteer"
                  name="midwife_volunteer_count"
                  handleChange={handleChange}
                  value={values.midwife_volunteer_count}
                  error={Boolean(touched.midwife_volunteer_count && errors.midwife_volunteer_count)}
                  helperText={touched.midwife_volunteer_count && errors.midwife_volunteer_count}
                />
                <HCPFormControl
                  disabled={readOnly}
                  label="Total Number of Hours in a Facility per Week"
                  name="midwife_parttimers_weekly_hour_count"
                  handleChange={handleChange}
                  value={values.midwife_parttimers_weekly_hour_count}
                  error={Boolean(touched.midwife_parttimers_weekly_hour_count && errors.midwife_parttimers_weekly_hour_count)}
                  helperText={touched.midwife_parttimers_weekly_hour_count && errors.midwife_parttimers_weekly_hour_count}
                />
              </Box>
              <Divider />
              {/* ----- registered pharmacist ----- */}
              { ((facilityType !== BARANGAY_HEALTH_STATION) && (facilityType !== SAFE_BIRTHING_FACILITY) && (facilityType !== NUTRITION_POST) && (facilityType !== AUX_FACILITY)) && (
                <>
                  <Box sx={{ p: 2 }}>
                    <Typography variant="h5" gutterBottom>
                      Registered Pharmacist
                    </Typography>
                    <HCPFormControl
                      disabled={readOnly}
                      indent
                      label="Full-time Plantilla"
                      name="pharmacist_plantilla_count"
                      handleChange={handleChange}
                      value={values.pharmacist_plantilla_count}
                      error={Boolean(touched.pharmacist_plantilla_count && errors.pharmacist_plantilla_count)}
                      helperText={touched.pharmacist_plantilla_count && errors.pharmacist_plantilla_count}
                    />
                    <HCPFormControl
                      disabled={readOnly}
                      indent
                      label="Full-time Job Order/Casual"
                      name="pharmacist_casual_count"
                      handleChange={handleChange}
                      value={values.pharmacist_casual_count}
                      error={Boolean(touched.pharmacist_casual_count && errors.pharmacist_casual_count)}
                      helperText={touched.pharmacist_casual_count && errors.pharmacist_casual_count}
                    />
                    <HCPFormControl
                      disabled={readOnly}
                      indent
                      label="Full-time Deployed by DOH"
                      name="pharmacist_deployed_by_doh_count"
                      handleChange={handleChange}
                      value={values.pharmacist_deployed_by_doh_count}
                      error={Boolean(touched.pharmacist_deployed_by_doh_count && errors.pharmacist_deployed_by_doh_count)}
                      helperText={touched.pharmacist_deployed_by_doh_count && errors.pharmacist_deployed_by_doh_count}
                    />
                    <HCPFormControl
                      disabled={readOnly}
                      indent
                      label="Part-time"
                      name="pharmacist_part_time_count"
                      handleChange={handleChange}
                      value={values.pharmacist_part_time_count}
                      error={Boolean(touched.pharmacist_part_time_count && errors.pharmacist_part_time_count)}
                      helperText={touched.pharmacist_part_time_count && errors.pharmacist_part_time_count}
                    />
                    <HCPFormControl
                      disabled={readOnly}
                      indent
                      label="Volunteer"
                      name="pharmacist_volunteer_count"
                      handleChange={handleChange}
                      value={values.pharmacist_volunteer_count}
                      error={Boolean(touched.pharmacist_volunteer_count && errors.pharmacist_volunteer_count)}
                      helperText={touched.pharmacist_volunteer_count && errors.pharmacist_volunteer_count}
                    />
                    <HCPFormControl
                      disabled={readOnly}
                      label="Total Number of Hours in a Facility per Week"
                      name="pharmacist_parttimers_weekly_hour_count"
                      handleChange={handleChange}
                      value={values.pharmacist_parttimers_weekly_hour_count}
                      error={Boolean(touched.pharmacist_parttimers_weekly_hour_count && errors.pharmacist_parttimers_weekly_hour_count)}
                      helperText={touched.pharmacist_parttimers_weekly_hour_count && errors.pharmacist_parttimers_weekly_hour_count}
                    />
                  </Box>
                  <Divider />
                </>
              )}
              {/* ----- medtech ----- */}
              { ((facilityType !== BARANGAY_HEALTH_STATION) && (facilityType !== SAFE_BIRTHING_FACILITY) && (facilityType !== NUTRITION_POST) && (facilityType !== AUX_FACILITY)) && (
                <>
                  <Box sx={{ p: 2 }}>
                    <Typography variant="h5" gutterBottom>
                      Medical Technologist
                    </Typography>
                    <HCPFormControl
                      disabled={readOnly}
                      indent
                      label="Full-time Plantilla"
                      name="medtech_plantilla_count"
                      handleChange={handleChange}
                      value={values.medtech_plantilla_count}
                      error={Boolean(touched.medtech_plantilla_count && errors.medtech_plantilla_count)}
                      helperText={touched.medtech_plantilla_count && errors.medtech_plantilla_count}
                    />
                    <HCPFormControl
                      disabled={readOnly}
                      indent
                      label="Full-time Job Order/Casual"
                      name="medtech_casual_count"
                      handleChange={handleChange}
                      value={values.medtech_casual_count}
                      error={Boolean(touched.medtech_casual_count && errors.medtech_casual_count)}
                      helperText={touched.medtech_casual_count && errors.medtech_casual_count}
                    />
                    <HCPFormControl
                      disabled={readOnly}
                      indent
                      label="Full-time Deployed by DOH"
                      name="medtech_deployed_by_doh_count"
                      handleChange={handleChange}
                      value={values.medtech_deployed_by_doh_count}
                      error={Boolean(touched.medtech_deployed_by_doh_count && errors.medtech_deployed_by_doh_count)}
                      helperText={touched.medtech_deployed_by_doh_count && errors.medtech_deployed_by_doh_count}
                    />
                    <HCPFormControl
                      disabled={readOnly}
                      indent
                      label="Part-time"
                      name="medtech_part_time_count"
                      handleChange={handleChange}
                      value={values.medtech_part_time_count}
                      error={Boolean(touched.medtech_part_time_count && errors.medtech_part_time_count)}
                      helperText={touched.medtech_part_time_count && errors.medtech_part_time_count}
                    />
                    <HCPFormControl
                      disabled={readOnly}
                      indent
                      label="Volunteer"
                      name="medtech_volunteer_count"
                      handleChange={handleChange}
                      value={values.medtech_volunteer_count}
                      error={Boolean(touched.medtech_volunteer_count && errors.medtech_volunteer_count)}
                      helperText={touched.medtech_volunteer_count && errors.medtech_volunteer_count}
                    />
                    <HCPFormControl
                      disabled={readOnly}
                      label="Total Number of Hours in a Facility per Week"
                      name="medtech_parttimers_weekly_hour_count"
                      handleChange={handleChange}
                      value={values.medtech_parttimers_weekly_hour_count}
                      error={Boolean(touched.medtech_parttimers_weekly_hour_count && errors.medtech_parttimers_weekly_hour_count)}
                      helperText={touched.medtech_parttimers_weekly_hour_count && errors.medtech_parttimers_weekly_hour_count}
                    />
                  </Box>
                  <Divider />
                </>
              )}
              {/* ----- dentist ----- */}
              { ((facilityType !== BARANGAY_HEALTH_STATION) && (facilityType !== SAFE_BIRTHING_FACILITY) && (facilityType !== NUTRITION_POST) && (facilityType !== AUX_FACILITY)) && (
                <>
                  <Box sx={{ p: 2 }}>
                    <Typography variant="h5" gutterBottom>
                      Dentist
                    </Typography>
                    <HCPFormControl
                      disabled={readOnly}
                      indent
                      label="Full-time Plantilla"
                      name="dentist_plantilla_count"
                      handleChange={handleChange}
                      value={values.dentist_plantilla_count}
                      error={Boolean(touched.dentist_plantilla_count && errors.dentist_plantilla_count)}
                      helperText={touched.dentist_plantilla_count && errors.dentist_plantilla_count}
                    />
                    <HCPFormControl
                      disabled={readOnly}
                      indent
                      label="Full-time Job Order/Casual"
                      name="dentist_casual_count"
                      handleChange={handleChange}
                      value={values.dentist_casual_count}
                      error={Boolean(touched.dentist_casual_count && errors.dentist_casual_count)}
                      helperText={touched.dentist_casual_count && errors.dentist_casual_count}
                    />
                    <HCPFormControl
                      disabled={readOnly}
                      indent
                      label="Full-time Deployed by DOH"
                      name="dentist_deployed_by_doh_count"
                      handleChange={handleChange}
                      value={values.dentist_deployed_by_doh_count}
                      error={Boolean(touched.dentist_deployed_by_doh_count && errors.dentist_deployed_by_doh_count)}
                      helperText={touched.dentist_deployed_by_doh_count && errors.dentist_deployed_by_doh_count}
                    />
                    <HCPFormControl
                      disabled={readOnly}
                      indent
                      label="Part-time"
                      name="dentist_part_time_count"
                      handleChange={handleChange}
                      value={values.dentist_part_time_count}
                      error={Boolean(touched.dentist_part_time_count && errors.dentist_part_time_count)}
                      helperText={touched.dentist_part_time_count && errors.dentist_part_time_count}
                    />
                    <HCPFormControl
                      disabled={readOnly}
                      indent
                      label="Volunteer"
                      name="dentist_volunteer_count"
                      handleChange={handleChange}
                      value={values.dentist_volunteer_count}
                      error={Boolean(touched.dentist_volunteer_count && errors.dentist_volunteer_count)}
                      helperText={touched.dentist_volunteer_count && errors.dentist_volunteer_count}
                    />
                    <HCPFormControl
                      disabled={readOnly}
                      label="Total Number of Hours in a Facility per Week"
                      name="dentist_parttimers_weekly_hour_count"
                      handleChange={handleChange}
                      value={values.dentist_parttimers_weekly_hour_count}
                      error={Boolean(touched.dentist_parttimers_weekly_hour_count && errors.dentist_parttimers_weekly_hour_count)}
                      helperText={touched.dentist_parttimers_weekly_hour_count && errors.dentist_parttimers_weekly_hour_count}
                    />
                  </Box>
                  <Divider />
                </>
              )}
              {/* ------ dental aide ------ */}
              { ((facilityType !== BARANGAY_HEALTH_STATION) && (facilityType !== SAFE_BIRTHING_FACILITY) && (facilityType !== NUTRITION_POST) && (facilityType !== AUX_FACILITY)) && (
                <>
                  <Box sx={{ p: 2 }}>
                    <Typography variant="h5" gutterBottom>
                      Dental Aide
                    </Typography>
                    <HCPFormControl
                      disabled={readOnly}
                      indent
                      label="Full-time Plantilla"
                      name="dentalaide_plantilla_count"
                      handleChange={handleChange}
                      value={values.dentalaide_plantilla_count}
                      error={Boolean(touched.dentalaide_plantilla_count && errors.dentalaide_plantilla_count)}
                      helperText={touched.dentalaide_plantilla_count && errors.dentalaide_plantilla_count}
                    />
                    <HCPFormControl
                      disabled={readOnly}
                      indent
                      label="Full-time Job Order/Casual"
                      name="dentalaide_casual_count"
                      handleChange={handleChange}
                      value={values.dentalaide_casual_count}
                      error={Boolean(touched.dentalaide_casual_count && errors.dentalaide_casual_count)}
                      helperText={touched.dentalaide_casual_count && errors.dentalaide_casual_count}
                    />
                    <HCPFormControl
                      disabled={readOnly}
                      indent
                      label="Full-time Deployed by DOH"
                      name="dentalaide_deployed_by_doh_count"
                      handleChange={handleChange}
                      value={values.dentalaide_deployed_by_doh_count}
                      error={Boolean(touched.dentalaide_deployed_by_doh_count && errors.dentalaide_deployed_by_doh_count)}
                      helperText={touched.dentalaide_deployed_by_doh_count && errors.dentalaide_deployed_by_doh_count}
                    />
                    <HCPFormControl
                      disabled={readOnly}
                      indent
                      label="Part-time"
                      name="dentalaide_part_time_count"
                      handleChange={handleChange}
                      value={values.dentalaide_part_time_count}
                      error={Boolean(touched.dentalaide_part_time_count && errors.dentalaide_part_time_count)}
                      helperText={touched.dentalaide_part_time_count && errors.dentalaide_part_time_count}
                    />
                    <HCPFormControl
                      disabled={readOnly}
                      indent
                      label="Volunteer"
                      name="dentalaide_volunteer_count"
                      handleChange={handleChange}
                      value={values.dentalaide_volunteer_count}
                      error={Boolean(touched.dentalaide_volunteer_count && errors.dentalaide_volunteer_count)}
                      helperText={touched.dentalaide_volunteer_count && errors.dentalaide_volunteer_count}
                    />
                    <HCPFormControl
                      disabled={readOnly}
                      label="Total Number of Hours in a Facility per Week"
                      name="dentalaide_parttimers_weekly_hour_count"
                      handleChange={handleChange}
                      value={values.dentalaide_parttimers_weekly_hour_count}
                      error={Boolean(touched.dentalaide_parttimers_weekly_hour_count && errors.dentalaide_parttimers_weekly_hour_count)}
                      helperText={touched.dentalaide_parttimers_weekly_hour_count && errors.dentalaide_parttimers_weekly_hour_count}
                    />
                  </Box>
                  <Divider />
                </>
              )}
              {/* ----- active barangay health worker ----- */}
              { ((facilityType !== INFIRMARY) && (facilityType !== SAFE_BIRTHING_FACILITY)) && (
                <>
                  <Box sx={{ p: 2 }}>
                    <Typography variant="h5" gutterBottom>
                      Active Barangay Health Worker (BHW)
                    </Typography>
                    <HCPFormControl
                      disabled={readOnly}
                      indent
                      label="Full-time Plantilla"
                      name="bhw_plantilla_count"
                      handleChange={handleChange}
                      value={values.bhw_plantilla_count}
                      error={Boolean(touched.bhw_plantilla_count && errors.bhw_plantilla_count)}
                      helperText={touched.bhw_plantilla_count && errors.bhw_plantilla_count}
                    />
                    <HCPFormControl
                      disabled={readOnly}
                      indent
                      label="Full-time Job Order/Casual"
                      name="bhw_casual_count"
                      handleChange={handleChange}
                      value={values.bhw_casual_count}
                      error={Boolean(touched.bhw_casual_count && errors.bhw_casual_count)}
                      helperText={touched.bhw_casual_count && errors.bhw_casual_count}
                    />
                    <HCPFormControl
                      disabled={readOnly}
                      indent
                      label="Full-time Deployed by DOH"
                      name="bhw_deployed_by_doh_count"
                      handleChange={handleChange}
                      value={values.bhw_deployed_by_doh_count}
                      error={Boolean(touched.bhw_deployed_by_doh_count && errors.bhw_deployed_by_doh_count)}
                      helperText={touched.bhw_deployed_by_doh_count && errors.bhw_deployed_by_doh_count}
                    />
                    <HCPFormControl
                      disabled={readOnly}
                      indent
                      label="Part-time"
                      name="bhw_part_time_count"
                      handleChange={handleChange}
                      value={values.bhw_part_time_count}
                      error={Boolean(touched.bhw_part_time_count && errors.bhw_part_time_count)}
                      helperText={touched.bhw_part_time_count && errors.bhw_part_time_count}
                    />
                    <HCPFormControl
                      disabled={readOnly}
                      indent
                      label="Volunteer"
                      name="bhw_volunteer_count"
                      handleChange={handleChange}
                      value={values.bhw_volunteer_count}
                      error={Boolean(touched.bhw_volunteer_count && errors.bhw_volunteer_count)}
                      helperText={touched.bhw_volunteer_count && errors.bhw_volunteer_count}
                    />
                    <HCPFormControl
                      disabled={readOnly}
                      label="Total Number of Hours in a Facility per Week"
                      name="bhw_parttimers_weekly_hour_count"
                      handleChange={handleChange}
                      value={values.bhw_parttimers_weekly_hour_count}
                      error={Boolean(touched.bhw_parttimers_weekly_hour_count && errors.bhw_parttimers_weekly_hour_count)}
                      helperText={touched.bhw_parttimers_weekly_hour_count && errors.bhw_parttimers_weekly_hour_count}
                    />
                  </Box>
                  <Divider />
                </>
              )}
              {/* ----- barangay nutrition scholar ----- */}
              { ((facilityType !== INFIRMARY) && (facilityType !== SAFE_BIRTHING_FACILITY)) && (
                <>
                  <Box sx={{ p: 2 }}>
                    <Typography variant="h5" gutterBottom>
                      Active Barangay Nutrition Scholar (BNS)
                    </Typography>
                    <HCPFormControl
                      disabled={readOnly}
                      indent
                      label="Full-time Plantilla"
                      name="bns_plantilla_count"
                      handleChange={handleChange}
                      value={values.bns_plantilla_count}
                      error={Boolean(touched.bns_plantilla_count && errors.bns_plantilla_count)}
                      helperText={touched.bns_plantilla_count && errors.bns_plantilla_count}
                    />
                    <HCPFormControl
                      disabled={readOnly}
                      indent
                      label="Full-time Job Order/Casual"
                      name="bns_casual_count"
                      handleChange={handleChange}
                      value={values.bns_casual_count}
                      error={Boolean(touched.bns_casual_count && errors.bns_casual_count)}
                      helperText={touched.bns_casual_count && errors.bns_casual_count}
                    />
                    <HCPFormControl
                      disabled={readOnly}
                      indent
                      label="Full-time Deployed by DOH"
                      name="bns_deployed_by_doh_count"
                      handleChange={handleChange}
                      value={values.bns_deployed_by_doh_count}
                      error={Boolean(touched.bns_deployed_by_doh_count && errors.bns_deployed_by_doh_count)}
                      helperText={touched.bns_deployed_by_doh_count && errors.bns_deployed_by_doh_count}
                    />
                    <HCPFormControl
                      disabled={readOnly}
                      indent
                      label="Part-time"
                      name="bns_part_time_count"
                      handleChange={handleChange}
                      value={values.bns_part_time_count}
                      error={Boolean(touched.bns_part_time_count && errors.bns_part_time_count)}
                      helperText={touched.bns_part_time_count && errors.bns_part_time_count}
                    />
                    <HCPFormControl
                      indent
                      label="Volunteer"
                      name="bns_volunteer_count"
                      handleChange={handleChange}
                      value={values.bns_volunteer_count}
                      error={Boolean(touched.bns_volunteer_count && errors.bns_volunteer_count)}
                      helperText={touched.bns_volunteer_count && errors.bns_volunteer_count}
                    />
                    <HCPFormControl
                      disabled={readOnly}
                      label="Total Number of Hours in a Facility per Week"
                      name="bns_parttimers_weekly_hour_count"
                      handleChange={handleChange}
                      value={values.bns_parttimers_weekly_hour_count}
                      error={Boolean(touched.bns_parttimers_weekly_hour_count && errors.bns_parttimers_weekly_hour_count)}
                      helperText={touched.bns_parttimers_weekly_hour_count && errors.bns_parttimers_weekly_hour_count}
                    />
                  </Box>
                  <Divider />
                </>
              )}
              {/* ------ community health volunteers ----- */}
              {/* ----- barangay nutrition scholar ----- */}
              { ((facilityType !== INFIRMARY) && (facilityType !== SAFE_BIRTHING_FACILITY)) && (
                <>
                  <Box sx={{ p: 2 }}>
                    <Typography variant="h5" gutterBottom>
                      Community Health Volunteers (Who are not BHW)
                    </Typography>
                    <HCPFormControl
                      disabled={readOnly}
                      indent
                      label="Full-time Plantilla"
                      name="chv_plantilla_count"
                      handleChange={handleChange}
                      value={values.chv_plantilla_count}
                      error={Boolean(touched.chv_plantilla_count && errors.chv_plantilla_count)}
                      helperText={touched.chv_plantilla_count && errors.chv_plantilla_count}
                    />
                    <HCPFormControl
                      disabled={readOnly}
                      indent
                      label="Full-time Job Order/Casual"
                      name="chv_casual_count"
                      handleChange={handleChange}
                      value={values.chv_casual_count}
                      error={Boolean(touched.chv_casual_count && errors.chv_casual_count)}
                      helperText={touched.chv_casual_count && errors.chv_casual_count}
                    />
                    <HCPFormControl
                      disabled={readOnly}
                      indent
                      label="Full-time Deployed by DOH"
                      name="chv_deployed_by_doh_count"
                      handleChange={handleChange}
                      value={values.chv_deployed_by_doh_count}
                      error={Boolean(touched.chv_deployed_by_doh_count && errors.chv_deployed_by_doh_count)}
                      helperText={touched.chv_deployed_by_doh_count && errors.chv_deployed_by_doh_count}
                    />
                    <HCPFormControl
                      disabled={readOnly}
                      indent
                      label="Part-time"
                      name="chv_part_time_count"
                      handleChange={handleChange}
                      value={values.chv_part_time_count}
                      error={Boolean(touched.chv_part_time_count && errors.chv_part_time_count)}
                      helperText={touched.chv_part_time_count && errors.chv_part_time_count}
                    />
                    <HCPFormControl
                      disabled={readOnly}
                      indent
                      label="Volunteer"
                      name="chv_volunteer_count"
                      handleChange={handleChange}
                      value={values.chv_volunteer_count}
                      error={Boolean(touched.chv_volunteer_count && errors.chv_volunteer_count)}
                      helperText={touched.chv_volunteer_count && errors.chv_volunteer_count}
                    />
                    <HCPFormControl
                      disabled={readOnly}
                      label="Total Number of Hours in a Facility per Week"
                      name="chv_parttimers_weekly_hour_count"
                      handleChange={handleChange}
                      value={values.chv_parttimers_weekly_hour_count}
                      error={Boolean(touched.chv_parttimers_weekly_hour_count && errors.chv_parttimers_weekly_hour_count)}
                      helperText={touched.chv_parttimers_weekly_hour_count && errors.chv_parttimers_weekly_hour_count}
                    />
                  </Box>
                </>
              )}
              <Divider />
              {/* ----- emergency transport driver ----- */}
              {/* ----- barangay nutrition scholar ----- */}
              { ((facilityType !== NUTRITION_POST) || (facilityType !== AUX_FACILITY)) && (
                <>
                  <Box sx={{ p: 2 }}>
                    <Typography variant="h5" gutterBottom>
                      Ambulance Driver / Emergency Transport Driver
                    </Typography>
                    <HCPFormControl
                      disabled={readOnly}
                      indent
                      label="Full-time Plantilla"
                      name="driver_plantilla_count"
                      handleChange={handleChange}
                      value={values.driver_plantilla_count}
                      error={Boolean(touched.driver_plantilla_count && errors.driver_plantilla_count)}
                      helperText={touched.driver_plantilla_count && errors.driver_plantilla_count}
                    />
                    <HCPFormControl
                      disabled={readOnly}
                      indent
                      label="Full-time Job Order/Casual"
                      name="driver_casual_count"
                      handleChange={handleChange}
                      value={values.driver_casual_count}
                      error={Boolean(touched.driver_casual_count && errors.driver_casual_count)}
                      helperText={touched.driver_casual_count && errors.driver_casual_count}
                    />
                    <HCPFormControl
                      disabled={readOnly}
                      indent
                      label="Full-time Deployed by DOH"
                      name="driver_deployed_by_doh_count"
                      handleChange={handleChange}
                      value={values.driver_deployed_by_doh_count}
                      error={Boolean(touched.driver_deployed_by_doh_count && errors.driver_deployed_by_doh_count)}
                      helperText={touched.driver_deployed_by_doh_count && errors.driver_deployed_by_doh_count}
                    />
                    <HCPFormControl
                      disabled={readOnly}
                      indent
                      label="Part-time"
                      name="driver_part_time_count"
                      handleChange={handleChange}
                      value={values.driver_part_time_count}
                      error={Boolean(touched.driver_part_time_count && errors.driver_part_time_count)}
                      helperText={touched.driver_part_time_count && errors.driver_part_time_count}
                    />
                    <HCPFormControl
                      disabled={readOnly}
                      indent
                      label="Volunteer"
                      name="driver_volunteer_count"
                      handleChange={handleChange}
                      value={values.driver_volunteer_count}
                      error={Boolean(touched.driver_volunteer_count && errors.driver_volunteer_count)}
                      helperText={touched.driver_volunteer_count && errors.driver_volunteer_count}
                    />
                    <HCPFormControl
                      disabled={readOnly}
                      label="Total Number of Hours in a Facility per Week"
                      name="driver_parttimers_weekly_hour_count"
                      handleChange={handleChange}
                      value={values.driver_parttimers_weekly_hour_count}
                      error={Boolean(touched.driver_parttimers_weekly_hour_count && errors.driver_parttimers_weekly_hour_count)}
                      helperText={touched.driver_parttimers_weekly_hour_count && errors.driver_parttimers_weekly_hour_count}
                    />
                  </Box>
                  <Divider />
                </>
              )}
              {/* ----- nutritionist ---- */}
              {/* ----- barangay nutrition scholar ----- */}
              { ((facilityType !== BARANGAY_HEALTH_STATION) || (facilityType !== SAFE_BIRTHING_FACILITY) || (facilityType !== NUTRITION_POST) || (facilityType !== AUX_FACILITY)) && (
                <>
                  <Box sx={{ p: 2 }}>
                    <Typography variant="h5" gutterBottom>
                      Nutritionist / Dietician
                    </Typography>
                    <HCPFormControl
                      disabled={readOnly}
                      indent
                      label="Full-time Plantilla"
                      name="nutritionist_plantilla_count"
                      handleChange={handleChange}
                      value={values.nutritionist_plantilla_count}
                      error={Boolean(touched.nutritionist_plantilla_count && errors.nutritionist_plantilla_count)}
                      helperText={touched.nutritionist_plantilla_count && errors.nutritionist_plantilla_count}
                    />
                    <HCPFormControl
                      disabled={readOnly}
                      indent
                      label="Full-time Job Order/Casual"
                      name="nutritionist_casual_count"
                      handleChange={handleChange}
                      value={values.nutritionist_casual_count}
                      error={Boolean(touched.nutritionist_casual_count && errors.nutritionist_casual_count)}
                      helperText={touched.nutritionist_casual_count && errors.nutritionist_casual_count}
                    />
                    <HCPFormControl
                      disabled={readOnly}
                      indent
                      label="Full-time Deployed by DOH"
                      name="nutritionist_deployed_by_doh_count"
                      handleChange={handleChange}
                      value={values.nutritionist_deployed_by_doh_count}
                      error={Boolean(touched.nutritionist_deployed_by_doh_count && errors.nutritionist_deployed_by_doh_count)}
                      helperText={touched.nutritionist_deployed_by_doh_count && errors.nutritionist_deployed_by_doh_count}
                    />
                    <HCPFormControl
                      disabled={readOnly}
                      indent
                      label="Part-time"
                      name="nutritionist_part_time_count"
                      handleChange={handleChange}
                      value={values.nutritionist_part_time_count}
                      error={Boolean(touched.nutritionist_part_time_count && errors.nutritionist_part_time_count)}
                      helperText={touched.nutritionist_part_time_count && errors.nutritionist_part_time_count}
                    />
                    <HCPFormControl
                      disabled={readOnly}
                      indent
                      label="Volunteer"
                      name="nutritionist_volunteer_count"
                      handleChange={handleChange}
                      value={values.nutritionist_volunteer_count}
                      error={Boolean(touched.nutritionist_volunteer_count && errors.nutritionist_volunteer_count)}
                      helperText={touched.nutritionist_volunteer_count && errors.nutritionist_volunteer_count}
                    />
                    <HCPFormControl
                      disabled={readOnly}
                      label="Total Number of Hours in a Facility per Week"
                      name="nutritionist_parttimers_weekly_hour_count"
                      handleChange={handleChange}
                      value={values.nutritionist_parttimers_weekly_hour_count}
                      error={Boolean(touched.nutritionist_parttimers_weekly_hour_count && errors.nutritionist_parttimers_weekly_hour_count)}
                      helperText={touched.nutritionist_parttimers_weekly_hour_count && errors.nutritionist_parttimers_weekly_hour_count}
                    />
                  </Box>
                  <Divider />
                </>
              )}
              {/* ----- other 1 ----- */}
              <Box sx={{ p: 2 }}>
                <Box display="flex">
                  <Typography
                    className="other-field-label"
                    gutterBottom
                    variant="h5"
                  >
                    Other:
                  </Typography>
                  <TextField
                    disabled={readOnly}
                    name="other1_role"
                    onChange={handleChange}
                    placeholder="Enter New Occupational Category Name"
                    size="small"
                    sx={{ width: 500 }}
                    value={values.other1_role || ''}
                    variant="outlined"
                  />
                </Box>
                <HCPFormControl
                  disabled={readOnly}
                  indent
                  label="Full-time Plantilla"
                  name="other1_plantilla_count"
                  handleChange={handleChange}
                  value={values.other1_plantilla_count}
                  error={Boolean(touched.other1_plantilla_count && errors.other1_plantilla_count)}
                  helperText={touched.other1_plantilla_count && errors.other1_plantilla_count}
                />
                <HCPFormControl
                  disabled={readOnly}
                  indent
                  label="Full-time Job Order/Casual"
                  name="other1_casual_count"
                  handleChange={handleChange}
                  value={values.other1_casual_count}
                  error={Boolean(touched.other1_casual_count && errors.other1_casual_count)}
                  helperText={touched.other1_casual_count && errors.other1_casual_count}
                />
                <HCPFormControl
                  disabled={readOnly}
                  indent
                  label="Full-time Deployed by DOH"
                  name="other1_deployed_by_doh_count"
                  handleChange={handleChange}
                  value={values.other1_deployed_by_doh_count}
                  error={Boolean(touched.other1_deployed_by_doh_count && errors.other1_deployed_by_doh_count)}
                  helperText={touched.other1_deployed_by_doh_count && errors.other1_deployed_by_doh_count}
                />
                <HCPFormControl
                  disabled={readOnly}
                  indent
                  label="Part-time"
                  name="other1_part_time_count"
                  handleChange={handleChange}
                  value={values.other1_part_time_count}
                  error={Boolean(touched.other1_part_time_count && errors.other1_part_time_count)}
                  helperText={touched.other1_part_time_count && errors.other1_part_time_count}
                />
                <HCPFormControl
                  disabled={readOnly}
                  indent
                  label="Volunteer"
                  name="other1_volunteer_count"
                  handleChange={handleChange}
                  value={values.other1_volunteer_count}
                  error={Boolean(touched.other1_volunteer_count && errors.other1_volunteer_count)}
                  helperText={touched.other1_volunteer_count && errors.other1_volunteer_count}
                />
                <HCPFormControl
                  disabled={readOnly}
                  label="Total Number of Hours in a Facility per Week"
                  name="other1_parttimers_weekly_hour_count"
                  handleChange={handleChange}
                  value={values.other1_parttimers_weekly_hour_count}
                  error={Boolean(touched.other1_parttimers_weekly_hour_count && errors.other1_parttimers_weekly_hour_count)}
                  helperText={touched.other1_parttimers_weekly_hour_count && errors.other1_parttimers_weekly_hour_count}
                />
              </Box>
              <Divider />
              {/* ----- other 2 ----- */}
              <Box sx={{ p: 2 }}>
                <Box display="flex">
                  <Typography
                    className="other-field-label"
                    gutterBottom
                    variant="h5"
                  >
                    Other:
                  </Typography>
                  <TextField
                    disabled={readOnly}
                    name="other2_role"
                    onChange={handleChange}
                    placeholder="Enter New Occupational Category Name"
                    size="small"
                    sx={{ width: 500 }}
                    value={values.other2_role || ''}
                    variant="outlined"
                  />
                </Box>
                <HCPFormControl
                  disabled={readOnly}
                  indent
                  label="Full-time Plantilla"
                  name="other2_plantilla_count"
                  handleChange={handleChange}
                  value={values.other2_plantilla_count}
                  error={Boolean(touched.other2_plantilla_count && errors.other2_plantilla_count)}
                  helperText={touched.other2_plantilla_count && errors.other2_plantilla_count}
                />
                <HCPFormControl
                  disabled={readOnly}
                  indent
                  label="Full-time Job Order/Casual"
                  name="other2_casual_count"
                  handleChange={handleChange}
                  value={values.other2_casual_count}
                  error={Boolean(touched.other2_casual_count && errors.other2_casual_count)}
                  helperText={touched.other2_casual_count && errors.other2_casual_count}
                />
                <HCPFormControl
                  disabled={readOnly}
                  indent
                  label="Full-time Deployed by DOH"
                  name="other2_deployed_by_doh_count"
                  handleChange={handleChange}
                  value={values.other2_deployed_by_doh_count}
                  error={Boolean(touched.other2_deployed_by_doh_count && errors.other2_deployed_by_doh_count)}
                  helperText={touched.other2_deployed_by_doh_count && errors.other2_deployed_by_doh_count}
                />
                <HCPFormControl
                  disabled={readOnly}
                  indent
                  label="Part-time"
                  name="other2_part_time_count"
                  handleChange={handleChange}
                  value={values.other2_part_time_count}
                  error={Boolean(touched.other2_part_time_count && errors.other2_part_time_count)}
                  helperText={touched.other2_part_time_count && errors.other2_part_time_count}
                />
                <HCPFormControl
                  disabled={readOnly}
                  indent
                  label="Volunteer"
                  name="other2_part_time_count"
                  handleChange={handleChange}
                  value={values.other2_part_time_count}
                  error={Boolean(touched.other2_part_time_count && errors.other2_part_time_count)}
                  helperText={touched.other2_part_time_count && errors.other2_part_time_count}
                />
                <HCPFormControl
                  disabled={readOnly}
                  label="Total Number of Hours in a Facility per Week"
                  name="other2_parttimers_weekly_hour_count"
                  handleChange={handleChange}
                  value={values.other2_parttimers_weekly_hour_count}
                  error={Boolean(touched.other2_parttimers_weekly_hour_count && errors.other2_parttimers_weekly_hour_count)}
                  helperText={touched.other2_parttimers_weekly_hour_count && errors.other2_parttimers_weekly_hour_count}
                />
              </Box>
              <Divider />
              {/* ----- other 3 ----- */}
              <Box sx={{ p: 2 }}>
                <Box display="flex">
                  <Typography
                    className="other-field-label"
                    gutterBottom
                    variant="h5"
                  >
                    Other:
                  </Typography>
                  <TextField
                    disabled={readOnly}
                    name="other3_role"
                    onChange={handleChange}
                    placeholder="Enter New Occupational Category Name"
                    size="small"
                    sx={{ width: 500 }}
                    value={values.other3_role || ''}
                    variant="outlined"
                  />
                </Box>
                <HCPFormControl
                  disabled={readOnly}
                  indent
                  label="Full-time Plantilla"
                  name="other3_plantilla_count"
                  handleChange={handleChange}
                  value={values.other3_plantilla_count}
                  error={Boolean(touched.other3_plantilla_count && errors.other3_plantilla_count)}
                  helperText={touched.other3_plantilla_count && errors.other3_plantilla_count}
                />
                <HCPFormControl
                  disabled={readOnly}
                  indent
                  label="Full-time Job Order/Casual"
                  name="other3_casual_count"
                  handleChange={handleChange}
                  value={values.other3_casual_count}
                  error={Boolean(touched.other3_casual_count && errors.other3_casual_count)}
                  helperText={touched.other3_casual_count && errors.other3_casual_count}
                />
                <HCPFormControl
                  disabled={readOnly}
                  indent
                  label="Full-time Deployed by DOH"
                  name="other3_deployed_by_doh_count"
                  handleChange={handleChange}
                  value={values.other3_deployed_by_doh_count}
                  error={Boolean(touched.other3_deployed_by_doh_count && errors.other3_deployed_by_doh_count)}
                  helperText={touched.other3_deployed_by_doh_count && errors.other3_deployed_by_doh_count}
                />
                <HCPFormControl
                  disabled={readOnly}
                  indent
                  label="Part-time"
                  name="other3_part_time_count"
                  handleChange={handleChange}
                  value={values.other3_part_time_count}
                  error={Boolean(touched.other3_part_time_count && errors.other3_part_time_count)}
                  helperText={touched.other3_part_time_count && errors.other3_part_time_count}
                />
                <HCPFormControl
                  disabled={readOnly}
                  indent
                  label="Volunteer"
                  name="other3_volunteer_count"
                  handleChange={handleChange}
                  value={values.other3_volunteer_count}
                  error={Boolean(touched.other3_volunteer_count && errors.other3_volunteer_count)}
                  helperText={touched.other3_volunteer_count && errors.other3_volunteer_count}
                />
                <HCPFormControl
                  disabled={readOnly}
                  label="Total Number of Hours in a Facility per Week"
                  name="other3_parttimers_weekly_hour_count"
                  handleChange={handleChange}
                  value={values.other3_parttimers_weekly_hour_count}
                  error={Boolean(touched.other3_parttimers_weekly_hour_count && errors.other3_parttimers_weekly_hour_count)}
                  helperText={touched.other3_parttimers_weekly_hour_count && errors.other3_parttimers_weekly_hour_count}
                />
              </Box>
              <Divider />
              {/* ----- other 4 ----- */}
              <Box sx={{ p: 2 }}>
                <Box display="flex">
                  <Typography
                    className="other-field-label"
                    gutterBottom
                    variant="h5"
                  >
                    Other:
                  </Typography>
                  <TextField
                    disabled={readOnly}
                    name="other4_role"
                    onChange={handleChange}
                    placeholder="Enter New Occupational Category Name"
                    size="small"
                    sx={{ width: 500 }}
                    value={values.other4_role || ''}
                    variant="outlined"
                  />
                </Box>
                <HCPFormControl
                  disabled={readOnly}
                  indent
                  label="Full-time Plantilla"
                  name="other4_plantilla_count"
                  handleChange={handleChange}
                  value={values.other4_plantilla_count}
                  error={Boolean(touched.other4_plantilla_count && errors.other4_plantilla_count)}
                  helperText={touched.other4_plantilla_count && errors.other4_plantilla_count}
                />
                <HCPFormControl
                  disabled={readOnly}
                  indent
                  label="Full-time Job Order/Casual"
                  name="other4_casual_count"
                  handleChange={handleChange}
                  value={values.other4_casual_count}
                  error={Boolean(touched.other4_casual_count && errors.other4_casual_count)}
                  helperText={touched.other4_casual_count && errors.other4_casual_count}
                />
                <HCPFormControl
                  disabled={readOnly}
                  indent
                  label="Full-time Deployed by DOH"
                  name="other4_deployed_by_doh_count"
                  handleChange={handleChange}
                  value={values.other4_deployed_by_doh_count}
                  error={Boolean(touched.other4_deployed_by_doh_count && errors.other4_deployed_by_doh_count)}
                  helperText={touched.other4_deployed_by_doh_count && errors.other4_deployed_by_doh_count}
                />
                <HCPFormControl
                  disabled={readOnly}
                  indent
                  label="Part-time"
                  name="other4_part_time_count"
                  handleChange={handleChange}
                  value={values.other4_part_time_count}
                  error={Boolean(touched.other4_part_time_count && errors.other4_part_time_count)}
                  helperText={touched.other4_part_time_count && errors.other4_part_time_count}
                />
                <HCPFormControl
                  disabled={readOnly}
                  indent
                  label="Volunteer"
                  name="other4_volunteer_count"
                  handleChange={handleChange}
                  value={values.other4_volunteer_count}
                  error={Boolean(touched.other4_volunteer_count && errors.other4_volunteer_count)}
                  helperText={touched.other4_volunteer_count && errors.other4_volunteer_count}
                />
                <HCPFormControl
                  disabled={readOnly}
                  label="Total Number of Hours in a Facility per Week"
                  name="other4_parttimers_weekly_hour_count"
                  handleChange={handleChange}
                  value={values.other4_parttimers_weekly_hour_count}
                  error={Boolean(touched.other4_parttimers_weekly_hour_count && errors.other4_parttimers_weekly_hour_count)}
                  helperText={touched.other4_parttimers_weekly_hour_count && errors.other4_parttimers_weekly_hour_count}
                />
              </Box>
              <Divider />
              <Box sx={{ p: 2 }}>
                { facilityType !== NUTRITION_POST && (
                  <RadioGroupFormControl
                    disabled={readOnly}
                    label="Is there a health service provider present at all times, or officially on call for the facility at all times (24hrs a day including weekends) for emergencies and/or deliveries?"
                    fieldName="facility_available_24_hours"
                    fieldValue={values.facility_available_24_hours}
                    options={hcpAvailability}
                    setFieldValue={setFieldValue}
                  />
                )}
                <RadioGroupFormControl
                  disabled={readOnly}
                  label="On average, how many hours is this facility open?"
                  fieldName="facility_avg_availability"
                  fieldValue={values.facility_avg_availability}
                  options={facilityAvailability}
                  setFieldValue={setFieldValue}
                />
              </Box>
            </CardContent>
            <Divider />
            <Box className="box-button">
              <Button
                disabled={isLoading || isSubmitting || !readOnly}
                color="primary"
                onClick={() => handleEditClick()}
                variant="contained"
                sx={{ mx: 2 }}
              >
                Edit
              </Button>
              <Button
                disabled={isLoading || isSubmitting || readOnly}
                color="primary"
                onClick={() => handleSaveClick(handleSubmit)}
                type="submit"
                variant="contained"
              >
                Save
              </Button>
            </Box>
            {(isLoading || isSubmitting) && <LinearProgress />}
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default HealthCarePersonnel;
