import _ from 'lodash';
import {
  AUTH_ERROR,
  ADD_FACILITY,
  EDIT_FACILITY,
  FETCH_FACILITIES,
  FETCH_FACILITY,
  SIGN_OUT
} from 'src/actions/types';

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_FACILITIES:
      return {
        ..._.mapKeys(action.payload, 'id')
      };

    case ADD_FACILITY:
      return {
        [action.payload.id]: action.payload
      };

    case EDIT_FACILITY:
    case FETCH_FACILITY:
      return {
        ...state,
        [action.payload.id]: action.payload
      };

    case AUTH_ERROR:
    case SIGN_OUT:
      return {};

    default:
      return state;
  }
};
