import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import {
  Box,
  CircularProgress,
  Container,
  Grid
} from '@mui/material';
import AccountDetailsForm from 'src/components/account/AccountDetailsForm';
import AccountPassword from 'src/components/account/AccountPassword';
import { editProfile } from 'src/actions/account';

const Account = (props) => {
  const { user, isSubmitting, payload } = props;
  if (!user) {
    return (
      <Box className="spinner-container">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Helmet>
        <title>Account - FReSAA</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="lg">
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={8}
              md={8}
              xs={12}
            >
              <Box>
                <AccountDetailsForm
                  firstName={user.first_name}
                  lastName={user.last_name}
                  email={user.email}
                  phone={user.phone}
                  isSubmitting={isSubmitting}
                  payload={payload}
                  onSubmit={props.editProfile}
                />
              </Box>
              <Box sx={{ pt: 3 }}>
                <AccountPassword />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  payload: state.messages.payload,
  isSubmitting: state.messages.isSubmitting,
  user: state.auth.user
});

export default connect(mapStateToProps, {
  editProfile
})(Account);
