export default [
  {
    name: 'Medical Doctor',
    value: 1
  },
  {
    name: 'Registered Midwife',
    value: 2
  },
  {
    name: 'Registered Nurse',
    value: 3
  },

];
