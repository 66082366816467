import React from 'react';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  LinearProgress,
  Typography
} from '@mui/material';
import {
  Edit as EditIcon,
  Save as SaveIcon
} from '@mui/icons-material';
import AvailabilityFunctioningFormGroup from '../../formSections/AvailabilityFunctioningFormGroup';

const NewbornCareEquipment = (props) => {
  const {
    formValues,
    isLoading,
    isSubmitting,
    readOnly,
    setReadOnly
  } = props;

  const handleEditClick = () => {
    setReadOnly(false);
  };

  const handleSaveClick = (handleSubmit) => {
    handleSubmit();
    setReadOnly(true);
  };

  const initialValues = {
    apgar_and_ballard_scoring_chart_available: null,
    apgar_and_ballard_scoring_chart_functioning: null,
    drying_rack_available: null,
    drying_rack_functioning: null,
    ...formValues
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => {
        props.onSubmit('newborn_care_equipment', values);
      }}
    >
      {({
        handleSubmit,
        setFieldValue,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardContent>
              <Box className="form-header">
                <Typography variant="h4" gutterBottom>
                  Newborn Care
                </Typography>
                {readOnly ? (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleEditClick()}
                  >
                    <EditIcon size="medium" />
                  </IconButton>
                ) : (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleSaveClick(handleSubmit)}
                  >
                    <SaveIcon size="medium" />
                  </IconButton>
                )}
              </Box>
            </CardContent>
            <Divider />
            <CardContent>
              <Box sx={{ p: 2 }}>
                <AvailabilityFunctioningFormGroup
                  disabled={readOnly}
                  label="Apgar and Ballard Scoring Chart"
                  fieldName="apgar_and_ballard_scoring_chart_available"
                  fieldValue={values.apgar_and_ballard_scoring_chart_available}
                  fieldFunctioningName="apgar_and_ballard_scoring_chart_functioning"
                  fieldFunctioningValue={values.apgar_and_ballard_scoring_chart_functioning}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityFunctioningFormGroup
                  disabled={readOnly}
                  label="Drying rack"
                  fieldName="drying_rack_available"
                  fieldValue={values.drying_rack_available}
                  fieldFunctioningName="drying_rack_functioning"
                  fieldFunctioningValue={values.drying_rack_functioning}
                  setFieldValue={setFieldValue}
                />
              </Box>
            </CardContent>
            <Divider />
            <Box className="box-button">
              <Button
                disabled={isLoading || isSubmitting || !readOnly}
                color="primary"
                onClick={() => handleEditClick()}
                variant="contained"
                sx={{ mx: 2 }}
              >
                Edit
              </Button>
              <Button
                disabled={isLoading || isSubmitting || readOnly}
                color="primary"
                onClick={() => handleSaveClick(handleSubmit)}
                type="submit"
                variant="contained"
              >
                Save
              </Button>
            </Box>
            {(isLoading || isSubmitting) && <LinearProgress />}
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default NewbornCareEquipment;
