export const antenatal = [
  {
    label: 'Iron + Folic Acid Tablet (1 box)',
    available: 'iron_and_folic_acid_tablet_available',
    rso: 'iron_and_folic_acid_tablet_rso'
  },
  {
    label: 'Tetanus Toxoid Vaccine / Tetanus-diphtheria (Td)',
    available: 'tetanus_vaccine_available',
    rso: 'tetanus_vaccine_rso'
  },
  {
    label: 'Calcium Carbonate (1 box)',
    available: 'calcium_carbonate_available',
    rso: 'calcium_carbonate_rso'
  },
  {
    label: 'Methyldopa Tablet',
    available: 'methyldopa_tablet_available',
    rso: 'methyldopa_tablet_rso'
  },
  {
    label: 'Amoxicillin Capsule',
    available: 'amoxicillin_capsule_available',
    rso: 'amoxicillin_capsule_rso'
  },
  {
    label: 'Antibiotics Appropriate for Bacterial STI (e.g Ceftriaxone, Azithromycin',
    available: 'antibiotics_for_bacterial_sti_available',
    rso: 'antibiotics_for_bacterial_sti_rso'
  },
  {
    label: 'Anti-Malarials for Pregnant Women (for endemic areas only)',
    available: 'anti_malarials_for_pregnant_women_available',
    rso: 'anti_malarials_for_pregnant_women_rso'
  },
  {
    label: 'Anti-Retrovirals',
    available: 'anti_retrovirals_available',
    rso: 'anti_retrovirals_rso'
  },
  {
    label: 'Medical examination gloves',
    available: 'medical_exam_gloves_available',
    rso: 'medical_exam_gloves_rso'
  },
  {
    label: 'Lubricant (water-based)',
    available: 'water_based_lubricant_available',
    rso: 'water_based_lubricant_rso'
  },
];

export const delivery = [
  {
    label: 'Emergency kit or cart/Portable key or trolley',
    available: 'emergency_kit_available',
    rso: 'emergency_kit_rso'
  },
  {
    label: 'Injectable Uterotonic at least 10 ampules',
    available: 'injectable_uteronic_available',
    rso: 'injectable_uteronic_rso'
  },
  {
    label: 'Injectable Broad-Spectrum Antibiotic',
    available: 'injectable_broad_spectrum_antibiotic_available',
    rso: 'injectable_broad_spectrum_antibiotic_rso'
  },
  {
    label: 'Injectable Magnesium Sulphate (at least 5 ampules 500 mg/2 mL ampule',
    available: 'injectable_magnesium_sulphate_available',
    rso: 'injectable_magnesium_sulphate_rso'
  },
  {
    label: 'At least one oral antibiotics: amoxicillin, ampicillin, cephalosporin, cloxacillin, erythromycin, azithromycin, trimethoprim + sulfamethoxazole, tetracycline or doxycycline (RPR + partner), metronidazole',
    available: 'oral_antibiotics_available',
    rso: 'oral_antibiotics_rso'
  },
  {
    label: 'Methyldopa',
    available: 'methyldopa_available',
    rso: 'methyldopa_rso'
  },
  {
    label: 'At least one tetracycline eye ointment: eye antimicrobial (1% silver nitrate or 2.5% povidine iodine) or erthromycin ophthalmic ointment 5%',
    available: 'tetracycline_eye_ointment_available',
    rso: 'tetracycline_eye_ointment_rso'
  },
  {
    label: 'At least one mebendazole: artemether or quinine, chloriquine tablet, sulphadoxine-pyremethatane and mebendazole',
    available: 'malaria_medication_available',
    rso: 'malaria_medication_rso'
  },
  {
    label: 'Injectible Diazepam',
    available: 'injectable_diazepam_available',
    rso: 'injectable_diazepam_rso'
  },
  {
    label: 'IV Infusion Set',
    available: 'iv_infusion_set_available',
    rso: 'iv_infusion_set_rso'
  },
  {
    label: 'IV Cannula, Gauge 18 and 19',
    available: 'iv_cannula_available',
    rso: 'iv_cannula_rso'
  },
  {
    label: 'Injectable Calcium Gluconate 10 mg/ampule',
    available: 'injectable_calcium_gluconate_available',
    rso: 'injectable_calcium_gluconate_rso'
  },
  {
    label: 'Injectable Hydralazine',
    available: 'injectable_hydralazine_available',
    rso: 'injectable_hydralazine_rso'
  },
  {
    label: 'Nifedipine tablet',
    available: 'nifedipine_tablet_available',
    rso: 'nifedipine_tablet_rso'
  },
  {
    label: 'Diphenhydramine 50 mg/ampule',
    available: 'diphenhydramine_available',
    rso: 'diphenhydramine_rso'
  },
  {
    label: 'At least one corticosteroids: dexamethasomne vial or ampule, or betamethasone vial or ampule',
    available: 'corticosteroids_available',
    rso: 'corticosteroids_rso'
  },
  {
    label: 'Paracetamol Tablet 500 mg (1 box of 100s) or Injectable',
    available: 'paracetamol_tablet_available',
    rso: 'paracetamol_tablet_rso'
  },
  {
    label: 'Epinephrine / adrenaline',
    available: 'epinephrine_or_adrenaline_available',
    rso: 'epinephrine_or_adrenaline_rso'
  },
  {
    label: 'Atropine 1 mg/ml ampule',
    available: 'atropine_available',
    rso: 'atropine_rso'
  },
  {
    label: 'Lignocaine / Lidocaine / Xylocaine (1 multidose vial, 50 mL) or 5 pcs. poly amp at 5 mL each',
    available: 'ligonocaine_available',
    rso: 'ligonocaine_rso'
  },
  {
    label: 'Sterile Cord Clamp',
    available: 'sterile_cord_clamp_available',
    rso: 'sterile_cord_clamp_rso'
  },
  {
    label: 'Sterile Gloves (1 box)',
    available: 'sterile_gloves_available',
    rso: 'sterile_gloves_rso'
  },
  {
    label: 'Disposable Syrinces with Needles',
    available: 'disposable_syringes_with_needles_available',
    rso: 'disposable_syringes_with_needles_rso'
  },
  {
    label: 'Plaster',
    available: 'plaster_available',
    rso: 'plaster_rso'
  },
  {
    label: 'IV Fuilds: glucose 50% solution (1 polyamp 50 mL), normal saline 0.9% (at least 3 bottles), Ringer Lactate (at least 5 bottles D5LR) and plain LR (at least 3 bottles)',
    available: 'iv_fluids_available',
    rso: 'iv_fluids_rso'
  },
  {
    label: 'Sterile Absorbable Suture',
    available: 'sterile_absorbable_suture_available',
    rso: 'sterile_absorbable_suture_rso'
  },
  {
    label: 'Sterile Curring Needle',
    available: 'sterile_cutting_needle_available',
    rso: 'sterile_cutting_needle_rso'
  },
  {
    label: 'Sterile Round Needle',
    available: 'sterile_round_needle_available',
    rso: 'sterile_round_needle_rso'
  },
  {
    label: 'Sterile Cotton Balls (1 pack)',
    available: 'sterile_cotton_balls_available',
    rso: 'sterile_cotton_balls_rso'
  },
  {
    label: 'Sterile Cotton pledgets (1 pack)',
    available: 'sterile_cotton_pledgets_available',
    rso: 'sterile_cotton_pledgets_rso'
  },
  {
    label: 'Sterile Gauze (1 pack)',
    available: 'sterile_gauze_available',
    rso: 'sterile_gauze_rso'
  },
  {
    label: 'Nasal Cannula, Adult',
    available: 'nasal_cannula_for_adult_available',
    rso: 'nasal_cannula_for_adult_rso'
  },
  {
    label: 'Inhaled Bronchodilators',
    available: 'inhaled_bronchodilators_available',
    rso: 'inhaled_bronchodilators_rso'
  },
  {
    label: 'Partograph',
    available: 'partograph_available',
    rso: 'partograph_rso'
  },
  {
    label: 'Surgical Cap (1 box)',
    available: 'surgical_cap_available',
    rso: 'surgical_cap_rso'
  },
  {
    label: 'Surgical mask (1 box)',
    available: 'surgical_mask_available',
    rso: 'surgical_mask_rso'
  },
  {
    label: 'Patient Gown',
    available: 'patient_gown_available',
    rso: 'patient_gown_rso'
  },
  {
    label: 'Scrub suit',
    available: 'scrub_suit_available',
    rso: 'scrub_suit_rso'
  },
  {
    label: 'Sterile drape',
    available: 'sterile_drape_available',
    rso: 'sterile_drape_rso'
  },
  {
    label: 'Plastic Apron',
    available: 'plastic_apron_available',
    rso: 'plastic_apron_rso'
  },
  {
    label: 'Kellypad',
    available: 'kelly_pad_available',
    rso: 'kelly_pad_rso'
  },
  {
    label: 'Urinary catherter with bag or container for collecting urine',
    available: 'urinary_catheter_with_urine_container_available',
    rso: 'urinary_catheter_with_urine_container_rso'
  },
  {
    label: 'Cloth for Drying and Warming the newborn',
    available: 'cloth_for_drying_and_warming_newborn_available',
    rso: 'cloth_for_drying_and_warming_newborn_rso'
  },
  {
    label: 'Clean towels',
    available: 'clean_towels_available',
    rso: 'clean_towels_rso'
  },
  {
    label: 'Sanitary pads',
    available: 'sanitary_pads_available',
    rso: 'sanitary_pads_rso'
  },
  {
    label: 'Baby feeding cup',
    available: 'baby_feeding_cup_available',
    rso: 'baby_feeding_cup_rso'
  },
  {
    label: 'Soap',
    available: 'soap_available',
    rso: 'soap_rso'
  },
  {
    label: 'Antiseptic solution (iodophors or chlorhexidine/cetrinide)',
    available: 'antiseptic_solution_available',
    rso: 'antiseptic_solution_rso'
  },
  {
    label: 'Bleach powder/tablet-Jik (chlorine base compound)',
    available: 'bleach_power_or_tablet_available',
    rso: 'bleach_power_or_tablet_rso'
  },
];

export const postnatal = [
  {
    label: 'Vitamin A 200,000 IU Capsules (at least 2 boxes)',
    available: 'vitamin_a_available',
    rso: 'vitamin_a_rso'
  },
  {
    label: 'Iron + Folic Acid Tablet',
    available: 'iron_and_folic_acid_tablet_available',
    rso: 'iron_and_folic_acid_tablet_rso'
  },
  {
    label: 'Oral Antibiotics',
    available: 'oral_antibiotics_available',
    rso: 'oral_antibiotics_rso'
  },
  {
    label: 'Oral Anti-Hypertensive Medications for Postpartum',
    available: 'oral_antihypertensive_meds_for_postpartum_available',
    rso: 'oral_antihypertensive_meds_for_postpartum_rso'
  },
];

export const newborn = [
  {
    label: 'Vitamin K Ampules (at least 2 ampules)',
    available: 'vitamin_k_ampules_available',
    rso: 'vitamin_k_ampules_rso'
  },
  {
    label: 'Hepatitis B Vaccine (stored inside ref at 2-8 degree Celsius)',
    available: 'hepatitis_b_vaccine_available',
    rso: 'hepatitis_b_vaccine_rso'
  },
  {
    label: 'BCG Vaccine',
    available: 'bcg_vaccine_available',
    rso: 'bcg_vaccine_rso'
  },
  {
    label: 'Erythromycin Eye Ointment 0.5%',
    available: 'erythromycin_eye_ointment_available',
    rso: 'erythromycin_eye_ointment_rso'
  },
  {
    label: 'Injectable Ampicillin',
    available: 'injectable_ampicillin_available',
    rso: 'injectable_ampicillin_rso'
  },
  {
    label: 'Gentamicin',
    available: 'gentamicin_available',
    rso: 'gentamicin_rso'
  },
  {
    label: 'Amikacin',
    available: 'amikacin_available',
    rso: 'amikacin_rso'
  },
  {
    label: 'Gentian Violet',
    available: 'gential_violet_available',
    rso: 'gential_violet_rso'
  },
  {
    label: 'Sterile water',
    available: 'sterile_water_available',
    rso: 'sterile_water_rso'
  },
  {
    label: 'Clean gloves',
    available: 'clean_gloves_available',
    rso: 'clean_gloves_rso'
  },
  {
    label: 'Dry cotton balls/swabs',
    available: 'dry_cotton_balls_or_swabs_available',
    rso: 'dry_cotton_balls_or_swabs_rso'
  },
  {
    label: 'Newborn Screening Card/Kit',
    available: 'newborn_screening_card_or_kit_available',
    rso: 'newborn_screening_card_or_kit_rso'
  },
  {
    label: 'Newborn Screening Filter',
    available: 'newborn_screening_filter_available',
    rso: 'newborn_screening_filter_rso'
  },
  {
    label: 'Sterile Lancets 3mm',
    available: 'sterile_lancets_3mm_available',
    rso: 'sterile_lancets_3mm_rso'
  },
  {
    label: 'Warm moist towel',
    available: 'warm_moist_towel_available',
    rso: 'warm_moist_towel_rso'
  },
];

export const family = [
  {
    label: 'Combined oral contraceptive pills',
    available: 'combined_oral_contraceptive_pills_available',
    rso: 'combined_oral_contraceptive_pills_rso'
  },
  {
    label: 'Progestin only contraceptive pills',
    available: 'progestin_only_contraceptive_pills_available',
    rso: 'progestin_only_contraceptive_pills_rso'
  },
  {
    label: 'Progestin-only injectable contraceptives',
    available: 'progestin_only_injectable_contraceptives_available',
    rso: 'progestin_only_injectable_contraceptives_rso'
  },
  {
    label: 'Male condom',
    available: 'male_condom_available',
    rso: 'male_condom_rso'
  },
  {
    label: 'Intrauterin device',
    available: 'intrauterine_device_available',
    rso: 'intrauterine_device_rso'
  },
  {
    label: 'Implant',
    available: 'implant_available',
    rso: 'implant_rso'
  },
  {
    label: 'Cycle beads for standard days method',
    available: 'cycle_beads_for_standard_days_method_available',
    rso: 'cycle_beads_for_standard_days_method_rso'
  },
  {
    label: 'Anesthesia',
    available: 'anesthesia_available',
    rso: 'anesthesia_rso'
  },
  {
    label: 'Acetic Acid',
    available: 'acetic_acid_available',
    rso: 'acetic_acid_rso'
  },
  {
    label: 'Bleach for Preparing 0.5% Decontaminating Solution',
    available: 'bleach_available',
    rso: 'bleach_rso'
  },
  {
    label: 'Mops and Rags',
    available: 'mops_and_rags_available',
    rso: 'mops_and_rags_rso'
  },
  {
    label: 'Cottons',
    available: 'cotton_available',
    rso: 'cotton_rso'
  },
  {
    label: 'Gauze',
    available: 'gauze_available',
    rso: 'gauze_rso'
  },
  {
    label: 'Pregnancy Test Kit',
    available: 'pregnancy_test_kit_available',
    rso: 'pregnancy_test_kit_rso'
  },
];

export const vaccination = [
  {
    label: 'BCG Vaccine and diluent',
    available: 'bcg_vaccine_and_diluent_available',
    rso: 'bcg_vaccine_and_diluent_rso'
  },
  {
    label: 'Hepatitis B Vaccination (Birth Dose)',
    available: 'hepatitis_b_vaccination_available',
    rso: 'hepatitis_b_vaccination_rso'
  },
  {
    label: 'DPT-Hib+HepB (Pentavalent)',
    available: 'pentavalent_available',
    rso: 'pentavalent_rso'
  },
  {
    label: 'Oral Polio Vaccine',
    available: 'oral_polio_vaccine_available',
    rso: 'oral_polio_vaccine_rso'
  },
  {
    label: 'Inactivated Polio Vaccine',
    available: 'inactivated_polio_vaccine_available',
    rso: 'inactivated_polio_vaccine_rso'

  },
  {
    label: 'Pneumococcal Conjugate Vaccine',
    available: 'pneumococcal_conjugate_vaccine_available',
    rso: 'pneumococcal_conjugate_vaccine_rso'
  },
  {
    label: 'Rotavirus Vaccine',
    available: 'rotavirus_vaccine_available',
    rso: 'rotavirus_vaccine_rso'
  },
  {
    label: 'MR/MMR Vaccine',
    available: 'mr_or_mmr_vaccine_available',
    rso: 'mr_or_mmr_vaccine_rso'
  },
  {
    label: 'ECCD/Mother-Baby Book',
    available: 'eccd_or_mother_baby_book_available',
    rso: 'eccd_or_mother_baby_book_rso'
  },
  {
    label: 'Blank / unused individual child vaccination cards or booklets',
    available: 'blank_child_vaccination_cards_or_booklets_available',
    rso: 'blank_child_vaccination_cards_or_booklets_rso'
  },
  {
    label: 'Index cards (summary forms)',
    available: 'index_cards_available',
    rso: 'index_cards_rso'
  },
  {
    label: 'Single-use standard disposable syringes with needles or auto-disabled syringes with needles',
    available: 'syringes_with_needles_available',
    rso: 'syringes_with_needles_rso'
  },
  {
    label: 'Disposable Latex Gloves',
    available: 'disposable_latex_gloves_available',
    rso: 'disposable_latex_gloves_rso'
  },
  {
    label: 'Cotton Balls',
    available: 'cotton_balls_available',
    rso: 'cotton_balls_rso'
  },
];

export const childPreventive = [
  {
    label: 'Fuji 9 GIC for atraumatic restorative treatment',
    available: 'fuji_9_gic_available',
    rso: 'fuji_9_gic_rso'
  },
  {
    label: 'Flouride varnish',
    available: 'fluoride_varnish_available',
    rso: 'fluoride_varnish_rso'
  },
  {
    label: 'Pit and Fissure sealant',
    available: 'pit_and_fissure_sealant_available',
    rso: 'pit_and_fissure_sealant_rso'
  },
  {
    label: 'Toothbrush',
    available: 'tooth_brush_available',
    rso: 'tooth_brush_rso'
  },
  {
    label: 'Poly Paste Toothpaste',
    available: 'poly_paste_toothpaste_available',
    rso: 'poly_paste_toothpaste_rso'
  },
  {
    label: 'Amoxicillin suspencion drops and syrup',
    available: 'amoxicillin_suspension_drops_and_syrup_available',
    rso: 'amoxicillin_suspension_drops_and_syrup_rso'
  },
  {
    label: 'Cefalexin syrup',
    available: 'cefalexin_syrup_available',
    rso: 'cefalexin_syrup_rso'
  },
  {
    label: 'Cotrimoxazole suspension',
    available: 'cotrimoxazole_suspension_available',
    rso: 'cotrimoxazole_suspension_rso'
  },
  {
    label: 'Flucloxacillin syrup',
    available: 'flucloxacillin_syrup_available',
    rso: 'flucloxacillin_syrup_rso'
  },
  {
    label: 'Injectable Benzathine Benzylpenicillin',
    available: 'injectable_benzathine_benzylpenicillin_available',
    rso: 'injectable_benzathine_benzylpenicillin_rso'
  },
  {
    label: 'Injectable Ceftriaxone',
    available: 'injectable_ceftriaxone_available',
    rso: 'injectable_ceftriaxone_rso'
  },
  {
    label: 'Injectable Erythromycin',
    available: 'injectable_erythromycin_available',
    rso: 'injectable_erythromycin_rso'
  },
  {
    label: 'Injectable Azithromycin',
    available: 'injectable_azithromycin_available',
    rso: 'injectable_azithromycin_rso'
  },
  {
    label: 'Streptomycin injectable',
    available: 'epinephrine_available',
    rso: 'epinephrine_rso'
  },
  {
    label: 'Epinephrine',
    available: 'streptomycin_injectable_available',
    rso: 'streptomycin_injectable_rso'
  },
  {
    label: 'Paracetamol syrup',
    available: 'paracetamol_syrup_available',
    rso: 'paracetamol_syrup_rso'
  },
  {
    label: 'Mefenamic Acid',
    available: 'mefenamic_acid_available',
    rso: 'mefenamic_acid_rso'
  },
  {
    label: 'Tranexamic Acid',
    available: 'tranexamic_acid_available',
    rso: 'tranexamic_acid_rso'
  },
  {
    label: 'Injectable Adrenaline',
    available: 'injectable_adrenaline_available',
    rso: 'injectable_adrenaline_rso'
  },
  {
    label: 'Injectable Diazepam',
    available: 'injectable_diazepram_available',
    rso: 'injectable_diazepram_rso'
  },
  {
    label: 'Lidocaine 1%',
    available: 'lidocaine_available',
    rso: 'lidocaine_rso'
  },
  {
    label: 'Prednisone Tablet',
    available: 'prednisone_tablet_available',
    rso: 'prednisone_tablet_rso'
  },
  {
    label: 'Salbutamol nebulizer solution',
    available: 'salbutamol_nebulizer_solution_available',
    rso: 'salbutamol_nebulizer_solution_rso'
  },
  {
    label: 'Metered-dose inhaler (MDI)',
    available: 'metered_dose_inhaler_available',
    rso: 'metered_dose_inhaler_rso'
  },
  {
    label: 'Vitamin A 200,000 I.U. capsules',
    available: 'vitamin_a_capsules_available',
    rso: 'vitamin_a_capsules_rso'
  },
  {
    label: 'Zinc Supplements (drops and syrup)',
    available: 'zinc_supplements_available',
    rso: 'zinc_supplements_rso'
  },
  {
    label: 'Reformulated ORS with Zinc',
    available: 'reformulated_ors_with_zinc_available',
    rso: 'reformulated_ors_with_zinc_rso'
  },
  {
    label: 'FeSO4 drops and syrup',
    available: 'fe_so4_drops_and_syrup_available',
    rso: 'fe_so4_drops_and_syrup_rso'
  },
  {
    label: 'Syrup containing 30 mg. Elemental Iron/5 ml',
    available: 'syrup_with_30mg_elemental_iron_available',
    rso: 'syrup_with_30mg_elemental_iron_rso'
  },
  {
    label: 'Tablet containing 60 mg. Elemental Iron with 400 mcg folic acid (coated)',
    available: 'tablet_with_60mg_elemental_iron_and_400mcg_folic_acid_available',
    rso: 'tablet_with_60mg_elemental_iron_and_400mcg_folic_acid_rso'
  },
  {
    label: 'Iodized oil capsule with 200 mg. Iodine',
    available: 'iodized_oil_capsule_available',
    rso: 'iodized_oil_capsule_rso'
  },
  {
    label: '10% Glucose',
    available: 'ten_percent_glucose_available',
    rso: 'ten_percent_glucose_rso'
  },
  {
    label: '10% glucose infusion (Neonatalyte)',
    available: 'ten_percent_glucose_infusion_available',
    rso: 'ten_percent_glucose_infusion_rso'
  },
  {
    label: 'Formula 75 (F75)',
    available: 'f75_available',
    rso: 'f75_rso'
  },
  {
    label: 'Ready-to-Use Therapeutic Food (RUTF)',
    available: 'rutf_available',
    rso: 'rutf_rso'
  },
  {
    label: 'Albendazole (400 mg) tablet',
    available: 'albendazole_tablet_available',
    rso: 'albendazole_tablet_rso'
  },
  {
    label: 'Mebendazole (500 mg) tablet',
    available: 'mebendazole_tablet_available',
    rso: 'mebendazole_tablet_rso'
  },
  {
    label: 'Nystatin',
    available: 'nystatin_available',
    rso: 'nystatin_rso'
  },
  {
    label: 'Chloramphenicol ointment',
    available: 'chloramphenicol_ointment_available',
    rso: 'chloramphenicol_ointment_rso'
  },
  {
    label: 'Povidone Iodine cream (5%) or ointment (10%)',
    available: 'povidone_iodine_cream_or_ointment_available',
    rso: 'povidone_iodine_cream_or_ointment_rso'
  },
  {
    label: 'Chlorhexidine 0.2% or salt water',
    available: 'chlorhexidine_or_salt_water_available',
    rso: 'chlorhexidine_or_salt_water_rso'
  },
  {
    label: 'Calamine lotion',
    available: 'calamine_lotion_available',
    rso: 'calamine_lotion_rso'
  },
  {
    label: 'Hydrocortisone acetate 1% cream',
    available: 'hydrocortisone_acetate_cream_available',
    rso: 'hydrocortisone_acetate_cream_rso'
  },
  {
    label: 'Imidazole cream (e.g. clotrimazole 2%)',
    available: 'imidazole_cream_available',
    rso: 'imidazole_cream_rso'
  },
  {
    label: 'Acyclovir cream/ointment',
    available: 'acyclovir_cream_or_ointment_available',
    rso: 'acyclovir_cream_or_ointment_rso'
  },
  {
    label: 'Podophyllum Resin 20% and Salicylic Acid 25% ointment',
    available: 'podophyllum_resin_and_salicylic_acid_ointment_available',
    rso: 'podophyllum_resin_and_salicylic_acid_ointment_rso'
  },
  {
    label: 'Petroleum Jelly',
    available: 'petroleum_jelly_available',
    rso: 'petroleum_jelly_rso'
  },
  {
    label: 'Selenium Sulfide 2% suspension',
    available: 'selenium_sulfide_suspension_available',
    rso: 'selenium_sulfide_suspension_rso'
  },
  {
    label: 'Sulfur ointment',
    available: 'sulfur_ointment_available',
    rso: 'sulfur_ointment_rso'
  },
  {
    label: 'Ethambutol',
    available: 'ethambutol_available',
    rso: 'ethambutol_rso'
  },
  {
    label: 'Isoniazid',
    available: 'isoniazid_available',
    rso: 'isoniazid_rso'
  },
  {
    label: 'Pyrazinamide',
    available: 'pyrazinamide_available',
    rso: 'pyrazinamide_rso'
  },
  {
    label: 'Rifampicin',
    available: 'rifampicin_available',
    rso: 'rifampicin_rso'
  },
  {
    label: 'Isoniazid + Rifampicin (2FDC)',
    available: 'isoniazid_plus_rifampicin_available',
    rso: 'isoniazid_plus_rifampicin_rso'
  },
  {
    label: 'Isoniazid + Ethambutol (EH) (2FDC)',
    available: 'isoniazid_plus_ethambutol_available',
    rso: 'isoniazid_plus_ethambutol_rso'
  },
  {
    label: 'Isoniazid + Rifampicin + Pyrazinamide (RHZ) (3FDC)',
    available: 'isoniazid_plus_rifampicin_plus_pyrazinamide_available',
    rso: 'isoniazid_plus_rifampicin_plus_pyrazinamide_rso'
  },
  {
    label: 'Isoniazid + Rifampicin + Ethambutol (RHE) (4FDC)',
    available: 'isoniazid_plus_rifampicin_plus_ethambutol_available',
    rso: 'isoniazid_plus_rifampicin_plus_ethambutol_rso'
  },
  {
    label: 'Cup and spoon',
    available: 'cup_abd_spoon_available',
    rso: 'cup_abd_spoon_rso'
  },
  {
    label: 'Nasal cannula/nasal prongs',
    available: 'nasal_cannula_or_nasal_prongs_available',
    rso: 'nasal_cannula_or_nasal_prongs_rso'
  },
  {
    label: 'FG-8 Nasogastric tube',
    available: 'fg8_nasogastric_tube_available',
    rso: 'fg8_nasogastric_tube_rso'
  },
  {
    label: 'Blood glucose testing strips',
    available: 'blood_glucose_testing_strips_available',
    rso: 'blood_glucose_testing_strips_rso'
  },
  {
    label: 'Pure protein derivative (PPD)',
    available: 'pure_protein_derivative_available',
    rso: 'pure_protein_derivative_rso'
  },
];
