import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import {
  Box,
  Button,
  Container,
  Grid,
  Typography
} from '@mui/material';
import { signOut } from 'src/actions/auth';

const Logout = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (props.isSignedIn === false || props.isSignedIn === null) {
      navigate('/login', { replace: true });
    }
  });

  const navigateToDashboard = () => {
    navigate('/dashboard', { replace: true });
  };

  const handleSignout = () => {
    props.signOut();
  };

  return (
    <>
      <Helmet>
        <title>Logout - FReSAA</title>
      </Helmet>

      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Box sx={{ mb: 3 }}>
            <Typography
              color="textPrimary"
              variant="h2"
            >
              Are you sure you want to sign out?
            </Typography>
          </Box>
          <Box sx={{ py: 2 }}>
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <Button
                  aria-label="cancel"
                  color="secondary"
                  fullWidth
                  onClick={navigateToDashboard}
                  size="large"
                  variant="contained"
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  aria-label="sign out"
                  color="primary"
                  fullWidth
                  onClick={handleSignout}
                  size="large"
                  variant="contained"
                >
                  Sign out
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  isSignedIn: state.auth.isSignedIn,
});

export default connect(mapStateToProps, { signOut })(Logout);
