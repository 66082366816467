import React from 'react';
import { TableContainer } from '@mui/material';
import outcomeIndicators from 'src/constants/fields/section2/outcomeIndicators';
import CountTable from '../tables/CountTable';

const Section202 = (props) => {
  const { provinces, data } = props;

  return (
    <TableContainer>
      <CountTable
        ariaLabel="2.2 mnchn outcome indicators"
        provinces={provinces}
        rows={outcomeIndicators}
        response={data}
        sectionKey="mnchn_outcome_indicators"
      />
    </TableContainer>
  );
};

export default Section202;
