import api from 'src/apis/api';
import {
  getAccessToken,
  tokenConfig,
  throwError
} from 'src/actions';
import {
  DONE_LOADING,
  LOADING_DATA,
  FETCH_ASSESSMENTS,
  FETCH_ASSESSMENT,
  SUBMITTING_DATA,
  THROW_SUCCESS,
  UPDATE_ASSESSMENT
} from 'src/actions/types';

export const fetchAssessments = (facilityType, province, facilityId) => async (dispatch, getState) => {
  dispatch({ type: LOADING_DATA });

  const { accessToken } = getState().auth;
  let response;

  try {
    if (facilityType && province) {
      response = await api.get(
        `/v1/assessments/?facility__type=${facilityType}&facility__province=${province}`,
        tokenConfig(accessToken)
      );
    } else if (facilityType) {
      response = await api.get(
        `/v1/assessments/?facility__type=${facilityType}`,
        tokenConfig(accessToken)
      );
    } else if (province) {
      response = await api.get(
        `/v1/assessments/?facility__province=${province}`,
        tokenConfig(accessToken)
      );
    } else if (facilityId) {
      response = await api.get(
        `/v1/assessments/?facility__id=${facilityId}`,
        tokenConfig(accessToken)
      );
    } else {
      response = await api.get(
        '/v1/assessments/',
        tokenConfig(accessToken)
      );
    }

    dispatch({
      type: FETCH_ASSESSMENTS,
      payload: response.data
    });

    dispatch({ type: DONE_LOADING });
  } catch (e) {
    if (e.response && e.response.status === 401) {
      await dispatch(getAccessToken());
      await dispatch(fetchAssessments(facilityType, province));
    } else {
      throwError(e, dispatch);
    }
  }
};

export const fetchAssessment = (id) => async (dispatch, getState) => {
  dispatch({ type: LOADING_DATA });

  const { accessToken } = getState().auth;

  try {
    const response = await api.get(
      `/v1/assessments/${id}/`,
      tokenConfig(accessToken)
    );

    dispatch({
      type: FETCH_ASSESSMENT,
      payload: response.data
    });

    dispatch({ type: DONE_LOADING });
  } catch (e) {
    if (e.response && e.response.status === 401) {
      await dispatch(getAccessToken());
      await dispatch(fetchAssessment(id));
    } else {
      throwError(e, dispatch);
    }
  }
};

export const updateAssessment = (id, form) => async (dispatch, getState) => {
  dispatch({
    type: SUBMITTING_DATA,
    payload: 'assessment'
  });

  const { accessToken } = getState().auth;

  try {
    const response = await api.put(
      `/v1/assessments/${id}/`,
      form,
      tokenConfig(accessToken)
    );

    dispatch({
      type: UPDATE_ASSESSMENT,
      payload: response.data
    });

    dispatch({
      type: THROW_SUCCESS,
      payload: 'Successfully updated assessment'
    });
  } catch (e) {
    if (e.response && e.response.status === 401) {
      await dispatch(getAccessToken());
      await dispatch(updateAssessment(id, form));
    } else {
      throwError(e, dispatch);
    }
  }
};

export const updateAssessmentStatus = (id, form) => async (dispatch, getState) => {
  dispatch({
    type: SUBMITTING_DATA,
    payload: 'assessment'
  });

  const { accessToken } = getState().auth;

  try {
    const response = await api.patch(
      `/v1/assessments/${id}/`,
      form,
      tokenConfig(accessToken)
    );

    dispatch({
      type: UPDATE_ASSESSMENT,
      payload: response.data
    });

    dispatch({
      type: THROW_SUCCESS,
      payload: 'Successfully updated assessment status'
    });
  } catch (e) {
    if (e.response && e.response.status === 401) {
      await dispatch(getAccessToken());
      await dispatch(updateAssessmentStatus(id, form));
    } else {
      throwError(e, dispatch);
    }
  }
};

export const assessmentReview = (id, action, form) => async (dispatch, getState) => {
  dispatch({ type: SUBMITTING_DATA });
  const { accessToken } = getState().auth;
  let response;

  try {
    switch (action) {
      case 'assign':
        response = await api.post(
          `/v1/assessments/${id}/assign_review/`,
          form,
          tokenConfig(accessToken)
        );

        dispatch({
          type: THROW_SUCCESS,
          payload: response.data.message
        });

        break;

      case 'start':
        response = await api.post(
          `/v1/assessments/${id}/start_review/`,
          null,
          tokenConfig(accessToken)
        );

        dispatch({
          type: THROW_SUCCESS,
          payload: 'Assessment state has been changed'
        });

        break;

      case 'complete':
        response = await api.post(
          `/v1/assessments/${id}/complete_review/`,
          null,
          tokenConfig(accessToken)
        );

        dispatch({
          type: THROW_SUCCESS,
          payload: 'Assessment review has been saved'
        });

        break;

      default:
        break;
    }
  } catch (e) {
    if (e.response && e.response.status === 401) {
      await dispatch(getAccessToken());
      await dispatch(assessmentReview(action, form));
    } else {
      throwError(e, dispatch);
    }
  }
};
