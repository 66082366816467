import React from 'react';
import { Box, TableContainer } from '@mui/material/';
import {
  services,
  guidelines,
  trainings,
  space
} from 'src/constants/fields/section4/4.6/adolescentHealth';
import YesNoTable from '../../tables/YesNoTable';
import ScheduleTable from '../../tables/ScheduleTable';

const spaceHeaders = [
  'Yes, Level 1',
  'Yes, Level 2',
  'Yes, Level 3',
  'No'
];

const Section464 = (props) => {
  const { provinces, data } = props;

  return (
    <TableContainer>
      <YesNoTable
        ariaLabel="4.6.4 adolescent health services"
        provinces={provinces}
        rows={services}
        response={data}
        title="Adolescent Health Services"
        sectionKey="adolescent_health"
      />
      <Box sx={{ height: 40 }} />
      <YesNoTable
        ariaLabel="4.6.4 adolescent health guidelines"
        provinces={provinces}
        rows={guidelines}
        response={data}
        title="National guidelines, job-aids, checklist, charts on adolescent health services"
        sectionKey="adolescent_health"
      />
      <Box sx={{ height: 40 }} />
      <YesNoTable
        ariaLabel="4.6.4 adolescent health trainined personnels"
        provinces={provinces}
        rows={trainings}
        response={data}
        title="Trainings"
        sectionKey="adolescent_health"
      />
      <Box sx={{ height: 40 }} />
      <ScheduleTable
        ariaLabel="4.6.4 adolescent health schedule"
        provinces={provinces}
        response={data}
        field={space}
        headers={spaceHeaders}
        sectionKey="adolescent_health"
      />
    </TableContainer>
  );
};

export default Section464;
