import React from 'react';
import {
  Box,
  TableContainer,
} from '@mui/material';
import {
  fields,
  extraFields,
  inpatientToilet,
  outpatientToilet
} from 'src/constants/fields/section3/physicalStructure';
import YesNoTable from '../tables/YesNoTable';
import ToiletTypes from '../tables/ToiletTypes';

const Section312 = (props) => {
  const { data, provinces } = props;

  return (
    <TableContainer>
      <YesNoTable
        ariaLabel="3.1.2 physical structure"
        provinces={provinces}
        rows={fields}
        response={data}
        sectionKey="physical_structure"
      />
      <Box sx={{ height: 40 }} />
      <ToiletTypes
        ariaLabel="3.1.2 toilet for inpatents"
        field={inpatientToilet}
        provinces={provinces}
        response={data}
        sectionKey="physical_structure"
      />
      <Box sx={{ height: 40 }} />
      <ToiletTypes
        ariaLabel="3.1.2 toilet for outpatients"
        field={outpatientToilet}
        provinces={provinces}
        response={data}
        sectionKey="physical_structure"
      />
      <Box sx={{ height: 40 }} />
      <YesNoTable
        ariaLabel="3.1.2 physical structure 2"
        provinces={provinces}
        rows={extraFields}
        response={data}
        sectionKey="physical_structure"
      />
    </TableContainer>
  );
};

export default Section312;
