import api from 'src/apis/api';
import {
  getAccessToken,
  tokenConfig,
  throwError
} from 'src/actions';
import {
  ADD_ASSIGNMENT,
  DONE_LOADING,
  EDIT_ASSIGNMENT,
  FETCH_ASSIGNMENTS,
  LOADING_DATA,
  SUBMITTING_DATA,
  THROW_SUCCESS
} from 'src/actions/types';

export const fetchAssignments = () => async (dispatch, getState) => {
  dispatch({ type: LOADING_DATA });

  const { accessToken } = getState().auth;

  try {
    const response = await api.get(
      '/v1/assignments/',
      tokenConfig(accessToken)
    );

    dispatch({
      type: FETCH_ASSIGNMENTS,
      payload: response.data
    });

    dispatch({ type: DONE_LOADING });
  } catch (e) {
    if (e.response && e.response.status === 401) {
      await dispatch(getAccessToken());
      await dispatch(fetchAssignments());
    } else {
      throwError(e, dispatch);
    }
  }
};

export const addAssignment = (form) => async (dispatch, getState) => {
  dispatch({
    type: SUBMITTING_DATA,
    payload: 'assignment-form'
  });

  const { accessToken } = getState().auth;

  try {
    const response = await api.post(
      '/v1/assignments/',
      form,
      tokenConfig(accessToken)
    );

    dispatch({
      type: ADD_ASSIGNMENT,
      payload: response.data
    });

    dispatch({
      type: THROW_SUCCESS,
      payload: 'Successfully assigned facility'
    });
  } catch (e) {
    if (e.response && e.response.status === 401) {
      await dispatch(getAccessToken());
      await dispatch(addAssignment(form));
    } else {
      throwError(e, dispatch);
    }
  }
};

export const editAssignment = (id, form) => async (dispatch, getState) => {
  dispatch({
    type: SUBMITTING_DATA,
    payload: 'assignment-form'
  });

  const { accessToken } = getState().auth;

  try {
    const response = await api.patch(
      `/v1/assignments/${id}/`,
      form,
      tokenConfig(accessToken)
    );

    dispatch({
      type: EDIT_ASSIGNMENT,
      payload: response.data
    });

    dispatch({
      type: THROW_SUCCESS,
      payload: 'Successfully updated assignment'
    });
  } catch (e) {
    if (e.response && e.response.status === 401) {
      await dispatch(getAccessToken());
      await dispatch(editAssignment(id, form));
    } else {
      throwError(e, dispatch);
    }
  }
};
