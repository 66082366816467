export const services = [
  {
    label: 'Measurement of weight',
    id: 'weight_measurement'
  },
  {
    label: 'Measurement of height',
    id: 'height_measurement'
  },
  {
    label: 'Measurement of mid-upper-arm circumference',
    id: 'midupper_arm_circumference_measurement'
  },
  {
    label: 'National guidelines job-aids, checklist, charts for growth monitoring',
    id: 'national_guidelines_present'
  },
  {
    label: 'Health workers trained in Growth Monitoring or Training Course on Child Growth Standards',
    id: 'training_in_the_last_3_years'
  },
];

export const schedule = {
  label: 'Schedule for conducting child growth monitoring',
  id: 'measurement_schedule'
};

export const observations = [
  {
    label: 'Individual child records for growth monitoring maintained',
    id: 'individual_records_for_child_vaccination'
  }
];
