import { Navigate } from 'react-router-dom';
import Account from './pages/Account';
import AssessmentList from './pages/AssessmentList';
import AssessmentOverview from './pages/AssessmentOverview';
import AssessmentSection1 from './pages/AssessmentSection1';
import AuthRoute from './pages/AuthRoute';
import Dashboard from './pages/Dashboard';
import DashboardLayout from './components/DashboardLayout';
import FacilityCreate from './pages/FacilityCreate';
import FacilityDetail from './pages/FacilityDetail';
import FacilityList from './pages/FacilityList';
import Login from './pages/Login';
import Logout from './pages/Logout';
import MainLayout from './components/MainLayout';
import NotFound from './pages/NotFound';
import Register from './pages/Register';
import Settings from './pages/Settings';
import StaffList from './pages/StaffList';
import GeneralServicesPage from './pages/Section2/GeneralServicesPage';
import MNCHNOutcomeIndicatorsPage from './pages/Section2/MNCHNOutcomeIndicatorsPage';
import HealthCarePersonnelPage from './pages/Section2/HealthCarePersonnelPage';
import BasicAmenitiesPage from './pages/section3/BasicAmenitiesPage';
import BasicSuppliesPage from './pages/section3/BasicSuppliesPage';
import PrecautionsWasteManagementPage from './pages/section3/PrecautionsWasteManagementPage';
import PoliciesPage from './pages/section3/PoliciesPage';
import AntenatalCarePage from './pages/section4/AntenatalCarePage';
import DeliveryServicesPage from './pages/section4/DeliveryServicesPage';
import PostnatalCarePage from './pages/section4/PostnatalCarePage';
import NewbornCarePage from './pages/section4/NewbornCarePage';
import FamilyPlanningPage from './pages/section4/FamilyPlanningPage';
import ChildHealthServices from './pages/section4/ChildHealthServices';
import EquipmentsPage from './pages/section4/EquipmentsPage';
import SuppliesPage from './pages/section4/SuppliesPage';
import StaffCreate from './pages/StaffCreate';
import StaffDetail from './pages/StaffDetail';
import ForgotPassword from './pages/ForgotPassword';
import ConfirmPasswordReset from './pages/ConfirmPasswordReset';
import PrivacyPolicy from './pages/PrivacyPolicy';
import AuthDashboardRoute from './pages/AuthDashboardRoute';
import NoPermission from './pages/NoPermission';
import ReportDetail from './pages/ReportDetail';

const routes = [
  {
    path: '/',
    element: <DashboardLayout />,
    children: [
      { path: '/', element: <Navigate to="/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> },
      { path: 'account', element: <AuthRoute component={Account} /> },
      { path: 'assessments', element: <AuthDashboardRoute component={AssessmentList} /> },
      { path: 'assessments/:id', element: <AuthDashboardRoute component={AssessmentOverview} /> },
      { path: 'assessments/:id/sections/1', element: <AuthDashboardRoute component={AssessmentSection1} /> },
      { path: 'assessments/:id/sections/2/2.1', element: <AuthDashboardRoute component={GeneralServicesPage} /> },
      { path: 'assessments/:id/sections/2/2.2', element: <AuthDashboardRoute component={MNCHNOutcomeIndicatorsPage} /> },
      { path: 'assessments/:id/sections/2/2.3', element: <AuthDashboardRoute component={HealthCarePersonnelPage} /> },
      { path: 'assessments/:id/sections/3/3.1', element: <AuthDashboardRoute component={BasicAmenitiesPage} /> },
      { path: 'assessments/:id/sections/3/3.2', element: <AuthDashboardRoute component={BasicSuppliesPage} /> },
      { path: 'assessments/:id/sections/3/3.3', element: <AuthDashboardRoute component={PrecautionsWasteManagementPage} /> },
      { path: 'assessments/:id/sections/3/3.4', element: <AuthDashboardRoute component={PoliciesPage} /> },
      { path: 'assessments/:id/sections/4/4.1', element: <AuthDashboardRoute component={AntenatalCarePage} /> },
      { path: 'assessments/:id/sections/4/4.2', element: <AuthDashboardRoute component={DeliveryServicesPage} /> },
      { path: 'assessments/:id/sections/4/4.3', element: <AuthDashboardRoute component={PostnatalCarePage} /> },
      { path: 'assessments/:id/sections/4/4.4', element: <AuthDashboardRoute component={NewbornCarePage} /> },
      { path: 'assessments/:id/sections/4/4.5', element: <AuthDashboardRoute component={FamilyPlanningPage} /> },
      { path: 'assessments/:id/sections/4/4.6', element: <AuthDashboardRoute component={ChildHealthServices} /> },
      { path: 'assessments/:id/sections/4/4.7', element: <AuthDashboardRoute component={EquipmentsPage} /> },
      { path: 'assessments/:id/sections/4/4.8', element: <AuthDashboardRoute component={SuppliesPage} /> },
      { path: 'dashboard', element: <AuthRoute component={Dashboard} /> },
      { path: 'dashboard/:id', element: <AuthRoute component={ReportDetail} /> },
      { path: 'facilities', element: <AuthDashboardRoute component={FacilityList} /> },
      { path: 'facilities/:id', element: <AuthDashboardRoute component={FacilityDetail} /> },
      { path: 'facilities/add', element: <AuthDashboardRoute component={FacilityCreate} /> },
      { path: 'settings', element: <AuthRoute component={Settings} /> },
      { path: 'staff', element: <AuthDashboardRoute component={StaffList} /> },
      { path: 'staff/add', element: <AuthDashboardRoute component={StaffCreate} /> },
      { path: 'staff/:id', element: <AuthDashboardRoute component={StaffDetail} /> },
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'password/reset', element: <ForgotPassword /> },
      { path: 'password/reset/confirm/:uid/:token', element: <ConfirmPasswordReset /> },
      { path: 'login', element: <Login /> },
      { path: 'logout', element: <Logout /> },
      { path: 'register', element: <Register /> },
      { path: '404', element: <NotFound /> },
      { path: '*', element: <Navigate to="/404" /> },
      { path: 'privacy_policy', element: <PrivacyPolicy /> },
      { path: '403', element: <NoPermission /> },
    ]
  }
];

export default routes;
