import api from 'src/apis/api';
import {
  CHANGE_PASSWORD,
  EDIT_PROFILE,
  SUBMITTING_DATA,
  THROW_SUCCESS
} from 'src/actions/types';
import {
  tokenConfig,
  getAccessToken,
  throwError,
} from 'src/actions';

export const editProfile = (form) => async (dispatch, getState) => {
  dispatch({
    type: SUBMITTING_DATA,
    payload: 'profileForm'
  });

  const { accessToken } = getState().auth;

  try {
    const response = await api.patch(
      'v1/auth/user/',
      form,
      tokenConfig(accessToken)
    );

    dispatch({
      type: EDIT_PROFILE,
      payload: response.data
    });

    dispatch({
      type: THROW_SUCCESS,
      payload: 'Profile information has been updated.'
    });
  } catch (e) {
    if (e.response && e.response.status === 401) {
      await dispatch(getAccessToken());
      await dispatch(editProfile(form));
    } else {
      throwError(e, dispatch);
    }
  }
};

export const changePassword = (form) => async (dispatch, getState) => {
  dispatch({
    type: SUBMITTING_DATA,
    payload: 'passwordForm'
  });

  const { accessToken } = getState().auth;
  const formValues = {
    new_password1: form.password1,
    new_password2: form.password2,
  };

  try {
    const response = await api.post(
      'v1/auth/password/change/',
      formValues,
      tokenConfig(accessToken)
    );

    dispatch({
      type: CHANGE_PASSWORD,
      payload: response.data
    });

    dispatch({
      type: THROW_SUCCESS,
      payload: response.data.detail
    });
  } catch (e) {
    if (e.response && e.response.status === 401) {
      await dispatch(getAccessToken());
      await dispatch(changePassword(form));
    } else {
      throwError(e, dispatch);
    }
  }
};
