import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const CustomDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

CustomDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ImageDialog = (props) => {
  const handleClose = () => {
    props.setShowModal(false);
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="image-dialog-title"
        open={props.open}
      >
        <CustomDialogTitle id="image-dialog-title" onClose={handleClose}>
          <Typography variant="h4">
            {props.title}
          </Typography>
        </CustomDialogTitle>
        <DialogContent dividers>
          <img
            className="dialog-image"
            src={props.src}
            alt="Attestor’s Signature"
            loading="lazy"
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ImageDialog;
