export const examinations = [
  {
    label: 'Monitoring and Management of Labor using Partograph',
    id: 'partograph'
  },
  {
    label: 'Thermal Protection, Immediate Drying and Skin-to-Skin Contact',
    id: 'thermal_protection_immediate_drying_and_skin_to_skin_contact'
  },
  {
    label: 'Delayed Cord Cutting',
    id: 'delayed_cord_cutting'
  },
  {
    label: 'Hygienic Cord Care',
    id: 'hygienic_cord_care'
  },
  {
    label: 'Immediate and Exclusive Breastfeeding',
    id: 'immediate_and_exclusive_breastfeeding'
  },
  {
    label: 'Normal Delivery',
    id: 'normal_delivery'
  },
  {
    label: 'Assisted Vaginal Delivery, including imminent Breech Delivery',
    id: 'assisted_vaginal_delivery'
  },
  {
    label: 'Parenteral Administration of Uterotonic (e.g. Oxytocin) Drug',
    id: 'parenteral_administration_of_uterotonic'
  },
  {
    label: 'Manual Removal of Placenta',
    id: 'manual_removal_of_placenta'
  },
  {
    label: 'Removal of Retained Products after Delivery',
    id: 'removal_of_retained_products_after_delivery'
  },
  {
    label: 'Administration of Corticosteroids of Pre-term Labor',
    id: 'corticosteroids_for_preterm_labor'
  },
  {
    label: 'Parenteral Administration of Intravenous Antibiotics',
    id: 'parenteral_administration_of_iv_antibiotics'
  },
  {
    label: 'Parenteral Administration of Anticonvulsant',
    id: 'parenteral_administration_of_anticonvulsant'
  },
  {
    label: 'Basic Emergency Obstetric Care',
    id: 'basic_emergency_obstetric_care'
  },
  {
    label: 'Neonatal Resuscitation',
    id: 'neonatal_resuscitation'
  },
];

export const guidelines = [
  {
    label: 'QAP Manual / Pregnancy, Childbirth, Postpartum, Newborn Care Manual',
    id: 'qap_manual_or_equivalent'
  },
  {
    label: 'EINC Clinical Practical Pocket Guide',
    id: 'einc_clinical_practical_pocket_guide'
  },
];

export const training = [
  {
    label: 'Basic Emergency Obstetric and Neonatal Care (BEMONC)',
    id: 'bemonc_training'
  },
  {
    label: 'Essential Intrapartum and Newborn Care (EINC)',
    id: 'einc_training'
  },
  {
    label: 'Lactation Management Training (LMT)',
    id: 'lmt_training'
  },
  {
    label: 'Basic Life Support (BLS)',
    id: 'bls_training'
  },
];

export const personnel = [
  {
    label: 'Physicial trained in Basic Emergency Obstetric and Neonatal Care',
    id: 'physician_trained_in_bemonc'
  },
  {
    label: 'PhilHealth Accredited Professional Provider',
    id: 'philhealth_accredited_professional_provider'
  },
  {
    label: 'Bachelor of Science in Midwifery',
    id: 'midwife_with_bs_diploma'
  },
];

export const observations = [
  {
    label: 'Maternal clinical charts with property accoplished consent forms and ICD-10 coded medical diagnosis and procedures preformed',
    id: 'maternal_clinic_charts'
  },
  {
    label: 'Newbown patient charts with properly and completely filled out birth certificate forms',
    id: 'newborn_patient_charts'
  },
  {
    label: 'Referral forms and return refferals',
    id: 'referral_forms'
  },
];
