import React, { memo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import TableLink from 'src/components/common/TableLink';
import NotApplicable from 'src/components/common/NotApplicable';

const ToiletTypes = (props) => {
  const {
    ariaLabel,
    field,
    provinces,
    response,
    sectionKey
  } = props;

  return (
    <Table
      sx={{ minWidth: 650 }}
      size="small"
      aria-label={ariaLabel}
    >
      <TableHead>
        <TableRow>
          <TableCell colSpan={13}>
            {field.label}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="center">
            Province
          </TableCell>
          <TableCell colSpan={5} align="center">
            Flush or Pour Flush Toilet
          </TableCell>
          <TableCell colSpan={3} align="center">
            Pit Latrine
          </TableCell>
          <TableCell align="right">
            Composting Tablet
          </TableCell>
          <TableCell align="right">
            Bucket Toilet
          </TableCell>
          <TableCell align="right">
            Hanging Toilet
          </TableCell>
          <TableCell align="right">
            N/A
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell />
          <TableCell align="right">Piped Sewer System</TableCell>
          <TableCell align="right">Septic Tank</TableCell>
          <TableCell align="right">Pit Latrine</TableCell>
          <TableCell align="right">Somewhere Else</TableCell>
          <TableCell align="right">Don&lsquo;t Know Where</TableCell>
          <TableCell align="right">Ventilated Improved Pit Latrine</TableCell>
          <TableCell align="right">Pit Latrine with Slab</TableCell>
          <TableCell align="right">Open Pit</TableCell>
          <TableCell />
          <TableCell />
          <TableCell />
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        {provinces.map((province) => {
          const fieldProvince = response[province.id];
          const fieldItem = fieldProvince ? fieldProvince[field.id] : {};

          return (
            <TableRow key={`${field}-${province.id}`}>
              <TableCell>{province.name}</TableCell>
              <TableCell align="right" width="5%">
                {fieldItem ? (
                  <TableLink
                    field={field.id}
                    title={field.label}
                    value="101"
                    readableValue="Piped Sewer System"
                    province={province}
                    sectionKey={sectionKey}
                  >
                    {fieldItem[101]}
                  </TableLink>
                ) : <NotApplicable />}
              </TableCell>
              <TableCell align="right" width="5%">
                {fieldItem ? (
                  <TableLink
                    field={field.id}
                    title={field.label}
                    value="102"
                    readableValue="Septic Tank"
                    province={province}
                    sectionKey={sectionKey}
                  >
                    {fieldItem[102]}
                  </TableLink>
                ) : <NotApplicable />}
              </TableCell>
              <TableCell align="right" width="5%">
                {fieldItem ? (
                  <TableLink
                    field={field.id}
                    title={field.label}
                    value="103"
                    readableValue="Pit Latrine"
                    province={province}
                    sectionKey={sectionKey}
                  >
                    {fieldItem[103]}
                  </TableLink>
                ) : <NotApplicable />}
              </TableCell>
              <TableCell align="right" width="5%">
                {fieldItem ? (
                  <TableLink
                    field={field.id}
                    title={field.label}
                    value="104"
                    readableValue="Somewhere Else"
                    province={province}
                    sectionKey={sectionKey}
                  >
                    {fieldItem[104]}
                  </TableLink>
                ) : <NotApplicable />}
              </TableCell>
              <TableCell align="right" width="5%">
                {fieldItem ? (
                  <TableLink
                    field={field.id}
                    title={field.label}
                    value="105"
                    readableValue="Don't Know Where"
                    province={province}
                    sectionKey={sectionKey}
                  >
                    {fieldItem[105]}
                  </TableLink>
                ) : <NotApplicable />}
              </TableCell>
              <TableCell align="right" width="5%">
                {fieldItem ? (
                  <TableLink
                    field={field.id}
                    title={field.label}
                    value="201"
                    readableValue="Ventilated Improved Pit Latrine"
                    province={province}
                    sectionKey={sectionKey}
                  >
                    {fieldItem[201]}
                  </TableLink>
                ) : <NotApplicable />}
              </TableCell>
              <TableCell align="right" width="5%">
                {fieldItem ? (
                  <TableLink
                    field={field.id}
                    title={field.label}
                    value="202"
                    readableValue="Pit Latrine with Slab"
                    province={province}
                    sectionKey={sectionKey}
                  >
                    {fieldItem[202]}
                  </TableLink>
                ) : <NotApplicable />}
              </TableCell>
              <TableCell align="right" width="5%">
                {fieldItem ? (
                  <TableLink
                    field={field.id}
                    title={field.label}
                    value="203"
                    readableValue="Open Pit"
                    province={province}
                    sectionKey={sectionKey}
                  >
                    {fieldItem[203]}
                  </TableLink>
                ) : <NotApplicable />}
              </TableCell>
              <TableCell align="right" width="5%">
                {fieldItem ? (
                  <TableLink
                    field={field.id}
                    title={field.label}
                    value="401"
                    readableValue="Composting Tablet"
                    province={province}
                    sectionKey={sectionKey}
                  >
                    {fieldItem[401]}
                  </TableLink>
                ) : <NotApplicable />}
              </TableCell>
              <TableCell align="right" width="5%">
                {fieldItem ? (
                  <TableLink
                    field={field.id}
                    title={field.label}
                    value="402"
                    readableValue="Bucket Toilet"
                    province={province}
                    sectionKey={sectionKey}
                  >
                    {fieldItem[402]}
                  </TableLink>
                ) : <NotApplicable />}
              </TableCell>
              <TableCell align="right" width="5%">
                {fieldItem ? (
                  <TableLink
                    field={field.id}
                    title={field.label}
                    value="403"
                    readableValue="Hanging Toilet"
                    province={province}
                    sectionKey={sectionKey}
                  >
                    {fieldItem[403]}
                  </TableLink>
                ) : <NotApplicable />}
              </TableCell>
              <TableCell align="right" width="5%">
                {fieldItem ? (
                  <TableLink
                    field={field.id}
                    title={field.label}
                    value="404"
                    readableValue="Not Applicable"
                    province={province}
                    sectionKey={sectionKey}
                  >
                    {fieldItem[404]}
                  </TableLink>
                ) : <NotApplicable />}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default memo(ToiletTypes);
