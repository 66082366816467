import React from 'react';
import { Box, TableContainer } from '@mui/material/';
import {
  services,
  guidelines,
  trainings,
  observations
} from 'src/constants/fields/section4/familyPlanning';
import YesNoTable from '../tables/YesNoTable';
import ObservationTable from '../tables/ObservationTable';

const Section405 = (props) => {
  const { provinces, data } = props;

  return (
    <TableContainer>
      <YesNoTable
        ariaLabel="4.4 family planning procedures"
        provinces={provinces}
        rows={services}
        response={data}
        title="Services available as part of Family Planning"
        sectionKey="family_planning"
      />
      <Box sx={{ height: 40 }} />
      <YesNoTable
        ariaLabel="4.4 family planning guidelines"
        provinces={provinces}
        rows={guidelines}
        response={data}
        title="National guidelines, job-aids, checklist, charts for family planning"
        sectionKey="family_planning"
      />
      <Box sx={{ height: 40 }} />
      <YesNoTable
        ariaLabel="4.5 family planning trained personnels"
        provinces={provinces}
        rows={trainings}
        response={data}
        title="Health workers assigned trained on the following"
        sectionKey="family_planning"
      />
      <Box sx={{ height: 40 }} />
      <ObservationTable
        ariaLabel="4.2 family planning observations"
        provinces={provinces}
        rows={observations}
        response={data}
        sectionKey="family_planning"
      />
    </TableContainer>
  );
};

export default Section405;
