import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Grid,
  Typography
} from '@mui/material';
import FacilityForm from 'src/components/facility/FacilityForm';
import { addFacility } from 'src/actions/facility';
import { fetchBarangays } from 'src/actions/places';
import { getLocalStorageItem } from 'src/utils/localStorage';

const FacilityCreate = (props) => {
  const navigate = useNavigate();
  const {
    barangays,
    isLoading,
    isSubmitting,
    facilities,
    success
  } = props;

  const [readOnly, setReadOnly] = useState(false);
  const [barangayList, setBarangayList] = useState([]);
  const [isBarangayListInStorage, setIsBarangayListInStorage] = useState(false);

  useEffect(() => {
    const storedBarangays = JSON.parse(getLocalStorageItem('barangays'));
    if (storedBarangays) {
      setIsBarangayListInStorage(true);
      setBarangayList([...barangayList, ...storedBarangays]);
    } else {
      props.fetchBarangays();
    }
  }, []);

  useEffect(() => {
    if (success) {
      const facility = facilities[0];
      navigate(`/facilities/${facility.id}`, { replace: true });
    }
  }, [success]);

  return (
    <>
      <Helmet>
        <title>New Facility - FReSAA</title>
      </Helmet>
      <Box className="box-page">
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography
                color="textPrimary"
                gutterBottom
                variant="h2"
              >
                New Facility
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FacilityForm
                onSubmit={props.addFacility}
                barangays={isBarangayListInStorage ? barangayList : barangays}
                isLoading={isLoading}
                isSubmitting={isSubmitting}
                readOnly={readOnly}
                setReadOnly={setReadOnly}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  barangays: Object.values(state.barangays),
  facilities: Object.values(state.facilities),
  isLoading: state.messages.isLoading,
  isSubmitting: state.messages.isSubmitting,
  success: state.messages.success
});

export default connect(mapStateToProps, {
  addFacility,
  fetchBarangays,
})(FacilityCreate);
