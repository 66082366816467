import _ from 'lodash';
import {
  AUTH_ERROR,
  FETCH_BARANGAY,
  FETCH_BARANGAYS,
  SIGN_OUT
} from 'src/actions/types';
import { saveToLocalStorage } from 'src/utils/localStorage';

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_BARANGAYS:
      saveToLocalStorage('barangays', JSON.stringify(action.payload), 36000000);
      return {
        ..._.mapKeys(action.payload, 'id')
      };

    case FETCH_BARANGAY:
      return {
        ...state,
        [action.payload.id]: action.payload
      };

    case AUTH_ERROR:
    case SIGN_OUT:
      return {};

    default:
      return state;
  }
};
