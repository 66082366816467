import api from 'src/apis/api';
import {
  DONE_LOADING,
  FETCH_BARANGAY,
  FETCH_BARANGAYS,
  FETCH_PROVINCES,
  LOADING_DATA
} from 'src/actions/types';
import {
  getAccessToken,
  tokenConfig,
  throwError
} from 'src/actions';

export const fetchBarangays = () => async (dispatch, getState) => {
  dispatch({ type: LOADING_DATA });

  const { accessToken } = getState().auth;

  try {
    const response = await api.get(
      'v1/barangays/',
      tokenConfig(accessToken)
    );

    dispatch({
      type: FETCH_BARANGAYS,
      payload: response.data
    });

    dispatch({ type: DONE_LOADING });
  } catch (e) {
    if (e.response && e.response.status === 401) {
      await dispatch(getAccessToken());
      await dispatch(fetchBarangays());
    } else {
      throwError(e, dispatch);
    }
  }
};

export const fetchBarangay = (barangayId) => async (dispatch, getState) => {
  const { accessToken } = getState().auth;

  try {
    const response = await api.get(
      `/v1/barangays/${barangayId}/`,
      tokenConfig(accessToken)
    );

    dispatch({
      type: FETCH_BARANGAY,
      payload: response.data
    });
  } catch (e) {
    if (e.response && e.response.status === 401) {
      await dispatch(getAccessToken());
      await dispatch(fetchBarangay(barangayId));
    } else {
      throwError(e, dispatch);
    }
  }
};

export const fetchProvinces = () => async (dispatch, getState) => {
  dispatch({ type: LOADING_DATA });

  const { accessToken } = getState().auth;

  try {
    const response = await api.get(
      'v1/provinces/?recursiveChildren=True',
      tokenConfig(accessToken)
    );

    dispatch({
      type: FETCH_PROVINCES,
      payload: response.data
    });
  } catch (e) {
    if (e.response && e.response.status === 401) {
      await dispatch(getAccessToken());
      await dispatch(fetchProvinces());
    } else {
      throwError(e, dispatch);
    }
  }
};

export const fetchChildren = (parentId) => async (dispatch, getState) => {
  dispatch({ type: LOADING_DATA });

  const { accessToken } = getState().auth;

  try {
    const response = await api.get(
      `v1/places/${parentId}/children`,
      tokenConfig(accessToken)
    );

    dispatch({
      type: FETCH_PROVINCES,
      payload: response.data
    });
  } catch (e) {
    if (e.response && e.response.status === 401) {
      await dispatch(getAccessToken());
      await dispatch(fetchChildren(parentId));
    } else {
      throwError(e, dispatch);
    }
  }
};
