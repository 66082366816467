import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Typography
} from '@mui/material';

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>Privacy Policy</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          padding: 10
        }}
      >
        <Container maxWidth="md">
          <Typography variant="h2" gutterBottom>
            Privacy Policy
          </Typography>
          <Grid container spacing={2} className="block">
            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom>
                Facility Readiness Service Availability Assessment (FRESAA) Digital Tool Website and Application
              </Typography>
              <Divider />
              <Typography
                variant="subtitle1"
                color="textSecondary"
                sx={{ fontSize: 14 }}
              >
                Last updated: February 14, 2022
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" gutterBottom>
                It is Automaton Software Systems, Inc.’s policy to respect and protect any personally identifiable information you may provide us through the Facility Readiness Service Availability Assessment (FRESAA) Tool Website and/or Application. This privacy policy (&quot;Privacy Policy&quot;) explains what information may be collected on these platforms, Our procedures of collection, how We use this information, and under what circumstances We may disclose the information to third parties when you use the Service.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" gutterBottom>
                This Privacy Policy also describes your privacy rights and how the law protects you.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" gutterBottom>
                This Privacy Policy applies only to information we collect through the Website and Application and does not apply to our collection of information from other sources.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" gutterBottom>
                By using the Service, you agree to the collection and use of information in accordance with this Privacy Policy.
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2} className="block">
            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom>
                Interpretation and Definitions
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Interpretation
              </Typography>
              <Typography variant="body1">
                The words of which the initial letter is capitalized, except for ‘You/Your’, have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Definitions
              </Typography>
              <Typography variant="body1">
                For the purposes of this Privacy Policy:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className="indent">
                <b>Account </b>
                means a unique account created for you to access our Service or parts of our Service.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className="indent">
                <b>Affiliate </b>
                means an entity that controls, is controlled by or is under common control with a party, where &quot;control&quot; means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className="indent">
                <b>Application </b>
                means the software program provided by the Company downloaded by you on any electronic device, named Digital Facility Readiness Service Availability (FRESAA) Tool
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className="indent">
                <b>Company </b>
                (referred to as either &quot;the Company&quot;, &quot;we&quot;, &quot;us&quot; or &quot;our&quot; in this Agreement) refers to Automaton Software Systems, Inc., 8th Floor, The Company, Mabuhay Tower, Abad St., Cebu Asiatown IT Park, Lahug, Cebu City, Cebu, Philippines.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className="indent">
                <b>Cookies </b>
                are small files that are placed on your computer, mobile device or any other device by a website, containing the details of your browsing history on that website among its many uses.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className="indent">
                <b>Country </b>
                refers to: Philippines
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className="indent">
                <b>Device </b>
                means any device that can access the Service such as a computer, a mobile phone, or a digital tablet.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className="indent">
                <b>Personal Data </b>
                is any information that relates to an identified or identifiable individual.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className="indent">
                <b>Service </b>
                refers to the Application or the Website or both.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className="indent">
                <b>Service Provider </b>
                means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className="indent">
                <b>Usage Data </b>
                refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className="indent">
                <b>Website </b>
                refers to the Digital FRESAA website, accessible from https://fresaa-web-staging.automatonsystems.io.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className="indent">
                <b>You/your </b>
                refers to the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2} className="block">
            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom>
                Philippine Data Privacy Act of 2012
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                We comply with Republic Act 10173 — the Philippine Data Privacy Act of 2012 and its relevant laws — which allows us to help uphold and protect Our users’ fundamental right to privacy and data security, and the free flow of information.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                This Privacy Policy sets forth the general rules and policies governing your use of the FRESAA Website and Application. Depending on your activities when using these platforms, you may be required to agree to additional terms and conditions.
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2} className="block">
            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom>
                Collecting and Using your Personal Data
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">
                Types of Data Collected
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Personal Data
              </Typography>
              <Typography variant="body1">
                While using Our Service, especially in the creation of a dedicated User Account, We may ask you to provide certain personally identifiable information that can be used to contact or identify you, as well as to set the unique association between you and your user account. Personally identifiable information may include, but is not limited to:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" className="indent" gutterBottom>
                <ul>
                  <li>Email address</li>
                  <li>First name and last name</li>
                  <li>Phone number</li>
                  <li>Usage Data</li>
                </ul>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Usage Data
              </Typography>
              <Typography variant="body1">
                Usage Data is collected automatically when using the Service.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                Usage Data may include information such as your Device&apos;s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers,  and other diagnostic data.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                When you access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers, and other diagnostic data.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" gutterBottom>
                We may also collect information that your browser sends whenever you visit our Service or when you access the Service by or through a mobile device.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Tracking Technologies and Cookies
              </Typography>
              <Typography variant="body1">
                We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. The technologies We use may include:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" className="indent">
                <b>Cookies or Browser Cookies. </b>
                A cookie is a small file placed on your Device. you can instruct your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if you do not accept Cookies, you may not be able to use some parts of our Service. Unless you have adjusted your browser setting so that it will refuse Cookies, our Service may use Cookies.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" className="indent">
                <b>Flash Cookies. </b>
                Certain features of our Service may use local stored objects (or Flash Cookies) to collect and store information about your preferences or your activity on our Service. Flash Cookies are not managed by the same browser settings as those used for Browser Cookies. For more information on how you can delete Flash Cookies, please read &quot;Where can I change the settings for disabling, or deleting local shared objects?&quot; available
                <a href="https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_"> here</a>
                .
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" className="indent" gutterBottom>
                <b>Web Beacons. </b>
                Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages, opened an email, or performed other related website statistics (for example, recording the frequency of use of a certain section and verifying system and server integrity).
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Local Session Storage
              </Typography>
              <Typography variant="body1">
                To enhance your experience of the Website and the Mobile Application, we may also store usage data in your device’s local storage. You have the freedom to block or delete stored data. Users who do not wish to have stored data placed on their computers should set their browsers to refuse these data before using Automaton System’s Website, with the drawback that some of the features of these platforms may not function properly without the aid of locally stored data.
              </Typography>
            </Grid>
            <Grid item sx={12}>
              <Typography variant="body1">
                By continuing to use and navigate the Company’s Website and Application without changing your local storage settings, you hereby acknowledge and agree to our use of locally stored data.
              </Typography>
            </Grid>
            <Grid item sx={12}>
              <Typography variant="body1">
                Some locally stored data behave like cookies for the purposes set out below:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" className="indent">
                <ul>
                  <li>
                    For providing you with the services available through the Website and to enable you to use some of its features. This type of stored data helps authenticate users and prevent fraudulent use of user accounts. Without locally stored data, the services that you have asked for cannot be provided. Additionally, Automaton Software Systems only uses these data to provide you with those services;
                  </li>
                </ul>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" className="indent">
                <ul>
                  <li>
                    For Notice Acceptance, or identifying if users have accepted local data storage;
                  </li>
                </ul>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" className="indent" gutterBottom>
                <ul>
                  <li>
                    For allowing our system to remember past choices you make when you use either the Website or the Mobile Application, thereby helping to avoid having to re-enter your preferences every time you use the Website  or Application. This includes remembering your login details or language preferences.
                  </li>
                </ul>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Use of your Personal Data
              </Typography>
              <Typography variant="body1">
                The Company may use Personal Data for the following purposes:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" className="indent">
                <ul>
                  <li>
                    <b>To provide and maintain our Service, </b>
                    including to monitor the usage of our Service.
                  </li>
                </ul>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" className="indent">
                <ul>
                  <li>
                    <b>To manage your Account: </b>
                    to manage your registration as a user of the Service. The Personal Data you provide can give you access to different functionalities of the Service that are available to you as a registered user.
                  </li>
                </ul>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" className="indent">
                <ul>
                  <li>
                    <b>For the performance of a contract: </b>
                    the development, compliance, and undertaking of the purchase contract for the products, items or services you have availed of through the Website or Application, or of any other contract with Us through the Service.
                  </li>
                </ul>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" className="indent">
                <ul>
                  <li>
                    <b>To contact you: </b>
                    To contact you by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application&apos;s push notifications regarding updates or informative communications related to the functionalities, products, or contracted services, including the security updates, when necessary or reasonable for their implementation.
                  </li>
                </ul>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" className="indent">
                <ul>
                  <li>
                    <b>To provide you </b>
                    with updates and general information about pertinent services which we offer that are similar or in direct connection to those that you have already availed of or enquired about through our platforms, unless you have opted not to receive such information.
                  </li>
                </ul>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" className="indent">
                <ul>
                  <li>
                    <b>To manage your requests: </b>
                    To attend and manage your requests to Us.
                  </li>
                </ul>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" className="indent">
                <ul>
                  <li>
                    <b>For business transfers: </b>
                    We may use your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.
                  </li>
                </ul>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" className="indent">
                <ul>
                  <li>
                    <b>For other purposes: </b>
                    We may use your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of interface design elements, and to evaluate and improve our Service and your experience.
                  </li>
                </ul>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                We may share your personal information in the following situations:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" className="indent">
                <ul>
                  <li>
                    <b>With Service Providers: </b>
                    We may share your personal information with Service Providers to monitor and analyze the use of our Service, to contact you.
                  </li>
                </ul>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" className="indent">
                <ul>
                  <li>
                    <b>For business transfers: </b>
                    We may share or transfer your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.
                  </li>
                </ul>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" className="indent">
                <ul>
                  <li>
                    <b>With Affiliates: </b>
                    We may share your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.
                  </li>
                </ul>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" className="indent">
                <ul>
                  <li>
                    <b>With business partners: </b>
                    We may share your information with Our business partners to offer you certain products, services or promotions.
                  </li>
                </ul>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" className="indent">
                <ul>
                  <li>
                    <b>With other ssers: </b>
                    when you share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside.
                  </li>
                </ul>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" className="indent">
                <ul>
                  <li>
                    <b>With your consent: </b>
                    We may disclose your personal information for any other purpose with your consent.
                  </li>
                </ul>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Retention of your Personal Data
              </Typography>
              <Typography variant="body1">
                The Company will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Transfer of your Personal Data
              </Typography>
              <Typography variant="body1">
                Your information, including Personal Data, is processed at the Company&apos;s operating office and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those from your jurisdiction.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                The Company will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Disclosure of your Personal Data
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Business Transactions
              </Typography>
              <Typography variant="body1">
                If the Company is involved in a merger, acquisition or asset sale, your Personal Data may be transferred. We will provide notice before your Personal Data is transferred and becomes subject to a different Privacy Policy.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Law enforcement
              </Typography>
              <Typography variant="body1">
                Under certain circumstances, the Company may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Other legal requirements
              </Typography>
              <Typography variant="body1">
                The Company may disclose your Personal Data in the good faith belief that such action is necessary to:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" className="indent">
                <ul>
                  <li>Comply with a legal obligation</li>
                  <li>Protect and defend the rights or property of the Company</li>
                  <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
                  <li>Protect the personal safety of Users of the Service or the public</li>
                  <li>Protect against legal liability</li>
                </ul>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Security of your Personal Data
              </Typography>
              <Typography variant="body1">
                The security of your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect your Personal Data, We cannot guarantee its absolute security.
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="block">
            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom>
                Children&apos;s Privacy
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If you are a parent or guardian and you are aware that your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                If We need to rely on consent as a legal basis for processing your information and your country requires consent from a parent, We may require your parent&apos;s consent before We collect and use that information.
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="block">
            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom>
                Links to Other Websites
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                Our Service may contain links to other websites that are not operated by Us. If you click on a third party link, you will be directed to that third party&apos;s site. We strongly advise you to review the Privacy Policy of every site you visit.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="block">
            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom>
                Changes to this Privacy Policy
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                We may update Our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                We will let you know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the &quot;Last updated&quot; date at the top of this Privacy Policy.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="block">
            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom>
                Contact Us
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                If you have any questions about this Privacy Policy, you can contact us by email at admin@automatonsystems.io
              </Typography>
            </Grid>
          </Grid>
          <Box display="flex" sx={{ justifyContent: 'center' }}>
            <Button
              aria-label="back"
              color="primary"
              variant="text"
              onClick={() => navigate(-1)}
            >
              Go back
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default PrivacyPolicy;
