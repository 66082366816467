import React, { useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import {
  Breadcrumbs,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Menu,
  MenuItem,
  Typography
} from '@mui/material';

const paperProps = {
  elevation: 0,
  sx: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 6,
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  }
};

const sections = [
  {
    id: 1,
    hasSubMenu: false,
    label: 'Section 1',
    link: 'sections/1',
    name: 'section1',
  },
  {
    id: 2,
    hasSubMenu: true,
    label: 'Section 2',
    name: 'section2',
    menu: 'section2-menu',
  },
  {
    id: 3,
    hasSubMenu: true,
    label: 'Section 3',
    name: 'section3',
    menu: 'section3-menu',
  },
  {
    id: 4,
    hasSubMenu: true,
    label: 'Section 4',
    name: 'section4',
    menu: 'section3-menu',
  }
];

const SectionBreadcrumbs = (props) => {
  const {
    active,
    assessmentId,
    next,
    previous
  } = props;

  const [anchorEl, setAnchorEl] = useState({
    section2: null,
    section3: null,
    section4: null
  });

  const open = {
    section2: Boolean(anchorEl.section2),
    section3: Boolean(anchorEl.section3),
    section4: Boolean(anchorEl.section4),
  };

  const handleClick = (e, section) => {
    setAnchorEl({
      ...anchorEl,
      [section]: e.currentTarget
    });
  };

  const handleClose = (sectionKey) => {
    setAnchorEl({
      ...anchorEl,
      [sectionKey]: null
    });
  };

  return (
    <Grid item xs={12}>
      <Card>
        <CardContent>
          <Breadcrumbs gutterBottom>
            <Link to={`/assessments/${assessmentId}`}>
              <Button>
                <Typography variant="subtitle" sx={{ fontWeight: 400 }}>
                  Overview
                </Typography>
              </Button>
            </Link>
            {sections.map((item) => {
              if (item.hasSubMenu) {
                return (
                  <Button
                    id={`section${item.id}-btn`}
                    aria-controls={item.hasSubMenu ? item.menu : null}
                    aria-haspopup={item.hasSubMenu}
                    aria-expanded={open[item.name] ? 'true' : undefined}
                    onClick={(e) => handleClick(e, item.name)}
                    key={item.id}
                  >
                    {active === item.name ? (
                      <Typography variant="subtitle" sx={{ fontWeight: 900 }}>
                        {item.label}
                      </Typography>
                    ) : (
                      <Typography variant="subtitle" sx={{ fontWeight: 400 }}>
                        {item.label}
                      </Typography>
                    )}
                  </Button>
                );
              }
              return (
                <Link
                  to={`/assessments/${assessmentId}/sections/${item.id}`}
                  key={item.id}
                >
                  <Button>
                    {active === item.name ? (
                      <Typography variant="subtitle" sx={{ fontWeight: 900 }}>
                        {item.label}
                      </Typography>
                    ) : (
                      <Typography variant="subtitle" sx={{ fontWeight: 400 }}>
                        {item.label}
                      </Typography>
                    )}
                  </Button>
                </Link>
              );
            })}
          </Breadcrumbs>
          <Menu
            id="section2-menu"
            MenuListProps={{
              'aria-labelledby': 'section2-btn',
            }}
            anchorEl={anchorEl.section2}
            open={open.section2}
            onClose={() => handleClose('section2')}
            PaperProps={paperProps}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem
              component={Link}
              to={`/assessments/${assessmentId}/sections/2/2.1`}
            >
              2.1 General MNCHN Services
            </MenuItem>
            <MenuItem
              component={Link}
              to={`/assessments/${assessmentId}/sections/2/2.2`}
            >
              2.2 MNCHN-Related Impact/Outcome Indicators
            </MenuItem>
            <MenuItem
              component={Link}
              to={`/assessments/${assessmentId}/sections/2/2.3`}
            >
              2.3 Health Care Personnel
            </MenuItem>
          </Menu>
          <Menu
            id="section3-menu"
            MenuListProps={{
              'aria-labelledby': 'section3-btn',
            }}
            anchorEl={anchorEl.section3}
            open={open.section3}
            onClose={() => handleClose('section3')}
            PaperProps={paperProps}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem
              component={Link}
              to={`/assessments/${assessmentId}/sections/3/3.1`}
            >
              3.1 Basic Amenities
            </MenuItem>
            <MenuItem
              className="submenu"
              component={HashLink}
              to={`/assessments/${assessmentId}/sections/3/3.1/#3.1.1`}
              onClick={() => handleClose('section3')}
            >
              3.1.1 Liscensing and Certification
            </MenuItem>
            <MenuItem
              className="submenu"
              component={HashLink}
              to={`/assessments/${assessmentId}/sections/3/3.1/#3.1.2`}
              onClick={() => handleClose('section3')}
            >
              3.1.2 Physical Structure of Facility
            </MenuItem>
            <MenuItem
              component={Link}
              to={`/assessments/${assessmentId}/sections/3/3.2`}
            >
              3.2 Basic Supplies and Equipment
            </MenuItem>
            <MenuItem
              component={Link}
              to={`/assessments/${assessmentId}/sections/3/3.3`}
            >
              3.3 Standard Precautions and Waste Management
            </MenuItem>
            <MenuItem
              component={Link}
              to={`/assessments/${assessmentId}/sections/3/3.4`}
            >
              3.4 Policies
            </MenuItem>
          </Menu>
          <Menu
            id="section4-menu"
            MenuListProps={{
              'aria-labelledby': 'section4-btn',
            }}
            anchorEl={anchorEl.section4}
            open={open.section4}
            onClose={() => handleClose('section4')}
            PaperProps={paperProps}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem
              component={Link}
              to={`/assessments/${assessmentId}/sections/4/4.1`}
            >
              4.1 Antental Care
            </MenuItem>
            <MenuItem
              component={Link}
              to={`/assessments/${assessmentId}/sections/4/4.2`}
            >
              4.2 Delivery Services
            </MenuItem>
            <MenuItem
              component={Link}
              to={`/assessments/${assessmentId}/sections/4/4.3`}
            >
              4.3 Postnatal Care
            </MenuItem>
            <MenuItem
              component={Link}
              to={`/assessments/${assessmentId}/sections/4/4.4`}
            >
              4.4 Newborn Care
            </MenuItem>
            <MenuItem
              component={Link}
              to={`/assessments/${assessmentId}/sections/4/4.5`}
            >
              4.5 Family Planning
            </MenuItem>
            <MenuItem
              component={Link}
              to={`/assessments/${assessmentId}/sections/4/4.6`}
            >
              4.6 Child and Adolescent Health Services
            </MenuItem>
            <MenuItem
              className="submenu"
              component={HashLink}
              to={`/assessments/${assessmentId}/sections/4/4.6/#4.6.1`}
              onClick={() => handleClose('section4')}
            >
              4.6.1 Child Vaccination
            </MenuItem>
            <MenuItem
              className="submenu"
              component={HashLink}
              to={`/assessments/${assessmentId}/sections/4/4.6/#4.6.2`}
              onClick={() => handleClose('section4')}
            >
              4.6.2 Child Growth Monitoring Services
            </MenuItem>
            <MenuItem
              className="submenu"
              component={HashLink}
              to={`/assessments/${assessmentId}/sections/4/4.6/#4.6.3`}
              onClick={() => handleClose('section4')}
            >
              4.6.3 Child Preventive and Curative Care Services
            </MenuItem>
            <MenuItem
              className="submenu"
              component={HashLink}
              to={`/assessments/${assessmentId}/sections/4/4.6/#4.6.4`}
              onClick={() => handleClose('section4')}
            >
              4.6.4 Adolescent Health Services
            </MenuItem>
            <MenuItem
              component={Link}
              to={`/assessments/${assessmentId}/sections/4/4.7`}
            >
              4.7 Equipment, Instruments, and Other Fixtures
            </MenuItem>
            <MenuItem
              component={Link}
              to={`/assessments/${assessmentId}/sections/4/4.8`}
            >
              4.8 Medicines, Supplies and Commodities
            </MenuItem>
          </Menu>
          <Box className="section-nav-button-cont">
            {previous ? (
              <Link to={previous}>
                <Button variant="outlined">
                  Previous Section
                </Button>
              </Link>
            ) : (
              <Button variant="outlined" disabled>
                Previous Section
              </Button>
            )}
            {next ? (
              <Link to={next}>
                <Button variant="outlined">
                  Next Section
                </Button>
              </Link>
            ) : (
              <Button variant="outlined" disabled>
                Next Section
              </Button>
            )}
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default SectionBreadcrumbs;
