import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Link as MUILink,
  MenuItem,
  Table,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import {
  Edit as EditIcon,
  Visibility as VisibilityIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon
} from '@mui/icons-material';
import AssessmentReviewerModal from 'src/components/assessment/AssessmentReviewerModal';
import ImageDialog from 'src/components/common/ImageDialog';
import StyledMenu from 'src/components/common/StyledMenu';
import {
  fetchAssessment,
  updateAssessmentStatus,
  assessmentReview,
} from 'src/actions/assessment';
import { fetchUsers } from 'src/actions/user';

const status = [
  {
    label: 'NEW',
    value: 1,
    disabled: true,
    action: ''
  },
  {
    label: 'FOR REVIEW',
    value: 2,
    disabled: true,
    action: ''
  },
  {
    label: 'UNDER REVIEW',
    value: 3,
    disabled: false,
    action: 'start'
  },
  {
    label: 'REVIEWED',
    value: 4,
    disabled: false,
    action: 'complete'
  }
];

const AssessmentOverview = (props) => {
  const { id } = useParams();
  const {
    assessments,
    isSubmitting,
    users
  } = props;
  const [assessment, setAssessment] = useState(null);
  const [reviewerDialogOpen, setReviewerDialogOpen] = useState(false);
  const [signatureDialogOpen, setSignatureDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [assessmentStatus, setAssessmentStatus] = useState(null);
  const [assessmentReviewer, setAssessmentReviewer] = useState(null);
  const [assessor, setAssessor] = useState(null);
  const openStatus = Boolean(anchorEl);

  useEffect(() => {
    props.fetchAssessment(id);
    props.fetchUsers();
  }, []);

  useEffect(() => {
    const assessmentTmp = assessments[id];
    if (assessmentTmp) {
      setAssessment(assessmentTmp);

      const statusTmp = (status.find((x) => x.value === assessmentTmp.status)).label;
      setAssessmentStatus(statusTmp);
    }
  }, [assessments]);

  useEffect(() => {
    const assessmentTmp = assessments[id];

    if (users.length > 0 && assessmentTmp) {
      if (assessmentTmp.reviewer) {
        const reviewerTmp = users.find((x) => x.id === assessmentTmp.reviewer.id);
        setAssessmentReviewer(reviewerTmp);
      }

      if (assessmentTmp.assessor_id) {
        const assessorTmp = users.find((x) => x.id === assessmentTmp.assessor_id);
        setAssessor(assessorTmp);
      }
    }
  }, [assessments, users]);

  const handleViewSignature = () => {
    setSignatureDialogOpen(true);
  };

  const handleStatusClose = () => {
    setAnchorEl(null);
  };

  const handleChangeStatus = (status) => {
    setAssessmentStatus(status.label);
    props.assessmentReview(id, status.action, null);
  };

  if (!assessment) {
    return (
      <Box className="spinner-container">
        <CircularProgress />
      </Box>
    );
  }

  const assessorName = assessor ? `${assessor.first_name} ${assessor.last_name}` : '';

  return (
    <>
      <Helmet>
        <title>
          {`${assessment.facility.name} - Assessment - FReSAA`}
        </title>
      </Helmet>
      {isSubmitting && <LinearProgress />}
      <Box className="box-page">
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Box className="overview-header">
                    <Box>
                      <Typography
                        color="textPrimary"
                        gutterBottom
                        variant="h4"
                      >
                        {assessment.facility.name}
                      </Typography>
                      <Typography color="textSecondary" variant="subtitle1">
                        {`Assessor: ${assessorName}`}
                      </Typography>
                      <Typography color="textSecondary" variant="subtitle1">
                        {`Attested by: ${assessment.reviewer1_name}`}
                      </Typography>
                      <Typography color="textSecondary" variant="subtitle1">
                        Attestor’s Signature:
                        <MUILink
                          className="cs-link"
                          onClick={handleViewSignature}
                          sx={{ mx: 1 }}
                        >
                          View
                        </MUILink>
                      </Typography>
                      <Typography color="textSecondary" variant="subtitle1">
                        Reviewer:
                        {assessment.reviewer && (
                          ` ${assessment.reviewer.first_name} ${assessment.reviewer.last_name}`
                        )}
                        <MUILink
                          className="cs-link"
                          onClick={() => setReviewerDialogOpen(true)}
                          sx={{ mx: 1 }}
                        >
                          Assign
                        </MUILink>
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        gap: 2,
                        alignItems: 'flex-start'
                      }}
                    >
                      <Button
                        id="status-button"
                        aria-controls={openStatus ? 'status-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openStatus ? 'true' : undefined}
                        variant="contained"
                        disableElevation
                        onClick={(e) => setAnchorEl(e.currentTarget)}
                        endIcon={<KeyboardArrowDownIcon />}
                      >
                        {assessmentStatus}
                      </Button>
                      <StyledMenu
                        id="demo-customized-menu"
                        MenuListProps={{
                          'aria-labelledby': 'status-button',
                        }}
                        anchorEl={anchorEl}
                        open={openStatus}
                        onClose={handleStatusClose}
                      >
                        {status.map((item) => (
                          <MenuItem
                            key={item.value}
                            onClick={() => handleChangeStatus(item)}
                            disableRipple
                            disabled={item.disabled || assessment.status < 2}
                          >
                            {item.label}
                          </MenuItem>
                        ))}
                      </StyledMenu>
                    </Box>
                  </Box>
                </CardContent>
                <Divider />
                <CardContent>
                  <Table className="overview" size="small">
                    <tbody>
                      <TableRow hover>
                        <TableCell>
                          <Typography variant="h5">
                            Section 1: Facility Identification
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <IconButton component={Link} to={`/assessments/${id}/sections/1`}>
                            <VisibilityIcon color="primary" />
                          </IconButton>
                          <IconButton component={Link} to={`/assessments/${id}/sections/1?edit=true`}>
                            <EditIcon color="primary" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <TableRow hover>
                        <TableCell colSpan={2}>
                          <Typography variant="h5">
                            Section 2: General Service Availability
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow hover>
                        <TableCell>
                          <Typography className="submenu" variant="body1">
                            2.1: General MNCHN Services
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <IconButton component={Link} to={`/assessments/${id}/sections/2/2.1`}>
                            <VisibilityIcon color="primary" />
                          </IconButton>
                          <IconButton component={Link} to={`/assessments/${id}/sections/2/2.1?edit=true`}>
                            <EditIcon color="primary" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <TableRow hover>
                        <TableCell>
                          <Typography className="submenu" variant="body1">
                            2.2: MNCHN-Related Impact/Outcome Indicators
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <IconButton component={Link} to={`/assessments/${id}/sections/2/2.2`}>
                            <VisibilityIcon color="primary" />
                          </IconButton>
                          <IconButton component={Link} to={`/assessments/${id}/sections/2/2.2?edit=true`}>
                            <EditIcon color="primary" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <TableRow hover>
                        <TableCell>
                          <Typography className="submenu" variant="body1">
                            2.3: Health Care Personnel
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <IconButton component={Link} to={`/assessments/${id}/sections/2/2.3`}>
                            <VisibilityIcon color="primary" />
                          </IconButton>
                          <IconButton component={Link} to={`/assessments/${id}/sections/2/2.3?edit=true`}>
                            <EditIcon color="primary" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <TableRow hover>
                        <TableCell colSpan={2}>
                          <Typography variant="h5">
                            Section 3: General Service Readiness
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow hover>
                        <TableCell>
                          <Typography className="submenu" variant="body1">
                            3.1: Basic Amenities
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <IconButton component={Link} to={`/assessments/${id}/sections/3/3.1`}>
                            <VisibilityIcon color="primary" />
                          </IconButton>
                          <IconButton component={Link} to={`/assessments/${id}/sections/3/3.1?edit=true`}>
                            <EditIcon color="primary" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <TableRow hover>
                        <TableCell>
                          <Typography className="submenu" variant="body1">
                            3.2: Basic Supplies and Equipments
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <IconButton component={Link} to={`/assessments/${id}/sections/3/3.2`}>
                            <VisibilityIcon color="primary" />
                          </IconButton>
                          <IconButton component={Link} to={`/assessments/${id}/sections/3/3.2?edit=true`}>
                            <EditIcon color="primary" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <TableRow hover>
                        <TableCell>
                          <Typography className="submenu" variant="body1">
                            3.3: Standard Precautions and Waste Management
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <IconButton component={Link} to={`/assessments/${id}/sections/3/3.3`}>
                            <VisibilityIcon color="primary" />
                          </IconButton>
                          <IconButton component={Link} to={`/assessments/${id}/sections/3/3.3?edit=true`}>
                            <EditIcon color="primary" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <TableRow hover>
                        <TableCell>
                          <Typography className="submenu" variant="body1">
                            3.4: Policies
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <IconButton component={Link} to={`/assessments/${id}/sections/3/3.4`}>
                            <VisibilityIcon color="primary" />
                          </IconButton>
                          <IconButton component={Link} to={`/assessments/${id}/sections/3/3.4?edit=true`}>
                            <EditIcon color="primary" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <TableRow hover>
                        <TableCell colSpan={2}>
                          <Typography variant="h5">
                            Section 4: Service-Specific Readiness
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow hover>
                        <TableCell>
                          <Typography className="submenu" variant="body1">
                            4.1: Antenatal Care
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <IconButton component={Link} to={`/assessments/${id}/sections/4/4.1`}>
                            <VisibilityIcon color="primary" />
                          </IconButton>
                          <IconButton component={Link} to={`/assessments/${id}/sections/4/4.1?edit=true`}>
                            <EditIcon color="primary" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <TableRow hover>
                        <TableCell>
                          <Typography className="submenu" variant="body1">
                            4.2: Delivery Services
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <IconButton component={Link} to={`/assessments/${id}/sections/4/4.2`}>
                            <VisibilityIcon color="primary" />
                          </IconButton>
                          <IconButton component={Link} to={`/assessments/${id}/sections/4/4.2?edit=true`}>
                            <EditIcon color="primary" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <TableRow hover>
                        <TableCell>
                          <Typography className="submenu" variant="body1">
                            4.3: Postnatal Care
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <IconButton component={Link} to={`/assessments/${id}/sections/4/4.3`}>
                            <VisibilityIcon color="primary" />
                          </IconButton>
                          <IconButton component={Link} to={`/assessments/${id}/sections/4/4.3?edit=true`}>
                            <EditIcon color="primary" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <TableRow hover>
                        <TableCell>
                          <Typography className="submenu" variant="body1">
                            4.4: Newborn Care
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <IconButton component={Link} to={`/assessments/${id}/sections/4/4.4`}>
                            <VisibilityIcon color="primary" />
                          </IconButton>
                          <IconButton component={Link} to={`/assessments/${id}/sections/4/4.4?edit=true`}>
                            <EditIcon color="primary" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <TableRow hover>
                        <TableCell>
                          <Typography className="submenu" variant="body1">
                            4.5: Family Planning
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <IconButton component={Link} to={`/assessments/${id}/sections/4/4.5`}>
                            <VisibilityIcon color="primary" />
                          </IconButton>
                          <IconButton component={Link} to={`/assessments/${id}/sections/4/4.5?edit=true`}>
                            <EditIcon color="primary" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <TableRow hover>
                        <TableCell>
                          <Typography className="submenu" variant="body1">
                            4.6: Child and Adolescent Health Services
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <IconButton component={Link} to={`/assessments/${id}/sections/4/4.6`}>
                            <VisibilityIcon color="primary" />
                          </IconButton>
                          <IconButton component={Link} to={`/assessments/${id}/sections/4/4.6?edit=true`}>
                            <EditIcon color="primary" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <TableRow hover>
                        <TableCell>
                          <Typography className="submenu" variant="body1">
                            4.7: Equipment, Instruments, and Other Fixtures
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <IconButton component={Link} to={`/assessments/${id}/sections/4/4.7`}>
                            <VisibilityIcon color="primary" />
                          </IconButton>
                          <IconButton component={Link} to={`/assessments/${id}/sections/4/4.7?edit=true`}>
                            <EditIcon color="primary" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <TableRow hover>
                        <TableCell>
                          <Typography className="submenu" variant="body1">
                            4.8: Medicines, Supplies and Commodities
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <IconButton component={Link} to={`/assessments/${id}/sections/4/4.8`}>
                            <VisibilityIcon color="primary" />
                          </IconButton>
                          <IconButton component={Link} to={`/assessments/${id}/sections/4/4.8?edit=true`}>
                            <EditIcon color="primary" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    </tbody>
                  </Table>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <ImageDialog
        content="Lorem ipsum"
        open={signatureDialogOpen}
        setShowModal={setSignatureDialogOpen}
        title="Attestor’s Signature"
        src={assessment.reviewer1_signature_image_url}
      />
      <AssessmentReviewerModal
        id={id}
        isSubmitting={isSubmitting}
        open={reviewerDialogOpen}
        users={users}
        setOpen={setReviewerDialogOpen}
        onSubmit={props.assessmentReview}
        user={assessmentReviewer}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  assessments: state.assessments,
  isLoading: state.messages.isLoading,
  isSubmitting: state.messages.isSubmitting,
  payload: state.messages.payload,
  users: Object.values(state.users)
});

export default connect(mapStateToProps, {
  assessmentReview,
  fetchAssessment,
  fetchUsers,
  updateAssessmentStatus
})(AssessmentOverview);
