export default {
  iron_and_folic_acid_tablet_available: null,
  iron_and_folic_acid_tablet_rso: null,
  tetanus_vaccine_available: null,
  tetanus_vaccine_rso: null,
  calcium_carbonate_available: null,
  calcium_carbonate_rso: null,
  methyldopa_tablet_available: null,
  methyldopa_tablet_rso: null,
  amoxicillin_capsule_available: null,
  amoxicillin_capsule_rso: null,
  antibiotics_for_bacterial_sti_available: null,
  antibiotics_for_bacterial_sti_rso: null,
  anti_malarials_for_pregnant_women_available: null,
  anti_malarials_for_pregnant_women_rso: null,
  anti_retrovirals_available: null,
  anti_retrovirals_rso: null,
  medical_exam_gloves_available: null,
  medical_exam_gloves_rso_available: null,
  water_based_lubricant_available: null,
  water_based_lubricant_rso: null
};
