export const procedures = [
  {
    label: 'Vitamin K injection',
    id: 'vitamin_k_injection'
  },
  {
    label: 'Eye prophylaxis',
    id: 'eye_prophylaxis'
  },
  {
    label: 'Hepatitis B injection within 24 hours of life',
    id: 'hepatitis_b_injection_within_24_hrs'
  },
  {
    label: 'BCG injection within 24 hours of life',
    id: 'bcg_injection_within_24_hrs'
  },
  {
    label: 'BCG injection after 24 hours of life',
    id: 'bcg_injection_after_24_hrs'
  },
  {
    label: 'Weighing of the newborn and measurement of length',
    id: 'weighing_of_the_newborn'
  },
  {
    label: 'Physical examination of the baby',
    id: 'physical_exam_of_baby'
  },
  {
    label: 'Parenteral Antibiotics for Babies whose mothers had premature rupture of membranes',
    id: 'parenteral_antibiotics_for_moms_with_premature_membrane_rupture'
  },
  {
    label: 'Parenteral Antibiotics for Meconium-Stained Babies',
    id: 'parenteral_antibiotics_for_meconium_stained_babies'
  },
  {
    label: 'Parenteral Antibiotics for Neonatal Sepsis',
    id: 'parenteral_antibiotics_for_neonatal_sepsis'
  },
  {
    label: 'Newborn Screening / Expanded Newborn Screening',
    id: 'newborn_screening'
  },
  {
    label: 'Newborn Hearing Screening',
    id: 'newborn_hearing_screening'
  },
  {
    label: 'Apgar Scoring',
    id: 'apgar_scoring'
  },
  {
    label: 'Ballard Scoring',
    id: 'ballard_scoring'
  },
];

export const guidelines = [
  {
    label: 'EINC Clinical Practical Pocket Guide or equivalent',
    id: 'einc_pocket_guide_or_equivalent'
  }
];

export const trainings = [
  {
    label: 'Newborn Screening',
    id: 'newborn_screening_training'
  },
  {
    label: 'Infant and Young Child Feeding / Lactation Management Training',
    id: 'infant_and_young_child_feeding_training'
  },
  {
    label: 'Expanded Program on Immunization / National Immunization Program',
    id: 'expanded_program_on_immunization_training'
  }
];
