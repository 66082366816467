import React from 'react';
import { Box, TableContainer } from '@mui/material/';
import {
  routines,
  guidelines,
  training,
  observations
} from 'src/constants/fields/section4/postnatalCare';
import YesNoTable from '../tables/YesNoTable';
import ObservationTable from '../tables/ObservationTable';

const Section403 = (props) => {
  const { provinces, data } = props;

  return (
    <TableContainer>
      <YesNoTable
        ariaLabel="4.3 postnatal care routines"
        provinces={provinces}
        rows={routines}
        response={data}
        title="Routines as part of postnatal care"
        sectionKey="postnatal_care"
      />
      <Box sx={{ height: 40 }} />
      <YesNoTable
        ariaLabel="4.3 postnatal care guidelines"
        provinces={provinces}
        rows={guidelines}
        response={data}
        title="National guidelines, job-aids, checklist, charts for postpartum care"
        sectionKey="postnatal_care"
      />
      <Box sx={{ height: 40 }} />
      <YesNoTable
        ariaLabel="4.3 postnatal care trained personnels"
        provinces={provinces}
        rows={training}
        response={data}
        title="Health workers assigned trained in the following within the past 5 years"
        sectionKey="postnatal_care"
      />
      <Box sx={{ height: 40 }} />
      <ObservationTable
        ariaLabel="4.3 postnatal care observations"
        provinces={provinces}
        rows={observations}
        response={data}
        sectionKey="postnatal_care"
      />
    </TableContainer>
  );
};

export default Section403;
