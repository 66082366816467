import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Autocomplete,
  Box,
  Button,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import { DatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import moment from 'moment';

const AssignmentForm = (props) => {
  const {
    facility, handleClose, isSubmitting, users
  } = props;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        assessor: props.assessor || null,
        startDate: props.startDate || null,
        deadline: props.deadline || null
      }}
      onSubmit={(values) => {
        const form = {
          facility_id: facility.id,
          assessor_id: values.assessor.id,
          date: values.startDate
        };
        props.onSubmit(form);
        props.handleClose();
      }}
      validationSchema={Yup.object().shape({
        assessor: Yup.object().nullable().required('Assessor cannot be blank'),
      })}
    >
      {({
        errors,
        handleSubmit,
        setFieldValue,
        touched,
        values
      }) => (
        <form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="subtitle2">
                  Assessor&apos;s Details
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  name="assessor"
                  options={users}
                  value={values.assessor}
                  onChange={(event, newValue) => {
                    setFieldValue('assessor', newValue, false);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Assessor"
                      variant="outlined"
                      padding="none"
                      required
                      error={Boolean(touched.assessor && errors.assessor)}
                      helperText={touched.assessor && errors.assessor}
                    />
                  )}
                  disableClearable
                  disabled={isSubmitting}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      {...props}
                    >
                      <div style={{ fontWeight: 400, width: '100%' }}>
                        {`${option.first_name} ${option.last_name}`}
                      </div>
                      <Typography variant="body2" color="textSecondary">
                        {option.email}
                      </Typography>
                    </Box>
                  )}
                  getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
                />
              </Grid>
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disabled={isSubmitting}
                    label="Assessment Start Date"
                    name="date"
                    value={values.startDate}
                    onChange={(newValue) => {
                      setFieldValue('startDate', moment(newValue).format('YYYY-MM-DD'));
                    }}
                    views={['year', 'month', 'day']}
                    error={Boolean(touched.startDate && errors.startDate)}
                    helperText={touched.startDate && errors.startDate}
                    required
                    renderInput={(params) => <TextField {...params} fullWidth />}
                    inputFormat="yyyy-MM-dd"
                    mask="____-__-__"
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button disabled={isSubmitting} onClick={handleClose}>
              Cancel
            </Button>
            <Button disabled={isSubmitting} type="submit">
              Confirm Assignment
            </Button>
          </DialogActions>
        </form>
      )}
    </Formik>
  );
};

export default AssignmentForm;
