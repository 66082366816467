import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  CircularProgress,
  Container,
  Fab,
  LinearProgress,
  Typography
} from '@mui/material';
import BarChartIcon from '@mui/icons-material/BarChart';
import RefreshIcon from '@mui/icons-material/Refresh';
import ReportModal from 'src/components/dashboard/ReportModal';
import ReportList from 'src/components/dashboard/ReportList';
import api from 'src/apis/api';
import { getAccessToken, throwError, tokenConfig } from 'src/actions';
import { fetchProvinces, fetchChildren } from 'src/actions/places';

const Dashboard = (props) => {
  const dispatch = useDispatch();
  const {
    accessToken, provinces, user
  } = props;
  const [loading, setLoading] = useState(false);
  const [loadingReports, setLoadingReports] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [menuPlaces, setMenuPlaces] = useState([]);
  // 1229 is the ID for Region 11, our default place
  const [menuPlaceID, setMenuPlaceID] = useState(1229);
  const [state, setState] = useState({
    section100: false,
    section200: false,
    section201: false,
    section202: false,
    section203: false,
    section300: false,
    section301: false,
    section311: false,
    section312: false,
    section302: false,
    section303: false,
    section304: false,
    section400: false,
    section401: false,
    section402: false,
    section403: false,
    section404: false,
    section405: false,
    section406: false,
    section461: false,
    section462: false,
    section463: false,
    section464: false,
    section407: false,
    section471: false,
    section472: false,
    section473: false,
    section474: false,
    section475: false,
    section476: false,
    section477: false,
    section408: false,
    section481: false,
    section482: false,
    section483: false,
    section484: false,
    section485: false,
    section486: false,
    section487: false
  });

  const subsections2 = [
    state.section201,
    state.section202,
    state.section203,
  ];

  const subsections3 = [
    state.section301,
    state.section311,
    state.section312,
    state.section302,
    state.section303,
    state.section304
  ];

  const subsections4 = [
    state.section401,
    state.section402,
    state.section403,
    state.section404,
    state.section405,
    state.section406,
    state.section461,
    state.section462,
    state.section463,
    state.section464,
    state.section407,
    state.section471,
    state.section472,
    state.section473,
    state.section474,
    state.section475,
    state.section476,
    state.section477,
    state.section408,
    state.section481,
    state.section482,
    state.section483,
    state.section484,
    state.section485,
    state.section486,
    state.section487
  ];

  const navigate = useNavigate();

  const [hasFetchedProvinces, setHasFetchedProvinces] = useState(false);
  const [newReport, setNewReport] = useState(false);

  const [reports, setReports] = useState([]);

  const fetchReports = async () => {
    setLoadingReports(true);
    const token = tokenConfig(accessToken);
    const response = await api.get('/v1/reports/', token);
    setReports(response.data);
    setLoadingReports(false);
  };

  useEffect(async () => {
    fetchReports();
    await props.fetchProvinces();
    setHasFetchedProvinces(true);
  }, []);

  useEffect(() => {
    if (!hasFetchedProvinces) {
      setMenuPlaces(provinces);
    }
  }, [provinces]);

  useEffect(() => {
    if (user && !user.is_dashboard_user) {
      navigate('/account', { replace: true });
    }
  }, [user]);

  const handleViewReports = () => {
    setOpenModal(true);
  };

  const createReport = async (sections, provinceId) => {
    const token = tokenConfig(accessToken);
    const requestBody = { sections, place: provinceId };
    await api.post('/v1/reports/', requestBody, token);
    fetchReports();
  };

  const generateReports = async () => {
    setLoading(true);

    const requestData = async (placeID) => {
      try {
        const sections = [];
        if (state.section100) {
          sections.push('facility_identification');
        }
        if (state.section201) {
          sections.push('general_mnchn_services');
        }
        if (state.section202) {
          sections.push('mnchn_outcome_indicators');
        }
        if (state.section203) {
          sections.push('healthcare_personnel');
        }
        if (state.section311) {
          sections.push('licensing_and_certification');
        }
        if (state.section312) {
          sections.push('physical_structure');
        }
        if (state.section302) {
          sections.push('basic_supplies_and_equipment');
        }
        if (state.section303) {
          sections.push('precautions_and_waste_management');
        }
        if (state.section304) {
          sections.push('policies');
        }
        if (state.section401) {
          sections.push('antenatal_care');
        }
        if (state.section402) {
          sections.push('delivery_services');
        }
        if (state.section403) {
          sections.push('postnatal_care');
        }
        if (state.section404) {
          sections.push('newborn_care');
        }
        if (state.section405) {
          sections.push('family_planning');
        }
        if (state.section461) {
          sections.push('child_vaccination');
        }
        if (state.section462) {
          sections.push('child_growth_monitoring');
        }
        if (state.section463) {
          sections.push('child_preventive_and_curative_care');
        }
        if (state.section464) {
          sections.push('adolescent_health');
        }
        if (state.section471) {
          sections.push('antenatal_care_equipment');
        }
        if (state.section472) {
          sections.push('delivery_services_equipment');
        }
        if (state.section473) {
          sections.push('newborn_care_equipment');
        }
        if (state.section474) {
          sections.push('family_planning_equipment');
        }
        if (state.section475) {
          sections.push('child_vaccination_equipment');
        }
        if (state.section476) {
          sections.push('child_growth_monitoring_equipment');
        }
        if (state.section477) {
          sections.push('child_preventive_and_curative_care_equipment');
        }
        if (state.section481) {
          sections.push('antenatal_care_supplies');
        }
        if (state.section482) {
          sections.push('delivery_services_supplies');
        }
        if (state.section483) {
          sections.push('postnatal_care_supplies');
        }
        if (state.section484) {
          sections.push('newborn_care_supplies');
        }
        if (state.section485) {
          sections.push('family_planning_supplies');
        }
        if (state.section486) {
          sections.push('child_vaccination_supplies');
        }
        if (state.section487) {
          sections.push('child_preventive_and_curative_care_supplies');
        }
        if (sections.length > 0) await createReport(sections, placeID);
      } catch (e) {
        if (e.response && e.response.state === 401) {
          getAccessToken();
          generateReports();
        } else {
          setLoading(false);
          throwError(e, dispatch);
        }
      }
    };

    await requestData(menuPlaceID);
    setLoading(false);
  };

  useEffect(() => {
    if (newReport && !loading) {
      generateReports();
      setNewReport(false);
    }
  }, [newReport]);

  const handleSelectPlace = (placeID) => {
    setMenuPlaceID(placeID);
  };

  if (user && user.is_dashboard_user) {
    return (
      <>
        {loading && <LinearProgress />}
        <Helmet>
          <title>Dashboard - FReSAA</title>
        </Helmet>
        <Box>
          {loadingReports && <LinearProgress />}
          <Container>
            {reports.length !== 0 && (
              <>
                <Fab
                  variant="extended"
                  size="medium"
                  aria-label="refresh"
                  onClick={fetchReports}
                  sx={{ mt: 3 }}
                >
                  <RefreshIcon sx={{ mr: 1 }} />
                  Refresh
                </Fab>
                <Typography
                  sx={{ fontStyle: 'italic', pt: 3 }}
                >
                  Note: Report generation may take up to 10 minutes. You will receive an email once the report is available.
                </Typography>
                <ReportList fetchReports={fetchReports} reports={reports} />
              </>
            )}
          </Container>
        </Box>
        <Fab
          variant="extended"
          size="medium"
          color="primary"
          aria-label="view reports"
          sx={{ position: 'absolute', bottom: 20, right: 20 }}
          onClick={handleViewReports}
        >
          <BarChartIcon sx={{ mr: 1 }} />
          Generate Report
        </Fab>
        <ReportModal
          handleSelectPlace={handleSelectPlace}
          menuPlaces={menuPlaces}
          open={openModal}
          state={state}
          setOpenModal={setOpenModal}
          setState={setState}
          generateReports={async () => {
            setLoading(true);
            await props.fetchChildren(menuPlaceID);
            setLoading(false);
            setNewReport(true);
          }}
          subsections2={subsections2}
          subsections3={subsections3}
          subsections4={subsections4}
        />
      </>
    );
  }

  return (
    <Box className="spinner-container">
      <CircularProgress />
    </Box>
  );
};

const mapStateToProps = (state) => ({
  provinces: Object.values(state.provinces),
  accessToken: state.auth.accessToken,
  user: state.auth.user
});

export default connect(mapStateToProps, {
  fetchProvinces, fetchChildren
})(Dashboard);
