import React from 'react';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  LinearProgress,
  Typography
} from '@mui/material';
import {
  Edit as EditIcon,
  Save as SaveIcon
} from '@mui/icons-material';
import newbornCare from 'src/fields/newbornCare';
import {
  BARANGAY_HEALTH_STATION,
  NUTRITION_POST,
  RURAL_HEALTH_UNIT,
  AUX_FACILITY
} from 'src/constants/facilityTypes';
import YesOrNoFormControl from '../formSections/YesOrNoFormControl';

const NewbornCare = (props) => {
  const {
    facilityType,
    formValues,
    isLoading,
    isSubmitting,
    readOnly,
    setReadOnly
  } = props;

  const handleEditClick = () => {
    setReadOnly(false);
  };

  const handleSaveClick = (handleSubmit) => {
    handleSubmit();
    setReadOnly(true);
  };

  const initialValues = {
    ...newbornCare,
    ...formValues
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => {
        props.onSubmit('newborn_care', values);
      }}
    >
      {({
        handleSubmit,
        setFieldValue,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardContent>
              <Box className="form-header">
                <Typography variant="h4" gutterBottom>
                  4.4 Newborn Care
                </Typography>
                {readOnly ? (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleEditClick()}
                  >
                    <EditIcon size="medium" />
                  </IconButton>
                ) : (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleSaveClick(handleSubmit)}
                  >
                    <SaveIcon size="medium" />
                  </IconButton>
                )}
              </Box>
            </CardContent>
            <Divider />
            {(facilityType !== NUTRITION_POST && facilityType !== AUX_FACILITY) ? (
              <>
                <CardContent sx={{ p: 5 }}>
                  {facilityType !== BARANGAY_HEALTH_STATION && (
                    <>
                      <Typography color="textSecondary" variant="h5" gutterBottom>
                        Are the following procedures routinely performed by providers of newborn care in this facility?
                      </Typography>
                      <Box sx={{ p: 2 }}>
                        {facilityType !== RURAL_HEALTH_UNIT && (
                          <>
                            <YesOrNoFormControl
                              disabled={readOnly}
                              label="Vitamin K injection"
                              fieldName="vitamin_k_injection"
                              fieldValue={values.vitamin_k_injection}
                              setFieldValue={setFieldValue}
                            />
                            <YesOrNoFormControl
                              disabled={readOnly}
                              label="Eye prophylaxis"
                              fieldName="eye_prophylaxis"
                              fieldValue={values.eye_prophylaxis}
                              setFieldValue={setFieldValue}
                            />
                            <YesOrNoFormControl
                              disabled={readOnly}
                              label="Hepatitis B injection within 24 hours of life"
                              fieldName="hepatitis_b_injection_within_24_hrs"
                              fieldValue={values.hepatitis_b_injection_within_24_hrs}
                              setFieldValue={setFieldValue}
                            />
                            <YesOrNoFormControl
                              disabled={readOnly}
                              label="BCG injection within 24 hours of life"
                              fieldName="bcg_injection_within_24_hrs"
                              fieldValue={values.bcg_injection_within_24_hrs}
                              setFieldValue={setFieldValue}
                            />
                          </>
                        )}
                        {facilityType === RURAL_HEALTH_UNIT && (
                          <YesOrNoFormControl
                            disabled={readOnly}
                            label="BCG injection after 24 hours of life"
                            fieldName="bcg_injection_after_24_hrs"
                            fieldValue={values.bcg_injection_after_24_hrs}
                            setFieldValue={setFieldValue}
                          />
                        )}
                        <YesOrNoFormControl
                          disabled={readOnly}
                          label="Weighing of the newborn"
                          fieldName="weighing_of_the_newborn"
                          fieldValue={values.weighing_of_the_newborn}
                          setFieldValue={setFieldValue}
                        />
                        <YesOrNoFormControl
                          disabled={readOnly}
                          label="Physical examination of the baby"
                          fieldName="physical_exam_of_baby"
                          fieldValue={values.physical_exam_of_baby}
                          setFieldValue={setFieldValue}
                        />
                        {facilityType !== RURAL_HEALTH_UNIT && (
                          <>
                            <YesOrNoFormControl
                              disabled={readOnly}
                              label="Parenteral Antibiotics for Babies whose mothers had Premature Rupture of Membranes"
                              fieldName="parenteral_antibiotics_for_moms_with_premature_membrane_rupture"
                              fieldValue={values.parenteral_antibiotics_for_moms_with_premature_membrane_rupture}
                              setFieldValue={setFieldValue}
                            />
                            <YesOrNoFormControl
                              disabled={readOnly}
                              label="Parenteral Antibiotics for Meconium-Stained Babies"
                              fieldName="parenteral_antibiotics_for_meconium_stained_babies"
                              fieldValue={values.parenteral_antibiotics_for_meconium_stained_babies}
                              setFieldValue={setFieldValue}
                            />
                            <YesOrNoFormControl
                              disabled={readOnly}
                              label="Parenteral Antibiotics for Neonatal Sepsis"
                              fieldName="parenteral_antibiotics_for_neonatal_sepsis"
                              fieldValue={values.parenteral_antibiotics_for_neonatal_sepsis}
                              setFieldValue={setFieldValue}
                            />
                            <YesOrNoFormControl
                              disabled={readOnly}
                              label="Newborn Screening / Expanded Newborn Screening"
                              fieldName="newborn_screening"
                              fieldValue={values.newborn_screening}
                              setFieldValue={setFieldValue}
                            />
                            <YesOrNoFormControl
                              disabled={readOnly}
                              label="Newborn Hearing Screening"
                              fieldName="newborn_hearing_screening"
                              fieldValue={values.newborn_hearing_screening}
                              setFieldValue={setFieldValue}
                            />
                            <YesOrNoFormControl
                              disabled={readOnly}
                              label="Apgar Scoring"
                              fieldName="apgar_scoring"
                              fieldValue={values.apgar_scoring}
                              setFieldValue={setFieldValue}
                            />
                            <YesOrNoFormControl
                              disabled={readOnly}
                              label="Ballard Scoring"
                              fieldName="ballard_scoring"
                              fieldValue={values.ballard_scoring}
                              setFieldValue={setFieldValue}
                            />
                          </>
                        )}
                      </Box>
                      <span className="whitespace" />
                    </>
                  )}
                  {facilityType !== BARANGAY_HEALTH_STATION && (
                    <>
                      <Typography color="textSecondary" variant="h5" gutterBottom>
                        Are there any newborn care national guidelines, job-aids, charts, checklist, posters in this facility today?
                      </Typography>
                      <Box sx={{ p: 2 }}>
                        <YesOrNoFormControl
                          disabled={readOnly}
                          label="EINC Clinical Practical Pocket Guide or equivalent"
                          fieldName="einc_pocket_guide_or_equivalent"
                          fieldValue={values.einc_pocket_guide_or_equivalent}
                          setFieldValue={setFieldValue}
                        />
                      </Box>
                      <span className="whitespace" />
                    </>
                  )}
                  <Typography color="textSecondary" variant="h5" gutterBottom>
                    Have you or any health worker assigned in this facility been trained in the following within the past 5 years?
                  </Typography>
                  <Box sx={{ p: 2 }}>
                    {facilityType !== 2 && (
                      <YesOrNoFormControl
                        disabled={readOnly}
                        label="Newborn Screening"
                        fieldName="newborn_screening_training"
                        fieldValue={values.newborn_screening_training}
                        setFieldValue={setFieldValue}
                      />
                    )}
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Infant and Young Child Feeding / Lactation Management Training"
                      fieldName="infant_and_young_child_feeding_training"
                      fieldValue={values.infant_and_young_child_feeding_training}
                      setFieldValue={setFieldValue}
                    />
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Expanded Program on Immunization / National Immunizaiton Program"
                      fieldName="expanded_program_on_immunization_training"
                      fieldValue={values.expanded_program_on_immunization_training}
                      setFieldValue={setFieldValue}
                    />
                  </Box>
                </CardContent>
                <Divider />
                <Box className="box-button">
                  <Button
                    disabled={isLoading || isSubmitting || !readOnly}
                    color="primary"
                    onClick={() => handleEditClick()}
                    variant="contained"
                    sx={{ mx: 2 }}
                  >
                    Edit
                  </Button>
                  <Button
                    disabled={isLoading || isSubmitting || readOnly}
                    color="primary"
                    onClick={() => handleSaveClick(handleSubmit)}
                    type="submit"
                    variant="contained"
                  >
                    Save
                  </Button>
                </Box>
              </>
            ) : (
              <CardContent>
                <Typography variant="subtitle1" color="textSecondary">
                  This section is disabled for this facility.
                </Typography>
              </CardContent>
            )}
            {(isLoading || isSubmitting) && <LinearProgress />}
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default NewbornCare;
