import React from 'react';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  LinearProgress,
  Typography
} from '@mui/material';
import {
  Edit as EditIcon,
  Save as SaveIcon
} from '@mui/icons-material';
import {
  INFIRMARY,
  BARANGAY_HEALTH_STATION,
  SAFE_BIRTHING_FACILITY,
  NUTRITION_POST,
  RURAL_HEALTH_UNIT,
  AUX_FACILITY
} from 'src/constants/facilityTypes';
import deliveryServicesEquipmentsFields from 'src/fields/deliveryServicesEquipmentsFields';
import AvailabilityFunctioningFormGroup from '../../formSections/AvailabilityFunctioningFormGroup';

const DeliveryServicesEquipment = (props) => {
  const {
    facilityType,
    formValues,
    isLoading,
    isSubmitting,
    readOnly,
    setReadOnly
  } = props;

  const handleEditClick = () => {
    setReadOnly(false);
  };

  const handleSaveClick = (handleSubmit) => {
    handleSubmit();
    setReadOnly(true);
  };

  const initialValues = {
    ...deliveryServicesEquipmentsFields,
    ...formValues
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => {
        props.onSubmit('delivery_services_equipment', values);
      }}
    >
      {({
        handleSubmit,
        setFieldValue,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardContent>
              <Box className="form-header">
                <Typography variant="h4" gutterBottom>
                  Delivery Services
                </Typography>
                {readOnly ? (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleEditClick()}
                  >
                    <EditIcon size="medium" />
                  </IconButton>
                ) : (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleSaveClick(handleSubmit)}
                  >
                    <SaveIcon size="medium" />
                  </IconButton>
                )}
              </Box>
            </CardContent>
            <Divider />
            <CardContent>
              <Box sx={{ p: 2 }}>
                <AvailabilityFunctioningFormGroup
                  disabled={readOnly}
                  label="Adult Weighing Scale"
                  fieldName="adult_weighing_scale_available"
                  fieldValue={values.adult_weighing_scale_available}
                  fieldFunctioningName="adult_weighing_scale_functioning"
                  fieldFunctioningValue={values.adult_weighing_scale_functioning}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityFunctioningFormGroup
                  disabled={readOnly}
                  label="Infant Weighing Scale"
                  fieldName="infant_weighing_scale_available"
                  fieldValue={values.infant_weighing_scale_available}
                  fieldFunctioningName="infant_weighing_scale_functioning"
                  fieldFunctioningValue={values.infant_weighing_scale_functioning}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityFunctioningFormGroup
                  disabled={readOnly}
                  label="Sphymomanometer, Non-Mercury"
                  fieldName="non_mercury_sphygmomanometer_available"
                  fieldValue={values.non_mercury_sphygmomanometer_available}
                  fieldFunctioningName="non_mercury_sphygmomanometer_functioning"
                  fieldFunctioningValue={values.non_mercury_sphygmomanometer_functioning}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityFunctioningFormGroup
                  disabled={readOnly}
                  label="Stethoscope, Adult"
                  fieldName="stethoscope_for_adults_available"
                  fieldValue={values.stethoscope_for_adults_available}
                  fieldFunctioningName="stethoscope_for_adults_functioning"
                  fieldFunctioningValue={values.stethoscope_for_adults_functioning}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityFunctioningFormGroup
                  disabled={readOnly}
                  label="Stethoscope, for Pediatric Use"
                  fieldName="stethoscope_for_pediatric_use_available"
                  fieldValue={values.stethoscope_for_pediatric_use_available}
                  fieldFunctioningName="stethoscope_for_pediatric_use_functioning"
                  fieldFunctioningValue={values.stethoscope_for_pediatric_use_functioning}
                  setFieldValue={setFieldValue}
                />
                {(facilityType !== BARANGAY_HEALTH_STATION && facilityType !== NUTRITION_POST && facilityType !== AUX_FACILITY) && (
                  <>
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Delivery Table with pail"
                      fieldName="delivery_table_with_pail_available"
                      fieldValue={values.delivery_table_with_pail_available}
                      fieldFunctioningName="delivery_table_with_pail_functioning"
                      fieldFunctioningValue={values.delivery_table_with_pail_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Newborn Resuscitation Table or equivalent"
                      fieldName="newborn_resuscitation_table_available"
                      fieldValue={values.newborn_resuscitation_table_available}
                      fieldFunctioningName="newborn_resuscitation_table_functioning"
                      fieldFunctioningValue={values.newborn_resuscitation_table_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Examination or Patient Bed with clean sheets/linen and footstool"
                      fieldName="examination_or_patient_bed_available"
                      fieldValue={values.examination_or_patient_bed_available}
                      fieldFunctioningName="examination_or_patient_bed_functioning"
                      fieldFunctioningValue={values.examination_or_patient_bed_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Delivery Pack (Consists of 2 Hemostat Forceps, 1 Needle Holder, 1 Surgical Scissor, 1 Tissue Forceps)"
                      fieldName="delivery_pack_available"
                      fieldValue={values.delivery_pack_available}
                      fieldFunctioningName="delivery_pack_functioning"
                      fieldFunctioningValue={values.delivery_pack_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Bandage scissors"
                      fieldName="bandage_scissors_available"
                      fieldValue={values.bandage_scissors_available}
                      fieldFunctioningName="bandage_scissors_functioning"
                      fieldFunctioningValue={values.bandage_scissors_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Thumb forceps"
                      fieldName="thumb_forceps_available"
                      fieldValue={values.thumb_forceps_available}
                      fieldFunctioningName="thumb_forceps_functioning"
                      fieldFunctioningValue={values.thumb_forceps_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Umbilical cord scissors"
                      fieldName="umbilical_cord_scissors_available"
                      fieldValue={values.umbilical_cord_scissors_available}
                      fieldFunctioningName="umbilical_cord_scissors_functioning"
                      fieldFunctioningValue={values.umbilical_cord_scissors_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Uterine sound"
                      fieldName="uterine_sound_available"
                      fieldValue={values.uterine_sound_available}
                      fieldFunctioningName="uterine_sound_functioning"
                      fieldFunctioningValue={values.uterine_sound_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Disposable delivery kit (sterile cord clip or ties, plastic sheet to place under mother and sterile blade)"
                      fieldName="disposable_delivery_kit_available"
                      fieldValue={values.disposable_delivery_kit_available}
                      fieldFunctioningName="disposable_delivery_kit_functioning"
                      fieldFunctioningValue={values.disposable_delivery_kit_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Instrument Tray with Cover"
                      fieldName="instrument_tray_with_cover_available"
                      fieldValue={values.instrument_tray_with_cover_available}
                      fieldFunctioningName="instrument_tray_with_cover_functioning"
                      fieldFunctioningValue={values.instrument_tray_with_cover_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Kidney basin"
                      fieldName="kidney_basin_available"
                      fieldValue={values.kidney_basin_available}
                      fieldFunctioningName="kidney_basin_functioning"
                      fieldFunctioningValue={values.kidney_basin_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Instrument Cabinet"
                      fieldName="instrument_cabinet_available"
                      fieldValue={values.instrument_cabinet_available}
                      fieldFunctioningName="instrument_cabinet_functioning"
                      fieldFunctioningValue={values.instrument_cabinet_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Instrument Table or equivalent"
                      fieldName="instrument_table_available"
                      fieldValue={values.instrument_table_available}
                      fieldFunctioningName="instrument_table_functioning"
                      fieldFunctioningValue={values.instrument_table_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Fetal Doppler"
                      fieldName="fetal_doppler_available"
                      fieldValue={values.fetal_doppler_available}
                      fieldFunctioningName="fetal_doppler_functioning"
                      fieldFunctioningValue={values.fetal_doppler_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Vaginal speculum (medium or large)"
                      fieldName="vaginal_speculum_available"
                      fieldValue={values.vaginal_speculum_available}
                      fieldFunctioningName="vaginal_speculum_functioning"
                      fieldFunctioningValue={values.vaginal_speculum_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Sponge Forceps"
                      fieldName="sponge_forceps_available"
                      fieldValue={values.sponge_forceps_available}
                      fieldFunctioningName="sponge_forceps_functioning"
                      fieldFunctioningValue={values.sponge_forceps_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Suction Apparatus"
                      fieldName="suction_apparatus_available"
                      fieldValue={values.suction_apparatus_available}
                      fieldFunctioningName="suction_apparatus_functioning"
                      fieldFunctioningValue={values.suction_apparatus_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Suction catheter"
                      fieldName="suction_catheter_available"
                      fieldValue={values.suction_catheter_available}
                      fieldFunctioningName="suction_catheter_functioning"
                      fieldFunctioningValue={values.suction_catheter_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Rubber Suction Bulb / Penguin"
                      fieldName="rubber_suction_bulb_available"
                      fieldValue={values.rubber_suction_bulb_available}
                      fieldFunctioningName="rubber_suction_bulb_functioning"
                      fieldFunctioningValue={values.rubber_suction_bulb_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Ambu bag or self-inflating bag (adult and pediatric)"
                      fieldName="ambu_bag_available"
                      fieldValue={values.ambu_bag_available}
                      fieldFunctioningName="ambu_bag_functioning"
                      fieldFunctioningValue={values.ambu_bag_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Oxygen Tank with Regulator and Humidifier"
                      fieldName="o2_tank_with_regulator_and_humidifier_available"
                      fieldValue={values.o2_tank_with_regulator_and_humidifier_available}
                      fieldFunctioningName="o2_tank_with_regulator_and_humidifier_functioning"
                      fieldFunctioningValue={values.o2_tank_with_regulator_and_humidifier_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Cone Mask, may be improvised"
                      fieldName="cone_mask_available"
                      fieldValue={values.cone_mask_available}
                      fieldFunctioningName="cone_mask_functioning"
                      fieldFunctioningValue={values.cone_mask_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Newborn Size Mask 0 and 1"
                      fieldName="newborn_size_mask_0_and_1_available"
                      fieldValue={values.newborn_size_mask_0_and_1_available}
                      fieldFunctioningName="newborn_size_mask_0_and_1_functioning"
                      fieldFunctioningValue={values.newborn_size_mask_0_and_1_functioning}
                      setFieldValue={setFieldValue}
                    />
                  </>
                )}
                {(facilityType !== SAFE_BIRTHING_FACILITY && facilityType !== NUTRITION_POST && (
                  <AvailabilityFunctioningFormGroup
                    disabled={readOnly}
                    label="Nebulizer (portable)"
                    fieldName="portable_nebulizer_available"
                    fieldValue={values.portable_nebulizer_available}
                    fieldFunctioningName="portable_nebulizer_functioning"
                    fieldFunctioningValue={values.portable_nebulizer_functioning}
                    setFieldValue={setFieldValue}
                  />
                ))}
                {(facilityType === INFIRMARY || facilityType === RURAL_HEALTH_UNIT) && (
                  <AvailabilityFunctioningFormGroup
                    disabled={readOnly}
                    label="RX Box or similar integrated technology"
                    fieldName="rx_box_available"
                    fieldValue={values.rx_box_available}
                    fieldFunctioningName="rx_box_functioning"
                    fieldFunctioningValue={values.rx_box_functioning}
                    setFieldValue={setFieldValue}
                  />
                )}
                {(facilityType !== BARANGAY_HEALTH_STATION && facilityType !== NUTRITION_POST && facilityType !== AUX_FACILITY) && (
                  <>
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Examination Light (Gooseneck Lamp or equivalent)"
                      fieldName="examination_light_available"
                      fieldValue={values.examination_light_available}
                      fieldFunctioningName="examination_light_functioning"
                      fieldFunctioningValue={values.examination_light_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Room thermometer"
                      fieldName="romm_thermometer_available"
                      fieldValue={values.romm_thermometer_available}
                      fieldFunctioningName="romm_thermometer_functioning"
                      fieldFunctioningValue={values.romm_thermometer_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="IV Stand or equivalent"
                      fieldName="iv_stand_available"
                      fieldValue={values.iv_stand_available}
                      fieldFunctioningName="iv_stand_functioning"
                      fieldFunctioningValue={values.iv_stand_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Thermometer, Non-Mercury"
                      fieldName="non_mercury_thermometer_available"
                      fieldValue={values.non_mercury_thermometer_available}
                      fieldFunctioningName="non_mercury_thermometer_functioning"
                      fieldFunctioningValue={values.non_mercury_thermometer_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Tape Measure"
                      fieldName="tape_measure_available"
                      fieldValue={values.tape_measure_available}
                      fieldFunctioningName="tape_measure_functioning"
                      fieldFunctioningValue={values.tape_measure_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Storage containers with cover for dry cotton balls"
                      fieldName="storage_containers_with_cove_for_dry_cotton_balls_available"
                      fieldValue={values.storage_containers_with_cove_for_dry_cotton_balls_available}
                      fieldFunctioningName="storage_containers_with_cove_for_dry_cotton_balls_functioning"
                      fieldFunctioningValue={values.storage_containers_with_cove_for_dry_cotton_balls_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Jar with cover and without cover"
                      fieldName="jar_available"
                      fieldValue={values.jar_available}
                      fieldFunctioningName="jar_functioning"
                      fieldFunctioningValue={values.jar_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Wall clock with seconds hand"
                      fieldName="wall_clock_with_seconds_hand_available"
                      fieldValue={values.wall_clock_with_seconds_hand_available}
                      fieldFunctioningName="wall_clock_with_seconds_hand_functioning"
                      fieldFunctioningValue={values.wall_clock_with_seconds_hand_functioning}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityFunctioningFormGroup
                      disabled={readOnly}
                      label="Slippers for use in Delivery Area"
                      fieldName="slippers_for_delivery_area_available"
                      fieldValue={values.slippers_for_delivery_area_available}
                      fieldFunctioningName="slippers_for_delivery_area_functioning"
                      fieldFunctioningValue={values.slippers_for_delivery_area_functioning}
                      setFieldValue={setFieldValue}
                    />
                  </>
                )}
              </Box>
            </CardContent>
            <Divider />
            <Box className="box-button">
              <Button
                disabled={isLoading || isSubmitting || !readOnly}
                color="primary"
                onClick={() => handleEditClick()}
                variant="contained"
                sx={{ mx: 2 }}
              >
                Edit
              </Button>
              <Button
                disabled={isLoading || isSubmitting || readOnly}
                color="primary"
                onClick={() => handleSaveClick(handleSubmit)}
                type="submit"
                variant="contained"
              >
                Save
              </Button>
            </Box>
            {(isLoading || isSubmitting) && <LinearProgress />}
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default DeliveryServicesEquipment;
