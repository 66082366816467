import React, { useContext } from 'react';
import {
  Link,
  Typography
} from '@mui/material/';
import FacilityListReportContext from 'src/components/dashboard/context';

const TableLink = ({
  field,
  title,
  value,
  readableValue,
  province,
  sectionKey,
  children
}) => {
  const onTableCellClick = useContext(FacilityListReportContext);

  return (
    <Link
      component="button"
      underline="hover"
      onClick={() => onTableCellClick({
        field,
        title,
        value,
        readableValue,
        province,
        sectionKey
      })}
    >
      <Typography variant="body2">
        {children}
      </Typography>
    </Link>
  );
};

export default TableLink;
