import React from 'react';
import { Box, TableContainer } from '@mui/material/';
import {
  services,
  schedule,
  observations
} from 'src/constants/fields/section4/4.6/childGrowthMonitoring';
import YesNoTable from '../../tables/YesNoTable';
import ObservationTable from '../../tables/ObservationTable';
import ScheduleTable from '../../tables/ScheduleTable';

const scheduleHeaders = [
  'Daily/Weekly',
  'Monthly/Quarterly',
  'Annual/Semi-annual',
  'Other'
];

const Section462 = (props) => {
  const { provinces, data } = props;

  return (
    <TableContainer>
      <YesNoTable
        ariaLabel="4.6.2 child growth monitoring services"
        provinces={provinces}
        rows={services}
        response={data}
        title="Growth Monitoring services offered"
        sectionKey="child_growth_monitoring"
      />
      <Box sx={{ height: 40 }} />
      <ScheduleTable
        ariaLabel="4.6.2 child growth monitoring schedule"
        provinces={provinces}
        response={data}
        field={schedule}
        headers={scheduleHeaders}
        sectionKey="child_growth_monitoring"
      />
      <Box sx={{ height: 40 }} />
      <ObservationTable
        ariaLabel="4.6.2 child growth monitoring observations"
        provinces={provinces}
        rows={observations}
        response={data}
        sectionKey="child_growth_monitoring"
      />
    </TableContainer>
  );
};

export default Section462;
