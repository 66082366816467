import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  LinearProgress,
  Typography
} from '@mui/material';
import {
  Edit as EditIcon,
  Save as SaveIcon
} from '@mui/icons-material';
import antenatalCare from 'src/fields/antenatalCare';
import {
  INFIRMARY,
  BARANGAY_HEALTH_STATION,
  SAFE_BIRTHING_FACILITY,
  NUTRITION_POST,
  AUX_FACILITY
} from 'src/constants/facilityTypes';
import RadioGroupFormControl from '../formSections/RadioGroupFormControl';
import YesOrNoFormControl from '../formSections/YesOrNoFormControl';
import HCPFormControl from '../formSections/HCPFormControl';

const availability = [
  {
    label: 'Yes, observed',
    value: 1
  },
  {
    label: 'Reported, not observed',
    value: 2
  },
  {
    label: 'No records kept',
    value: 3
  }
];

const schedule = [
  {
    label: 'Everyday',
    value: 1
  },
  {
    label: 'Once a week',
    value: 2
  },
  {
    label: 'Once a month',
    value: 3
  },
  {
    label: 'Other',
    value: 4
  }
];

const AntenatalCare = (props) => {
  const {
    facilityType,
    formValues,
    isLoading,
    isSubmitting,
    readOnly,
    setReadOnly
  } = props;

  const initialValues = {
    ...antenatalCare,
    ...formValues
  };

  const handleEditClick = () => {
    setReadOnly(false);
  };

  const handleSaveClick = (handleSubmit) => {
    handleSubmit();
    setReadOnly(true);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => {
        props.onSubmit('antenatal_care', values);
      }}
      validationSchema={Yup.object().shape({
        bhws_trained_in_ics_count: Yup.number().integer().min(0, 'Must be greater than 0'),
        bhws_trained_in_community_tracking_tool_count: Yup.number().integer().min(0, 'Must be greater than 0'),
      })}
    >
      {({
        errors,
        handleChange,
        handleSubmit,
        setFieldValue,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardContent>
              <Box className="form-header">
                <Typography variant="h4" gutterBottom>
                  4.1 Antenatal Care
                </Typography>
                {readOnly ? (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleEditClick()}
                  >
                    <EditIcon size="medium" />
                  </IconButton>
                ) : (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleSaveClick(handleSubmit)}
                  >
                    <SaveIcon size="medium" />
                  </IconButton>
                )}
              </Box>
            </CardContent>
            <Divider />
            <CardContent sx={{ p: 5 }}>
              <Typography color="textSecondary" variant="h5" gutterBottom>
                Are the following services available in this facility as part of its Antenatal Care services?
              </Typography>
              <Box sx={{ p: 2 }}>
                {(facilityType !== NUTRITION_POST && facilityType !== AUX_FACILITY) && (
                  <>
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Antenatal physical examination including Leopold's maneuver and fundic heigh measurement"
                      fieldName="antenatal_physical_examination"
                      fieldValue={values.antenatal_physical_examination}
                      setFieldValue={setFieldValue}
                    />
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Iron and Folic Acid Supplementation"
                      fieldName="iron_folic_acid_supplementation"
                      fieldValue={values.iron_folic_acid_supplementation}
                      setFieldValue={setFieldValue}
                    />
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Calcium Carbonate"
                      fieldName="calcium_carbonate"
                      fieldValue={values.calcium_carbonate}
                      setFieldValue={setFieldValue}
                    />
                  </>
                )}
                {facilityType !== SAFE_BIRTHING_FACILITY && (
                  <YesOrNoFormControl
                    disabled={readOnly}
                    label="Basic Oral Health Care"
                    fieldName="basic_oral_health_care"
                    fieldValue={values.basic_oral_health_care}
                    setFieldValue={setFieldValue}
                  />
                )}
                {(facilityType !== INFIRMARY && facilityType !== SAFE_BIRTHING_FACILITY && facilityType !== NUTRITION_POST) && (
                  <YesOrNoFormControl
                    disabled={readOnly}
                    label="Intermittent Preventive Treatment for Malaria (for endemic area only)"
                    fieldName="intermittent_preventive_treatment_for_malaria"
                    fieldValue={values.intermittent_preventive_treatment_for_malaria}
                    setFieldValue={setFieldValue}
                  />
                )}
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Tetanus Toxoid vaccination / Tetnus-diphtheria (Td) vaccination"
                  fieldName="tetanus_vaccination"
                  fieldValue={values.tetanus_vaccination}
                  setFieldValue={setFieldValue}
                />
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Monitoring for Hypertensive Disorder of Pregnancy"
                  fieldName="monitoring_for_hypertensive_disorder_of_pregnancy"
                  fieldValue={values.monitoring_for_hypertensive_disorder_of_pregnancy}
                  setFieldValue={setFieldValue}
                />
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Health education on the danger signs of pregnancy and importance of 4 prenatal visits"
                  fieldName="counseling_on_pregnancy_dangers"
                  fieldValue={values.counseling_on_pregnancy_dangers}
                  setFieldValue={setFieldValue}
                />
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Health education on having a birth plan and facility-based delivery"
                  fieldName="counseling_on_having_a_birth_plan_and_facility_based_delivery"
                  fieldValue={values.counseling_on_having_a_birth_plan_and_facility_based_delivery}
                  setFieldValue={setFieldValue}
                />
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Health education regarding Newborn Screening (Blood and Hearing)"
                  fieldName="health_education_on_newborn_screening"
                  fieldValue={values.health_education_on_newborn_screening}
                  setFieldValue={setFieldValue}
                />
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Health education on Family Planning"
                  fieldName="counseling_on_family_planning"
                  fieldValue={values.counseling_on_family_planning}
                  setFieldValue={setFieldValue}
                />
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Health education on Breastfeeding"
                  fieldName="conseling_on_breastfeeding"
                  fieldValue={values.conseling_on_breastfeeding}
                  setFieldValue={setFieldValue}
                />
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Health education on sexually transmitted infections from mother to child, including HIV"
                  fieldName="counseling_on_sti_from_mother_to_child"
                  fieldValue={values.counseling_on_sti_from_mother_to_child}
                  setFieldValue={setFieldValue}
                />
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Mental Health Screening"
                  fieldName="mental_health_screening"
                  fieldValue={values.mental_health_screening}
                  setFieldValue={setFieldValue}
                />
              </Box>
              <RadioGroupFormControl
                disabled={readOnly}
                label="What is the schedule for delivery of antenatal care services in this facility?"
                fieldName="antenatal_care_services_schedule"
                fieldNameOther="antenatal_care_services_schedule_other_value"
                fieldValue={values.antenatal_care_services_schedule}
                fieldValueOther={values.antenatal_care_services_schedule_other_value}
                options={schedule}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
              />
              <span className="whitespace" />
              {facilityType !== NUTRITION_POST && (
                <>
                  <Typography color="textSecondary" variant="h5" gutterBottom>
                    Are the following national guidelines, job-aids, checklist, charts on antenatal care available in the facility today?
                  </Typography>
                  <Box sx={{ p: 2 }}>
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Quality Assurance Package (QAP) Manual / Pregnancy, Childbirth, Postpartum and Newborn Care (PCPNC) Manual"
                      fieldName="qap_manual_or_pcpnc_manual"
                      fieldValue={values.qap_manual_or_pcpnc_manual}
                      setFieldValue={setFieldValue}
                    />
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Mother-Baby Book"
                      fieldName="mother_baby_book"
                      fieldValue={values.mother_baby_book}
                      setFieldValue={setFieldValue}
                    />
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Maternal Health Record"
                      fieldName="maternal_health_record"
                      fieldValue={values.maternal_health_record}
                      setFieldValue={setFieldValue}
                    />
                  </Box>
                  <span className="whitespace" />
                  <Typography color="textSecondary" variant="h5" gutterBottom>
                    Have you or any health worker assigned in this facility been trained in the following within the past 5 years?
                  </Typography>
                  <Box sx={{ p: 2 }}>
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Quality Assurance Package (QAP) Manual / Pregnancy, Childbirth, Postpartum and Newborn Care (PCPNC)"
                      fieldName="qap_or_pcpnc_training"
                      fieldValue={values.qap_or_pcpnc_training}
                      setFieldValue={setFieldValue}
                    />
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Interpersonnal Communication Skills"
                      fieldName="ics_training"
                      fieldValue={values.ics_training}
                      setFieldValue={setFieldValue}
                    />
                    {(facilityType !== INFIRMARY && facilityType !== SAFE_BIRTHING_FACILITY) && (
                      <YesOrNoFormControl
                        disabled={readOnly}
                        label="Community Tracking Tool"
                        fieldName="community_tracking_tool_training"
                        fieldValue={values.community_tracking_tool_training}
                        setFieldValue={setFieldValue}
                      />
                    )}
                    {(facilityType !== BARANGAY_HEALTH_STATION && facilityType !== AUX_FACILITY) && (
                      <>
                        <YesOrNoFormControl
                          disabled={readOnly}
                          label="Supportive Suppervision"
                          fieldName="supportive_supervision_training"
                          fieldValue={values.supportive_supervision_training}
                          setFieldValue={setFieldValue}
                        />
                        <YesOrNoFormControl
                          disabled={readOnly}
                          label="Lactation Management Training"
                          fieldName="lactation_management_training"
                          fieldValue={values.lactation_management_training}
                          setFieldValue={setFieldValue}
                        />
                        <YesOrNoFormControl
                          disabled={readOnly}
                          label="Diagnosis and Treatment of Malaria in Pregnancy"
                          fieldName="diagnosis_and_treatment_of_malaria_in_pregnancy_training"
                          fieldValue={values.diagnosis_and_treatment_of_malaria_in_pregnancy_training}
                          setFieldValue={setFieldValue}
                        />
                        <YesOrNoFormControl
                          disabled={readOnly}
                          label="Diagnosis and/or Treatment of STI, excluding HIV"
                          fieldName="diagnosis_treatment_of_sti"
                          fieldValue={values.diagnosis_treatment_of_sti}
                          setFieldValue={setFieldValue}
                        />
                        <YesOrNoFormControl
                          disabled={readOnly}
                          label="HIV Counseling"
                          fieldName="hiv_counseling_training"
                          fieldValue={values.hiv_counseling_training}
                          setFieldValue={setFieldValue}
                        />
                        <YesOrNoFormControl
                          disabled={readOnly}
                          label="Newborn Screening"
                          fieldName="newborn_screening_training"
                          fieldValue={values.newborn_screening_training}
                          setFieldValue={setFieldValue}
                        />
                      </>
                    )}
                    {facilityType !== AUX_FACILITY && (
                      <YesOrNoFormControl
                        disabled={readOnly}
                        label="Hemoglobin Determination using Copper Sulfate"
                        fieldName="hemoglobin_determination_using_copper_sulfate_training"
                        fieldValue={values.hemoglobin_determination_using_copper_sulfate_training}
                        setFieldValue={setFieldValue}
                      />
                    )}
                  </Box>
                  {(facilityType !== INFIRMARY && facilityType !== SAFE_BIRTHING_FACILITY && facilityType !== AUX_FACILITY) && (
                    <>
                      <HCPFormControl
                        disabled={readOnly}
                        label="How many active BHWs in this facility are trained on Interpersonal Communication Skills"
                        handleChange={handleChange}
                        name="bhws_trained_in_ics_count"
                        value={values.bhws_trained_in_ics_count}
                        error={Boolean(touched.bhws_trained_in_ics_count && errors.bhws_trained_in_ics_count)}
                        helperText={touched.bhws_trained_in_ics_count && errors.bhws_trained_in_ics_count}
                      />
                      <HCPFormControl
                        disabled={readOnly}
                        label="How many active BHWs in this facility are trained on Community Tracking Tool"
                        handleChange={handleChange}
                        name="bhws_trained_in_community_tracking_tool_count"
                        value={values.bhws_trained_in_community_tracking_tool_count}
                        error={Boolean(touched.bhws_trained_in_community_tracking_tool_count && errors.bhws_trained_in_community_tracking_tool_count)}
                        helperText={touched.bhws_trained_in_community_tracking_tool_count && errors.bhws_trained_in_community_tracking_tool_count}
                      />
                    </>
                  )}
                  <span className="whitespace" />
                  {(facilityType !== BARANGAY_HEALTH_STATION && facilityType !== SAFE_BIRTHING_FACILITY && facilityType !== AUX_FACILITY) && (
                    <>
                      <Typography color="textSecondary" variant="h5" gutterBottom>
                        Do you conduct the following diagnostic examinations in this facility at any time as part of Antenatal Care?
                      </Typography>
                      <Box sx={{ p: 2 }}>
                        <YesOrNoFormControl
                          disabled={readOnly}
                          label="Hemoglobin determination"
                          fieldName="hemoglobin_determination"
                          fieldValue={values.hemoglobin_determination}
                          setFieldValue={setFieldValue}
                        />
                        <YesOrNoFormControl
                          disabled={readOnly}
                          label="Urine dipstick test"
                          fieldName="urine_dipstick_test"
                          fieldValue={values.urine_dipstick_test}
                          setFieldValue={setFieldValue}
                        />
                        <YesOrNoFormControl
                          disabled={readOnly}
                          label="Urinalysis"
                          fieldName="urinalysis"
                          fieldValue={values.urinalysis}
                          setFieldValue={setFieldValue}
                        />
                        <YesOrNoFormControl
                          disabled={readOnly}
                          label="Urine glucose"
                          fieldName="urine_glucose_exam"
                          fieldValue={values.urine_glucose_exam}
                          setFieldValue={setFieldValue}
                        />
                        <YesOrNoFormControl
                          disabled={readOnly}
                          label="Blood glucose"
                          fieldName="blood_glucose_exam"
                          fieldValue={values.blood_glucose_exam}
                          setFieldValue={setFieldValue}
                        />
                        <YesOrNoFormControl
                          disabled={readOnly}
                          label="Blood typing"
                          fieldName="blood_typing"
                          fieldValue={values.blood_typing}
                          setFieldValue={setFieldValue}
                        />
                        <YesOrNoFormControl
                          disabled={readOnly}
                          label="Malaria rapid diagnostic test"
                          fieldName="malaria_rapid_diagnostic_test"
                          fieldValue={values.malaria_rapid_diagnostic_test}
                          setFieldValue={setFieldValue}
                        />
                        <YesOrNoFormControl
                          disabled={readOnly}
                          label="Syphilis rapid diagnostic test"
                          fieldName="syphilis_rapid_diagnostic_test"
                          fieldValue={values.syphilis_rapid_diagnostic_test}
                          setFieldValue={setFieldValue}
                        />
                        <YesOrNoFormControl
                          disabled={readOnly}
                          label="Hepatitis B diagnostic test"
                          fieldName="hepatitis_b_diagnostic_test"
                          fieldValue={values.hepatitis_b_diagnostic_test}
                          setFieldValue={setFieldValue}
                        />
                        <YesOrNoFormControl
                          disabled={readOnly}
                          label="HIV rapid diagnostic test"
                          fieldName="hiv_rapid_diagnostic_test"
                          fieldValue={values.hiv_rapid_diagnostic_test}
                          setFieldValue={setFieldValue}
                        />
                      </Box>
                    </>
                  )}
                  {facilityType !== AUX_FACILITY && (
                    <RadioGroupFormControl
                      disabled={readOnly}
                      label="Are individual client records (Maternal Health Record) for antenatal care maintained in this facility?"
                      fieldName="individual_client_records_are_maintained"
                      fieldValue={values.individual_client_records_are_maintained}
                      options={availability}
                      setFieldValue={setFieldValue}
                    />
                  )}
                  {(facilityType !== INFIRMARY && facilityType !== SAFE_BIRTHING_FACILITY && facilityType !== AUX_FACILITY) && (
                    <RadioGroupFormControl
                      disabled={readOnly}
                      label="Does this facility use any form of pregnancy tracking tool (e.g Community Tracking Tool)"
                      fieldName="pregnancy_tracking_tools_are_used"
                      fieldValue={values.pregnancy_tracking_tools_are_used}
                      options={availability}
                      setFieldValue={setFieldValue}
                    />
                  )}
                </>
              )}
            </CardContent>
            <Divider />
            <Box className="box-button">
              <Button
                disabled={isLoading || isSubmitting || !readOnly}
                color="primary"
                onClick={() => handleEditClick()}
                variant="contained"
                sx={{ mx: 2 }}
              >
                Edit
              </Button>
              <Button
                disabled={isLoading || isSubmitting || readOnly}
                color="primary"
                onClick={() => handleSaveClick(handleSubmit)}
                type="submit"
                variant="contained"
              >
                Save
              </Button>
            </Box>
            {(isLoading || isSubmitting) && <LinearProgress />}
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default AntenatalCare;
