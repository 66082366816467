import React from 'react';
import { TreeView, TreeItem } from '@mui/lab';
import { ChevronRight, ExpandMore } from '@mui/icons-material';

const PlaceSidebar = (props) => {
  const { places, handleSelect } = props;

  const renderPlaces = (places) => (
    places.map((place) => (
      <TreeItem key={place.id} nodeId={place.id.toString()} label={place.name}>
        {renderPlaces(place.children)}
      </TreeItem>
    ))
  );

  return (
    <TreeView
      aria-label="file system navigator"
      defaultCollapseIcon={<ExpandMore />}
      defaultExpandIcon={<ChevronRight />}
      onNodeSelect={(event, node) => handleSelect(node)}
      sx={{
        flexGrow: 1, maxWidth: 400, overflowY: 'auto'
      }}
    >
      {renderPlaces(places)}
    </TreeView>
  );
};

export default PlaceSidebar;
