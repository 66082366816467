import React from 'react';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  LinearProgress,
  Typography
} from '@mui/material';
import {
  Edit as EditIcon,
  Save as SaveIcon
} from '@mui/icons-material';
import AvailabilityFunctioningFormGroup from '../../formSections/AvailabilityFunctioningFormGroup';

const ChildGrowthMonitoringEquipment = (props) => {
  const {
    formValues,
    isLoading,
    isSubmitting,
    readOnly,
    setReadOnly
  } = props;

  const handleEditClick = () => {
    setReadOnly(false);
  };

  const handleSaveClick = (handleSubmit) => {
    handleSubmit();
    setReadOnly(true);
  };

  const initialValues = {
    child_weighing_scale_available: null,
    child_weighing_scale_functioning: null,
    infant_weighing_scale_available: null,
    infant_weighing_scale_functioning: null,
    height_or_length_board_available: null,
    height_or_length_board_functioning: null,
    midupper_arm_circumference_tape_available: null,
    midupper_arm_circumference_tape_functioning: null,
    ...formValues
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => {
        props.onSubmit('child_growth_monitoring_equipment', values);
      }}
    >
      {({
        handleSubmit,
        setFieldValue,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardContent>
              <Box className="form-header">
                <Typography variant="h4" gutterBottom>
                  Child Growth Monitoring Services
                </Typography>
                {readOnly ? (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleEditClick()}
                  >
                    <EditIcon size="medium" />
                  </IconButton>
                ) : (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleSaveClick(handleSubmit)}
                  >
                    <SaveIcon size="medium" />
                  </IconButton>
                )}
              </Box>
            </CardContent>
            <Divider />
            <CardContent>
              <Box x={{ p: 2 }}>
                <AvailabilityFunctioningFormGroup
                  disabled={readOnly}
                  label="Child Weighing Scale (250-gram gradation)"
                  fieldName="child_weighing_scale_available"
                  fieldValue={values.child_weighing_scale_available}
                  fieldFunctioningName="child_weighing_scale_functioning"
                  fieldFunctioningValue={values.child_weighing_scale_functioning}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityFunctioningFormGroup
                  disabled={readOnly}
                  label="Infant Weighing Scale (100-gram gradation)"
                  fieldName="infant_weighing_scale_available"
                  fieldValue={values.infant_weighing_scale_available}
                  fieldFunctioningName="infant_weighing_scale_functioning"
                  fieldFunctioningValue={values.infant_weighing_scale_functioning}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityFunctioningFormGroup
                  disabled={readOnly}
                  label="Height or Length board"
                  fieldName="height_or_length_board_available"
                  fieldValue={values.height_or_length_board_available}
                  fieldFunctioningName="height_or_length_board_functioning"
                  fieldFunctioningValue={values.height_or_length_board_functioning}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityFunctioningFormGroup
                  disabled={readOnly}
                  label="Mid-Upper Arm Circumference (MUAC) Tape"
                  fieldName="midupper_arm_circumference_tape_available"
                  fieldValue={values.midupper_arm_circumference_tape_available}
                  fieldFunctioningName="midupper_arm_circumference_tape_functioning"
                  fieldFunctioningValue={values.midupper_arm_circumference_tape_functioning}
                  setFieldValue={setFieldValue}
                />
              </Box>
            </CardContent>
            <Divider />
            <Box className="box-button">
              <Button
                disabled={isLoading || isSubmitting || !readOnly}
                color="primary"
                onClick={() => handleEditClick()}
                variant="contained"
                sx={{ mx: 2 }}
              >
                Edit
              </Button>
              <Button
                disabled={isLoading || isSubmitting || readOnly}
                color="primary"
                onClick={() => handleSaveClick(handleSubmit)}
                type="submit"
                variant="contained"
              >
                Save
              </Button>
            </Box>
            {(isLoading || isSubmitting) && <LinearProgress />}
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default ChildGrowthMonitoringEquipment;
