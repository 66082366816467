import React from 'react';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  FormControl,
  FormLabel,
  Grid,
  Select,
  LinearProgress,
  Typography
} from '@mui/material';
import {
  Edit as EditIcon,
  Save as SaveIcon
} from '@mui/icons-material';
import {
  BARANGAY_HEALTH_STATION,
  NUTRITION_POST,
  RURAL_HEALTH_UNIT,
  AUX_FACILITY
} from 'src/constants/facilityTypes';
import YesOrNoFormControl from 'src/components/assessment/formSections/YesOrNoFormControl';

const ToiletTypeOptions = () => (
  <>
    <optgroup label="Flush or Pour Flush Toilet">
      <option value={101}>Flush to Piped Sewer System</option>
      <option value={102}>Flush to Septic Tank</option>
      <option value={103}>Flush to Pit Latrine</option>
      <option value={104}>Flush to Somewhere Else</option>
      <option value={105}>Flush, Don&#39;t Know Where</option>
    </optgroup>
    <optgroup label="Pit Latrine">
      <option value={201}>Ventilated Improved Pit Latrine</option>
      <option value={202}>Pit Latrine with Slab</option>
      <option value={203}>Open Pit</option>
    </optgroup>
    <option value={401}>Composting Toilet</option>
    <option value={402}>Bucket Toilet</option>
    <option value={403}>Hanging Toilet</option>
    <option value={404}>No Functioning Facility/Bush/Field</option>
  </>
);

const PhysicalStructure = (props) => {
  const {
    facilityType,
    isLoading,
    isSubmitting,
    physicalStructure,
    readOnly,
    setReadOnly
  } = props;

  const handleEditClick = () => {
    setReadOnly(false);
  };

  const handleSaveClick = (handleSubmit) => {
    handleSubmit();
    setReadOnly(true);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={physicalStructure}
      onSubmit={(values) => {
        props.onSubmit('physical_structure', values);
      }}
    >
      {({
        handleChange,
        handleSubmit,
        setFieldValue,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardContent>
              <Box className="form-header">
                <Typography variant="h4" gutterBottom>
                  3.1.2 Physical Structure of Facility
                </Typography>
                {readOnly ? (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleEditClick()}
                  >
                    <EditIcon size="medium" />
                  </IconButton>
                ) : (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleSaveClick(handleSubmit)}
                  >
                    <SaveIcon size="medium" />
                  </IconButton>
                )}
              </Box>
            </CardContent>
            <Divider />
            <CardContent sx={{ px: 5 }}>
              <Box sx={{ p: 2 }}>
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Is there a clear sign bearing the name of the facility?"
                  fieldName="has_sign_with_facility_name"
                  fieldValue={values.has_sign_with_facility_name || false}
                  setFieldValue={setFieldValue}
                />
                { (facilityType !== BARANGAY_HEALTH_STATION && facilityType !== NUTRITION_POST && facilityType !== AUX_FACILITY) && (
                  <YesOrNoFormControl
                    disabled={readOnly}
                    label="Is there a sign indicating it is a PhilHealth provider?"
                    fieldName="has_sign_indicating_philhealth_membership"
                    fieldValue={values.has_sign_indicating_philhealth_membership || false}
                    setFieldValue={setFieldValue}
                  />
                )}
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Is there a sign enumerating the services available?"
                  fieldName="has_sign_with_list_of_services"
                  fieldValue={values.has_sign_with_list_of_services || false}
                  setFieldValue={setFieldValue}
                />
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Is there a generally clean environment inside the facility?"
                  fieldName="is_generally_clean_inside"
                  fieldValue={values.is_generally_clean_inside || false}
                  setFieldValue={setFieldValue}
                />
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Are there visible signs prohibiting smoking?"
                  fieldName="has_no_smoking_signs"
                  fieldValue={values.has_no_smoking_signs || false}
                  setFieldValue={setFieldValue}
                />
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Is there sufficient seating for clients in a well-ventilated area?"
                  fieldName="has_sufficient_seating_in_ventilated_area"
                  fieldValue={values.has_sufficient_seating_in_ventilated_area || false}
                  setFieldValue={setFieldValue}
                />
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Is there adequate lighting or naturally well-lighted?"
                  fieldName="has_good_lighting"
                  fieldValue={values.has_good_lighting || false}
                  setFieldValue={setFieldValue}
                />
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Is there adequate clean water supply from a reliable source?"
                  fieldName="has_adequate_clean_water_supply_from_reliable_source"
                  fieldValue={values.has_adequate_clean_water_supply_from_reliable_source || false}
                  setFieldValue={setFieldValue}
                />
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Is there a consultation/examination room/cubicle with auditory and visual privacy available?"
                  fieldName="has_consultation_area_with_privacy"
                  fieldValue={values.has_consultation_area_with_privacy || false}
                  setFieldValue={setFieldValue}
                />
                { (facilityType !== BARANGAY_HEALTH_STATION && facilityType !== NUTRITION_POST && facilityType !== AUX_FACILITY) && (
                  <>
                    {facilityType !== RURAL_HEALTH_UNIT && (
                      <YesOrNoFormControl
                        disabled={readOnly}
                        label="Is there a Delivery Room with area for cleaning/resuscitation of newborn?"
                        fieldName="has_delivery_room_for_newborn_cleaning_or_resuscitation"
                        fieldValue={values.has_delivery_room_for_newborn_cleaning_or_resuscitation || false}
                        setFieldValue={setFieldValue}
                      />
                    )}
                    <YesOrNoFormControl
                      disabled={readOnly}
                      label="Is there an area for cleaning instruments?"
                      fieldName="has_area_for_cleaning_instruments"
                      fieldValue={values.has_area_for_cleaning_instruments || false}
                      setFieldValue={setFieldValue}
                    />
                    {facilityType !== RURAL_HEALTH_UNIT && (
                      <YesOrNoFormControl
                        disabled={readOnly}
                        label="Is there any room/s for patients awaiting delivery or while recovering?"
                        fieldName="has_delivery_waiting_or_recovery_room"
                        fieldValue={values.has_delivery_waiting_or_recovery_room || false}
                        setFieldValue={setFieldValue}
                      />
                    )}
                  </>
                )}
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Are there adequate signages (entrance, exits, laboratory, etc.)?"
                  fieldName="has_adequate_signs"
                  fieldValue={values.has_adequate_signs || false}
                  setFieldValue={setFieldValue}
                />
                { (facilityType !== BARANGAY_HEALTH_STATION && facilityType !== NUTRITION_POST && facilityType !== AUX_FACILITY) && (
                  <>
                    <FormControl
                      component="fieldset"
                      fullWidth
                      sx={{ py: 1 }}
                      variant="outlined"
                    >
                      <Grid container>
                        <Grid item xs={12} sm={8} md={6}>
                          <FormLabel component="legend" className="field-label">
                            Is there a functional toilet for in-patient use only (1 toilet: 6 beds)?
                          </FormLabel>
                        </Grid>
                        <Grid item xs={12} sm={4} md={6}>
                          <Select
                            disabled={isSubmitting || readOnly}
                            name="functional_toilet_for_inpatient_use"
                            native
                            onChange={handleChange}
                            value={values.functional_toilet_for_inpatient_use}
                            size="small"
                          >
                            <ToiletTypeOptions />
                          </Select>
                        </Grid>
                      </Grid>
                    </FormControl>
                    <FormControl
                      component="fieldset"
                      fullWidth
                      sx={{ py: 1 }}
                      variant="outlined"
                    >
                      <Grid container>
                        <Grid item xs={12} sm={8} md={6}>
                          <FormLabel component="legend" className="field-label">
                            Is there a functional toilet for general out-patient use only?
                          </FormLabel>
                        </Grid>
                        <Grid item xs={12} sm={4} md={6}>
                          <Select
                            disabled={isSubmitting || readOnly}
                            name="functional_toilet_for_outpatient_use"
                            native
                            onChange={handleChange}
                            value={values.functional_toilet_for_outpatient_use}
                            size="small"
                          >
                            <ToiletTypeOptions />
                          </Select>
                        </Grid>
                      </Grid>
                    </FormControl>
                  </>
                )}
                { (facilityType !== BARANGAY_HEALTH_STATION && facilityType !== NUTRITION_POST && facilityType !== RURAL_HEALTH_UNIT && facilityType !== AUX_FACILITY) && (
                  <YesOrNoFormControl
                    disabled={readOnly}
                    label="Are there separate toilets for males and females?"
                    fieldName="has_separate_toilets_for_males_and_females"
                    fieldValue={values.has_separate_toilets_for_males_and_females || false}
                    setFieldValue={setFieldValue}
                  />
                )}
                { (facilityType !== BARANGAY_HEALTH_STATION && facilityType !== NUTRITION_POST && facilityType !== AUX_FACILITY) && (
                  <YesOrNoFormControl
                    disabled={readOnly}
                    label="Is there a ramp entrance?"
                    fieldName="has_ramp_entrace"
                    fieldValue={values.has_ramp_entrace || false}
                    setFieldValue={setFieldValue}
                  />
                )}
                <YesOrNoFormControl
                  disabled={readOnly}
                  label="Are there emergency escape plans posted in conspicuous places?"
                  fieldName="has_emergency_exit_plans_in_conspicuous_places"
                  fieldValue={values.has_emergency_exit_plans_in_conspicuous_places || false}
                  setFieldValue={setFieldValue}
                />
              </Box>
            </CardContent>
            <Divider />
            <Box className="box-button">
              <Button
                disabled={isLoading || isSubmitting || !readOnly}
                color="primary"
                onClick={() => handleEditClick()}
                variant="contained"
                sx={{ mx: 2 }}
              >
                Edit
              </Button>
              <Button
                disabled={isLoading || isSubmitting || readOnly}
                color="primary"
                onClick={() => handleSaveClick(handleSubmit)}
                type="submit"
                variant="contained"
              >
                Save
              </Button>
            </Box>
            {(isLoading || isSubmitting) && <LinearProgress />}
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default PhysicalStructure;
