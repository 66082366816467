import React from 'react';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  LinearProgress,
  Typography
} from '@mui/material';
import {
  Edit as EditIcon,
  Save as SaveIcon
} from '@mui/icons-material';
import { BARANGAY_HEALTH_STATION } from 'src/constants/facilityTypes';
import childPreventiveCareSupplies from 'src/fields/childPreventiveCareSupplies';
import AvailabilityStockOut from '../../formSections/AvailabilityStockOut';

const ChildPreventiveCareSupplies = (props) => {
  const {
    formValues,
    isLoading,
    isSubmitting,
    readOnly,
    setReadOnly,
    facilityType
  } = props;

  const handleEditClick = () => {
    setReadOnly(false);
  };

  const handleSaveClick = (handleSubmit) => {
    handleSubmit();
    setReadOnly(true);
  };

  const initialValues = {
    ...childPreventiveCareSupplies,
    ...formValues
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => {
        props.onSubmit('child_preventive_and_curative_care_supplies', values);
      }}
    >
      {({
        handleSubmit,
        setFieldValue,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardContent>
              <Box className="form-header">
                <Typography variant="h4" gutterBottom>
                  Child Preventive and Curative Care Services
                </Typography>
                {readOnly ? (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleEditClick()}
                  >
                    <EditIcon size="medium" />
                  </IconButton>
                ) : (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleSaveClick(handleSubmit)}
                  >
                    <SaveIcon size="medium" />
                  </IconButton>
                )}
              </Box>
            </CardContent>
            <Divider />
            <CardContent>
              <Box sx={{ p: 2 }}>
                {facilityType !== BARANGAY_HEALTH_STATION && (
                  <>
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Fuji 9 GIC for atraumatic restorative treatment"
                      fieldName="fuji_9_gic_available"
                      fieldValue={values.fuji_9_gic_available}
                      fieldStockOutName="fuji_9_gic_rso"
                      fieldStockOutValue={values.fuji_9_gic_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Fluoride varnish"
                      fieldName="fluoride_varnish_available"
                      fieldValue={values.fluoride_varnish_available}
                      fieldStockOutName="fluoride_varnish_rso"
                      fieldStockOutValue={values.fluoride_varnish_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Pit and Fissure sealant"
                      fieldName="pit_and_fissure_sealant_available"
                      fieldValue={values.pit_and_fissure_sealant_available}
                      fieldStockOutName="pit_and_fissure_sealant_rso"
                      fieldStockOutValue={values.pit_and_fissure_sealant_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Tooth brush"
                      fieldName="tooth_brush_available"
                      fieldValue={values.tooth_brush_available}
                      fieldStockOutName="tooth_brush_rso"
                      fieldStockOutValue={values.tooth_brush_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Poly Paste Toothpaste"
                      fieldName="poly_paste_toothpaste_available"
                      fieldValue={values.poly_paste_toothpaste_available}
                      fieldStockOutName="poly_paste_toothpaste_rso"
                      fieldStockOutValue={values.poly_paste_toothpaste_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Amoxicillin suspension drops and syrup"
                      fieldName="amoxicillin_suspension_drops_and_syrup_available"
                      fieldValue={values.amoxicillin_suspension_drops_and_syrup_available}
                      fieldStockOutName="amoxicillin_suspension_drops_and_syrup_rso"
                      fieldStockOutValue={values.amoxicillin_suspension_drops_and_syrup_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Cefalexin syrup"
                      fieldName="cefalexin_syrup_available"
                      fieldValue={values.cefalexin_syrup_available}
                      fieldStockOutName="cefalexin_syrup_rso"
                      fieldStockOutValue={values.cefalexin_syrup_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Cotrimoxazole suspension"
                      fieldName="cotrimoxazole_suspension_available"
                      fieldValue={values.cotrimoxazole_suspension_available}
                      fieldStockOutName="cotrimoxazole_suspension_rso"
                      fieldStockOutValue={values.cotrimoxazole_suspension_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Flucloxacillin syrup"
                      fieldName="flucloxacillin_syrup_available"
                      fieldValue={values.flucloxacillin_syrup_available}
                      fieldStockOutName="flucloxacillin_syrup_rso"
                      fieldStockOutValue={values.flucloxacillin_syrup_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Injectable Benzathine Benzylpenicillin"
                      fieldName="injectable_benzathine_benzylpenicillin_available"
                      fieldValue={values.injectable_benzathine_benzylpenicillin_available}
                      fieldStockOutName="injectable_benzathine_benzylpenicillin_rso"
                      fieldStockOutValue={values.injectable_benzathine_benzylpenicillin_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Injectable Ceftriaxone"
                      fieldName="injectable_ceftriaxone_available"
                      fieldValue={values.injectable_ceftriaxone_available}
                      fieldStockOutName="injectable_ceftriaxone_rso"
                      fieldStockOutValue={values.injectable_ceftriaxone_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Injectable Erythromycin"
                      fieldName="injectable_erythromycin_available"
                      fieldValue={values.injectable_erythromycin_available}
                      fieldStockOutName="injectable_erythromycin_rso"
                      fieldStockOutValue={values.injectable_erythromycin_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Injectable Azithromycin"
                      fieldName="injectable_azithromycin_available"
                      fieldValue={values.injectable_azithromycin_available}
                      fieldStockOutName="injectable_azithromycin_rso"
                      fieldStockOutValue={values.injectable_azithromycin_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Streptomycin injectable"
                      fieldName="streptomycin_injectable_available"
                      fieldValue={values.streptomycin_injectable_available}
                      fieldStockOutName="streptomycin_injectable_rso"
                      fieldStockOutValue={values.streptomycin_injectable_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Epinephrine"
                      fieldName="epinephrine_available"
                      fieldValue={values.epinephrine_available}
                      fieldStockOutName="epinephrine_rso"
                      fieldStockOutValue={values.epinephrine_rso}
                      setFieldValue={setFieldValue}
                    />
                  </>
                )}
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Paracetamol syrup"
                  fieldName="paracetamol_syrup_available"
                  fieldValue={values.paracetamol_syrup_available}
                  fieldStockOutName="paracetamol_syrup_rso"
                  fieldStockOutValue={values.paracetamol_syrup_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Mefenamic Acid"
                  fieldName="mefenamic_acid_available"
                  fieldValue={values.mefenamic_acid_available}
                  fieldStockOutName="mefenamic_acid_rso"
                  fieldStockOutValue={values.mefenamic_acid_rso}
                  setFieldValue={setFieldValue}
                />
                {facilityType !== BARANGAY_HEALTH_STATION && (
                  <>
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Tranexamic Acid"
                      fieldName="tranexamic_acid_available"
                      fieldValue={values.tranexamic_acid_available}
                      fieldStockOutName="tranexamic_acid_rso"
                      fieldStockOutValue={values.tranexamic_acid_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Injectable Adrenaline"
                      fieldName="injectable_adrenaline_available"
                      fieldValue={values.injectable_adrenaline_available}
                      fieldStockOutName="injectable_adrenaline_rso"
                      fieldStockOutValue={values.injectable_adrenaline_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Injecatable Diazepam"
                      fieldName="injectable_diazepram_available"
                      fieldValue={values.injectable_diazepram_available}
                      fieldStockOutName="injectable_diazepram_rso"
                      fieldStockOutValue={values.injectable_diazepram_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Lidocaine 1%"
                      fieldName="lidocaine_available"
                      fieldValue={values.lidocaine_available}
                      fieldStockOutName="lidocaine_rso"
                      fieldStockOutValue={values.lidocaine_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Prednisone Tablet"
                      fieldName="prednisone_tablet_available"
                      fieldValue={values.prednisone_tablet_available}
                      fieldStockOutName="prednisone_tablet_rso"
                      fieldStockOutValue={values.prednisone_tablet_rso}
                      setFieldValue={setFieldValue}
                    />
                  </>
                )}
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Salbutamol nebulizer solution"
                  fieldName="salbutamol_nebulizer_solution_available"
                  fieldValue={values.salbutamol_nebulizer_solution_available}
                  fieldStockOutName="salbutamol_nebulizer_solution_rso"
                  fieldStockOutValue={values.salbutamol_nebulizer_solution_rso}
                  setFieldValue={setFieldValue}
                />
                {facilityType !== BARANGAY_HEALTH_STATION && (
                  <>
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Metered-dose inhaler (MDI)"
                      fieldName="metered_dose_inhaler_available"
                      fieldValue={values.metered_dose_inhaler_available}
                      fieldStockOutName="metered_dose_inhaler_rso"
                      fieldStockOutValue={values.metered_dose_inhaler_rso}
                      setFieldValue={setFieldValue}
                    />
                  </>
                )}
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Vitamin A 200,000 I.U. capsules"
                  fieldName="vitamin_a_capsules_available"
                  fieldValue={values.vitamin_a_capsules_available}
                  fieldStockOutName="vitamin_a_capsules_rso"
                  fieldStockOutValue={values.vitamin_a_capsules_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Zinc supplements (drops and syrup)"
                  fieldName="zinc_supplements_available"
                  fieldValue={values.zinc_supplements_available}
                  fieldStockOutName="zinc_supplements_rso"
                  fieldStockOutValue={values.zinc_supplements_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Reformulated ORS with Zinc"
                  fieldName="reformulated_ors_with_zinc_available"
                  fieldValue={values.reformulated_ors_with_zinc_available}
                  fieldStockOutName="reformulated_ors_with_zinc_rso"
                  fieldStockOutValue={values.reformulated_ors_with_zinc_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="FeS04 drops and syrup"
                  fieldName="fe_so4_drops_and_syrup_available"
                  fieldValue={values.fe_so4_drops_and_syrup_available}
                  fieldStockOutName="fe_so4_drops_and_syrup_rso"
                  fieldStockOutValue={values.fe_so4_drops_and_syrup_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Syrup containing 30 mg. Elemental Iron/5 ml"
                  fieldName="syrup_with_30mg_elemental_iron_available"
                  fieldValue={values.syrup_with_30mg_elemental_iron_available}
                  fieldStockOutName="syrup_with_30mg_elemental_iron_rso"
                  fieldStockOutValue={values.syrup_with_30mg_elemental_iron_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Tablet containing 60 mg. Elemental Iron with 400 mcg folic acid (coated)"
                  fieldName="tablet_with_60mg_elemental_iron_and_400mcg_folic_acid_available"
                  fieldValue={values.tablet_with_60mg_elemental_iron_and_400mcg_folic_acid_available}
                  fieldStockOutName="tablet_with_60mg_elemental_iron_and_400mcg_folic_acid_rso"
                  fieldStockOutValue={values.tablet_with_60mg_elemental_iron_and_400mcg_folic_acid_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Iodized oil capsule with 200 mg. Iodine"
                  fieldName="iodized_oil_capsule_available"
                  fieldValue={values.iodized_oil_capsule_available}
                  fieldStockOutName="iodized_oil_capsule_rso"
                  fieldStockOutValue={values.iodized_oil_capsule_rso}
                  setFieldValue={setFieldValue}
                />
                {facilityType !== BARANGAY_HEALTH_STATION && (
                  <>
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="10% Glucose"
                      fieldName="ten_percent_glucose_available"
                      fieldValue={values.ten_percent_glucose_available}
                      fieldStockOutName="ten_percent_glucose_rso"
                      fieldStockOutValue={values.ten_percent_glucose_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="10% Glucose infusion (Neonatalyte)"
                      fieldName="ten_percent_glucose_infusion_available"
                      fieldValue={values.ten_percent_glucose_infusion_available}
                      fieldStockOutName="ten_percent_glucose_infusion_rso"
                      fieldStockOutValue={values.ten_percent_glucose_infusion_rso}
                      setFieldValue={setFieldValue}
                    />
                  </>
                )}
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Formula 75 (F75)"
                  fieldName="f75_available"
                  fieldValue={values.f75_available}
                  fieldStockOutName="f75_rso"
                  fieldStockOutValue={values.f75_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Ready-to-Use Therapeutic Food (RUTF)"
                  fieldName="rutf_available"
                  fieldValue={values.rutf_available}
                  fieldStockOutName="rutf_rso"
                  fieldStockOutValue={values.rutf_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Albendazole (400 mg) tablet"
                  fieldName="albendazole_tablet_available"
                  fieldValue={values.albendazole_tablet_available}
                  fieldStockOutName="albendazole_tablet_rso"
                  fieldStockOutValue={values.albendazole_tablet_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Mebendazole (500 mg) tablet"
                  fieldName="mebendazole_tablet_available"
                  fieldValue={values.mebendazole_tablet_available}
                  fieldStockOutName="mebendazole_tablet_rso"
                  fieldStockOutValue={values.mebendazole_tablet_rso}
                  setFieldValue={setFieldValue}
                />
                {facilityType !== BARANGAY_HEALTH_STATION && (
                  <>
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Nystatin"
                      fieldName="nystatin_available"
                      fieldValue={values.nystatin_available}
                      fieldStockOutName="nystatin_rso"
                      fieldStockOutValue={values.nystatin_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Chloramphenicol ointment"
                      fieldName="chloramphenicol_ointment_available"
                      fieldValue={values.chloramphenicol_ointment_available}
                      fieldStockOutName="chloramphenicol_ointment_rso"
                      fieldStockOutValue={values.chloramphenicol_ointment_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Povidone Iodine cream (5%) or ointment (10%)"
                      fieldName="povidone_iodine_cream_or_ointment_available"
                      fieldValue={values.povidone_iodine_cream_or_ointment_available}
                      fieldStockOutName="povidone_iodine_cream_or_ointment_rso"
                      fieldStockOutValue={values.povidone_iodine_cream_or_ointment_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Chlorhexidine 0.2% or salt water"
                      fieldName="chlorhexidine_or_salt_water_available"
                      fieldValue={values.chlorhexidine_or_salt_water_available}
                      fieldStockOutName="chlorhexidine_or_salt_water_rso"
                      fieldStockOutValue={values.chlorhexidine_or_salt_water_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Calamine lotion"
                      fieldName="calamine_lotion_available"
                      fieldValue={values.calamine_lotion_available}
                      fieldStockOutName="calamine_lotion_rso"
                      fieldStockOutValue={values.calamine_lotion_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Hydrocortisone acetate 1% cream"
                      fieldName="hydrocortisone_acetate_cream_available"
                      fieldValue={values.hydrocortisone_acetate_cream_available}
                      fieldStockOutName="hydrocortisone_acetate_cream_rso"
                      fieldStockOutValue={values.hydrocortisone_acetate_cream_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Imidazole cream (e.g clotrimazole 2%)"
                      fieldName="imidazole_cream_available"
                      fieldValue={values.imidazole_cream_available}
                      fieldStockOutName="imidazole_cream_rso"
                      fieldStockOutValue={values.imidazole_cream_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Acyclovir cream/ointment"
                      fieldName="acyclovir_cream_or_ointment_available"
                      fieldValue={values.acyclovir_cream_or_ointment_available}
                      fieldStockOutName="acyclovir_cream_or_ointment_rso"
                      fieldStockOutValue={values.acyclovir_cream_or_ointment_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Podophyllum Resin 20% and Salicylic Acid 20% ointment"
                      fieldName="podophyllum_resin_and_salicylic_acid_ointment_available"
                      fieldValue={values.podophyllum_resin_and_salicylic_acid_ointment_available}
                      fieldStockOutName="podophyllum_resin_and_salicylic_acid_ointment_rso"
                      fieldStockOutValue={values.podophyllum_resin_and_salicylic_acid_ointment_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Petroleum Jelly"
                      fieldName="petroleum_jelly_available"
                      fieldValue={values.petroleum_jelly_available}
                      fieldStockOutName="petroleum_jelly_rso"
                      fieldStockOutValue={values.petroleum_jelly_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Selenium Sulfide 2% suspension"
                      fieldName="selenium_sulfide_suspension_available"
                      fieldValue={values.selenium_sulfide_suspension_available}
                      fieldStockOutName="selenium_sulfide_suspension_rso"
                      fieldStockOutValue={values.selenium_sulfide_suspension_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Sulfur ointment"
                      fieldName="sulfur_ointment_available"
                      fieldValue={values.sulfur_ointment_available}
                      fieldStockOutName="sulfur_ointment_rso"
                      fieldStockOutValue={values.sulfur_ointment_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Ethambutol"
                      fieldName="ethambutol_available"
                      fieldValue={values.ethambutol_available}
                      fieldStockOutName="ethambutol_rso"
                      fieldStockOutValue={values.ethambutol_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Isoniazid"
                      fieldName="isoniazid_available"
                      fieldValue={values.isoniazid_available}
                      fieldStockOutName="isoniazid_rso"
                      fieldStockOutValue={values.isoniazid_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Pyrazinamide"
                      fieldName="pyrazinamide_available"
                      fieldValue={values.pyrazinamide_available}
                      fieldStockOutName="pyrazinamide_rso"
                      fieldStockOutValue={values.pyrazinamide_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Rifampicin"
                      fieldName="rifampicin_available"
                      fieldValue={values.rifampicin_available}
                      fieldStockOutName="rifampicin_rso"
                      fieldStockOutValue={values.rifampicin_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Isoniazid + Rifampicin (2FDC)"
                      fieldName="isoniazid_plus_rifampicin_available"
                      fieldValue={values.isoniazid_plus_rifampicin_available}
                      fieldStockOutName="isoniazid_plus_rifampicin_rso"
                      fieldStockOutValue={values.isoniazid_plus_rifampicin_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Isoniazid + Rifampicin + Ethambutol (RHE) (4FDC)"
                      fieldName="isoniazid_plus_ethambutol_available"
                      fieldValue={values.isoniazid_plus_ethambutol_available}
                      fieldStockOutName="isoniazid_plus_ethambutol_rso"
                      fieldStockOutValue={values.isoniazid_plus_ethambutol_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Cup and spoon"
                      fieldName="cup_abd_spoon_available"
                      fieldValue={values.cup_abd_spoon_available}
                      fieldStockOutName="cup_abd_spoon_rso"
                      fieldStockOutValue={values.cup_abd_spoon_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Nasal cannula/nasal prongs"
                      fieldName="nasal_cannula_or_nasal_prongs_available"
                      fieldValue={values.nasal_cannula_or_nasal_prongs_available}
                      fieldStockOutName="nasal_cannula_or_nasal_prongs_rso"
                      fieldStockOutValue={values.nasal_cannula_or_nasal_prongs_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="FG-8 Nasogastric tube"
                      fieldName="fg8_nasogastric_tube_available"
                      fieldValue={values.fg8_nasogastric_tube_available}
                      fieldStockOutName="fg8_nasogastric_tube_rso"
                      fieldStockOutValue={values.fg8_nasogastric_tube_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Blood glucose testing strips"
                      fieldName="blood_glucose_testing_strips_available"
                      fieldValue={values.blood_glucose_testing_strips_available}
                      fieldStockOutName="blood_glucose_testing_strips_rso"
                      fieldStockOutValue={values.blood_glucose_testing_strips_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Pure protein derivative (PPD)"
                      fieldName="pure_protein_derivative_available"
                      fieldValue={values.pure_protein_derivative_available}
                      fieldStockOutName="pure_protein_derivative_rso"
                      fieldStockOutValue={values.pure_protein_derivative_rso}
                      setFieldValue={setFieldValue}
                    />
                  </>
                )}
              </Box>
            </CardContent>
            <Divider />
            <Box className="box-button">
              <Button
                disabled={isLoading || isSubmitting || !readOnly}
                color="primary"
                onClick={() => handleEditClick()}
                variant="contained"
                sx={{ mx: 2 }}
              >
                Edit
              </Button>
              <Button
                disabled={isLoading || isSubmitting || readOnly}
                color="primary"
                onClick={() => handleSaveClick(handleSubmit)}
                type="submit"
                variant="contained"
              >
                Save
              </Button>
            </Box>
            {(isLoading || isSubmitting) && <LinearProgress />}
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default ChildPreventiveCareSupplies;
