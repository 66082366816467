import React from 'react';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  LinearProgress,
  Typography
} from '@mui/material';
import {
  Edit as EditIcon,
  Save as SaveIcon
} from '@mui/icons-material';
import { RURAL_HEALTH_UNIT } from 'src/constants/facilityTypes';
import deliveryServicesSupplies from 'src/fields/deliveryServicesSupplies';
import AvailabilityStockOut from '../../formSections/AvailabilityStockOut';

const DeliveryServicesSupplies = (props) => {
  const {
    formValues,
    isLoading,
    isSubmitting,
    readOnly,
    setReadOnly,
    facilityType
  } = props;

  const handleEditClick = () => {
    setReadOnly(false);
  };

  const handleSaveClick = (handleSubmit) => {
    handleSubmit();
    setReadOnly(true);
  };

  const initialValues = {
    ...deliveryServicesSupplies,
    ...formValues
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => {
        props.onSubmit('delivery_services_supplies', values);
      }}
    >
      {({
        handleSubmit,
        setFieldValue,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardContent>
              <Box className="form-header">
                <Typography variant="h4" gutterBottom>
                  Delivery Services
                </Typography>
                {readOnly ? (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleEditClick()}
                  >
                    <EditIcon size="medium" />
                  </IconButton>
                ) : (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleSaveClick(handleSubmit)}
                  >
                    <SaveIcon size="medium" />
                  </IconButton>
                )}
              </Box>
            </CardContent>
            <Divider />
            <CardContent>
              <Box sx={{ p: 2 }}>
                {facilityType !== RURAL_HEALTH_UNIT && (
                  <>
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Emergency kit or cart/Portable kit or trolley"
                      fieldName="emergency_kit_available"
                      fieldValue={values.emergency_kit_available}
                      fieldStockOutName="emergency_kit_rso"
                      fieldStockOutValue={values.emergency_kit_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Injectable Uterotonic (e.g. Oxytocin, ergometrine) at least 10 ampules"
                      fieldName="injectable_uteronic_available"
                      fieldValue={values.injectable_uteronic_available}
                      fieldStockOutName="injectable_uteronic_rso"
                      fieldStockOutValue={values.injectable_uteronic_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Injectable Broad-Spectrum Antibiotic (Pen. G, Gentamicin, Amikacin, Metronidazole, etc. - may include ceftriaxone and benzathine penicillin)"
                      fieldName="injectable_broad_spectrum_antibiotic_available"
                      fieldValue={values.injectable_broad_spectrum_antibiotic_available}
                      fieldStockOutName="injectable_broad_spectrum_antibiotic_rso"
                      fieldStockOutValue={values.injectable_broad_spectrum_antibiotic_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Injectable Magnesium Sulphate (at least 5 ampules 500 mg/ 2 mL ampule)"
                      fieldName="injectable_magnesium_sulphate_available"
                      fieldValue={values.injectable_magnesium_sulphate_available}
                      fieldStockOutName="injectable_magnesium_sulphate_rso"
                      fieldStockOutValue={values.injectable_magnesium_sulphate_rso}
                      setFieldValue={setFieldValue}
                    />
                  </>
                )}
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="At least one of the following oral antibiotics (preferably amoxicillin): amoxicillin, ampicillin, cephalosporin, cloxacillin, erythromycin, azithromycin, trimethoprim + sulfamethoxazole, tetracycline or doxycycline (RPR + partner), metronidazole"
                  fieldName="oral_antibiotics_available"
                  fieldValue={values.oral_antibiotics_available}
                  fieldStockOutName="oral_antibiotics_rso"
                  fieldStockOutValue={values.oral_antibiotics_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Methyldopa"
                  fieldName="methyldopa_available"
                  fieldValue={values.methyldopa_available}
                  fieldStockOutName="methyldopa_rso"
                  fieldStockOutValue={values.methyldopa_rso}
                  setFieldValue={setFieldValue}
                />
                {facilityType !== RURAL_HEALTH_UNIT && (
                  <AvailabilityStockOut
                    disabled={readOnly}
                    label="At least one of the following tetracycline eye ointment: eye antimicrobial (1% silver nitrate or 2.5% povidone iodine) or erythromycin ophthalmic ointment 5% *"
                    fieldName="tetracycline_eye_ointment_available"
                    fieldValue={values.tetracycline_eye_ointment_available}
                    fieldStockOutName="tetracycline_eye_ointment_rso"
                    fieldStockOutValue={values.tetracycline_eye_ointment_rso}
                    setFieldValue={setFieldValue}
                  />
                )}
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Injectable Diazepam"
                  fieldName="malaria_medication_available"
                  fieldValue={values.malaria_medication_available}
                  fieldStockOutName="malaria_medication_rso"
                  fieldStockOutValue={values.malaria_medication_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="IV Infusion Set"
                  fieldName="injectable_diazepam_available"
                  fieldValue={values.injectable_diazepam_available}
                  fieldStockOutName="injectable_diazepam_rso"
                  fieldStockOutValue={values.injectable_diazepam_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="IV Cannula, Gauge 18 and 19"
                  fieldName="iv_infusion_set_available"
                  fieldValue={values.iv_infusion_set_available}
                  fieldStockOutName="iv_infusion_set_rso"
                  fieldStockOutValue={values.iv_infusion_set_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Injectable Calcium Gluconate 10 mg/ampule"
                  fieldName="injectable_calcium_gluconate_available"
                  fieldValue={values.injectable_calcium_gluconate_available}
                  fieldStockOutName="injectable_calcium_gluconate_rso"
                  fieldStockOutValue={values.injectable_calcium_gluconate_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Injectable Hydralazine"
                  fieldName="injectable_hydralazine_available"
                  fieldValue={values.injectable_hydralazine_available}
                  fieldStockOutName="injectable_hydralazine_rso"
                  fieldStockOutValue={values.injectable_hydralazine_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Nifedipine tablet"
                  fieldName="nifedipine_tablet_available"
                  fieldValue={values.nifedipine_tablet_available}
                  fieldStockOutName="nifedipine_tablet_rso"
                  fieldStockOutValue={values.nifedipine_tablet_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Diphenhydramine 50 mg/ampule"
                  fieldName="diphenhydramine_available"
                  fieldValue={values.diphenhydramine_available}
                  fieldStockOutName="diphenhydramine_rso"
                  fieldStockOutValue={values.diphenhydramine_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="At least one of the following corticosteroids: dexamethasone vial or ampule or betamethasone vial or ampule"
                  fieldName="corticosteroids_available"
                  fieldValue={values.corticosteroids_available}
                  fieldStockOutName="corticosteroids_rso"
                  fieldStockOutValue={values.corticosteroids_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Paracetamol Tablet 500 mg (at least 1 box of 100's) or Injectable"
                  fieldName="paracetamol_tablet_available"
                  fieldValue={values.paracetamol_tablet_available}
                  fieldStockOutName="paracetamol_tablet_rso"
                  fieldStockOutValue={values.paracetamol_tablet_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Epinephrine / Adrenaline"
                  fieldName="epinephrine_or_adrenaline_available"
                  fieldValue={values.epinephrine_or_adrenaline_available}
                  fieldStockOutName="epinephrine_or_adrenaline_rso"
                  fieldStockOutValue={values.epinephrine_or_adrenaline_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Atropine 1 mg/ml ampule"
                  fieldName="atropine_available"
                  fieldValue={values.atropine_available}
                  fieldStockOutName="atropine_rso"
                  fieldStockOutValue={values.atropine_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Lignocaine / Lidocaine / Xylocaine (1 multidose vial at 50 mL) or 5 pcs. poly amp at 5 mL each"
                  fieldName="ligonocaine_available"
                  fieldValue={values.ligonocaine_available}
                  fieldStockOutName="ligonocaine_rso"
                  fieldStockOutValue={values.ligonocaine_rso}
                  setFieldValue={setFieldValue}
                />
                {facilityType !== RURAL_HEALTH_UNIT && (
                  <AvailabilityStockOut
                    disabled={readOnly}
                    label="Sterile Cord Clamp"
                    fieldName="sterile_cord_clamp_available"
                    fieldValue={values.sterile_cord_clamp_available}
                    fieldStockOutName="sterile_cord_clamp_rso"
                    fieldStockOutValue={values.sterile_cord_clamp_rso}
                    setFieldValue={setFieldValue}
                  />
                )}
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Sterile Gloves (1 box)"
                  fieldName="sterile_gloves_available"
                  fieldValue={values.sterile_gloves_available}
                  fieldStockOutName="sterile_gloves_rso"
                  fieldStockOutValue={values.sterile_gloves_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Disposable Syringes with Needles"
                  fieldName="disposable_syringes_with_needles_available"
                  fieldValue={values.disposable_syringes_with_needles_available}
                  fieldStockOutName="disposable_syringes_with_needles_rso"
                  fieldStockOutValue={values.disposable_syringes_with_needles_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Plaster"
                  fieldName="plaster_available"
                  fieldValue={values.plaster_available}
                  fieldStockOutName="plaster_rso"
                  fieldStockOutValue={values.plaster_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="IV Fluids: glucose 50% solution (1 polyamp 50 mL), normal saline 0.9% (at least 3 bottles), Ringer Lactate (at least 5 bottles D5LR) and plain LR (at least 3 bottles)"
                  fieldName="iv_fluids_available"
                  fieldValue={values.iv_fluids_available}
                  fieldStockOutName="iv_fluids_rso"
                  fieldStockOutValue={values.iv_fluids_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Sterile Absorbable Suture"
                  fieldName="sterile_absorbable_suture_available"
                  fieldValue={values.sterile_absorbable_suture_available}
                  fieldStockOutName="sterile_absorbable_suture_rso"
                  fieldStockOutValue={values.sterile_absorbable_suture_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Sterile Cutting Needle"
                  fieldName="sterile_cutting_needle_available"
                  fieldValue={values.sterile_cutting_needle_available}
                  fieldStockOutName="sterile_cutting_needle_rso"
                  fieldStockOutValue={values.sterile_cutting_needle_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Sterile Round Needle"
                  fieldName="sterile_round_needle_available"
                  fieldValue={values.sterile_round_needle_available}
                  fieldStockOutName="sterile_round_needle_rso"
                  fieldStockOutValue={values.sterile_round_needle_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Sterile Cotton Balls (1 pack)"
                  fieldName="sterile_cotton_balls_available"
                  fieldValue={values.sterile_cotton_balls_available}
                  fieldStockOutName="sterile_cotton_balls_rso"
                  fieldStockOutValue={values.sterile_cotton_balls_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Sterile Cotton pledgets (1 pack)"
                  fieldName="sterile_cotton_pledgets_available"
                  fieldValue={values.sterile_cotton_pledgets_available}
                  fieldStockOutName="sterile_cotton_pledgets_rso"
                  fieldStockOutValue={values.sterile_cotton_pledgets_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Sterile Gauze (1 pack)"
                  fieldName="sterile_gauze_available"
                  fieldValue={values.sterile_gauze_available}
                  fieldStockOutName="sterile_gauze_rso"
                  fieldStockOutValue={values.sterile_gauze_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Nasal Cannula, Adult"
                  fieldName="nasal_cannula_for_adult_available"
                  fieldValue={values.nasal_cannula_for_adult_available}
                  fieldStockOutName="nasal_cannula_for_adult_rso"
                  fieldStockOutValue={values.nasal_cannula_for_adult_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Inhaled Bronchodilators"
                  fieldName="inhaled_bronchodilators_available"
                  fieldValue={values.inhaled_bronchodilators_available}
                  fieldStockOutName="inhaled_bronchodilators_rso"
                  fieldStockOutValue={values.inhaled_bronchodilators_rso}
                  setFieldValue={setFieldValue}
                />
                {facilityType !== RURAL_HEALTH_UNIT && (
                  <AvailabilityStockOut
                    disabled={readOnly}
                    label="Partograph"
                    fieldName="partograph_available"
                    fieldValue={values.partograph_available}
                    fieldStockOutName="partograph_rso"
                    fieldStockOutValue={values.partograph_rso}
                    setFieldValue={setFieldValue}
                  />
                )}
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Surgical Cap (1 box)"
                  fieldName="surgical_cap_available"
                  fieldValue={values.surgical_cap_available}
                  fieldStockOutName="surgical_cap_rso"
                  fieldStockOutValue={values.surgical_cap_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Surgical mask (1 box)"
                  fieldName="surgical_mask_available"
                  fieldValue={values.surgical_mask_available}
                  fieldStockOutName="surgical_mask_rso"
                  fieldStockOutValue={values.surgical_mask_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Patient Gown"
                  fieldName="patient_gown_available"
                  fieldValue={values.patient_gown_available}
                  fieldStockOutName="patient_gown_rso"
                  fieldStockOutValue={values.patient_gown_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Scrub suit"
                  fieldName="scrub_suit_available"
                  fieldValue={values.scrub_suit_available}
                  fieldStockOutName="scrub_suit_rso"
                  fieldStockOutValue={values.scrub_suit_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Sterile drape"
                  fieldName="sterile_drape_available"
                  fieldValue={values.sterile_drape_available}
                  fieldStockOutName="sterile_drape_rso"
                  fieldStockOutValue={values.sterile_drape_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Plastic Apron"
                  fieldName="plastic_apron_available"
                  fieldValue={values.plastic_apron_available}
                  fieldStockOutName="plastic_apron_rso"
                  fieldStockOutValue={values.plastic_apron_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Kelly pad"
                  fieldName="kelly_pad_available"
                  fieldValue={values.kelly_pad_available}
                  fieldStockOutName="kelly_pad_rso"
                  fieldStockOutValue={values.kelly_pad_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Urinary catheter w/ bag or container for collecting urine"
                  fieldName="urinary_catheter_with_urine_container_available"
                  fieldValue={values.urinary_catheter_with_urine_container_available}
                  fieldStockOutName="urinary_catheter_with_urine_container_rso"
                  fieldStockOutValue={values.urinary_catheter_with_urine_container_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Cloth for Drying and Warming the newborn"
                  fieldName="cloth_for_drying_and_warming_newborn_available"
                  fieldValue={values.cloth_for_drying_and_warming_newborn_available}
                  fieldStockOutName="cloth_for_drying_and_warming_newborn_rso"
                  fieldStockOutValue={values.cloth_for_drying_and_warming_newborn_rso}
                  setFieldValue={setFieldValue}
                />
                {facilityType !== RURAL_HEALTH_UNIT && (
                  <>
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Clean towels"
                      fieldName="clean_towels_available"
                      fieldValue={values.clean_towels_available}
                      fieldStockOutName="clean_towels_rso"
                      fieldStockOutValue={values.clean_towels_rso}
                      setFieldValue={setFieldValue}
                    />
                    <AvailabilityStockOut
                      disabled={readOnly}
                      label="Sanitary pads"
                      fieldName="sanitary_pads_available"
                      fieldValue={values.sanitary_pads_available}
                      fieldStockOutName="sanitary_pads_rso"
                      fieldStockOutValue={values.sanitary_pads_rso}
                      setFieldValue={setFieldValue}
                    />
                  </>
                )}
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Baby feeding cup"
                  fieldName="baby_feeding_cup_available"
                  fieldValue={values.baby_feeding_cup_available}
                  fieldStockOutName="baby_feeding_cup_rso"
                  fieldStockOutValue={values.baby_feeding_cup_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Soap"
                  fieldName="soap_available"
                  fieldValue={values.soap_available}
                  fieldStockOutName="soap_rso"
                  fieldStockOutValue={values.soap_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Antiseptic solution (iodophors or chlorhexidine/cetrinide)"
                  fieldName="antiseptic_solution_available"
                  fieldValue={values.antiseptic_solution_available}
                  fieldStockOutName="antiseptic_solution_rso"
                  fieldStockOutValue={values.antiseptic_solution_rso}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityStockOut
                  disabled={readOnly}
                  label="Bleach poweder/tablet-Jik (chlorine base compound)"
                  fieldName="bleach_power_or_tablet_available"
                  fieldValue={values.bleach_power_or_tablet_available}
                  fieldStockOutName="bleach_power_or_tablet_rso"
                  fieldStockOutValue={values.bleach_power_or_tablet_rso}
                  setFieldValue={setFieldValue}
                />
              </Box>
            </CardContent>
            <Divider />
            <Box className="box-button">
              <Button
                disabled={isLoading || isSubmitting || !readOnly}
                color="primary"
                onClick={() => handleEditClick()}
                variant="contained"
                sx={{ mx: 2 }}
              >
                Edit
              </Button>
              <Button
                disabled={isLoading || isSubmitting || readOnly}
                color="primary"
                onClick={() => handleSaveClick(handleSubmit)}
                type="submit"
                variant="contained"
              >
                Save
              </Button>
            </Box>
            {(isLoading || isSubmitting) && <LinearProgress />}
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default DeliveryServicesSupplies;
