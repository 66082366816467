import { useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link } from 'react-router-dom';
import {
  Box,
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import EnhancedTableHead from 'src/components/common/EnhancedTableHead';
import facilityTypes from 'src/constants/facilityTypes';

const status = [
  'New',
  'For Review',
  'Under Review',
  'Reviewed'
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => (
  order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
);

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const AssessmentListResults = ({
  isLoading, assessments, page, setPage, ...rest
}) => {
  const classes = useStyles();
  const [limit, setLimit] = useState(50);
  const [selected, setSelected] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const numOfResultsOnPage = Math.min(limit, assessments.length - page * limit);

  const headCells = [
    {
      id: 'facility-code',
      numeric: false,
      label: 'Health Facility Code',
      width: '20%'
    },
    {
      id: 'gis-code',
      numeric: false,
      label: 'GIS Code',
    },
    {
      id: 'name',
      numeric: false,
      label: 'Health Facility Name',
      width: '20%'
    },
    {
      id: 'type',
      label: 'Facility Type',
      width: '20%'
    },
    {
      id: 'date',
      numeric: false,
      label: 'Assessment Date',
      width: '20%'
    },
    {
      id: 'status',
      numeric: false,
      label: 'Status',
      width: '10%'
    }
  ];

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={assessments.length}
              headCells={headCells}
            />
            <TableBody>
              {stableSort(assessments, getComparator(order, orderBy))
                .slice(page * limit, page * limit + limit)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `assessments-table-${index}`;
                  const facilityType = facilityTypes.find((_) => _.value === row.facility.type);
                  const date = new Date(row.created);

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell id={labelId}>
                        <Link to={`/assessments/${row.id}`}>
                          {row.facility.facility_code}
                        </Link>
                      </TableCell>
                      <TableCell>
                        {row.facility.gis_code}
                      </TableCell>
                      <TableCell>
                        {row.facility.name}
                      </TableCell>
                      <TableCell>
                        {facilityType.name}
                      </TableCell>
                      <TableCell>
                        {date.toDateString()}
                      </TableCell>
                      <TableCell>
                        {status[row.status - 1]}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {numOfResultsOnPage < 10 && (
                <TableRow style={{ height: 50 * (10 - numOfResultsOnPage) }}>
                  <TableCell colSpan={6}>
                    {isLoading && (
                      <Box className="spinner-container">
                        <CircularProgress />
                      </Box>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={assessments.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[50, 100, 200]}
      />
    </Card>
  );
};

AssessmentListResults.propTypes = {
  assessments: PropTypes.array.isRequired
};

export default AssessmentListResults;
