import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography
} from '@mui/material';
import {
  Edit as EditIcon,
  Save as SaveIcon
} from '@mui/icons-material';
import facilityTypes from 'src/constants/facilityTypes';
import headOfFacilityTypes from 'src/constants/headOfFacilityTypes';
import managingAuthorityTypes from 'src/constants/managingAuthorityTypes';
import { fetchAssessments } from 'src/actions/assessment';
import YesOrNoFormControl from '../assessment/formSections/YesOrNoFormControl';

const FacilityForm = (props) => {
  const {
    barangays,
    facility,
    assessments,
    isLoading,
    isSubmitting,
    readOnly,
    setReadOnly,
    fetchAssessment,
  } = props;

  useEffect(() => {
    if (fetchAssessment && facility) props.fetchAssessments(undefined, undefined, facility.id);
  }, []);

  const facilityAssessment = assessments[0];

  const initialValues = {
    name: '',
    facility_code: '',
    gis_code: '',
    municipality: facility && facility.barangay && facility.barangay.city_or_municipality ? facility.barangay.city_or_municipality.name : '',
    province: facility && facility.barangay && facility.barangay.province ? facility.barangay.province.name : '',
    region: facility && facility.barangay && facility.barangay.region ? facility.barangay.region.name : '',
    barangay: null,
    projected_population: '',
    actual_population: '',
    type: '',
    facility_head_name: '',
    managing_authority_type: '',
    managing_authority_type_other: '',
    facility_head_position_type: '',
    facility_head_position_type_other: '',
    ...facility
  };

  const handleEditClick = () => {
    setReadOnly(false);
  };

  const handleSaveClick = (handleSubmit) => {
    handleSubmit();
    setReadOnly(true);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        type: Yup.string().required('Facility type cannot be blank'),
        name: Yup.string().required('Facility name cannot be blank'),
        facility_code: Yup.string().required('Facility code is required'),
        gis_code: Yup.string().required('GIS Code cannot be blank'),
        actual_population: Yup.number().required('Actual population is required'),
        projected_population: Yup.number().required('Projected population is required'),
        managing_authority_type: Yup.number().required('Managing authority type cannot be blank'),
        facility_head_position_type: Yup.number().required('Facility head position type cannot be blank')
      })}
      onSubmit={(values) => {
        const form = {
          ...values,
          barangay: values.barangay ? values.barangay.id : ''
        };
        props.onSubmit(form);
      }}
      enableReinitialize
    >
      {({
        errors,
        handleChange,
        handleSubmit,
        setFieldValue,
        touched,
        values
      }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
        >
          <Card>
            <CardContent>
              <Box className="form-header">
                <Typography variant="h4" gutterBottom>
                  Facility Details
                </Typography>
                {readOnly ? (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleEditClick()}
                  >
                    <EditIcon size="medium" />
                  </IconButton>
                ) : (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleSaveClick(handleSubmit)}
                  >
                    <SaveIcon size="medium" />
                  </IconButton>
                )}
              </Box>
              {fetchAssessment && facilityAssessment && (
                <Link to={`/assessments/${facilityAssessment.id}`}>
                  <Typography variant="body2">
                    Go to assessment &gt;
                  </Typography>
                </Link>
              )}
            </CardContent>
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    error={Boolean(touched.type && errors.type)}
                  >
                    <InputLabel id="type-label">
                      Facility Type
                    </InputLabel>
                    <Select
                      disabled={isSubmitting || readOnly}
                      label="Facility Type"
                      labelId="type-label"
                      name="type"
                      onChange={handleChange}
                      value={values.type}
                      required
                    >
                      {facilityTypes.map((ft) => (
                        <MenuItem value={ft.value} key={ft.value}>
                          {ft.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{touched.type && errors.type}</FormHelperText>
                  </FormControl>
                  <YesOrNoFormControl
                    disabled={isSubmitting || readOnly}
                    label="Is this an auxiliary facility?"
                    fieldName="is_auxiliary_facility"
                    fieldValue={values.is_auxiliary_facility}
                    setFieldValue={setFieldValue}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    disabled={isSubmitting || readOnly}
                    fullWidth
                    label="Facility Code"
                    name="facility_code"
                    onChange={handleChange}
                    value={values.facility_code}
                    required
                    error={Boolean(touched.facility_code && errors.facility_code)}
                    helperText={touched.facility_code && errors.facility_code}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    disabled={isSubmitting || readOnly}
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    label="Facility Name"
                    name="name"
                    onChange={handleChange}
                    required
                    value={values.name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    disabled={isSubmitting || readOnly}
                    fullWidth
                    label="GIS Code"
                    name="gis_code"
                    onChange={handleChange}
                    value={values.gis_code}
                    required
                    error={Boolean(touched.gis_code && errors.gis_code)}
                    helperText={touched.gis_code && errors.gis_code}
                  />
                </Grid>
                <Grid item xs={12} padding="none">
                  <InputLabel id="population">
                    Facility Location
                  </InputLabel>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    disabled
                    fullWidth
                    label="Region"
                    name="region"
                    onChange={handleChange}
                    value={values.region}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    disabled
                    fullWidth
                    label="Province"
                    name="province"
                    onChange={handleChange}
                    value={values.province}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    disabled
                    fullWidth
                    label="City or Municipality"
                    name="municipality"
                    onChange={handleChange}
                    value={values.municipality}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    name="barangay"
                    options={barangays}
                    value={values.barangay}
                    onChange={(event, newValue) => {
                      setFieldValue('barangay', newValue, false);
                      setFieldValue('region', newValue.region.name, false);
                      setFieldValue('province', newValue.province.name, false);
                      setFieldValue('municipality', newValue.city_or_municipality.name, false);
                    }}
                    getOptionLabel={(option) => `${option.name} (${option.city_or_municipality.name})`}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Barangay"
                      />
                    )}
                    disableClearable
                    disabled={isSubmitting || readOnly}
                  />
                </Grid>
                <Grid item xs={12} padding="none">
                  <InputLabel id="population">
                    Population Coverage of Facility
                  </InputLabel>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    disabled={isSubmitting || readOnly}
                    fullWidth
                    label="Projected Population"
                    name="projected_population"
                    onChange={handleChange}
                    value={values.projected_population}
                    required
                    error={Boolean(touched.projected_population && errors.projected_population)}
                    helperText={touched.projected_population && errors.projected_population}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    disabled={isSubmitting || readOnly}
                    fullWidth
                    label="Actual Population"
                    name="actual_population"
                    onChange={handleChange}
                    value={values.actual_population}
                    required
                    error={Boolean(touched.actual_population && errors.actual_population)}
                    helperText={touched.actual_population && errors.actual_population}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    component="fieldset"
                    error={Boolean(touched.managing_authority_type && errors.managing_authority_type)}
                  >
                    <FormLabel component="legend">Managing Authority</FormLabel>
                    <RadioGroup
                      aria-label="managing authority"
                      name="managing_authority_type"
                      onChange={(e, value) => (
                        setFieldValue('managing_authority_type', parseInt(value, 10), false)
                      )}
                      value={values.managing_authority_type}
                      className="radio-group"
                      required
                    >
                      {managingAuthorityTypes.map((mat) => (
                        <FormControlLabel
                          className="radio-label"
                          control={<Radio size="small" />}
                          disabled={isSubmitting || readOnly}
                          key={mat.value}
                          label={mat.name}
                          value={mat.value}
                        />
                      ))}
                      <Box>
                        <FormControlLabel
                          control={<Radio size="small" />}
                          disabled={isSubmitting || readOnly}
                          label="Other:"
                          value={4}
                        />
                        <TextField
                          disabled={isSubmitting || readOnly}
                          name="managing_authority_type_other"
                          onChange={handleChange}
                          variant="standard"
                          value={values.managing_authority_type_other}
                        />
                      </Box>
                    </RadioGroup>
                    <FormHelperText>{touched.managing_authority_type && errors.managing_authority_type}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    component="fieldset"
                    error={Boolean(touched.facility_head_position_type && errors.facility_head_position_type)}
                  >
                    <FormLabel component="legend">Head of Facility</FormLabel>
                    <RadioGroup
                      aria-label="head of facility"
                      name="facility_head_position_type"
                      onChange={(e, value) => (
                        setFieldValue('facility_head_position_type', parseInt(value, 10), false)
                      )}
                      value={values.facility_head_position_type}
                      className="radio-group"
                    >
                      {headOfFacilityTypes.map((hoft) => (
                        <FormControlLabel
                          control={<Radio size="small" />}
                          disabled={isSubmitting || readOnly}
                          key={hoft.value}
                          label={hoft.name}
                          value={hoft.value}
                        />
                      ))}
                      <Box>
                        <FormControlLabel
                          control={<Radio size="small" />}
                          disabled={isSubmitting || readOnly}
                          label="Other:"
                          value={4}
                        />
                        <TextField
                          disabled={isSubmitting || readOnly}
                          name="facility_head_position_type_other"
                          onChange={handleChange}
                          variant="standard"
                          value={values.facility_head_position_type_other}
                        />
                      </Box>
                      <FormHelperText>{touched.facility_head_position_type && errors.facility_head_position_type}</FormHelperText>
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    disabled={isSubmitting || readOnly}
                    fullWidth
                    label="Name of Head of the Facility"
                    name="facility_head_name"
                    onChange={handleChange}
                    value={values.facility_head_name}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box className="box-button">
              <Button
                disabled={isLoading || isSubmitting || !readOnly}
                color="primary"
                onClick={() => handleEditClick()}
                variant="contained"
                sx={{ mx: 2 }}
              >
                Edit
              </Button>
              <Button
                disabled={isLoading || isSubmitting || readOnly}
                color="primary"
                onClick={() => handleSaveClick(handleSubmit)}
                type="submit"
                variant="contained"
              >
                Save
              </Button>
            </Box>
            {(isLoading || isSubmitting) && <LinearProgress />}
          </Card>
        </form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state) => ({
  assessments: Object.values(state.assessments),
});

export default connect(mapStateToProps, {
  fetchAssessments,
})(FacilityForm);
