import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import {
  Close as CloseIcon
} from '@mui/icons-material';

const FacilityListReportModal = (props) => {
  const {
    facilities,
    hasCount,
    loading,
    open,
    state,
    setOpen
  } = props;

  const handleClose = () => {
    setOpen(false);
  };

  const renderTable = (facilities) => {
    if (facilities.length > 0) {
      return (
        facilities.map((facility, idx) => (
          <TableRow hover key={facility.id}>
            <TableCell padding="checkbox" align="right">
              {idx + 1}
            </TableCell>
            <TableCell>
              <Link to={`/assessments/${facility.assessment_id}`} target="_blank" rel="noopener noreferrer">
                {facility.name}
              </Link>
            </TableCell>
            <TableCell>{facility.city_muni_name}</TableCell>
            <TableCell>{facility.barangay_name}</TableCell>
            {hasCount && (
              <TableCell align="right">
                {facility.value || '0'}
              </TableCell>
            )}
          </TableRow>
        ))
      );
    }
    return (
      <TableRow>
        <TableCell align="center" colSpan={hasCount ? 5 : 4}>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ p: 2 }}
          >
            Sorry! There are no answers for this field right now. Check again later.
          </Typography>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
      >
        <DialogTitle sx={{ p: 1 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ p: 2 }}>
              <Typography variant="h4" gutterBottom>
                {state.title}
              </Typography>
            </Box>
            <IconButton
              onClick={() => handleClose()}
              sx={{
                alignItems: 'flex-start',
                display: 'inline-flex',
                height: '100%'
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ paddingBottom: 3 }}>
            <Typography variant="body1">
              {'Answer: '}
              <span className="emphasized">{state.readableValue}</span>
            </Typography>
            <Typography variant="body1">
              {'Field: '}
              <span className="emphasized">{state.title}</span>
            </Typography>
            <Typography variant="body1">
              {'Province: '}
              <span className="emphasized">{state.province.name}</span>
            </Typography>
          </Box>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell width="5%" padding="checkbox" />
                  <TableCell width={hasCount ? '23%' : '31%'}>
                    Facilities
                  </TableCell>
                  <TableCell width={hasCount ? '23%' : '31%'}>
                    City / Municipality
                  </TableCell>
                  <TableCell width={hasCount ? '23%' : '31%'}>
                    Barangay
                  </TableCell>
                  {hasCount && (
                    <TableCell
                      align="right"
                      width="23%"
                    >
                      Count
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                {loading ? (
                  <TableRow>
                    <TableCell
                      colSpan={hasCount ? 5 : 4}
                      align="center"
                      sx={{ p: 2 }}
                    >
                      <CircularProgress size={30} />
                    </TableCell>
                  </TableRow>
                ) : renderTable(facilities)}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default memo(FacilityListReportModal);
