import React from 'react';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  LinearProgress,
  Typography
} from '@mui/material';
import {
  Edit as EditIcon,
  Save as SaveIcon
} from '@mui/icons-material';
import { INFIRMARY, BARANGAY_HEALTH_STATION } from 'src/constants/facilityTypes';
import AvailabilityFunctioningFormGroup from '../../formSections/AvailabilityFunctioningFormGroup';

const AntenatalCareEquipment = (props) => {
  const {
    facilityType,
    formValues,
    isLoading,
    isSubmitting,
    readOnly,
    setReadOnly
  } = props;

  const handleEditClick = () => {
    setReadOnly(false);
  };

  const handleSaveClick = (handleSubmit) => {
    handleSubmit();
    setReadOnly(true);
  };

  const initialValues = {
    non_mercury_sphygmomanometer_available: null,
    non_mercury_sphygmomanometer_functioning: null,
    stethoscope_available: null,
    stethoscope_functioning: null,
    adult_weighing_scale_available: null,
    adult_weighing_scale_functioning: null,
    tape_measure_available: null,
    tape_measure_functioning: null,
    fetal_doppler_available: null,
    fetal_doppler_functioning: null,
    examination_table_available: null,
    examination_table_functioning: null,
    ultrasound_machine_available: null,
    ultrasound_machine_functioning: null,
    digital_thermometer_available: null,
    digital_thermometer_functioning: null,
    ...formValues
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => {
        props.onSubmit('antenatal_care_equipment', values);
      }}
    >
      {({
        handleSubmit,
        setFieldValue,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardContent>
              <Box className="form-header">
                <Typography variant="h4" gutterBottom>
                  Antenatal Care
                </Typography>
                {readOnly ? (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleEditClick()}
                  >
                    <EditIcon size="medium" />
                  </IconButton>
                ) : (
                  <IconButton
                    disabled={isLoading || isSubmitting}
                    onClick={() => handleSaveClick(handleSubmit)}
                  >
                    <SaveIcon size="medium" />
                  </IconButton>
                )}
              </Box>
            </CardContent>
            <Divider />
            <CardContent>
              <Box sx={{ p: 2 }}>
                <AvailabilityFunctioningFormGroup
                  disabled={readOnly}
                  label="Non-mercury Sphygmomanometer"
                  fieldName="non_mercury_sphygmomanometer_available"
                  fieldValue={values.non_mercury_sphygmomanometer_available}
                  fieldFunctioningName="non_mercury_sphygmomanometer_functioning"
                  fieldFunctioningValue={values.non_mercury_sphygmomanometer_functioning}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityFunctioningFormGroup
                  disabled={readOnly}
                  label="Stethoscope"
                  fieldName="stethoscope_available"
                  fieldValue={values.stethoscope_available}
                  fieldFunctioningName="stethoscope_functioning"
                  fieldFunctioningValue={values.stethoscope_functioning}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityFunctioningFormGroup
                  disabled={readOnly}
                  label="Adult Weighing Scale"
                  fieldName="adult_weighing_scale_available"
                  fieldValue={values.adult_weighing_scale_available}
                  fieldFunctioningName="adult_weighing_scale_functioning"
                  fieldFunctioningValue={values.adult_weighing_scale_functioning}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityFunctioningFormGroup
                  disabled={readOnly}
                  label="Tape measure"
                  fieldName="tape_measure_available"
                  fieldValue={values.tape_measure_available}
                  fieldFunctioningName="tape_measure_functioning"
                  fieldFunctioningValue={values.tape_measure_functioning}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityFunctioningFormGroup
                  disabled={readOnly}
                  label="Fetal Doppler"
                  fieldName="fetal_doppler_available"
                  fieldValue={values.fetal_doppler_available}
                  fieldFunctioningName="fetal_doppler_functioning"
                  fieldFunctioningValue={values.fetal_doppler_functioning}
                  setFieldValue={setFieldValue}
                />
                <AvailabilityFunctioningFormGroup
                  disabled={readOnly}
                  label="Examination table with or without stirrups"
                  fieldName="examination_table_available"
                  fieldValue={values.examination_table_available}
                  fieldFunctioningName="examination_table_functioning"
                  fieldFunctioningValue={values.examination_table_functioning}
                  setFieldValue={setFieldValue}
                />
                {facilityType !== BARANGAY_HEALTH_STATION && (
                  <AvailabilityFunctioningFormGroup
                    disabled={readOnly}
                    label="Ultrasound machine"
                    fieldName="ultrasound_machine_available"
                    fieldValue={values.ultrasound_machine_available}
                    fieldFunctioningName="ultrasound_machine_functioning"
                    fieldFunctioningValue={values.ultrasound_machine_functioning}
                    setFieldValue={setFieldValue}
                  />
                )}
                {facilityType === INFIRMARY && (
                  <AvailabilityFunctioningFormGroup
                    disabled={readOnly}
                    label="Digital thermometer"
                    fieldName="digital_thermometer_available"
                    fieldValue={values.digital_thermometer_available}
                    fieldFunctioningName="digital_thermometer_functioning"
                    fieldFunctioningValue={values.digital_thermometer_functioning}
                    setFieldValue={setFieldValue}
                  />
                )}
              </Box>
            </CardContent>
            <Divider />
            <Box className="box-button">
              <Button
                disabled={isLoading || isSubmitting || !readOnly}
                color="primary"
                onClick={() => handleEditClick()}
                variant="contained"
                sx={{ mx: 2 }}
              >
                Edit
              </Button>
              <Button
                disabled={isLoading || isSubmitting || readOnly}
                color="primary"
                onClick={() => handleSaveClick(handleSubmit)}
                type="submit"
                variant="contained"
              >
                Save
              </Button>
            </Box>
            {(isLoading || isSubmitting) && <LinearProgress />}
          </Card>
        </form>
      )}

    </Formik>
  );
};

export default AntenatalCareEquipment;
