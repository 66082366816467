import React from 'react';
import {
  Box,
  TableContainer,
} from '@mui/material/';
import {
  bhw,
  schedule,
  services,
  guidelines,
  trainings,
  examinations,
  records,
} from 'src/constants/fields/section4/antenatalCare';
import YesNoTable from '../tables/YesNoTable';
import ScheduleTable from '../tables/ScheduleTable';
import CountTable from '../tables/CountTable';
import ObservationTable from '../tables/ObservationTable';

const scheduleHeaders = [
  'Everyday',
  'Once a week',
  'Once a month',
  'Other'
];

const Section401 = (props) => {
  const { provinces, data } = props;

  return (
    <TableContainer>
      <YesNoTable
        ariaLabel="4.1 antenatal care services"
        provinces={provinces}
        rows={services}
        response={data}
        title="Services available as part of Antenatal Care"
        sectionKey="antenatal_care"
      />
      <Box sx={{ height: 40 }} />
      <ScheduleTable
        ariaLabel="4.1 antenatal care schedule"
        field={schedule}
        headers={scheduleHeaders}
        provinces={provinces}
        response={data}
        sectionKey="antenatal_care"
      />
      <Box sx={{ height: 40 }} />
      <YesNoTable
        ariaLabel="4.1 antenatal care guidelines"
        provinces={provinces}
        rows={guidelines}
        response={data}
        title="National guidelines, job-aids, checklist, charts on antenatal care"
        sectionKey="antenatal_care"
      />
      <Box sx={{ height: 40 }} />
      <YesNoTable
        ariaLabel="4.1 antenatal care trainings"
        provinces={provinces}
        rows={trainings}
        response={data}
        title="Health workers assigned that been trained within the past 3 years"
        sectionKey="antenatal_care"
      />
      <Box sx={{ height: 40 }} />
      <CountTable
        ariaLabel="4.1 antenatal care trained bhw count"
        provinces={provinces}
        rows={bhw}
        response={data}
        title="Number of BHWs trained on the following"
        sectionKey="antenatal_care"
      />
      <Box sx={{ height: 40 }} />
      <YesNoTable
        ariaLabel="4.1 antenatal care examinations"
        provinces={provinces}
        rows={examinations}
        response={data}
        title="Diagnostic examinations as part of Antenatal Care"
        sectionKey="antenatal_care"
      />
      <Box sx={{ height: 40 }} />
      <ObservationTable
        ariaLabel="4.1 antenatal care records"
        provinces={provinces}
        rows={records}
        response={data}
        sectionKey="antenatal_care"
      />
    </TableContainer>
  );
};

export default Section401;
